<template>
  <div class="custom-selectbox">
    <VueSelect
      v-model="selectedOption"
      :reduce="(option: any) => option.handle"
      :options="options"
      :placeholder="placeholder"
      :class="{ disabled: disabled }"
      :disabled="disabled"
      :filter-by="filter"
      :style="cssVars"
    >
      <template #option="option">
        <slot name="custom_option" :option="option">
          {{ option.label }}
        </slot>
      </template>
      <template #list-footer>
        <slot name="custom_list-footer" />
      </template>
    </VueSelect>
  </div>
</template>

<script lang="ts">
import VueSelect from 'vue-select'
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'CustomSelect',
  components: {
    VueSelect,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
      required: true,
    },
    selected: {
      type: String,
      default: '',
      required: false,
    },
    placeholder: {
      type: String,
      default: '',
      required: false,
    },
    height: {
      type: String,
      default: '200px',
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    filter: {
      type: Function,
      default: (option: any, _: any, search: string) => {
        const temp = search.toLowerCase()
        return option.label.toLowerCase().indexOf(temp) > -1
      },
    },
  },
  emits: ['selected'],
  setup(props, { emit }) {
    const selectedOption = computed({
      get: () => props.selected,
      set: (val) => {
        emit('selected', val)
      },
    })
    const cssVars = {
      '--max-height': props.height,
    }
    return {
      cssVars,
      selectedOption,
    }
  },
})
</script>

<style lang="scss">
@import '@/assets/scss/variables';
.custom-selectbox {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.vs__selected-options {
  padding: 0;
}
.vs__search {
  opacity: 0.75;
}
.vs__selected,
.vs__search,
.vs__search:focus {
  padding: 0;
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  color: #495057;
}
.vs__actions {
  display: none;
}
.vs__dropdown-toggle {
  min-width: 0;
  margin-bottom: 0;
  height: 50px;
  padding: 13px 24px;
  border-radius: $border-radius;
}
.vs__dropdown-menu {
  max-height: var(--max-height);
}
</style>
