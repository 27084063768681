<template>
  <BaseLabel :id="labelId" :short-text="shortText" :tooltip-text="tooltipText" :class-name="className" />
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { getDistributionChannelTxt } from '@/services/Utils'
import { BOX, LETTER, PARCEL } from '@/const/DistributionChannels'
import BaseLabel from '@/views/Components/Labels/BaseLabel.vue'
import type { ListAssignment } from '@/types/Models/Assignment'

export default defineComponent({
  name: 'AssignmentDistributionChannel',
  components: { BaseLabel },
  props: {
    assignment: {
      type: Object as () => ListAssignment,
      required: true,
    },
  },
  setup(props) {
    const labelId = computed(() => `assignment-distribution-channel-${props.assignment.id}`)
    const shortText = computed(() => {
      switch (props.assignment.distributionChannel) {
        case PARCEL:
          return 'PR'
        case BOX:
          return 'LK'
        case LETTER:
          return 'MW'
        default:
          return 'PK'
      }
    })
    const tooltipText = computed(() => getDistributionChannelTxt(props.assignment.distributionChannel))
    const className = computed(() => {
      switch (props.assignment.distributionChannel) {
        case PARCEL:
          return 'distribution-parcel'
        case BOX:
          return 'distribution-box'
        case LETTER:
          return 'distribution-letter'
        default:
          return 'distribution-package'
      }
    })

    return {
      labelId,
      shortText,
      tooltipText,
      className,
    }
  },
})
</script>
