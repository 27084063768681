<template>
  <b-dropdown :text="text" class="custom-dropdown">
    <div v-if="withSearch" class="p-2">
      <b-input placeholder="Search..." @input="filter" />
    </div>
    <b-dropdown-item
      v-for="(option, index) in optionsFiltered"
      :key="'option_' + index + option.value"
      @click="() => selectOption(option)"
    >
      {{ option.text }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import type { SelectOptions } from '@/types/Components/SelectOptions'

export default defineComponent({
  name: 'Dropdown',
  props: {
    options: {
      type: Array as () => SelectOptions[],
      default: () => [],
    },
    selectedOption: {
      type: String,
      default: null,
    },
    dropdownText: {
      type: String,
      default: '',
    },
    withSearch: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['selected'],
  setup(props, { emit }) {
    const selected = ref(props.selectedOption)
    const text = ref(props.dropdownText)
    const optionsFiltered = ref<SelectOptions[]>(props.options)

    const selectOption = (option: SelectOptions) => {
      selected.value = option.value
      text.value = option.text
      emit('selected', option)
    }

    const filter = (event: Event) => {
      const target = event.target as HTMLInputElement
      const searchText = target.value.toLowerCase()
      optionsFiltered.value = props.options.filter((option) => option.text.toLowerCase().includes(searchText))
    }

    return {
      text,
      optionsFiltered,
      selectOption,
      filter,
    }
  },
})
</script>

<style lang="scss" scoped>
.custom-dropdown {
  &.show {
    &:deep(.dropdown-toggle) {
      background-color: #ffffff;
    }
  }
  &.border-0 {
    &:deep(.dropdown-toggle) {
      border: none !important;
    }
  }
  &:deep(.dropdown-menu) {
    transform: translate3d(0, 45px, 0) !important;
    z-index: 3;
    max-height: 400px;
    overflow-y: auto;
  }
  &:deep(.dropdown-toggle) {
    width: 172px;
    height: 40px;
    font-size: 14px;
    color: #757575;
    border-color: #c8c8c8;
    padding-left: 15px;
    text-align: left;
    line-height: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    background: #ffffff url(@/assets/img/icon-select.svg) right 14px top 14px/12px 12px no-repeat !important;
    &:hover,
    &:focus,
    &:active {
      background-color: unset;
      border-color: #c8c8c8;
      color: #757575;
    }
    &::after {
      display: none;
    }
  }
}
</style>
