import { CUSTOMER, STAFF } from '@/const/AccountTypes'
import {
  CANCELLED,
  COMPLETE,
  IN_REVIEW,
  LABELS_CREATING,
  LABELS_RELEASED,
  OPEN,
  OPTIMISING,
  PENDING,
  REPORT_ACCEPTED,
  REPORT_DENIED,
  REPORT_RELEASED,
  VALIDATING,
  VALIDATION_COMPLETE,
} from '@/const/AssignmentStatuses'
import type { AccountType } from '@/types/Models/Account'
import type { StatusType } from '@/types/Models/Assignment'

type StatusColorMap = {
  [key in AccountType]: {
    [key in StatusType]: string
  }
}
export const StatusColor: StatusColorMap = {
  [STAFF]: {
    [OPEN]: '#C8C8C8',
    [PENDING]: '#C8C8C8',
    [COMPLETE]: '#08A762',
    [CANCELLED]: '#FF5252',
    [VALIDATING]: '#C8C8C8',
    [VALIDATION_COMPLETE]: '#151515',
    [IN_REVIEW]: '#FF5252',
    [OPTIMISING]: '#C8C8C8',
    [REPORT_RELEASED]: '#C8C8C8',
    [REPORT_ACCEPTED]: '#058880',
    [REPORT_DENIED]: '#FF5252',
    [LABELS_CREATING]: '#C8C8C8',
    [LABELS_RELEASED]: '#08A762',
  },
  [CUSTOMER]: {
    [OPEN]: '#C8C8C8',
    [PENDING]: '#C8C8C8',
    [COMPLETE]: '#08A762',
    [CANCELLED]: '#FF5252',
    [VALIDATING]: '#C8C8C8',
    [VALIDATION_COMPLETE]: '#151515',
    [IN_REVIEW]: '#C8C8C8',
    [OPTIMISING]: '#C8C8C8',
    [REPORT_RELEASED]: '#FFCA10',
    [REPORT_ACCEPTED]: '#FFCA10',
    [REPORT_DENIED]: '#FF5252',
    [LABELS_CREATING]: '#C8C8C8',
    [LABELS_RELEASED]: '#08A762',
  },
}
