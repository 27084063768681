export const PROCESS_ASSIGNMENT = 'processAssignment'
export const VIEW_ASSIGNMENT = 'viewAssignment'
export const DOWNLOAD_SOURCE = 'downloadSource'
export const ARCHIVE_ASSIGNMENT = 'archiveAssignment'
export const UNARCHIVE_ASSIGNMENT = 'unarchiveAssignment'
export const VIEW_REPORT = 'viewReport'
export const DOWNLOAD_REPORT = 'downloadReport'
export const DOWNLOAD_LABELS = 'downloadLabels'
export const DOWNLOAD_DOCUMENTS = 'downloadDocuments'
export const VIEW_SHIPMENTS = 'viewShipments'
