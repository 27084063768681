<template>
  <div class="d-flex">
    <b-icon icon="circle-fill" scale="1.3" :style="circleStyle" />
    <div class="pl-2 ptop">
      {{ statusName }}
    </div>
  </div>
</template>

<script lang="ts">
import type { ListAssignment } from '@/types/Models/Assignment'
import { computed, defineComponent } from 'vue'
import BIcon from '@/views/Components/Elements/Icons/BIcon.vue'
import { useAssignmentStatus } from '@/composables/assignment/useAssignmentStatus'

export default defineComponent({
  name: 'StatusCell',
  components: { BIcon },
  props: {
    assignment: {
      type: Object as () => ListAssignment,
      required: true,
    },
  },
  setup(props) {
    const assignmentRef = computed(() => props.assignment)

    const { statusName, circleStyle } = useAssignmentStatus({
      assignment: assignmentRef,
    })

    return {
      statusName,
      circleStyle,
    }
  },
})
</script>

<style scoped>
.ptop {
  padding: 2px 0 0 8px;
}
</style>
