<template>
  <div v-if="carrier" class="d-flex align-items-center p-0 carrier-icon-wrap">
    <img :src="logoHandle" alt="carrier" />
    <span v-if="!onlyIcon" class="tooltip-text ml-2">
      {{ carrierHandler2Name(carrier) }}
    </span>
    <span v-if="suffix" class="tooltip-text ml-2">
      {{ suffix }}
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { BRING, UPS, CARRIERS } from '@/const/Carriers'
import { carrierHandler2Name } from '@/services/Helpers'
import type { KeyAccessor } from '@/types/Helpers/Arrays'

export default defineComponent({
  name: 'CarrierRowWithIcon',
  props: {
    carrier: {
      type: String,
      default: '',
      validator: (value: string) => Object.values(CARRIERS).indexOf(value) !== -1,
    },
    onlyIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    suffix: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const logoHandle = computed(() => {
      const map: KeyAccessor = {
        [BRING]: 'carriers/bring.png',
        [UPS]: 'carriers/ups.png',
      }

      const logo = map[props.carrier]
      const baseURL = import.meta.env.BASE_URL.endsWith('/')
        ? import.meta.env.BASE_URL.slice(0, -1)
        : import.meta.env.BASE_URL
      return `${baseURL}/assets/img/${logo ? logo : `${props.carrier}-logo.svg`}`
    })

    return {
      logoHandle,
      carrierHandler2Name,
    }
  },
})
</script>

<style lang="scss">
.carrier-icon-wrap {
  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
}
</style>
