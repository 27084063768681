<template>
  <div>
    <div class="child-row__title pb-4">Messages</div>

    <Spinner v-if="isLoading" class="mb-3"> Loading messages </Spinner>
    <div v-else>
      <table class="w-100">
        <thead>
          <tr>
            <th>Time</th>
            <th>Recipient</th>
            <th>Email subject / SMS text</th>
          </tr>
        </thead>
        <tbody>
          <Message v-for="message in messages" :key="message.id" :message="message" />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, type PropType, type Ref } from 'vue'
import { PENDING, LOADING, SUCCESS, FAILURE } from '@/const/LoadingStatus'
import ProxyLogProxy from '@/proxies/ProxyLogProxy'
import Message from '@/views/Components/Shipments/Messages/Message.vue'
import Spinner from '@/views/Components/Loaders/Spinner.vue'
import type { Shipment, Message as ShipmentMessage } from '@/types/Models/Shipment'

export default defineComponent({
  name: 'ShipmentMessages',
  components: { Spinner, Message },
  props: {
    shipment: {
      type: Object as PropType<Shipment>,
      required: true,
    },
  },
  setup(props) {
    const messages: Ref<ShipmentMessage[]> = ref([])
    const loadingStatus: Ref<string> = ref(PENDING)

    const isLoading = computed(() => [PENDING, LOADING].includes(loadingStatus.value))

    const fetchMessages = async () => {
      loadingStatus.value = LOADING
      try {
        const { data } = await new ProxyLogProxy().find(props.shipment.id)
        messages.value = data
        loadingStatus.value = SUCCESS
      } catch {
        loadingStatus.value = FAILURE
      }
    }

    onMounted(fetchMessages)

    return {
      messages,
      isLoading,
    }
  },
})
</script>
