<template>
  <b-overlay class="loader" no-wrap fixed :show="isShow">
    <template #overlay>
      <div class="center">
        <div>
          <b-spinner label="Spinning"></b-spinner>
        </div>
        <div class="pt-5">Loading process. It may take a few seconds.</div>
      </div>
    </template>
  </b-overlay>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { DEFAULT_LOADER } from '@/const/LoadersNames'

export default defineComponent({
  name: 'LoaderDefault',
  setup() {
    const store = useStore()

    const loader = computed(() => store.getters['ui/loader'])
    const isShow = computed(() => loader.value.name === DEFAULT_LOADER && loader.value.show)

    return {
      isShow,
    }
  },
})
</script>

<style scoped>
.center {
  text-align: center;
}
</style>
