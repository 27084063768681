import { BaseProxy, type ProxyMethods } from '@/proxies/Proxy'

export default class SenderAddressProxy extends BaseProxy implements ProxyMethods {
  public endpoint = ''

  constructor(userId: string, parameters: object = {}) {
    super(parameters)
    this.endpoint = `users/${userId}/sender-addresses`
  }

  getSenderAddress(addressId: string) {
    return this.submit('get', `/${this.endpoint}/${addressId}`)
  }

  setDefaultSenderAddress(addressId: string) {
    return this.submit('patch', `/${this.endpoint}/${addressId}/default`)
  }
}
