<template>
  <BModal v-bind="$attrs" :id="id" v-model="modalOpen" class="modal-info" :hide-footer="hideFooter">
    <template #title>
      <slot :data="data" name="modal-title" />
    </template>
    <slot :data="data" />
    <template #footer>
      <slot :data="data" :close-modal="closeModal" name="modal-footer" />
    </template>
  </BModal>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'ModalWrap',
  props: {
    name: {
      type: String,
      required: true,
    },
    hideFooter: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const modalOpen = ref(false)
    const store = useStore()
    const data = computed(() => {
      return store.state.vueNextModal.modalProps || {}
    })

    const closeModal = () => {
      store.dispatch('vueNextModal/closeModal', props.name)
    }

    return { data, modalOpen, closeModal }
  },
})
</script>
