<template>
  <QueryStringDropdown :options="options" :with-filter="false" :query-param="DISTRIBUTION_CHANNEL" />
</template>

<script lang="ts">
import { computed, defineComponent, type Ref } from 'vue'
import { BOX, LETTER, PACKAGE, PARCEL } from '@/const/DistributionChannels'
import { DISTRIBUTION_CHANNEL } from '@/const/AssignmentListQueryFilters'
import { type Store, useStore } from 'vuex'
import QueryStringDropdown from '@/views/Components/PageParts/QueryStringDropdown.vue'
import type { SelectOptions } from '@/types/Components/SelectOptions'
import type { RootStoreState } from '@/types/Store'

export default defineComponent({
  name: 'DistributionChannelFilter',
  components: { QueryStringDropdown },
  setup() {
    const store: Store<RootStoreState> = useStore()
    const isStaff: Ref<boolean> = computed(() => store.getters['account/isStaff'])
    const options: SelectOptions[] = isStaff.value
      ? [
          { value: '', text: 'Distribution channel' },
          { value: PACKAGE, text: 'Packet' },
          { value: PARCEL, text: 'Parcel' },
          { value: BOX, text: 'Parcel Locker' },
          { value: LETTER, text: 'Letter' },
        ]
      : [
          { value: '', text: 'Distribution channel' },
          { value: PACKAGE, text: 'Packet' },
          { value: PARCEL, text: 'Parcel' },
        ]

    return {
      DISTRIBUTION_CHANNEL,
      options,
    }
  },
})
</script>
