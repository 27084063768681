<template>
  <modal-wrap
    ref="downloadModal"
    :name="modalName"
    :header-class="'service-level-header'"
    :body-class="'service-level-modal'"
    :hide-footer="false"
    size="lg"
  >
    <template #modal-title> Download Shipping Labels </template>
    <template #default>
      <div class="d-flex align-items-center">
        <h2 class="modal-sub-title">Labels preferences</h2>
        <span v-b-tooltip="{ title: preferenceTooltip, placement: 'bottom' }">
          <img src="../../../assets/img/ant-design_question-circle-outlined.svg" class="ml-2" alt="" />
        </span>
      </div>
      <b-form-group>
        <div class="d-flex mt-2">
          <div class="mr-3 custom-control custom-radio">
            <input
              id="consolidated_value"
              v-model="labelsType"
              type="radio"
              name="consolidated"
              class="custom-control-input"
              value="consolidated"
            />
            <label class="custom-control-label" for="consolidated_value"> Combine in 1 file </label>
          </div>
          <div class="custom-control custom-radio">
            <input
              id="separate_value"
              v-model="labelsType"
              type="radio"
              name="consolidated"
              class="custom-control-input"
              value="separate"
            />
            <label class="custom-control-label" for="separate_value"> Separately </label>
          </div>
        </div>
      </b-form-group>
      <div class="row row-flex mt-4">
        <div class="col-12 mb-2">
          <div class="download-labels-group">
            <div class="download-labels-group__title">
              <mdicon name="printer" size="25" />
              Standard printers (A4 format)
            </div>
            <div class="download-labels-group__types">
              <div
                class="download-labels-group-type download-labels-group-type_landscape"
                :class="{
                  'download-labels-group-type_active': isSubTypeAndPosition('a4_landscape', 'pairs'),
                }"
                @click="setSubTypeAndPosition('a4_landscape', 'pairs')"
              >
                <div class="download-labels-group-type-visual download-labels-group-type-visual_landscape">
                  <div class="download-labels-group-type-visual__body d-flex justify-content-between">
                    <div class="download-labels-group-type-visual__rectangle" />
                    <div class="download-labels-group-type-visual__rectangle" />
                  </div>
                </div>
                <div class="download-labels-group-type__title">A4 Landscape 2 x 2</div>
              </div>
              <div
                class="download-labels-group-type download-labels-group-type_landscape"
                :class="{
                  'download-labels-group-type_active': isSubTypeAndPosition('a4_landscape', 'single'),
                }"
                @click="setSubTypeAndPosition('a4_landscape')"
              >
                <div class="download-labels-group-type-visual download-labels-group-type-visual_landscape">
                  <div class="download-labels-group-type-visual__body d-flex justify-content-between">
                    <div class="download-labels-group-type-visual__rectangle" />
                    <div class="download-labels-group-type-visual__rectangle bg-grey" />
                  </div>
                </div>
                <div class="download-labels-group-type__title">A4 Landscape 1 x 2</div>
              </div>
              <div
                class="download-labels-group-type download-labels-group-type_landscape"
                :class="{
                  'download-labels-group-type_active': isSubTypeAndPosition('a4_landscape', 'triplets'),
                }"
                @click="setSubTypeAndPosition('a4_landscape', 'triplets')"
              >
                <div class="download-labels-group-type-visual download-labels-group-type-visual_landscape">
                  <div class="download-labels-group-type-visual__body d-flex justify-content-between">
                    <div class="download-labels-group-type-visual__rectangle mr-1 rectangle-small">
                      <span class="landscape" />
                    </div>
                    <div class="download-labels-group-type-visual__rectangle rectangle-small">
                      <span class="landscape" />
                    </div>
                    <div class="download-labels-group-type-visual__rectangle ml-1 rectangle-small">
                      <span class="landscape" />
                    </div>
                  </div>
                </div>
                <div class="download-labels-group-type__title">A4 Landscape 3 x 3</div>
              </div>
              <div
                class="download-labels-group-type download-labels-group-type_portrait"
                :class="{
                  'download-labels-group-type_active': isSubTypeAndPosition('a4_portrait'),
                }"
                @click="setSubTypeAndPosition('a4_portrait')"
              >
                <div class="download-labels-group-type-visual download-labels-group-type-visual_portrait">
                  <div class="download-labels-group-type-visual__body">
                    <div class="download-labels-group-type-visual__rectangle" />
                  </div>
                </div>
                <div class="download-labels-group-type__title">A4 Portrait 1 x 1</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="download-labels-group">
            <div class="download-labels-group__title">
              <mdicon name="printerPos" size="25" />
              Recommended for Zebra 420d
            </div>
            <div class="download-labels-group__types">
              <div
                class="download-labels-group-type download-labels-group-type_zebra-big"
                :class="{
                  'download-labels-group-type_active': isSubTypeAndPosition('zebra_big'),
                }"
                @click="setSubTypeAndPosition('zebra_big')"
              >
                <div class="download-labels-group-type-visual download-labels-group-type-visual_zebra-big">
                  <div class="download-labels-group-type-visual__body">
                    <div class="download-labels-group-type-visual__rectangle" />
                  </div>
                </div>
                <div class="download-labels-group-type__title">105 x 190 mm</div>
              </div>
              <div
                class="download-labels-group-type download-labels-group-type_zebra-small"
                :class="{
                  'download-labels-group-type_active': isSubTypeAndPosition('zebra_small'),
                }"
                @click="setSubTypeAndPosition('zebra_small')"
              >
                <div class="download-labels-group-type-visual download-labels-group-type-visual_zebra-small">
                  <div class="download-labels-group-type-visual__body">
                    <div class="download-labels-group-type-visual__rectangle" />
                  </div>
                </div>
                <div class="download-labels-group-type__title">105 x 72 mm</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #modal-footer>
      <b-button size="md" class="btn-grey mr-3" @click="closeModal"> Cancel </b-button>
      <b-button variant="success" size="md" class="btn-green" @click="downloadLabels"> Download labels </b-button>
    </template>
  </modal-wrap>
</template>

<script lang="ts">
import { ref, computed, defineComponent, toRaw } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { notify } from '@kyvg/vue3-notification'
import { DOWNLOAD_LABELS } from '@/const/ModalTypes'
import { LABELS_DOWNLOAD_LOADER } from '@/const/LoadersNames'
import { STAFF_ASSIGNMENTS_LIST } from '@/types/router'
import ModalWrap from '@/views/Modals/ModalWrap.vue'

export default defineComponent({
  name: 'DownloadLabelsModal',
  components: { ModalWrap },
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const labelsType = ref('consolidated')
    const format = ref('a4_landscape')
    const positioning = ref('pairs')
    const modalName = DOWNLOAD_LABELS

    const preferenceTooltip =
      'Choose how you would like your labels delivered:\n' +
      'Combined in 1 file - all labels will be placed inside one multi-page PDF.\n' +
      'Separately - each label will be saved as a separate PDF file and placed inside one ZIP archive.\n'

    const modalData = computed(() => store.getters['vueNextModal/modalProps'](modalName))
    const assignmentsIds = computed(() => store.getters['vueNextModal/modalProps'](modalName)?.ids || [])

    const setSubTypeAndPosition = (subType: string, pos: string = 'single') => {
      format.value = subType
      positioning.value = pos
    }

    const isSubTypeAndPosition = (subType: string, pos: string = 'single') => {
      return format.value === subType && positioning.value === pos
    }

    const closeModal = () => {
      store.dispatch('vueNextModal/closeModal', modalName)
    }

    const downloadLabels = () => {
      const payload: any = {
        mode: labelsType.value,
        layout: format.value,
        positioning: positioning.value,
        assignments: toRaw(assignmentsIds.value),
      }

      const modalProps = store.getters['vueNextModal/modalProps'](modalName)

      if (route.params.id) {
        payload.id = route.params.id
      } else if (modalProps.id) {
        payload.id = modalProps.id
      }

      store
        .dispatch('assignment/downloadLabelsMultiple', payload)
        .then(() => {
          store.dispatch('ui/loaderShow', {
            name: LABELS_DOWNLOAD_LOADER,
            data: {
              progress: 0,
              labelsType: labelsType.value,
              callback: () => {
                router.push({ name: STAFF_ASSIGNMENTS_LIST })
              },
            },
          })
          closeModal()
        })
        .catch((e: any) => {
          store.dispatch('ui/loaderHide', { name: LABELS_DOWNLOAD_LOADER })
          store.dispatch('vueNextModal/closeModal', modalName)
          notify({ group: 'error', text: `Download Error: ${e.message}` })
        })
    }

    return {
      labelsType,
      format,
      positioning,
      modalName,
      modalData,
      assignmentsIds,
      preferenceTooltip,
      setSubTypeAndPosition,
      isSubTypeAndPosition,
      downloadLabels,
      closeModal,
    }
  },
})
</script>

<style lang="scss" scoped>
// Radio start

.custom-radio .custom-control-label:before {
  border-radius: 50%;
}
.custom-control-label:before,
.custom-file-label,
.custom-select {
  -webkit-transition:
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition:
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.custom-control-label:after,
.custom-control-label:before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: '';
}
.custom-control-label:before {
  pointer-events: none;
  background-color: #fff;
  border: 1px solid #adb5bd;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label:after {
  background: #058880;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  left: -1.25rem;
  top: 0.5rem;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}
.custom-control-label:after {
  background: 50% / 50% 50% no-repeat;
}
.custom-control-label:after,
.custom-control-label:before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: '';
}
//Radio end
.download-labels-group {
  height: 100%;
  width: 100%;
  &__types {
    display: flex;
    width: 100%;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 32px 35px;
    text-align: center;
    margin-top: 7.5px;
    min-height: 237px;
  }
  &-type {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    width: 100%;
    &_active {
      .download-labels-group-type-visual {
        border: 1px solid #058880;
      }
    }
    &__title {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      text-align: center;
      color: #151515;
      margin-top: 23px;
    }
    &-visual {
      margin: auto;
      &_landscape {
        width: 128px;
        height: 92.9px;
        background: #ffffff;
        border: 1px solid #cccccc;
        box-sizing: border-box;
        border-radius: 4px;
        .download-labels-group-type-visual__body {
          padding: 12px;
        }
        .download-labels-group-type-visual__rectangle {
          width: 48px;
          height: 69px;
          background: #ff4e10;
          opacity: 0.72;
          border-radius: 2px;
          .small {
            height: 20px;
          }
        }
      }
      &_portrait {
        height: 128px;
        width: 92.9px;
        background: #ffffff;
        border: 1px solid #cccccc;
        box-sizing: border-box;
        border-radius: 4px;
        .download-labels-group-type-visual__body {
          padding: 12px;
        }
        .download-labels-group-type-visual__rectangle {
          width: 48px;
          height: 69px;
          background: #ff4e10;
          opacity: 0.72;
          border-radius: 2px;
        }
      }
      &_zebra-big {
        height: 96px;
        width: 69.68px;
        background: #ffffff;
        border: 1px solid #cccccc;
        box-sizing: border-box;
        border-radius: 4px;
        .download-labels-group-type-visual__body {
          width: 100%;
          height: 100%;
          padding: 15px 11.2px;
        }
        .download-labels-group-type-visual__rectangle {
          width: 100%;
          height: 100%;
          background: #ff4e10;
          opacity: 0.72;
          border-radius: 2px;
        }
      }
      &_zebra-small {
        width: 61.07px;
        height: 44.32px;
        background: #ffffff;
        border: 1px solid #cccccc;
        box-sizing: border-box;
        border-radius: 4px;
        .download-labels-group-type-visual__body {
          width: 100%;
          height: 100%;
          padding: 8px 6px;
        }
        .download-labels-group-type-visual__rectangle {
          width: 100%;
          height: 100%;
          background: #ff4e10;
          opacity: 0.72;
          border-radius: 2px;
        }
      }
      .rectangle-small {
        background: white;
        border: 1px solid #cccccc;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        span {
          border-radius: 2px;
          width: 100% !important;
          height: 100% !important;
          background: #ff4e10;
          display: block;
        }
      }
      .rectangle-small.portrait {
        height: 25px !important;
        margin-bottom: 3px;
      }
    }
  }
}
.row-flex {
  display: flex;
  flex-wrap: wrap;
}
:deep(.loader) {
  z-index: 9999999 !important;
}

.modal-sub-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  margin: 21px 0 17.5px 0;
}

.bg-grey {
  background: #f0f0ef !important;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
  input[type='radio'] {
    display: none;
  }
}
.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background: #058880;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  left: -1.25rem;
  top: 0.5rem;
}
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #058880;
  background: #fff;
}
</style>
