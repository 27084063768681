<template>
  <div class="bg-light">
    <simple-header />
    <div class="page-body">
      <div v-if="!submitted" class="container">
        <div class="wrap-form">
          <div class="title mb-4">Create a password for your account</div>
          <VeeForm @submit="createPassword">
            <Field v-slot="{ field, errors }" name="password" :rules="{ password: true, required: true }">
              <b-form-group class="form-group position-relative">
                <input
                  v-bind="field"
                  v-model="password"
                  type="password"
                  name="password"
                  class="form-control inspectletIgnore"
                  placeholder="Set a password *"
                  autocomplete="off"
                />
                <div class="invalid-feedback">
                  {{ errors.length ? errors.join() : '' }}
                </div>
              </b-form-group>
            </Field>
            <Field
              v-slot="{ field, errors }"
              name="confirmedPassword"
              :rules="{ confirmed: '@password', required: true, password: true }"
            >
              <b-form-group class="form-group position-relative">
                <input
                  v-bind="field"
                  v-model="confirmedPassword"
                  type="password"
                  name="confirm_password"
                  class="form-control inspectletIgnore"
                  placeholder="Confirm password *"
                  autocomplete="off"
                />
                <div class="invalid-feedback">
                  {{ errors.length ? errors.join() : '' }}
                </div>
              </b-form-group>
            </Field>
            <b-form-group class="mb-3 mt-4">
              <input v-model="agree" class="form-check-input" type="checkbox" :value="true" />
              I agree with
              <router-link :to="{ name: PRIVACY }" class="green-color"> Privacy Policy </router-link>
              and
              <router-link :to="{ name: TERMS }" class="green-color"> Terms of Use </router-link>
            </b-form-group>
            <b-button type="submit" :disabled="isDisabled" class="w-100"> Create password </b-button>
          </VeeForm>
        </div>
      </div>
      <div v-else class="container">
        <div class="wrap-form center-text">
          <h5 class="center-text mb-4">Password successfully set</h5>
          <b-button class="assignmentButton mt-2" type="submit" :to="{ name: LOGIN_PAGE }"> Log In </b-button>
        </div>
      </div>
    </div>
    <LayoutFooter />
  </div>
</template>

<script lang="ts">
import { ref, computed, onMounted, defineComponent, type ComputedRef, type Ref } from 'vue'
import { Form as VeeForm, Field } from 'vee-validate'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useNotification } from '@kyvg/vue3-notification'
import { LOGIN_PAGE, PRIVACY, TERMS } from '@/types/router'
import LayoutFooter from '@/views/Layouts/LayoutFooter.vue'
import SimpleHeader from '@/views/Layouts/SimpleHeader.vue'
import type { CreatePasswordData } from '@/types/Models/Account'

export default defineComponent({
  name: 'CreatePass',
  components: {
    VeeForm,
    Field,
    SimpleHeader,
    LayoutFooter,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const { notify } = useNotification()

    const password: Ref<string> = ref('')
    const confirmedPassword: Ref<string> = ref('')
    const submitted: Ref<boolean> = ref(false)
    const agree: Ref<boolean> = ref(false)
    const customer: Ref<CreatePasswordData> = ref({
      email: '',
      token: '',
      password: '',
    })

    const isDisabled: ComputedRef<boolean> = computed(() => !password.value || !confirmedPassword.value || !agree.value)

    const createPassword = () => {
      const payload: CreatePasswordData = {
        email: customer.value.email,
        token: customer.value.token,
        password: password.value,
      }
      store
        .dispatch('auth/createPassword', payload)
        .then(() => {
          submitted.value = true
          notify({ group: 'success', text: 'Password successfully set' })
        })
        .catch((e) => {
          if (e?.email) notify({ group: 'error', text: 'The selected email is invalid.' })
          if (e?.token) notify({ group: 'error', text: 'The selected token is invalid.' })
        })
    }

    onMounted(() => {
      customer.value.email = String(router.currentRoute.value.query.email)
      customer.value.token = String(router.currentRoute.value.query.token)
    })

    return {
      agree,
      customer,
      password,
      submitted,
      isDisabled,
      confirmedPassword,
      createPassword,
      LOGIN_PAGE,
      PRIVACY,
      TERMS,
    }
  },
})
</script>

<style lang="scss" scoped>
.title {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0;
  text-align: center;
}
.bg-light {
  height: 100%;
  display: flex;
  flex-direction: column;
}
footer {
  margin-top: auto;
}
.green-color {
  color: rgb(12, 137, 128);
}
.invalid-feedback {
  display: block;
}
.form-check-input {
  margin-top: 0;
}
</style>
