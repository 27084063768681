import { BaseProxy, type ProxyMethods } from '@/proxies/Proxy'

export default class IntegrationsProxy extends BaseProxy implements ProxyMethods {
  public endpoint = 'integrations'

  constructor(userId: string) {
    super({ user_id: userId })
  }

  initConnection(system: string) {
    const params = {
      user_id: this.parameters.user_id,
      system,
    }

    return this.submit('post', `/${this.endpoint}/init`, params, {})
  }

  connectWoocommerce(credentials: object, options: object) {
    const params = {
      user_id: this.parameters.user_id,
      system: 'woocommerce',
      external_id: null,
      payload: {
        ...options,
        credentials,
        marketplace: 'woocommerce',
      },
    }

    return this.submit('post', `/${this.endpoint}`, params, {})
  }

  connectShopify(token: string) {
    const params = {
      user_id: this.parameters.user_id,
      system: 'shopify',
      external_id: null,
      token,
    }

    return this.submit('post', `/${this.endpoint}`, params, {})
  }

  connectOngoing(token: string, externalId: string) {
    const params = {
      user_id: this.parameters.user_id,
      system: 'ongoing',
      external_id: externalId,
      token,
    }

    return this.submit('post', `/${this.endpoint}`, params, {})
  }

  runActions(id: string | number, action: string, params: object) {
    const data = {
      user_id: this.parameters.user_id,
      action,
      ...params,
    }
    return this.submit('post', `/${this.endpoint}/${id}/action`, data, {})
  }
}
