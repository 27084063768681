import type { RouteRecordRaw } from 'vue-router'
import { MOBILE_UNSUPPORTED, PERMISSIONS_ERROR, PROFORMA_INVOICE_GUIDE } from '@/types/router'

const uncategorized: Array<RouteRecordRaw> = [
  {
    path: '/permissions-error',
    name: PERMISSIONS_ERROR,
    component: () => import('@/views/Errors/403.vue'),
    meta: {
      guest: true,
    },
  },
  {
    path: '/unsupported',
    name: MOBILE_UNSUPPORTED,
    component: () => import('@/views/Errors/MobileUnsupported.vue'),
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: () => import('@/views/Errors/404.vue'),
  },
  {
    path: '/guides',
    component: () => import('@/views/Guides/Index.vue'),
    children: [
      {
        path: 'how-to-use-proforma-invoice',
        component: () => import('@/views/Guides/ProformaInvoice.vue'),
        name: PROFORMA_INVOICE_GUIDE,
        meta: {
          title: 'How to use proforma invoice',
          guest: true,
        },
      },
    ],
  },
]

export default uncategorized
