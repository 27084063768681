import { openModal } from '@/services/Modal'
import { DOWNLOAD_LABELS, DOWNLOAD_LABELS_WARNING } from '@/const/ModalTypes'
import type { BaseAssignment, ListAssignment, SingleAssignment } from '@/types/Models/Assignment'

export type AssignmentsUnion = ListAssignment | BaseAssignment | SingleAssignment

export const useDownloadLabelsAction = () => {
  return (assignments: AssignmentsUnion[]) => {
    if (assignments.some((a) => a.denyLabelsDownload)) {
      openModal(DOWNLOAD_LABELS_WARNING, { assignments })
    } else {
      openModal(DOWNLOAD_LABELS, { ids: assignments.map((a) => a.id) })
    }
  }
}
