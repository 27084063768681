<template>
  <div class="privacy">
    <h1 class="privacy-title">Dimensions and weight requirements for parcel characteristics</h1>
    <div>
      <div>
        <p class="privacy-text">
          When you ship parcels and packets, it is important that you comply with the allowed dimensions, weight and
          requirements.
        </p>
      </div>
      <div class="privacy-section">
        <h2 class="privacy-sub-title">Distribution types</h2>

        <h2 class="privacy-sub-title">1. Package - packets (no multiple parcels available)</h2>
        <div class="sub-section">
          <h3 class="privacy-sub-point">1.1 Domestic delivery</h3>
          <ul>
            <li>Weight - minimum 1 gram, maximum 5000 grams</li>
            <li>Width - minimum 1cm, maximum 60cm</li>
            <li>Height - minimum 1cm, maximum 60cm</li>
            <li>Length - minimum 1cm, maximum 60cm</li>
          </ul>
          <p class="privacy-text">All dimensions combined can not be greater than 90 centimeters</p>
        </div>

        <div class="sub-section">
          <h3 class="privacy-sub-point">1.2 International delivery</h3>
          <ul>
            <li>Weight - minimum 1 gram, maximum 2000 grams</li>
            <li>Width - minimum 1cm, maximum 60cm</li>
            <li>Height - minimum 1cm, maximum 60cm</li>
            <li>Length - minimum 1cm, maximum 60cm</li>
          </ul>
          <p class="privacy-text">All dimensions combined can not be greater than 90 centimeters</p>
        </div>

        <h2 class="privacy-sub-title">2. Parcel - Parcels/packages</h2>
        <div class="sub-section">
          <h3 class="privacy-sub-point">2.1 Domestic shipment</h3>
          <h3 class="privacy-sub-point">
            B2C - no company name provided (Multiple parcels in one sending are not allowed)
          </h3>
          <ul>
            <li>Weight - minimum 100 grams, maximum 20000 grams</li>
            <li>Width - minimum 11cm, maximum 131.5cm</li>
            <li>Height - minimum 3.5cm, maximum 139cm</li>
            <li>Length - minimum 15cm, maximum 150cm</li>
          </ul>
          <p class="privacy-text">Circumference = length + 2 * width + 2 * height should not be greater than 300cm</p>

          <h3 class="privacy-sub-point">B2B - company name provided (Multiple parcels in one sending are allowed)</h3>
          <p class="privacy-text">Total weight for one sending - minimum 100 grams, maximum - 150000 grams</p>
          <p class="privacy-text">For each parcel</p>
          <ul>
            <li>Weight - minimum 100 grams, maximum 20000 grams</li>
            <li>Width - minimum 11cm, maximum 50cm</li>
            <li>Height - minimum 3.5cm, maximum 50cm</li>
            <li>Length - minimum 15cm, maximum 150cm</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ParcelRequirements',
})
</script>
