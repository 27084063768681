import { SINGLE } from '@/const/AssignmentTypes'
import { DEFAULT_LOADER } from '@/const/LoadersNames'
import { type Reactive, type Ref, toRaw } from 'vue'
import ItemTransformer from '@/transformers/ItemTransformer'
import { PARCEL } from '@/const/DistributionChannels'
import { ADDRESS_DELIVERY, SERVICE_POINT_DELIVERY } from '@/const/AssignmentDeliveryTypes'
import { buildFormData } from '@/services/Form'
import {
  CUSTOMER_ASSIGNMENT_SUMMARY,
  CUSTOMER_ASSIGNMENTS_LIST,
  STAFF_ASSIGNMENT_SUMMARY,
  STAFF_ASSIGNMENTS_LIST,
} from '@/types/router'
import { openModal } from '@/services/Modal'
import { CREATE_ASSIGNMENT_SOURCE_FILE_ERRORS, REQUEST_ERROR } from '@/const/ModalTypes'
import type { AssignmentData, ErrorsList } from '@/views/Components/Assignment/AssignmentForm/types'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import AssignmentProxy from '@/proxies/AssignmentProxy'

type Props = {
  assignment: Ref<AssignmentData>
  isStaff: Ref<boolean>
  isFileUpdated: Ref<boolean>
  errorsList: Reactive<ErrorsList>
}

export const useOnSubmit = ({ assignment, isStaff, isFileUpdated, errorsList }: Props) => {
  const store = useStore()
  const router = useRouter()

  const proxy = new AssignmentProxy()

  return () => {
    let promise = null
    if (assignment.value.assignment_type !== SINGLE) {
      errorsList.customer_id = isStaff.value && assignment.value.customer_id === '' ? 'Select client, please.' : ''
    }

    if (errorsList.customer_id === '') {
      store.dispatch('ui/loaderShow', { name: DEFAULT_LOADER })
      errorsList.file = ''
      const data: any = { ...toRaw(assignment.value) }
      if (!data?.sender_address_id) {
        delete data.sender_address_id
      }
      if (!isStaff.value) {
        delete data.customer_id
      }

      // transform item
      data.item = ItemTransformer.create(data.item)

      if (assignment.value.assignment_type === SINGLE) {
        delete data.file
        if (assignment.value.distribution_channel === PARCEL && data.item) {
          // move this hack to backend
          data.item.delivery_type = data.item.company_name ? ADDRESS_DELIVERY : SERVICE_POINT_DELIVERY
        }

        data.optimize = true
        data.create_report = false
        data.create_labels = false

        promise = proxy.create(data)
      } else {
        delete data.item
        const formData = new FormData()
        buildFormData(formData, data)
        if (!isFileUpdated.value) {
          formData.delete('file')
        }
        promise = proxy.create(formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
      }

      promise
        .then((val: any) => {
          if (assignment.value.assignment_type !== SINGLE) {
            router.push({
              name: isStaff.value ? STAFF_ASSIGNMENTS_LIST : CUSTOMER_ASSIGNMENTS_LIST,
            })
            return
          }
          router.push({
            name: isStaff.value ? STAFF_ASSIGNMENT_SUMMARY : CUSTOMER_ASSIGNMENT_SUMMARY,
            params: { id: val.data.id },
          })
        })
        .catch((e: any) => {
          const fileErrors = e?.errors?.file || []
          if (fileErrors.length > 0) {
            openModal(CREATE_ASSIGNMENT_SOURCE_FILE_ERRORS, { fileErrors })
          } else if (e?.errors) {
            openModal(REQUEST_ERROR, { errors: e.errors })
          }
        })
        .finally(() => {
          store.dispatch('ui/loaderHide', { name: DEFAULT_LOADER })
        })
    }
  }
}
