import FAQProxy from '@/proxies/FAQProxy'
import type { ActionContext } from 'vuex'
import type { FAQStoreState, RootStoreState } from '@/types/Store'
import type { FAQ } from '@/types/Models/FAQ'
import { none } from '@/services/Helpers'

export const SET_FAQ_QUESTION = 'faq/SET_FAQ_QUESTION'
export const SET_FAQ_QUESTIONS = 'faq/SET_FAQ_QUESTIONS'

type Context = ActionContext<FAQStoreState, RootStoreState>

const state: FAQStoreState = {
  question: {
    question: '',
    answer: '',
  },
  questions: [],
}

const getters = {}

const mutations = {
  [SET_FAQ_QUESTION](state: FAQStoreState, question: FAQ) {
    state.question = question
  },
  [SET_FAQ_QUESTIONS](state: FAQStoreState, questions: FAQ[]) {
    state.questions = questions
  },
}

const actions = {
  fetchQuestions: ({ commit }: Context) =>
    new FAQProxy().all().then((response) => {
      commit(SET_FAQ_QUESTIONS, response.data)
    }),
  createQuestion: ({ dispatch }: Context, data: FAQ) =>
    new FAQProxy().create(data).then(() => {
      dispatch('fetchQuestions').then(none)
    }),
  fetchQuestion: ({ commit }: Context, id: string) =>
    new FAQProxy().find(id).then((response) => {
      commit(SET_FAQ_QUESTION, response.data)
      return response.data
    }),
  deleteQuestion: ({ dispatch }: Context, id: string) =>
    new FAQProxy().destroy(id).then(() => {
      dispatch('fetchQuestions').then(none)
    }),
  updateQuestion: ({ dispatch }: Context, { id, data }: { id: string; data: FAQ }) =>
    new FAQProxy().update(id, data).then(() => {
      dispatch('fetchQuestions').then(none)
    }),
}
export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
}
