<template>
  <div>
    <div>
      <RouterLink :to="detailsRoute" class="pointer text-light-green">
        {{ assignment.reference }}
      </RouterLink>
    </div>

    <div v-if="assignment.archived">
      <b-badge variant="danger"> Archived </b-badge>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import {
  CUSTOMER_ASSIGNMENT_SUMMARY,
  CUSTOMER_ASSIGNMENTS_SINGLE,
  STAFF_ASSIGNMENT_SUMMARY,
  STAFF_ASSIGNMENTS_SINGLE,
} from '@/types/router'
import { CONSOLIDATE } from '@/const/AssignmentTypes'
import type { ListAssignment } from '@/types/Models/Assignment'

export default defineComponent({
  name: 'ReferenceCell',
  props: {
    assignment: {
      type: Object as () => ListAssignment,
      required: true,
    },
  },
  setup(props) {
    const store = useStore()

    const isStaff = computed(() => store.getters['account/isStaff'])
    const staffAssignmentPage = computed(() =>
      props.assignment.type === CONSOLIDATE ? STAFF_ASSIGNMENTS_SINGLE : STAFF_ASSIGNMENT_SUMMARY,
    )
    const customerAssignmentPage = computed(() =>
      props.assignment.type === CONSOLIDATE ? CUSTOMER_ASSIGNMENTS_SINGLE : CUSTOMER_ASSIGNMENT_SUMMARY,
    )

    const detailsRoute = computed(() => {
      return {
        name: isStaff.value ? staffAssignmentPage.value : customerAssignmentPage.value,
        params: {
          id: props.assignment.id,
        },
      }
    })

    return {
      detailsRoute,
    }
  },
})
</script>
