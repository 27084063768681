import Pusher from 'pusher-js'
import Echo from 'laravel-echo'

const isProd = import.meta.env.VITE_ENV === 'production'

Pusher.logToConsole = !isProd
export const echo = new Echo({
  broadcaster: 'pusher',
  cluster: import.meta.env.VITE_APP_WEBSOCKETS_CLUSTER,
  key: import.meta.env.VITE_APP_WEBSOCKETS_KEY,
  authEndpoint: import.meta.env.VITE_APP_BROADCASTING_AUTH,
  auth: {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('id_token')}`,
    },
  },
})

window.Echo = echo
