import type { AssignmentPiece } from '@/views/Components/Assignment/Pieces/types'

export const defaultPiece: AssignmentPiece = {
  quantity: 1,
  weight: 0,
  height: 0,
  length: 0,
  width: 0,
  description: '',
  commodity_code: '',
  estimated_cost: 0,
}
