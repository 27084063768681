/* ============
 * Account Transformer
 * ============
 *
 * The transformer for the account.
 */

import type { RequestUser, User } from '@/types/Models/User'

export default class UserTransformer {
  /**
   * Method used to transform a fetched account.
   */
  public static fetch(user: User): User {
    return {
      ...user,
      id: user.id,
      type: user.type,
      clientType: user.clientType,
      firstName: user.firstName,
      fullName: user.fullName,
      lastName: user.lastName,
      email: user.email,
      clientNumber: user.clientNumber,
      phoneNumber: user.phoneNumber,
      contractParty: user.contractParty,
      shortName: user.shortName,
      company: user.company,
      createdAt: user.createdAt,
      updatedAt: user.updatedAt,
      salesman: user.salesman || {},
      salesmanId: user.salesman?.id || null,
      note: user.note,
      hasCustomPrices: !!user.hasCustomPrices,
      hasCustomPriorities: user.hasCustomPriorities,
      hidePrice: user.hidePrice,
      clientAddress: user.clientAddress,
      senderAddress: user.senderAddress,
      canHasCustomerNumbers: user.canHasCustomerNumbers,
      shipmentNotifications: user.shipmentNotifications,
      isBufferingEnabled: user.isBufferingEnabled,
      bufferingOptions: user.bufferingOptions,
    }
  }

  /**
   * Method used to transform a send user data.
   */
  static send(user: User): RequestUser {
    const data: RequestUser = {
      ...user,
      id: user.id,
      type: user.type,
      firstName: user.firstName,
      lastName: user.lastName,
      fullName: user.fullName,
      email: user.email,
      clientNumber: user.clientNumber,
      phoneNumber: user.phoneNumber,
      contractParty: user.contractParty,
      shortName: user.shortName,
      company: user.company,
      note: user.note,
      salesman: user.salesman,
      salesmanId: user.salesmanId,
      senderAddress: user.senderAddress,
      clientAddress: user.clientAddress,
      hidePrice: user.hidePrice,
      canHasCustomerNumbers: user.canHasCustomerNumbers,
      shipmentNotifications: user.shipmentNotifications,
      isBufferingEnabled: user.isBufferingEnabled,
      bufferingOptions: user.bufferingOptions,
    }
    if (user.password) {
      data.password = user.password
    }
    return data
  }

  /**
   * Method used to transform a fetched collection.
   */
  static fetchCollection(items: User[]) {
    return items.map((item) => this.fetch(item))
  }
}
