// Type: Vuex module
import type { ListAssignment } from '@/types/Models/Assignment'
import type { AssignmentSummaryStoreState, RootStoreState } from '@/types/Store'
import type { ActionContext } from 'vuex'
import AssignmentProxy from '@/proxies/AssignmentProxy'
import AssignmentTransformer from '@/transformers/AssignmentTransformer'

export const FETCH_ASSIGNMENT_SUMMARY = 'FETCH_ASSIGNMENT_SUMMARY'

type Context = ActionContext<AssignmentSummaryStoreState, RootStoreState>

const state: AssignmentSummaryStoreState = {
  assignment: null,
}

const getters = {
  get: (state: AssignmentSummaryStoreState) => state.assignment,
}

const mutations = {
  [FETCH_ASSIGNMENT_SUMMARY](state: AssignmentSummaryStoreState, data: ListAssignment) {
    state.assignment = data
  },
}

const actions = {
  fetch: ({ commit }: Context, id: string) =>
    new Promise((resolve, reject) => {
      new AssignmentProxy()
        .getAssignmentSummary(id)
        .then((response) => {
          const data = AssignmentTransformer.fetch(response.data)
          commit(FETCH_ASSIGNMENT_SUMMARY, data)
          resolve(data)
        })
        .catch((e) => {
          reject(e)
        })
    }),
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
