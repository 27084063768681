/* ============
 * Account Module
 * ============
 */

import optimizationReport from '@/store/modules/assignment/optimizationReport'
import assignmentSummary from '@/store/modules/assignment/assignmentSummary'
import { LABELS_CREATED_SUCCESS, LABELS_CREATED_WITH_ERRORS } from '@/const/LabelsCreatedStatuses'
import { STAFF } from '@/const/AccountTypes'
import { CANCELLED, CUSTOMER_EDITABLE_STATUSES, STAFF_EDITABLE_STATUSES } from '@/const/AssignmentStatuses'
import AssignmentProxy from '@/proxies/AssignmentProxy'
import AssignmentTransformer from '@/transformers/AssignmentTransformer'
import AssignmentStatusProxy from '@/proxies/AssignmentStatusProxy'
import { none } from '@/services/Helpers'
import type { RootStoreState, AssignmentStoreState } from '@/types/Store'
import type { ActionContext } from 'vuex'

export const FILL_ASSIGNMENT = 'FILL_ASSIGNMENT'
export const FILL_ASSIGNMENT_STATUS = 'FILL_ASSIGNMENT_STATUS'
export const FILL_ASSIGNMENT_LABELS_STATUSES = 'FILL_ASSIGNMENT_LABELS_STATUSES'
export const RESET_STATE = 'RESET_STATE'
export const RESET_LIST = 'RESET_LIST'
export const RESET_ITEMS_LIST = 'RESET_ITEMS_LIST'

export const defaultState: AssignmentStoreState = {
  id: null,
  createdAt: null,
  description: null,
  domesticErrorsCount: 0,
  domesticErrors: {},
  domesticItemsCount: 0,
  documentsUrl: null,
  fatalErrorsCount: 0,
  internationalErrorsCount: 0,
  internationalErrors: {},
  internationalItemsCount: 0,
  itemsCount: null,
  labelsDownloadStatus: null,
  labelsDownloadUrl: null,
  labelsStatuses: {},
  message: null,
  optimisationReportUrl: null,
  pickupDate: null,
  pickupInstructions: '',
  reference: null,
  serviceLevel: null,
  sourceFiles: [],
  staff: {},
  status: null,
  updatedAt: null,
  labelsCreatedAt: null,
  customerReference: null,
  user: null,
  utils: {
    statusUpdateInterval: null,
  },
  archived: null,
  senderAddress: null,
  version: '',
  itemsVersion: '',
  denyLabelsDownload: false,
}

type Context = ActionContext<AssignmentStoreState, RootStoreState>

const state: AssignmentStoreState = {
  ...defaultState,
}

const getters = {
  get: (state: AssignmentStoreState) => state,
  getId: (state: AssignmentStoreState) => state.id,
  getStatus: (state: AssignmentStoreState) => state.status,
  labelsDownloadStatus: (state: AssignmentStoreState) => state.labelsDownloadStatus,
  getFatalErrorsCount: (state: AssignmentStoreState) => state.fatalErrorsCount,
  getLabelsCreatedStatus: (state: AssignmentStoreState) =>
    Object.values(state.labelsStatuses).some((item) => item === 'error')
      ? LABELS_CREATED_WITH_ERRORS
      : LABELS_CREATED_SUCCESS,
  getLabelsSuccessCreatedCount: (state: AssignmentStoreState) =>
    Object.values(state.labelsStatuses).filter((item) => item === 'shipped').length,
  getLabelsNotCreatedCount: (state: AssignmentStoreState) =>
    Object.values(state.labelsStatuses).filter((item) => item === 'error').length,
  getUser: (state: AssignmentStoreState) => state.user,
  getSenderAddress: (state: AssignmentStoreState) => state.senderAddress,
  isItemsEditable: (state: AssignmentStoreState, _: any, rootState: RootStoreState) => {
    const isStaff = rootState.account.type === STAFF
    const status = state.archived ? CANCELLED : state.status || ''
    const editableStatuses = isStaff ? STAFF_EDITABLE_STATUSES : CUSTOMER_EDITABLE_STATUSES
    return editableStatuses.includes(status)
  },
  version: (state: AssignmentStoreState) => state.version,
  itemsVersion: (state: AssignmentStoreState) => state.itemsVersion,
}

const mutations = {
  [FILL_ASSIGNMENT](state: AssignmentStoreState, payload: AssignmentStoreState) {
    Object.assign(state, payload)
  },
  [FILL_ASSIGNMENT_STATUS](state: AssignmentStoreState, { status }: { status: string }) {
    state.status = status
  },
  [FILL_ASSIGNMENT_LABELS_STATUSES](state: AssignmentStoreState, statuses: object) {
    state.labelsStatuses = statuses
  },
  [RESET_STATE](state: AssignmentStoreState) {
    Object.assign(state, defaultState)
  },
  [RESET_LIST](state: AssignmentStoreState) {
    state.version = new Date().toISOString()
  },
  [RESET_ITEMS_LIST](state: AssignmentStoreState) {
    state.itemsVersion = new Date().toISOString()
  },
}

const actions = {
  //common actions
  validate: ({ state }: Context, payload: any) => new AssignmentProxy().validate(payload || state.id),
  archive: ({ state }: Context, payload: any) => new AssignmentStatusProxy(payload || state.id).submitCancel(),
  unarchive: ({ state }: Context, payload: any) => new AssignmentStatusProxy(payload || state.id).submitUnArchive(),
  update: ({ state }: Context, { id, data }: any) => new AssignmentProxy().update(id || state.id, data),
  create: (_: Context, payload: any) => new AssignmentProxy().create(payload),
  destroy: (_: Context, payload: any) => new AssignmentProxy().destroy(payload),
  complete: (_: Context, payload: any) => new AssignmentStatusProxy(payload).submitComplete(),
  downloadLabelsMultiple: (_: Context, payload: any) => new AssignmentProxy().getLabelsMultiple(payload),

  // buttons actions
  submitProcess: ({ state }: Context, payload: any) => new AssignmentStatusProxy(payload || state.id).submitProcess(),
  submitOptimize: ({ state }: Context, payload: any) => new AssignmentProxy().submitOptimize(payload || state.id),
  submitComplete: ({ state }: Context, payload: any) => new AssignmentStatusProxy(payload || state.id).submitComplete(),
  submitReportAccept: ({ state }: Context, payload: any) =>
    new AssignmentStatusProxy(payload || state.id).submitReportAccept(),
  submitReportDeny: ({ state }: Context, payload: any) =>
    new AssignmentStatusProxy(payload || state.id).submitReportDeny(),
  createLabels: ({ state }: Context, { id, pickupDate, pickupInstructions }: any) =>
    new AssignmentProxy().createLabels(id || state.id, {
      pickupDate,
      pickupInstructions,
    }),

  refresh: ({ commit }: Context) => {
    commit(RESET_LIST)
  },
  refreshItems: ({ commit }: Context) => {
    commit(RESET_ITEMS_LIST)
  },
  resetState: ({ commit }: Context) => {
    commit(RESET_STATE)
  },

  fetch: ({ commit, state }: Context, payload: any) => {
    const assignmentId = payload || state.id
    new AssignmentProxy()
      .find(assignmentId)
      .then((response) => {
        if (response.data.id) {
          commit(FILL_ASSIGNMENT, AssignmentTransformer.fetch(response.data))
        }
      })
      .catch(none)
  },
  fetchStatus: ({ commit, state }: Context, payload: any) => {
    new AssignmentProxy()
      .getStatus(payload || state.id)
      .then((response) => {
        if (response.data.status) {
          commit(FILL_ASSIGNMENT_STATUS, response.data)
        }
      })
      .catch(none)
  },
  fetchLabelsStatuses: async ({ commit, state }: Context, payload: any) => {
    const assignmentId = payload || state.id
    const response = await new AssignmentProxy().getLabelsStatuses(assignmentId)
    if (response.data) {
      commit(FILL_ASSIGNMENT_LABELS_STATUSES, response.data)
    }
    return response
  },
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
  modules: {
    optimizationReport,
    assignmentSummary,
  },
}
