<template>
  <div>
    <template v-if="!created">
      <h5 class="form-title title center-text m-auto">
        You can register users for the PacSort – 21grams here. After registering a new user, they will receive an email
        containing a link to create their password.
      </h5>
      <div class="container">
        <div class="wrap-form">
          <div>
            <CreateCustomerForm :route-after-save="toCustomers" @created="customerCreated" />
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="main-full-height">
        <div class="container">
          <div class="wrap-form center-text">
            <h5 class="center-text mb-4">Success! A letter was sent to the user's mail to complete registration.</h5>
            <b-button class="assignmentButton mt-2" type="submit" :to="toAssignment"> Go to assignments </b-button>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { STAFF_ASSIGNMENTS_LIST, STAFF_CUSTOMERS_LIST } from '@/types/router'
import CreateCustomerForm from '@/views/Components/Customer/CreateCustomerForm.vue'

export default defineComponent({
  name: 'CreateCustomerPage',
  components: { CreateCustomerForm },
  setup() {
    const created = ref(false)
    const toAssignment = computed(() => ({ name: STAFF_ASSIGNMENTS_LIST }))
    const toCustomers = computed(() => ({ name: STAFF_CUSTOMERS_LIST }))

    const customerCreated = () => {
      created.value = true
    }

    return {
      created,
      toAssignment,
      toCustomers,
      customerCreated,
    }
  },
})
</script>
