import allCountries from '@/assets/files/countries.json'
import type { Country } from '@/types/Models/Customer'

export const findCountryCode = (country: string) => {
  if (country.length === 2) {
    return country
  }

  return allCountries.find((c: Country) => c.text === country)?.value
}

export const findCountryName = (country: string) => {
  if (country.length > 2) {
    return country
  }

  return allCountries.find((c: Country) => c.value === country)?.text
}
