import type { AssignmentData } from '@/views/Components/Assignment/AssignmentForm/types'
import { PACKAGE } from '@/const/DistributionChannels'
import { SINGLE } from '@/const/AssignmentTypes'
import { OTHER } from '@/const/ProformaInvoiceTypes'
import { defaultPiece } from '@/views/Components/Assignment/Pieces/utils/default-piece'

export const defaultAssignment = (): AssignmentData => ({
  pickup_date: '',
  pickup_instructions: '',
  file: {
    name: '',
    data: '',
  },
  customer_id: '',
  sender_address_id: '',
  service_level: 'priority',
  description: '',
  message: '',
  customer_reference: '',
  distribution_channel: PACKAGE,
  subAccountId: '',
  assignment_type: SINGLE,
  item: {
    is_b2b_shipment: false,
    company_name: '',
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    country_code: '',
    city: '',
    postal_code: '',
    address: '',
    secondary_address: '',
    delivery_type: '',
    weight: '',
    height: '',
    length: '',
    width: '',
    reference: '',
    estimated_cost: '',
    invoiceType: OTHER,
    currency: 'SEK',
    pieces: [{ ...defaultPiece }],
  },
  report: {},
})
