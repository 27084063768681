<template>
  <div class="row child-row">
    <div class="col-4 pr-default pl-0">
      <div class="child-row__title pb-4">Details</div>

      <ShipmentCarrier :shipment="shipment" />

      <ShipmentServicePoint :shipment="shipment" />

      <div class="pb-3">
        <div class="child-row-item__title">Weight</div>
        <div class="child-row-item__subtitle">{{ shipment.weight }} grams</div>
      </div>

      <div class="pb-3">
        <div class="child-row-item__title">Dimensions</div>
        <div class="child-row-item__subtitle">
          {{ shipment.height }} x {{ shipment.length }} x {{ shipment.width }} cm
        </div>
      </div>

      <AssignmentLink :shipment="shipment" />

      <div class="pb-3">
        <div class="child-row-item__title">Distribution channel</div>
        <div class="child-row-item__subtitle">
          {{ getDistributionChannelTxt(shipment.distributionChannel) }}
        </div>
      </div>

      <ShipmentLabel :shipment="shipment" :url="shipment.labelUrl" type="Label" />

      <ShipmentLabel
        v-if="shipment.documentsUrl"
        :url="shipment.documentsUrl"
        :shipment="shipment"
        type-name="Documents"
      />

      <div class="pb-3">
        <div class="child-row-item__title">Service level</div>
        <div class="child-row-item__subtitle">
          {{ ucFirst(shipment.serviceLevel) }}
        </div>
      </div>

      <div class="pb-3">
        <div class="child-row-item__title">Item reference</div>
        <div class="child-row-item__subtitle">
          {{ shipment.itemReference }}
        </div>
      </div>

      <div class="pb-3">
        <div class="child-row-item__title">Mode</div>
        <div class="child-row-item__subtitle">
          {{ ucFirst(shipment.mode) }}
        </div>
      </div>

      <ShipmentCost :shipment="shipment" />

      <div v-if="isStaff" class="pb-3">
        <div class="child-row-item__title">User</div>
        <div class="child-row-item__subtitle">
          <div>
            {{ addressOwner(shipment.user) }}
          </div>
          <div>
            <small>
              <b>All:</b>

              <router-link class="green-link ml-2" :to="allShipmentsRoute">shipments</router-link>

              <router-link class="green-link ml-2" :to="allAssignmentsRoute">assignments</router-link>
            </small>
          </div>
        </div>
      </div>

      <div v-if="isStaff" class="pb-3">
        <div class="child-row-item__title">Identifiers</div>
        <div class="child-row-item__subtitle">
          <div>
            Item:
            <small class="text-muted">{{ shipment.id }}</small>
          </div>
          <div>
            Assignment:
            <small class="text-muted">{{ shipment.assignmentId }}</small>
          </div>
          <div>
            Recipient address:
            <small class="text-muted">{{ shipment.recipient.id }}</small>
          </div>
          <div>
            User:
            <small class="text-muted">{{ shipment.user.id }}</small>
          </div>
        </div>
      </div>
    </div>

    <div class="col-8 pr-default pl-0">
      <div class="pb-4">
        <ShipmentTrackingEvents :shipment="shipment" />
      </div>

      <div class="pb-4">
        <ShipmentMessages :shipment="shipment" />
      </div>

      <ShipmentOptimisationSummary v-if="isStaff" :shipment="shipment" />
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from 'vuex'
import { computed, defineComponent, type PropType } from 'vue'
import { addressOwner, ucFirst } from '@/services/Helpers'
import { getDistributionChannelTxt } from '@/services/Utils'
import { STAFF_ASSIGNMENTS_LIST, STAFF_SHIPMENTS } from '@/types/router'
import ShipmentLabel from '@/views/Components/Shipments/Details/ShipmentLabel.vue'
import ShipmentTrackingEvents from '@/views/Components/Shipments/Events/TrackingEvents.vue'
import ShipmentMessages from '@/views/Components/Shipments/Messages/Messages.vue'
import AssignmentLink from '@/views/Components/Shipments/Details/AssignmentLink.vue'
import ShipmentCost from '@/views/Components/Shipments/Details/ShipmentCost.vue'
import ShipmentOptimisationSummary from '@/views/Components/Shipments/OptimisationSummary/OptimisationSummary.vue'
import ShipmentServicePoint from '@/views/Components/Shipments/Details/ServicePoint.vue'
import ShipmentCarrier from '@/views/Components/Shipments/Details/Carrier.vue'
import type { Shipment } from '@/types/Models/Shipment'

export default defineComponent({
  name: 'ShipmentDetails',
  components: {
    ShipmentCarrier,
    ShipmentServicePoint,
    ShipmentOptimisationSummary,
    ShipmentCost,
    AssignmentLink,
    ShipmentMessages,
    ShipmentTrackingEvents,
    ShipmentLabel,
  },
  props: {
    shipment: {
      type: Object as PropType<Shipment>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore()
    const isStaff = computed(() => store.getters['account/isStaff'])

    const allShipmentsRoute = { name: STAFF_SHIPMENTS, query: { customer: [props.shipment.user.id] } }
    const allAssignmentsRoute = { name: STAFF_ASSIGNMENTS_LIST, query: { customerId: [props.shipment.user.id] } }

    return {
      isStaff,
      ucFirst,
      addressOwner,
      getDistributionChannelTxt,
      allShipmentsRoute,
      allAssignmentsRoute,
    }
  },
})
</script>

<style lang="scss" scoped>
.green-link {
  text-decoration: underline;
  color: #058880;

  &:hover {
    text-decoration: none;
  }
}
</style>
