<template>
  <div class="pb-3">
    <div class="child-row-item__title">
      <span v-if="belongsToSingleAssignment"> Single-item assignment </span>
      <span v-else> Batch assignment </span>
    </div>
    <div class="child-row-item__subtitle">
      <b-button variant="secondary" size="sm" @click="openAssignment">
        <i class="fa fa-info-circle" />
        {{ shipment.assignmentReference }}
      </b-button>

      <small v-if="!belongsToSingleAssignment" class="ml-2"> {{ shipment.assignmentItemsCount }} items </small>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, type PropType } from 'vue'
import { useRouter } from 'vue-router'
import { STAFF_ASSIGNMENT_SUMMARY, STAFF_ASSIGNMENTS_SINGLE } from '@/types/router'
import type { Shipment } from '@/types/Models/Shipment'

export default defineComponent({
  name: 'AssignmentLink',
  props: {
    shipment: {
      type: Object as PropType<Shipment>,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter()

    const belongsToSingleAssignment = computed(() => props.shipment.assignmentType === 'single')

    const pageLink = computed(() => ({
      name: belongsToSingleAssignment.value ? STAFF_ASSIGNMENT_SUMMARY : STAFF_ASSIGNMENTS_SINGLE,
      params: { id: props.shipment.assignmentId },
    }))

    function openAssignment() {
      router.push(pageLink.value)
    }

    return {
      belongsToSingleAssignment,
      openAssignment,
    }
  },
})
</script>
