<template>
  <div>
    <div class="container mb-5">
      <h2 class="form-title">Edit FAQ</h2>
      <VeeForm @submit="editFAQ">
        <Field v-slot="{ field, errors, meta }" :model-value="question" name="question" :rules="rules.question">
          <div class="form-group">
            <label class="fs-xl required">Question</label>
            <div class="input-group">
              <input
                v-bind="field"
                v-model="question"
                :class="['form-control', getValidationState(meta)]"
                type="text"
                placeholder="Question"
              />
              <div v-if="errors.length" class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </div>
          </div>
        </Field>

        <Field v-slot="{ field, errors, meta }" :model-value="answer" name="answer" :rules="rules.answer">
          <div class="form-group">
            <label class="fs-xl required">Answer</label>
            <div class="input-group">
              <input
                v-bind="field"
                v-model="answer"
                :class="['form-control', getValidationState(meta)]"
                type="text"
                placeholder="Answer"
              />
              <div v-if="errors.length" class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </div>
          </div>
        </Field>

        <div class="form-group mt-4">
          <button type="submit" class="btn create-btn btn-green">Update question</button>
        </div>
      </VeeForm>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, computed, defineComponent, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { Form as VeeForm, Field } from 'vee-validate'
import { FAQ_LIST } from '@/types/router'
import { getValidationState } from '@/services/Validation'

export default defineComponent({
  name: 'FAQCreate',
  components: { Field, VeeForm },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const question = ref('')
    const answer = ref('')

    const rules = {
      question: 'required|min:3',
      answer: 'required|min:3',
    }

    const isStaff = computed(() => store.getters['account/isStaff'])
    const faqId = computed(() => route.params.id)

    const editFAQ = async () => {
      await store
        .dispatch('faq/updateQuestion', {
          id: faqId.value,
          data: {
            question: question.value,
            answer: answer.value,
          },
        })
        .then(() => {
          router.push({ name: FAQ_LIST })
        })
    }
    const loadFAQ = async () => {
      await store.dispatch('faq/fetchQuestion', faqId.value).then((data) => {
        question.value = data.question
        answer.value = data.answer
      })
    }

    onMounted(() => {
      if (!isStaff.value) {
        router.push({ name: FAQ_LIST })
      }
      loadFAQ()
    })

    watch(faqId, loadFAQ)

    return {
      question,
      answer,
      rules,
      isStaff,
      editFAQ,
      getValidationState,
    }
  },
})
</script>
