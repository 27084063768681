<template>
  <modal-wrap
    :id="id"
    ref="PackingSlipProxy"
    :name="modalName"
    :header-class="'service-level-header'"
    :body-class="'service-level-modal'"
    :hide-footer="true"
    size="lg"
  >
    <template #modal-title> Create Packing Slip </template>
    <template #default>
      <PackingSlipLoader :packing-slip="packingSlip" />
      <div v-if="packingSlip && packingSlip.state === READY" class="p-4">
        <PackingSlipPreview :packing-slip="packingSlip" />
      </div>
    </template>
  </modal-wrap>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { PACKING_SLIP } from '@/const/ModalTypes'
import { READY } from '@/const/PackingSlipEventTypes'
import ModalWrap from '@/views/Modals/ModalWrap.vue'
import PackingSlipPreview from '@/views/Components/PackingSlip/PackingSlipPreview.vue'
import PackingSlipLoader from '@/views/Components/Loaders/PackingSlipLoader.vue'

export default defineComponent({
  name: 'CreatePackingSlipModal',
  components: {
    PackingSlipLoader,
    PackingSlipPreview,
    ModalWrap,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup() {
    const store = useStore()

    const modalName = PACKING_SLIP
    const packingSlip = computed(() => store.getters['packingSlip/getPackingSlip'])

    return {
      modalName,
      packingSlip,
      READY,
    }
  },
})
</script>
