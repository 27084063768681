<template>
  <i :class="iconClasses" :style="iconStyle"></i>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'BIcon',
  props: {
    icon: {
      type: String,
      required: true,
    },
    scale: {
      type: [String, Number],
      default: 1,
    },
    shiftH: {
      type: [String, Number],
      default: 0,
    },
    shiftV: {
      type: [String, Number],
      default: 0,
    },
    variant: {
      type: String,
      default: 'dark',
    },
  },
  setup(props) {
    const iconClasses = computed(() => ['bi', `bi-${props.icon}`])
    const iconStyle = computed(() => ({
      fontSize: `${props.scale}em`,
      transform: `translate(${props.shiftH}%, ${props.shiftV}%)`,
      color: `var(--bs-${props.variant})`,
    }))

    return {
      iconClasses,
      iconStyle,
    }
  },
})
</script>
