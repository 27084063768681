import { BaseProxy, type ProxyMethods } from '@/proxies/Proxy'
import type { AxiosCollection } from '@/types/Components/Response'
import type { ItemData } from '@/types/Models/Item'

export default class AssignmentProxy extends BaseProxy implements ProxyMethods {
  public endpoint = 'assignments'

  getItems(assignmentId: string): Promise<AxiosCollection<ItemData>> {
    return this.submit('get', `/${this.endpoint}/${assignmentId}/items`)
  }

  getStatus(assignmentId: string) {
    return this.submit('get', `/${this.endpoint}/${assignmentId}/status`)
  }

  // todo - deprecate this usage
  getLabelsStatuses(assignmentId: string) {
    return this.submit('get', `/${this.endpoint}/${assignmentId}/labels-statuses`)
  }

  validate(assignmentId: string) {
    return this.submit('post', `/${this.endpoint}/${assignmentId}/validate`)
  }

  getReportData(assignmentId: string) {
    return this.submit('get', `/${this.endpoint}/${assignmentId}/report`)
  }

  createLabels(assignmentId: string, payload: any) {
    return this.submit('post', `/${this.endpoint}/${assignmentId}/create-labels`, payload)
  }

  submitOptimize(assignmentId: string) {
    return this.submit('post', `/${this.endpoint}/${assignmentId}/optimize`)
  }

  getAssignmentSummary(assignmentId: string) {
    return this.submit('get', `/${this.endpoint}/${assignmentId}/single-parcel/summary`)
  }

  getLabelsMultiple(payload: any) {
    return this.submit('post', `/${this.endpoint}/labels/download`, payload)
  }

  attachSourceFile(assignmentId: string, payload: any) {
    return this.submit('post', `/${this.endpoint}/${assignmentId}/file`, payload)
  }
}
