<template>
  <div class="main__search-field">
    <b-input-group>
      <template #prepend>
        <div class="input-group-prepend">
          <button class="bg-white" @click="genericSearch">
            <Tooltip text="Click search icon or press enter to search">
              <SearchIcon class="text-secondary" />
            </Tooltip>
          </button>
        </div>
      </template>
      <input
        id="searchElement"
        ref="filter"
        v-model="searchText"
        placeholder="Search"
        type="text"
        autocomplete="off"
        class="form-control"
        @keyup.enter="genericSearch"
      />
      <template #append>
        <div class="input-group-append d-flex">
          <button
            v-if="searchText.length > 0"
            class="mr-1 clear d-flex justify-content-center align-items-center clear-button"
            @click="clear"
          >
            x
          </button>
          <button id="tooltip-target-1" class="bg-white" @click="toggleShow">
            <mdicon name="tune-vertical" class="text-secondary" />
          </button>
        </div>
      </template>
    </b-input-group>
    <div class="col-12 position-relative bg-white p-0">
      <div
        v-show="show"
        id="country"
        class="position-absolute col-12 bg-white p-3 cards-shadow mt-2 rounded search-dropdown"
      >
        <p class="mb-0 text-secondary">Filters</p>
        <div class="form-group d-flex col-12 p-0">
          <label class="d-inline col-4 p-0 mb-0">Country</label>
          <CountryPicker
            ref="country"
            class="col-8 p-0 country-field"
            name="Sender country"
            vid="country_code"
            placeholder="Country"
            @change="updateCountry"
            @select="searchByCountry"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, onMounted, defineComponent } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Tooltip from '@/views/Components/Elements/Tooltip.vue'
import SearchIcon from '@/views/Components/Elements/Icons/SearchIcon.vue'
import CountryPicker from '@/views/Components/Elements/Form/CountryPicker.vue'
import { BY_COUNTRY, SEARCH } from '@/const/AssignmentListQueryFilters'

export default defineComponent({
  name: 'SearchFilter',
  components: {
    CountryPicker,
    Tooltip,
    SearchIcon,
  },
  setup() {
    const searchText = ref<string>('')
    const country = ref<string>('')
    const show = ref<boolean>(false)
    const route = useRoute()
    const router = useRouter()

    onMounted(() => {
      searchText.value = String(route.query[SEARCH] || '')
      country.value = String(route.query[BY_COUNTRY] || '')
    })

    const genericSearch = () => {
      const query = { ...route.query }

      if (searchText.value) {
        query[SEARCH] = searchText.value
      } else {
        delete query[SEARCH]
      }

      router.push({ name: route.name!, query })
    }

    const searchByCountry = () => {
      const query = { ...route.query }

      if (country.value) {
        query[BY_COUNTRY] = country.value
      } else {
        delete query[BY_COUNTRY]
      }

      router.push({ name: route.name!, query })
    }

    const clear = () => {
      country.value = ''
      searchText.value = ''
      show.value = false

      const query = { ...route.query }

      delete query[SEARCH]
      delete query[BY_COUNTRY]

      router.push({ name: route.name!, query })
    }

    const updateCountry = (e: Event) => {
      country.value = (e.target as HTMLInputElement).value
    }

    const toggleShow = () => {
      show.value = !show.value
    }

    return {
      searchText,
      country,
      show,
      genericSearch,
      searchByCountry,
      clear,
      updateCountry,
      toggleShow,
    }
  },
})
</script>

<style lang="scss">
#country .form-group {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 0;
  margin-bottom: 0;

  input {
    width: 100% !important;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-radius: 0;
    height: 40px;
  }

  datalist {
    margin-bottom: 0 !important;
  }
}
</style>

<style scoped lang="scss">
.main__search-field {
  .form-control {
    border: 1px solid #c8c8c8;
    border-left: 0;
    padding-left: 5px;
    z-index: 0;
  }

  .input-group-text {
    border: 1px solid #c8c8c8;
    background: #ffffff;
  }

  input,
  dropdown {
    height: 40px;
    border-right: 0 !important;
  }

  .search-dropdown {
    z-index: 20;
  }

  .clear {
    padding: 2px;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    margin-top: 3px;
  }

  .clear-button {
    font-size: 12px;
    width: 16px;
    height: 16px;
    margin-top: 4px;
  }
}

.country-field {
  &:deep(input) {
    padding: 0;
  }
}
</style>
