<template>
  <div class="pb-3">
    <div class="child-row-item__title">
      {{ ucFirst(typeName) }}
    </div>
    <div class="child-row-item__subtitle">
      <b-button size="sm" target="_blank" @click="openLabel">
        <i class="fa fa-download" />
        {{ linkText }}
      </b-button>
    </div>
  </div>
</template>

<script lang="ts">
import { ucFirst } from '@/services/Helpers'
import { defineComponent, computed, type PropType } from 'vue'
import type { Shipment } from '@/types/Models/Shipment'

export default defineComponent({
  name: 'ShipmentLabel',
  props: {
    shipment: {
      type: Object as PropType<Shipment>,
      required: true,
    },
    typeName: {
      type: String,
      required: false,
      default: 'label',
    },
    url: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const linkText = computed(() => {
      return `${props.shipment.carrierHandle}_${props.shipment.shipmentNumber}_${props.typeName.toLowerCase()}.pdf`
    })

    function openLabel() {
      window.open(props.url, '_blank')
    }

    return {
      linkText,
      openLabel,
      ucFirst,
    }
  },
})
</script>

<style scoped></style>
