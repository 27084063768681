<template>
  <b-overlay :show="isShow" class="loader" fixed no-wrap no-center>
    <template #overlay>
      <div class="loader">
        <div class="wrap-custom-progress">
          <h1 class="mb-3">Downloading Labels</h1>
          <b-progress height="45px" :max="max">
            <span class="progress-value">{{ progress }} %</span>
            <b-progress-bar :value="progress" />
          </b-progress>
          <p class="mt-1 text-xxs" v-text="message ? message : 'Waiting for the server to start processing.'" />
          <p class="text-center mt-4">This can take up to several minutes, depending on how busy the server is.</p>
        </div>
      </div>
    </template>
  </b-overlay>
</template>

<script lang="ts">
import { ref, computed, watch, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { checkMessage, parseLoaderProgress } from '@/services/WebSockets'
import { LABELS_DOWNLOAD_LOADER } from '@/const/LoadersNames'

export default defineComponent({
  name: 'DownloadLabelsLoader',
  props: {
    show: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const store = useStore()
    const max = ref(100)
    const progress = ref(0)
    const message = ref<string | null>(null)

    const loader = computed(() => store.getters['ui/loader'])
    const userId = computed(() => store.getters['account/userId'])
    const isShow = computed(() => LABELS_DOWNLOAD_LOADER === loader.value.name && loader.value.show)

    watch(isShow, (val) => {
      const channel = `App.User.${userId.value}`
      if (val) {
        message.value = null
        checkMessage(message, progress)
        window.Echo.private(channel)
          .listen('Assignment\\AssignmentLabelsCreated', (result: any) => {
            progress.value = 100
            setTimeout(() => {
              store.dispatch('ui/loaderHide')
              window.open(result.labelsDownloadUrl, '_blank')
              if (typeof loader.value.data.callback !== 'undefined') {
                loader.value.data.callback()
              }
            }, 1000)
          })
          .listen('Assignment\\AssignmentLabelsFailed', (result: any) => {
            progress.value = 100
            setTimeout(() => {
              store.dispatch('ui/loaderHide')
              const link = document.createElement('a')
              link.href = result.labelsDownloadUrl
              link.setAttribute('download', '')
              link.click()
            }, 1000)
          })
          .listen('.loader-progress', (response: any) => parseLoaderProgress(response, message, progress))
        progress.value = 0
      } else {
        window.Echo.leave(channel)
        progress.value = 0
      }
    })

    return {
      max,
      progress,
      message,
      isShow,
    }
  },
})
</script>

<style scoped></style>
