<template>
  <div class="bg-dark-green">
    <div class="w-100">
      <div class="d-flex align-items-center justify-content-center w-100 flex-column">
        <div class="logo mt-5">
          <a href="https://www.21grams.se/">
            <img src="@/assets/img/logo.svg" alt="" />
          </a>
        </div>
        <img src="@/assets/img/21grams-white-logo.svg" class="mt-5" alt="" />
        <div class="unsupported-text">
          Unfortunately, this site is not supported by mobile version. Please use desktop version. Thank you.
        </div>
      </div>
    </div>
    <div class="pattern" />
    <div class="all-reserved">
      (c) 2021. All right reserved

      <div>
        <router-link class="text-light" :to="FAQ_LIST"> FAQ </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { FAQ_LIST } from '@/types/router'

export default defineComponent({
  name: 'MobileUnsupported',
  setup() {
    return {
      FAQ_LIST,
    }
  },
})
</script>

<style lang="scss" scoped>
.unsupported-text {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 135.5%;
  text-align: center;
  color: #ffffff;
  padding: 0 40px;
  margin-top: 125px;
}
.pattern {
  background-position: 40% -45%;
  background-size: unset;
}
.all-reserved {
  position: fixed;
  bottom: 20px;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 135.5%;
  color: #ffffff;
  text-align: center;
}
</style>
