import type { Address } from '@/types/Models/Customer'

export default class SenderAddressTransformer {
  /**
   * Method used to transform a fetched account.
   */
  static fetch(data: Address): Address {
    return {
      id: data.id,
      coAddress: data.coAddress,
      addressLine: data.addressLine,
      addressLine2: data.addressLine2,
      city: data.city,
      countryCode: data.countryCode,
      email: data.email,
      company: data.company,
      firstName: data.firstName,
      fullName: data.fullName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
      postalCode: data.postalCode,
      hasCustomPrices: data.hasCustomPrices,
      hasCustomPriorities: data.hasCustomPriorities,
      clientNumber: data.clientNumber,
      region: data.region,
      isDefault: data.isDefault,
      type: data.type,
      customNumbers: data.customNumbers || [],
      handle: data.id,
      label: `${data.company || data.firstName}, ${data.countryCode}, ${data.city}, ${data.coAddress || data.addressLine}`,
    } as Address
  }

  /**
   * Method used to transform a fetched collection.
   */
  static fetchCollection(items: any[]): Address[] {
    return items.map((item) => this.fetch(item))
  }
}
