<template>
  <span>
    <ViewAssignment v-if="isReportOrigin" />
    <ViewReport v-else />
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ViewReport from '@/views/Components/Assignment/Actions/Buttons/ViewReport.vue'
import ViewAssignment from '@/views/Components/Assignment/Actions/Buttons/ViewAssignment.vue'

export default defineComponent({
  name: 'ViewAdjacent',
  components: { ViewAssignment, ViewReport },
  props: {
    isReportOrigin: {
      type: Boolean,
      required: true,
    },
  },
})
</script>
