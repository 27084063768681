<template>
  <div class="row">
    <div class="col-md-3">
      <div class="form-group">
        <VSelect
          :options="categories"
          :rules="{ required: true }"
          placeholder="Category *"
          fieldset-class="category-select"
          vid="item.invoiceType"
          name="Category"
        />
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group currency-dropdown">
        <dropdown
          :with-search="true"
          :options="currencies"
          :dropdown-text="`${selectedCurrency.icon} ${selectedCurrency.value}`"
        />
      </div>
    </div>
    <div class="col-md-6 mt-3 mt-md-0">
      <div class="custom-alert">
        <div class="custom-alert-image">
          <mdicon name="Alert" />
        </div>
        <div class="custom-alert-text">
          Customs declaration must be filled out correctly. If in doubt, please read more
          <router-link :to="{ name: PROFORMA_INVOICE }" target="_blank"> here </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { SelectOptions } from '@/types/Components/SelectOptions'
import { defineComponent, computed, ref, type ComputedRef, type Ref } from 'vue'
import { getProformaInvoiceTypes } from '@/services/Utils'
import { PROFORMA_INVOICE } from '@/types/router'
import allCurrencies from '@/assets/files/currencies.json'
import Dropdown from '@/views/Components/Elements/Dropdown.vue'
import VSelect from '@/views/Components/Elements/Form/VSelect.vue'

export default defineComponent({
  name: 'DeclarationData',
  components: { VSelect, Dropdown },
  setup() {
    const categories: ComputedRef<SelectOptions[]> = computed(getProformaInvoiceTypes)

    const currencies: Ref<SelectOptions[]> = ref(
      allCurrencies.map((currency) => ({
        text: `${currency.icon} ${currency.value}`,
        value: currency.value,
      })),
    )

    const selectedCurrency = computed(() => allCurrencies[0])

    return {
      categories,
      currencies,
      PROFORMA_INVOICE,
      selectedCurrency,
    }
  },
})
</script>

<style lang="scss"></style>
