<template>
  <div class="table-tabs easy-data-table-dark">
    <easy-data-table
      ref="dataTable"
      v-model:server-options="serverOptions"
      v-model:items-selected="checkedItems"
      table-class-name="vue3-easy-data-table table table-shadow"
      :server-items-length="serverItemsLength"
      :loading="loading"
      :headers="headers"
      :items="items"
      :prevent-context-menu-row="false"
      :fixed-header="false"
      no-hover
      alternating
      hide-footer
      border-cell
      @update:server-options="updateServerOptions"
    >
      <template #loading>
        <div style="text-align: center">Loading...</div>
      </template>
      <template #no-data>
        <div style="text-align: center">No data available</div>
      </template>
    </easy-data-table>

    <TablePagination
      v-model:server-options-ref="serverOptions"
      v-model:data-table-ref="dataTable"
      :server-items-length="serverItemsLength"
    />
  </div>

  <modal-wrap :name="statisticsModalName" :hide-footer="true" :hide-header-close="false" size="lg">
    <template #default>
      {{ error }}
    </template>
  </modal-wrap>

  <div v-if="serverItemsLength > 7" class="table-side-scroll">
    <button @click="scrollLeft">
      <mdicon name="ChevronLeft" />
    </button>
    <button @click="scrollRight">
      <mdicon name="ChevronRight" />
    </button>
  </div>
</template>

<script lang="ts">
import { type Ref, defineComponent, ref, watch, onMounted } from 'vue'
import { registerModals } from '@/services/Modal'
import { scrollLeft, scrollRight } from '@/services/TableScroll'
import StatisticsProxy from '@/proxies/StatisticsProxy'
import EasyDataTable, { type ServerOptions } from 'vue3-easy-data-table'
import moment from 'moment'
import TablePagination from '@/views/Components/Elements/Table/TablePagination.vue'
import ModalWrap from '@/views/Modals/ModalWrap.vue'

export default defineComponent({
  name: 'StatisticsList',
  components: { ModalWrap, TablePagination, EasyDataTable },
  props: {
    startDate: {
      type: String,
      required: false,
      default: moment().subtract(1, 'week').format('YYYY-MM-DD'),
    },
    endDate: {
      type: String,
      required: false,
      default: moment().format('YYYY-MM-DD'),
    },
    query: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const dataTable = ref(null)
    const items: Ref<any[]> = ref([]) //TODO: type
    const checkedItems: Ref<any[]> = ref([])
    const error = ref(false)
    const loading = ref(false)
    const serverItemsLength = ref(0)
    const statisticsModalName = 'statistics-modal'
    const serverOptions: Ref<ServerOptions> = ref({
      page: 1,
      rowsPerPage: 50,
      sortBy: 'createdAt',
      sortType: 'desc',
    })
    const headers = [
      { text: 'Creation Date', value: 'createdAt', sortable: true },
      { text: 'Creation Time', value: 'createdAtTime' },
      { text: 'First Name*', value: 'firstName' },
      { text: 'Last Name*', value: 'lastName' },
      { text: 'Company', value: 'company' },
      { text: 'Assgn #', value: 'assignmentReference', sortable: true },
      { text: 'Address Line 1*', value: 'addressLine' },
      { text: 'Address Line 2*', value: 'addressLine2' },
      { text: 'Postcode', value: 'postalCode' },
      { text: 'Country', value: 'country' },
      { text: 'City*', value: 'city' },
      { text: 'E-mail*', value: 'email' },
      { text: 'Phone', value: 'phoneNumber' },
      { text: 'Carrier', value: 'carrierHandle' },
      { text: 'Tracking Number', value: 'shipmentNumber' },
      { text: 'Shipping Cost', value: 'shippingCost' },
      { text: 'Service Level*', value: 'serviceLevel' },
      { text: 'Weight*(g)', value: 'weight' },
      { text: 'Height*(cm)', value: 'height' },
      { text: 'Length*(cm)', value: 'length' },
      { text: 'Width*(cm)', value: 'width' },
      { text: 'Sender first Name*', value: 'senderFirstName' },
      { text: 'Sender Last Name*', value: 'senderLastName' },
      { text: 'Sender', value: 'senderCompany' },
      { text: 'Short Name', value: 'companyShortName' },
      { text: 'Send Date*', value: 'sendDate' },
      { text: 'Shipping Status', value: 'shippingStatus' },
      {
        text: 'Shipping Status Description',
        value: 'shippingStatusDescription',
      },
      { text: 'Client No', value: 'clientNo' },
      { text: 'Invoice ref.', value: 'customerReference' },
    ]

    const loadFromServer = async () => {
      loading.value = true
      const params = {
        page: serverOptions.value.page,
        limit: serverOptions.value.rowsPerPage,
        ascending: serverOptions.value.sortType === 'asc' ? 1 : 0,
        orderBy: serverOptions.value.sortBy || 'createdAt',
        startDate: props.startDate || moment().subtract(1, 'week').format('YYYY-MM-DD'),
        endDate: props.endDate || moment().format('YYYY-MM-DD'),
        query: props.query,
      }

      new StatisticsProxy(params)
        .getAdvancedFinance()
        .then((response) => {
          items.value = response.data || []
          serverItemsLength.value = response.count || 0
          loading.value = false
          return response
        })
        .catch(() => {
          serverItemsLength.value = 0
          loading.value = false
          items.value = []
          error.value = true
        })
    }

    const updateServerOptions = (newOptions: any) => {
      serverOptions.value = newOptions
    }

    watch(serverOptions, loadFromServer, { deep: true })
    watch(props, loadFromServer, { deep: true })

    onMounted(() => {
      loadFromServer()
      registerModals(statisticsModalName)
    })

    return {
      scrollRight,
      scrollLeft,
      dataTable,
      items,
      error,
      loading,
      headers,
      checkedItems,
      serverItemsLength,
      serverOptions,
      updateServerOptions,
      statisticsModalName: 'statistics-modal',
    }
  },
})
</script>

<style scoped lang="scss">
.table-tabs {
  :deep(.table-shadow) {
    td {
      white-space: nowrap;
    }
    tr > td:nth-child(1) {
      width: 100px;
    }
    th {
      border-right: none !important;
    }
  }
}
</style>
