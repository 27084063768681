<template>
  <div v-if="summary.length" class="pb-3">
    <div class="child-row-item__title">Address validation</div>
    <div class="child-row-item__subtitle">
      <table class="w-100">
        <tbody v-if="scalarSummary">
          <tr>
            <td>{{ summary[0] }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-for="[carrier, context] in summary" :key="carrier">
            <td>{{ carrierName(carrier) }}</td>
            <td>
              <textarea class="address-validation-context" :value="context" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { CARRIERS_NAMES } from '@/const/Carriers'
import type { PropType } from 'vue'
import type { Shipment } from '@/types/Models/Shipment'

export default defineComponent({
  name: 'AddressValidation',
  props: {
    shipment: {
      type: Object as PropType<Shipment>,
      required: true,
    },
  },
  setup(props) {
    const parsed = computed(() =>
      props.shipment.optimisationSummary ? JSON.parse(props.shipment.optimisationSummary) : {},
    )

    const summary = computed(() => parsed.value.summary || [])

    const scalarSummary = computed(() => summary.value.length === 1 && typeof summary.value[0] === 'string')

    function carrierName(carrierHandle: string) {
      if (!carrierHandle) return ''
      return CARRIERS_NAMES[carrierHandle]
    }

    return {
      summary,
      scalarSummary,
      carrierName,
    }
  },
})
</script>

<style lang="scss" scoped>
.address-validation-context {
  width: 100%;
  height: 80px;
  background: none;
}
</style>
