import { AbilityBuilder, PureAbility, type AbilityClass } from '@casl/ability'

type Actions = 'manage' | 'view' | 'create'
type Subjects =
  | 'staff.area'
  | 'prices'
  | 'proxy'
  | 'pickupDate'
  | 'pickups-list'
  | 'shipments'
  | 'assignmentsList'
  | 'assigment'
  | 'customer'
  | 'single-parcel-assigment'
  | 'faq'
  | 'customer.area'

export const defineAbilitiesFor = (account: any) => {
  const { can, build } = new AbilityBuilder<PureAbility<[Actions, Subjects]>>(
    PureAbility as AbilityClass<PureAbility<[Actions, Subjects]>>,
  )

  if (account && account.type === 'staff') {
    can('manage', 'staff.area')
    can('manage', 'prices')
    can('manage', 'proxy')
    can('manage', 'pickupDate')
    can('view', 'pickups-list')
    can('view', 'shipments')
    can('view', 'assignmentsList')
    can('create', 'assigment')
    can('create', 'customer')
    can('manage', 'assigment')
    can('manage', 'single-parcel-assigment')
    can('manage', 'customer')
    can('manage', 'faq')
  } else if (account && account.type === 'customer') {
    can('manage', 'customer.area')
    can('create', 'assigment')
    can('manage', 'assigment')
    can('manage', 'single-parcel-assigment')
    can('view', 'shipments')
    can('view', 'assignmentsList')
  }

  return build()
}
