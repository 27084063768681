<template>
  <div class="pt-1 border-bottom">
    <b-card-header
      header-tag="header"
      role="tab"
      class="text-dark text-left pl-0 d-flex justify-content-between align-items-center p-1"
    >
      <div class="d-flex justify-content-between align-items-center col-11 pl-0">
        <b-button
          class="col-9 text-left text-dark pl-0 single-packing-slip-preview-button"
          variant="light"
          @click="showPackingSlip(packingSlip.id)"
        >
          <span>
            Packing slip created: <b>{{ formattedDate(packingSlip.createdAt, 'YYYY-MM-DD') }}</b>
          </span>
          <br />
        </b-button>
        <b-button
          class="packing-slip-d-b"
          :disabled="!downloadUrl || packingSlip.state === FAILED"
          :style="downloadUrl ? '' : 'opacity: 0.5;'"
          @click="downloadFile(downloadUrl)"
        >
          <b-spinner v-show="state === GENERATING || state === CREATED" variant="success" class="mr-2" small />
          <b-icon v-if="state === READY" icon="download" class="mr-2" variant="secondary" small />
          {{ state === FAILED ? 'Failed' : 'Download' }}
        </b-button>
      </div>
      <b-button variant="light" class="text-dark" @click="show = !show">
        <span v-if="show">
          <b-icon icon="chevron-up" />
        </span>
        <span v-if="!show">
          <b-icon icon="chevron-down" />
        </span>
      </b-button>
    </b-card-header>
    <b-card-body v-if="show">
      <div>
        <PackingSlipItemsTable :packing-slip="packingSlip" :index="index" class="mt-4" />
      </div>
    </b-card-body>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, type PropType, type Ref, type ComputedRef } from 'vue'
import { useStore } from 'vuex'
import { openModal } from '@/services/Modal'
import { formattedDate } from '@/services/Helpers'
import { SET_PACKING_SLIP } from '@/store/modules/packingSlip'
import { PACKING_SLIP_PREVIEW } from '@/const/ModalTypes'
import { GENERATING, READY, FAILED, CREATED } from '@/const/PackingSlipEventTypes'
import PackingSlipItemsTable from '@/views/Components/PackingSlip/PackingSlipItemsTable.vue'
import BIcon from '@/views/Components/Elements/Icons/BIcon.vue'
import type { PackingSlip } from '@/types/Models/PackingSlip'

export default defineComponent({
  name: 'SinglePackingSlip',
  components: {
    BIcon,
    PackingSlipItemsTable,
  },
  props: {
    packingSlip: {
      type: Object as PropType<PackingSlip>,
      required: true,
    },
    index: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore()
    const show: Ref<boolean> = ref(false)
    const updatedDownloadUrl: Ref<string | null> = ref(null)
    const updatedState: Ref<string | null> = ref(null)

    const downloadUrl: ComputedRef<string> = computed(() =>
      updatedDownloadUrl.value !== null ? updatedDownloadUrl.value : props.packingSlip.downloadUrl || '',
    )
    const state: ComputedRef<string | null> = computed(() =>
      updatedState.value !== null ? updatedState.value : props.packingSlip.state,
    )

    const userId = store.getters['account/userId']

    const listenToPackingSlipEvents = (packingSlip: PackingSlip) => {
      window.Echo.channel(`App.User.${userId}`)
        .listen(`.${packingSlip.id}.${GENERATING}`, (data: PackingSlip) => {
          store.commit(`packingSlip/${SET_PACKING_SLIP}`, data)
          updatedDownloadUrl.value = null
          updatedState.value = data.state
        })
        .listen(`.${packingSlip.id}.${READY}`, (data: PackingSlip) => {
          store.commit(`packingSlip/${SET_PACKING_SLIP}`, data)
          updatedDownloadUrl.value = data.downloadUrl
          updatedState.value = data.state
          store.dispatch('packingSlip/getPackingSlips')
          stopListeningToPackingSlipEvents(packingSlip)
        })
        .listen(`.${packingSlip.id}.${FAILED}`, (data: PackingSlip) => {
          store.commit(`packingSlip/${SET_PACKING_SLIP}`, data)
          stopListeningToPackingSlipEvents(packingSlip)
        })
    }

    const stopListeningToPackingSlipEvents = (packingSlip: PackingSlip) => {
      window.Echo.channel(`App.User.${userId}`)
        .stopListening(`.${packingSlip.id}.${GENERATING}`)
        .stopListening(`.${packingSlip.id}.${READY}`)
        .stopListening(`.${packingSlip.id}.${FAILED}`)
    }

    onMounted(() => {
      listenToPackingSlipEvents(props.packingSlip)
    })

    const showPackingSlip = (id: string) => {
      store.commit(`packingSlip/${SET_PACKING_SLIP}`, null)
      store.dispatch('packingSlip/getPackingSlip', id).then(() => {
        openModal(PACKING_SLIP_PREVIEW)
      })
    }

    const downloadFile = (link: string) => {
      window?.open(link, '_blank')?.focus()
    }

    return {
      show,
      updatedDownloadUrl,
      updatedState,
      downloadUrl,
      userId,
      state,
      READY,
      FAILED,
      CREATED,
      GENERATING,
      showPackingSlip,
      downloadFile,
      formattedDate,
    }
  },
})
</script>

<style scoped lang="scss">
:deep(.card-body) {
  padding: 0 !important;
}
:deep(.card-header) {
  border-bottom: 0 !important;
}
:deep(.packing-slip-d-b) {
  padding: 5px 30px !important;
  border-radius: 5px;
  background: transparent;
  border: 1px solid gray;
  color: gray;
  font-weight: bold;
}
.packing-slip-d-b:hover {
  background: gray;
  color: white;
}
.packing-slip-d-b:hover svg {
  fill: white;
}
.single-packing-slip-preview-button:hover {
  background: gray;
  color: white !important;
  padding-left: 10px !important;
}
</style>
