<template>
  <!-- ############ Route view For child pages-->
  <v-layout>
    <router-view />
  </v-layout>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import VLayout from '@/views/Layouts/DefaultLayout.vue'

export default defineComponent({
  name: 'Customer',
  components: {
    VLayout,
  },
})
</script>
