<template>
  <router-view :key="route.fullPath" />
  <notifications group="success" position="top center">
    <template #body="{ item, close }">
      <div class="vue-notification-template success">
        <div v-text="item.text" />
        <a class="close" @click="close">Close</a>
      </div>
    </template>
  </notifications>
  <notifications group="error" position="top center">
    <template #body="{ item, close }">
      <div class="vue-notification-template error">
        <div v-text="item.text" />
        <a class="close" @click="close">Close</a>
      </div>
    </template>
  </notifications>
  <notifications />
</template>

<script lang="ts">
import { defineComponent, onMounted, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { setupApp } from '@/services/Helpers'

export default defineComponent({
  name: 'LabelPortal',
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    onMounted(() => {
      if (store.getters['isLoggedIn']) {
        store.dispatch('account/me')
      }
    })

    onBeforeMount(() => {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        if (window.location.pathname === '/faq') {
          return
        }
        router.push({ name: 'mobile.unsupported' })
      }
    })

    setInterval(() => {
      setupApp()
    }, 60000)

    return {
      route,
    }
  },
})
</script>
