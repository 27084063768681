import { FAQ_PAGE, FAQ_EDIT, FAQ_CREATE, FAQ_LIST } from '@/types/router'
import type { RouteRecordRaw } from 'vue-router'

const faq: Array<RouteRecordRaw> = [
  {
    path: '/faq',
    name: FAQ_PAGE,
    component: () => import('@/views/FAQ/Index.vue'),
    meta: {
      auth: false,
      guest: true,
    },
    children: [
      {
        path: '',
        component: () => import('@/views/FAQ/List.vue'),
        name: FAQ_LIST,
        meta: {
          title: 'Questions & Answers',
        },
      },
      {
        path: 'create',
        component: () => import('@/views/FAQ/Create.vue'),
        name: FAQ_CREATE,
        meta: {
          title: 'Ask a Question',
          resource: 'faq',
          action: 'manage',
          auth: true,
        },
      },
      {
        path: 'edit/:id',
        component: () => import('@/views/FAQ/Edit.vue'),
        name: FAQ_EDIT,
        meta: {
          title: 'Edit a Question',
          resource: 'faq',
          action: 'manage',
          auth: true,
        },
      },
    ],
  },
]

export default faq
