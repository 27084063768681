<template>
  <header class="main-header">
    <div class="container">
      <div class="d-flex h-110">
        <div class="logo">
          <router-link :to="{ name: logoLink }">
            <img src="../../assets/img/logo.svg" alt="" />
          </router-link>
        </div>
        <div class="header-title align-self-center">
          <img src="../../assets/img/21grams-dark-logo.svg" alt="" />
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { LOGIN_PAGE, STAFF_HOME_PAGE, CUSTOMER_HOME_PAGE } from '@/types/router'

export default defineComponent({
  name: 'SimpleHeader',
  setup() {
    const store = useStore()

    const isStaff = computed(() => store.getters['account/isStaff'])
    const isAuth = computed(() => store.getters['isLoggedIn'])

    const logoLink = computed(() => {
      if (!isAuth.value) {
        return LOGIN_PAGE
      }
      return isStaff.value ? STAFF_HOME_PAGE : CUSTOMER_HOME_PAGE
    })

    return {
      logoLink,
    }
  },
})
</script>
