import type { Integration } from '@/types/Models/Integration'

export default class IntegrationsTransformer {
  /**
   * Method used to transform a fetched account.
   */
  static fetch(data: Integration): Integration {
    return {
      id: data.id || '',
      system: data.system || '',
      user_external_id: data.user_external_id || '',
      details: data.details || {},
    }
  }

  /**
   * Method used to transform a fetched collection.
   */
  static fetchCollection(items: Integration[]) {
    return items.map((item: Integration) => this.fetch(item))
  }
}
