/* ============
 * Assignment Transformer
 * ============
 *
 * The transformer for the Assignment.
 */

import ItemTransformer from '@/transformers/ItemTransformer'
import type { ListAssignmentResponse } from '@/types/Models/Assignment'
import type { ListAssignment } from '@/types/Models/Assignment'

export default class AssignmentTransformer {
  /**
   * Method used to transform a fetched Assignment.
   */
  static fetch(data: ListAssignmentResponse): ListAssignment {
    const assignment: ListAssignment = {
      ...data,
      item: data.item ? ItemTransformer.fetch(data.item) : null,
      sourceFiles: data.sourceFiles || [],
      optimisationReportUrl: data.optimisationReportUrl || null,
      customerReference: data.customerReference || null,
      hasCustomPrices: data.hasCustomPrices || false,
      hasCustomPriorities: data.hasCustomPriorities || false,
      archived: data.archived || false,
      denyLabelsDownload: data.denyLabelsDownload || false,
    }

    return { ...data, ...assignment }
  }
}
