<template>
  <div class="general-spinner-parent">
    <div class="wrap-custom-progress">
      <div class="wrap-spinner">
        <div class="spinner" />
        <h3>
          <slot />
        </h3>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Spinner',
})
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';
.general-spinner-parent {
  .wrap-custom-progress {
    max-width: 1240px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    height: calc(100% - 110px); // 110 - height of header
    justify-content: center;
    position: relative;
    .progress {
      border: 1px solid $orange;
      background: transparent;
      &-bar {
        background: $orange;
        position: relative;
        overflow: visible;
        &:after {
          content: '';
          position: absolute;
          width: 30px;
          height: 90px;
          background: $orange;
          right: -15px;
          transform: rotate(30deg);
        }
      }
      & + p {
        font-size: 16px;
      }
      &-value {
        position: absolute;
        right: 0;
        font-size: 26px;
        line-height: 44px;
        top: 50%;
        transform: translateY(-90px);
      }
    }
  }

  .wrap-spinner {
    text-align: center;
    font-size: 16px;
    line-height: 44px;
  }

  .spinner {
    color: $green;
    font-size: 8px;
    margin: 50px auto;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: loading 1.3s infinite linear;
    animation: loading 1.3s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
}
</style>
