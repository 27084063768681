/* ============
 * Pickup Transformer
 * ============
 *
 * The transformer for the Pickup.
 */

export default class PickupTransformer {
  /**
   * Method used to transform a fetched Pickup.
   */
  static fetch(pickup: any) {
    return {
      id: pickup.id,
      status: pickup.status,
      carrier: pickup.carrier_handle,
      instructions: pickup.pickup_instructions,
      identifier: pickup.pickup_identifier,
      pickupDate: pickup.pickup_date,
      createdAt: pickup.created_at,
    }
  }

  /**
   * Method used to transform a fetched collection.
   */
  static fetchCollection(items: any[]) {
    return items.map((item) => this.fetch(item))
  }
}
