import { BOX, LETTER, PACKAGE, PARCEL } from '@/const/DistributionChannels'
import type { Ref } from 'vue'
import { computed } from 'vue'

type Props = {
  isStaff: Ref<boolean>
}

export const useDistributionChannels = ({ isStaff }: Props) => {
  return computed(() => [
    {
      code: PACKAGE,
      name: 'Packet',
      description: 'Mailbox delivery (or to the door step) – Up to 5 kg.',
      class: isStaff.value ? 'col-12 col-md-3' : 'col-12 col-md-6',
      beta: false,
      staffOnly: false,
      icon: 'fa fa-book',
    },
    {
      code: PARCEL,
      name: 'Parcel',
      description: 'Choose between delivery to Service point (B2C) or by Carrier (B2B).',
      class: isStaff.value ? 'col-12 col-md-4' : 'col-12 col-md-6',
      beta: false,
      staffOnly: false,
      icon: 'fa fa-cube',
    },
    {
      code: BOX,
      name: 'Parcel locker',
      description: 'Parcel lockers located outside or indoors.',
      class: 'col-12 col-md-3',
      beta: true,
      staffOnly: true,
      icon: 'fa fa-lock',
    },
    {
      code: LETTER,
      name: 'Letter',
      description: 'Up to 2 kg.',
      class: 'col-12 col-md-2',
      beta: true,
      staffOnly: true,
      icon: 'fa fa-envelope',
    },
  ])
}
