<template>
  <div class="d-flex align-items-center mr-auto">
    <mdicon v-if="icon" :name="icon" size="24" />
    <h2 :class="{ 'ml-2': icon }" class="text mb-0">
      {{ title }}
    </h2>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TableTitle',
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
      default: '',
    },
  },
})
</script>
