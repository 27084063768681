<template>
  <div>
    <AssignmentForm />
  </div>
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent } from 'vue'

export default defineComponent({
  name: 'SingleAssignmentEdit',
  components: {
    AssignmentForm: defineAsyncComponent(
      () => import('@/views/Components/Assignment/AssignmentForm/AssignmentForm.vue'),
    ),
  },
})
</script>
