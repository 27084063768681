import type { KeyAccessor } from '@/types/Helpers/Arrays'

export const INFO_RECEIVED = 'info_received'
export const IN_TRANSIT = 'in_transit'
export const OUT_FOR_DELIVERY = 'out_for_delivery'
export const DELIVERED = 'delivered'
export const DELIVERY_ISSUE = 'delivery_issue'
export const EXCEPTION = 'exception'
export const FAILED_ATTEMPT = 'failed_attempt'
export const RETURNED = 'returned'

export const TRACKING_STATUS_NAMES: KeyAccessor = {
  [INFO_RECEIVED]: 'Info received',
  [IN_TRANSIT]: 'Transit',
  [OUT_FOR_DELIVERY]: 'Out for delivery',
  [DELIVERED]: 'Delivered',
  [DELIVERY_ISSUE]: 'Issue',
  [FAILED_ATTEMPT]: 'Failed attempt',
  [EXCEPTION]: 'Exception',
  [RETURNED]: 'Returned',
}
