import type { RootStoreState, UILoader, UIStoreState } from '@/types/Store'
import type { ActionContext } from 'vuex'

export const SHOW = 'SHOW'
export const HIDE = 'HIDE'

const state: UIStoreState = {
  loader: {
    name: '',
    show: false,
    data: {},
  },
}

type Context = ActionContext<UIStoreState, RootStoreState>

const actions = {
  loaderShow: ({ commit }: Context, payload: UILoader) => commit(SHOW, payload),
  loaderHide: ({ commit }: Context, payload: UILoader) => commit(HIDE, payload),
}

const getters = {
  loader: (state: UIStoreState) => state.loader,
}

const mutations = {
  [SHOW]: (state: UIStoreState, { name, data }: UILoader) => {
    state.loader = {
      name,
      show: true,
      data,
    }
  },
  [HIDE]: (state: UIStoreState) => {
    state.loader = { name: null, show: false, data: {} }
  },
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
}
