import { useModal } from 'bootstrap-vue-next'
import { none } from '@/services/Helpers'
import store from '@/store'

const modals: any[] = []

export const registerModals = (name: string) => {
  const existingModalIndex = modals.findIndex((modal: any) => modal.name === name)
  if (existingModalIndex !== -1) {
    modals.splice(existingModalIndex, 1) // Remove existing modal
  }

  const { show, hide, modal } = useModal(name)
  modals.push({ name, show, hide, modal })
}

export const findModal = (name: string) => {
  return modals.find((modal: any) => modal.name === name)
}

export const openModal = (modalName: string, data = {}) => {
  store.dispatch('vueNextModal/openModal', { name: modalName, data }).then(none)
}
