<template>
  <b-card class="shipments-filter">
    <h3 class="pb-4">Filter shipments</h3>

    <div v-if="customers.length" class="pb-4"><b>Customer:</b> {{ customers.map((c) => c.company).join(', ') }}</div>

    <div class="d-flex pb-3 status-filter">
      <div v-for="{ value, text } in statusesData" :key="value" class="pr-4">
        <div class="form-check">
          <input :id="'status-' + value" v-model="statuses" class="form-check-input" type="checkbox" :value="value" />
          <label class="form-check-label" :for="'status-' + value">
            {{ text }}
          </label>
        </div>
      </div>
    </div>

    <div class="d-flex pb-3">
      <div v-for="carrierHandle in carriersData" :key="carrierHandle" class="pr-4">
        <div class="form-check">
          <input
            :id="'carrier-' + carrierHandle"
            v-model="carriers"
            class="form-check-input"
            type="checkbox"
            :value="carrierHandle"
          />
          <label class="form-check-label" :for="'carrier-' + carrierHandle">
            <CarrierRowWithIcon :carrier="carrierHandle" />
          </label>
        </div>
      </div>
    </div>

    <div class="row mb-3 delivery-filter">
      <div class="col-6">
        <div class="d-flex">
          <div class="form-check mr-3">
            <input id="diverted" v-model="packagesDivertedToServicePoint" class="form-check-input" type="checkbox" />
            <label class="form-check-label" for="diverted"> Diverted to service-point </label>
          </div>
          <div v-if="packagesDivertedToServicePoint" class="form-check">
            <input
              id="service-point"
              v-model="withServicePointCollectingProblem"
              class="form-check-input"
              type="checkbox"
            />
            <label class="form-check-label" for="service-point"> Where we could not collect service-point yet </label>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="form-check">
          <input
            id="recipient-problem"
            v-model="withRecipientNotificationProblem"
            class="form-check-input"
            type="checkbox"
          />
          <label class="form-check-label" for="recipient-problem"> With problems notifying recipient </label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-3">
        <div class="d-flex text-input-block pb-3">
          <input v-model="shipmentNumber" class="form-control" type="text" placeholder="Shipment number" />
        </div>
      </div>
      <div class="col-3">
        <div class="d-flex text-input-block pb-3">
          <input v-model="search" class="form-control" type="text" placeholder="Search" />
        </div>
      </div>
      <div class="col-3 date-block">
        <date-picker
          v-model="shippedAt"
          format="yyyy-MM-dd"
          range
          multi-calendars
          placeholder="Shipped at"
          input-class="date-input"
          popup-class="date-drop"
          :clearable="true"
        >
        </date-picker>
      </div>
      <div class="col-3 text-right">
        <b-button @click="submit"> Submit </b-button>
        <b-button v-if="hasSelectedFilters" class="ml-2" variant="secondary" @click="reset"> Reset </b-button>
      </div>
    </div>
  </b-card>
</template>

<script lang="ts">
import { ref, computed, watch, onMounted, defineComponent } from 'vue'
import { type LocationQueryValue, useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { none } from '@/services/Helpers'
import { CUSTOMER_SHIPMENTS, STAFF_SHIPMENTS } from '@/types/router'
import { CARRIERS } from '@/const/Carriers'
import { CUSTOMER } from '@/const/AccountTypes'
import DatePicker from '@vuepic/vue-datepicker'
import CarrierRowWithIcon from '@/views/Components/Assignment/CarrierRowWithIcon.vue'
import moment from 'moment'
import UserProxy from '@/proxies/UserProxy'
import type { Ref } from 'vue'
import type { User } from '@/types/Models/User'
import type { ShipmentsQueryParams } from '@/types/Components/SearchForm'

export default defineComponent({
  name: 'ShipmentsSearchForm',
  components: { DatePicker, CarrierRowWithIcon },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    const customerIds: Ref<LocationQueryValue[] | string> = ref([])
    const customers: Ref<User[]> = ref([])

    const statuses: Ref<LocationQueryValue[] | string> = ref([])
    const carriers: Ref<LocationQueryValue[] | string> = ref([])
    const shipmentNumber: Ref<string | null> = ref(null)
    const search: Ref<string | null> = ref(null)
    const shippedAt: Ref<string[] | null> = ref(null)
    const packagesDivertedToServicePoint: Ref<boolean> = ref(false)
    const withServicePointCollectingProblem: Ref<boolean> = ref(false)
    const withRecipientNotificationProblem: Ref<boolean> = ref(false)
    const carriersData = CARRIERS

    const statusesData = [
      { value: 'out_for_delivery', text: 'Out for delivery' },
      { value: 'delivered', text: 'Delivered' },
      { value: 'delivery_issue', text: 'Delivery issue' },
      { value: 'returned', text: 'Returned' },
    ]

    const isStaff = computed(() => store.getters['account/isStaff'])

    const routeHandle = computed(() => {
      return isStaff.value ? STAFF_SHIPMENTS : CUSTOMER_SHIPMENTS
    })

    const hasShippedAt = computed(() => {
      return shippedAt.value && Array.isArray(shippedAt.value) && shippedAt.value.length === 2
    })

    const hasSelectedFilters = computed(() => {
      return (
        customerIds.value.length ||
        statuses.value.length ||
        shipmentNumber.value ||
        carriers.value.length ||
        search.value ||
        hasShippedAt.value ||
        packagesDivertedToServicePoint.value ||
        withServicePointCollectingProblem.value ||
        withRecipientNotificationProblem.value
      )
    })

    watch(customerIds, (value) => {
      if (!value.length) {
        return
      }

      const parameters = {
        type: CUSTOMER,
        [`id[]`]: Array.isArray(value) ? value : [value],
      }

      new UserProxy(parameters)
        .all()
        .then((response) => (customers.value = response.data))
        .catch(none)
    })

    watch(packagesDivertedToServicePoint, (value) => {
      if (!value) {
        withServicePointCollectingProblem.value = false
      }
    })

    onMounted(() => {
      const routeQuery = route.query
      customerIds.value = routeQuery.customer || []
      statuses.value = routeQuery.statuses || []
      shipmentNumber.value = routeQuery.shipmentNumber ? String(routeQuery.shipmentNumber) : null
      search.value = routeQuery.search ? String(routeQuery.search) : null
      carriers.value = routeQuery.carriers || []

      if (routeQuery.dateFrom && routeQuery.dateTo) {
        shippedAt.value = [String(routeQuery.dateFrom), String(routeQuery.dateTo)]
      }

      packagesDivertedToServicePoint.value = Boolean(routeQuery.packagesDivertedToServicePoint || false)
      withServicePointCollectingProblem.value = Boolean(routeQuery.withServicePointCollectingProblem || false)
      withRecipientNotificationProblem.value = Boolean(routeQuery.withRecipientNotificationProblem || false)
    })

    const submit = () => {
      const query = {} as ShipmentsQueryParams

      if (shipmentNumber.value) {
        query.shipmentNumber = shipmentNumber.value
      }

      if (search.value) {
        query.search = search.value
      }

      if (statuses.value.length) {
        query.statuses = (Array.isArray(statuses.value) ? statuses.value : [statuses.value]).map(String)
      }

      if (carriers.value.length) {
        query.carriers = (Array.isArray(carriers.value) ? carriers.value : [carriers.value]).map(String)
      }

      if (shippedAt.value && Array.isArray(shippedAt.value) && shippedAt.value.length === 2) {
        const [dateFrom, dateTo] = shippedAt.value
        query.dateFrom = moment(dateFrom).format('YYYY-MM-DD')
        query.dateTo = moment(dateTo).format('YYYY-MM-DD')
      }

      if (packagesDivertedToServicePoint.value) {
        query.packagesDivertedToServicePoint = 'true'
      }

      if (withServicePointCollectingProblem.value) {
        query.withServicePointCollectingProblem = 'true'
      }

      if (withRecipientNotificationProblem.value) {
        query.withRecipientNotificationProblem = 'true'
      }

      router.push({ name: routeHandle.value, query })
    }

    const reset = () => {
      router.push({ name: routeHandle.value })
    }

    return {
      search,
      customers,
      statuses,
      carriers,
      shippedAt,
      routeHandle,
      carriersData,
      statusesData,
      hasShippedAt,
      shipmentNumber,
      hasSelectedFilters,
      packagesDivertedToServicePoint,
      withRecipientNotificationProblem,
      withServicePointCollectingProblem,
      submit,
      reset,
    }
  },
})
</script>
