<template>
  <div class="mh-v login-page">
    <header class="main-header">
      <div class="container">
        <div class="d-flex h-110">
          <div class="logo">
            <a href="https://www.21grams.se/">
              <img src="@/assets/img/logo.svg" alt="" />
            </a>
          </div>
          <div class="header-title align-self-center">
            <img src="@/assets/img/21grams-white-logo.svg" alt="" />
          </div>
        </div>
      </div>
    </header>

    <div class="main-full-height">
      <div class="container">
        <div class="wrap-form">
          <b-alert :model-value="isCredentialsError" variant="danger">Invalid credentials</b-alert>
          <div>
            <VeeForm @submit="login">
              <VInput v-model="authData.email" type="text" name="Email" rules="emailPhoneRequired" />
              <VInput
                v-model="authData.password"
                class="mb-0 inspectletIgnore"
                type="password"
                name="Password"
                rules="required"
              />
              <b-button class="w-100" type="submit" :disabled="isDisabled()"> Login </b-button>
              <b-form-group class="mb-3 mt-4 text-white">
                <input
                  id="keepLoggedIn"
                  v-model="authData.keepLoggedIn"
                  class="form-check-input"
                  type="checkbox"
                  :value="true"
                />
                <label for="keepLoggedIn">Keep me logged in</label>
              </b-form-group>
            </VeeForm>
            <div class="d-flex mt-4 justify-content-between">
              <router-link :to="registerLink" class="link"> Don't have an account? Request access </router-link>

              <router-link :to="forgotPasswordLink" class="link"> Forgot Password? </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pattern" />

    <LayoutFooter />
  </div>
</template>

<script lang="ts">
import { ref, defineComponent, type Ref } from 'vue'
import { Form as VeeForm } from 'vee-validate'
import { Store, useStore } from 'vuex'
import { CUSTOMER_FORGOT_PASSWORD, REGISTRATION } from '@/types/router'
import LayoutFooter from '@/views/Layouts/LayoutFooter.vue'
import VInput from '@/views/Components/Elements/Form/VInput.vue'
import type { AuthData } from '@/types/Models/Account'
import type { RouterLink } from '@/types/Components/Router'

export default defineComponent({
  name: 'LoginIndex',
  components: {
    VeeForm,
    LayoutFooter,
    VInput,
  },
  setup() {
    const authData: Ref<AuthData> = ref({
      email: null,
      password: null,
      keepLoggedIn: true,
    })
    const store: Store<any> = useStore()
    const isCredentialsError: Ref<boolean> = ref(false)
    const registerLink: RouterLink = { name: REGISTRATION }
    const forgotPasswordLink: RouterLink = { name: CUSTOMER_FORGOT_PASSWORD }

    const isDisabled = () => {
      return !authData.value.email || !authData.value.password
    }

    const login = () => {
      store.dispatch('auth/login', authData.value).catch(() => {
        isCredentialsError.value = true
      })
    }

    return {
      authData,
      isCredentialsError,
      forgotPasswordLink,
      registerLink,
      isDisabled,
      login,
    }
  },
})
</script>

<style scoped>
.link {
  color: #ffffff;
}
.form-check-input {
  border-color: #fff;
  margin: 0 10px 0 0;
}
</style>
