<template>
  <div>
    <TrackingEventsHeader :shipment="shipment" />

    <div v-if="hasAdjustedData" class="tracking-tabs">
      <b-tabs class="pb-0 pt-0">
        <b-tab v-for="piece in orderedPieces" :key="piece.runningNumber">
          <template #title>
            <CarrierRowWithIcon :carrier="piece.carrierHandle" :only-icon="true" :suffix="piece.shipmentNumber" />
          </template>
          <EventsCollection :shipment-number="piece.shipmentNumber" />
        </b-tab>
      </b-tabs>
    </div>

    <div v-else-if="primaryShipment">
      <EventsCollection :shipment-number="primaryShipment.shipmentNumber" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, type PropType, type ComputedRef } from 'vue'
import TrackingEventsHeader from '@/views/Components/Shipments/Events/TrackingEventsHeader.vue'
import EventsCollection from '@/views/Components/Shipments/Events/EventsCollection.vue'
import CarrierRowWithIcon from '@/views/Components/Assignment/CarrierRowWithIcon.vue'
import type { AdjustedShipment, Shipment } from '@/types/Models/Shipment'

export default defineComponent({
  name: 'ShipmentTrackingEvents',
  components: {
    CarrierRowWithIcon,
    EventsCollection,
    TrackingEventsHeader,
  },
  props: {
    shipment: {
      type: Object as PropType<Shipment>,
      required: true,
    },
  },
  setup(props) {
    const hasAdjustedData: ComputedRef<boolean> = computed(() =>
      props.shipment.shipments ? props.shipment.shipments.length > 1 : false,
    )
    const orderedPieces: ComputedRef<AdjustedShipment[]> = computed(() =>
      (props.shipment.shipments ? [...props.shipment.shipments] : []).sort(
        (a: AdjustedShipment, b: AdjustedShipment) => b.runningNumber - a.runningNumber,
      ),
    )
    const primaryShipment: ComputedRef<AdjustedShipment> = computed(() => orderedPieces.value[0] || null)

    return {
      hasAdjustedData,
      orderedPieces,
      primaryShipment,
    }
  },
})
</script>
