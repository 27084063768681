<template>
  <div v-b-tooltip="{ title: tooltipText }" :class="['label-wrap', className]">
    <span>{{ shortText }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BaseLabel',
  props: {
    id: {
      type: String,
      required: true,
    },
    shortText: {
      type: String,
      required: true,
    },
    tooltipText: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      required: true,
    },
  },
})
</script>
