import { computed, type Ref } from 'vue'
import type { AssignmentPiece } from '@/views/Components/Assignment/Pieces/types'

export type Props = {
  canChangeQuantity: Ref<boolean>
  outsideEu: Ref<boolean>
  pieces: Ref<AssignmentPiece[]>
}

export const useHeaders = ({ canChangeQuantity, outsideEu, pieces }: Props) => {
  return computed(() => {
    let data = [
      { text: 'Quantity', value: 'quantity' },
      { text: 'Weight', value: 'weight' },
      { text: 'Height', value: 'height' },
      { text: 'Length', value: 'length' },
      { text: 'Width', value: 'width' },
      { text: 'Value', value: 'estimated_cost' },
      { text: 'Description', value: 'description', width: 200 },
      { text: 'Commodity code', value: 'commodity_code', width: 200 },
      { text: '', value: 'remove' },
    ]

    if (!canChangeQuantity.value) {
      data = data.filter((i) => !['quantity'].includes(i.value))
    }
    if (!outsideEu.value) {
      data = data.filter((i) => !['estimated_cost', 'commodity_code'].includes(i.value))
    }

    if (pieces.value.length === 1) {
      data = data.filter((i) => i.value !== 'remove')
    }

    return data
  })
}
