<template>
  <b-button
    size="md"
    variant="link"
    :style="{ backgroundColor: '#08A762', color: '#ffffff' }"
    class="ml-3"
    @click="performAction"
  >
    <i class="fa fa-download" />
    Download labels
  </b-button>
</template>

<script lang="ts">
import { computed, type ComputedRef, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { useDownloadLabelsAction } from '@/composables/labels/use-download-labels-action'
import type { SingleAssignment } from '@/types/Models/Assignment'

export default defineComponent({
  name: 'DownloadLabels',
  setup() {
    const store = useStore()
    const downloadLabelsAction = useDownloadLabelsAction()

    const assignment: ComputedRef<SingleAssignment> = computed(() => store.getters['assignment/get'])

    const performAction = () => downloadLabelsAction([assignment.value])

    return {
      performAction,
    }
  },
})
</script>
