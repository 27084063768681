<template>
  <div class="sub-header">
    <div class="d-flex">
      <SubHeaderButton
        v-if="can('view', 'shipments')"
        :to="{ name: shipmentsPage }"
        icon="truck"
        name="Shipments"
        style="width: 180px"
        :is-add-new="false"
      />
      <SubHeaderButton
        v-if="can('view', 'assignmentsList')"
        :to="{ name: assignmentsList }"
        name="Assignments"
        icon="file-document-multiple-outline"
        @add-new="navigateToCreateAssignments"
      />
      <SubHeaderButton
        v-if="can('manage', 'customer')"
        :to="{ name: customersArea }"
        icon="account-outline"
        name="Customers area"
        @add-new="navigateToCreateCustomer"
      />
      <SubHeaderButton
        :to="{ name: statistics }"
        icon="file-document-outline"
        :is-add-new="false"
        name="Statistics"
        style="width: 160px"
      />
      <SubHeaderButton
        v-if="can('view', 'pickups-list')"
        :to="{ name: pickupsList }"
        icon="magnify"
        name="Pickups"
        style="width: 160px"
        :is-add-new="false"
      />
      <SubHeaderButton
        v-if="can('manage', 'faq')"
        :to="{ name: faqPage }"
        icon="help"
        name="FAQ"
        style="width: 180px"
        @add-new="navigateToCreateFaq"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import SubHeaderButton from '@/views/Components/PageParts/SubHeaderButton.vue'
import {
  CUSTOMER_ASSIGNMENTS_CREATE,
  CUSTOMER_ASSIGNMENTS_LIST,
  STAFF_ASSIGNMENTS_CREATE,
  STAFF_ASSIGNMENTS_LIST,
  STAFF_BASE_PRICE_PAGE,
  STAFF_CUSTOMERS_CREATE,
  STAFF_CUSTOMERS_LIST,
  STAFF_STATISTICS,
  FAQ_CREATE,
  FAQ_LIST,
  CUSTOMER_STATISTICS,
  STAFF_PICKUPS_LIST,
  STAFF_SHIPMENTS,
} from '@/types/router'
import { SINGLE } from '@/const/AssignmentTypes'

export default defineComponent({
  name: 'SubHeader',
  components: { SubHeaderButton },
  setup() {
    const store = useStore()
    const router = useRouter()

    const isStaff = computed(() => store.getters['account/isStaff'])
    const ability = computed(() => store.getters['ability'])

    const assignmentsPage = computed(() => (isStaff.value ? STAFF_ASSIGNMENTS_LIST : CUSTOMER_ASSIGNMENTS_LIST))
    const assignmentsList = computed(() => (isStaff.value ? STAFF_ASSIGNMENTS_LIST : CUSTOMER_ASSIGNMENTS_LIST))
    const createAssignmentsPage = computed(() =>
      isStaff.value ? STAFF_ASSIGNMENTS_CREATE : CUSTOMER_ASSIGNMENTS_CREATE,
    )
    const statistics = computed(() => (isStaff.value ? STAFF_STATISTICS : CUSTOMER_STATISTICS))

    const navigateToCreateAssignments = () => {
      router.push({
        name: createAssignmentsPage.value,
        query: { assignmentType: SINGLE },
      })
    }

    const navigateToCreateCustomer = () => {
      router.push({ name: STAFF_CUSTOMERS_CREATE })
    }

    const navigateToCreateFaq = () => {
      router.push({ name: FAQ_CREATE })
    }

    return {
      assignmentsPage,
      assignmentsList,
      createAssignmentsPage,
      statistics,
      customersArea: STAFF_CUSTOMERS_LIST,
      createCustomerPage: STAFF_CUSTOMERS_CREATE,
      pickupsList: STAFF_PICKUPS_LIST,
      basePricePage: STAFF_BASE_PRICE_PAGE,
      shipmentsPage: STAFF_SHIPMENTS,
      createFaqPage: FAQ_CREATE,
      faqPage: FAQ_LIST,
      navigateToCreateAssignments,
      navigateToCreateCustomer,
      navigateToCreateFaq,
      can: (rule: string, section: string) => ability.value.can(rule, section),
    }
  },
})
</script>
