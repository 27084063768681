<template>
  <div class="d-flex assignment-filters">
    <SearchFilter />
    <ArchiveModeFilter class="ml-2" />
    <DistributionChannelFilter class="ml-2" />
    <StatusFilter class="ml-2" />
    <TypeFilter class="ml-2" />
    <DateFilter class="ml-2" />
  </div>
</template>

<script lang="ts">
import StatusFilter from '@/views/Components/Assignment/Filters/StatusFilter.vue'
import TypeFilter from '@/views/Components/Assignment/Filters/TypeFilter.vue'
import DateFilter from '@/views/Components/Assignment/Filters/DateFilter.vue'
import DistributionChannelFilter from '@/views/Components/Assignment/Filters/DistributionChannelFilter.vue'
import ArchiveModeFilter from '@/views/Components/Assignment/Filters/ArchiveMode.vue'
import SearchFilter from '@/views/Components/Assignment/Filters/SearchFilter.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FiltersWrap',
  components: {
    SearchFilter,
    ArchiveModeFilter,
    DistributionChannelFilter,
    DateFilter,
    TypeFilter,
    StatusFilter,
  },
})
</script>
