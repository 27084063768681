<template>
  <div v-if="display" class="pb-3">
    <div class="child-row-item__title">Service point</div>
    <div class="child-row-item__subtitle">
      <div v-if="hasServicePoints">
        <div v-for="servicePoint in shipment.servicePoints" :key="servicePoint.identifier">
          <div>
            <b>{{ servicePoint.name }}</b>
            <small class="text-muted ml-2"> ID: {{ servicePoint.identifier }} </small>
          </div>
          <div>
            <small>
              {{ addressString(servicePoint) }}
            </small>
          </div>
        </div>
      </div>
      <div v-else>
        <b-card bg-variant="warning">
          <i class="fa fa-warning" />
          {{ adjustedServicePointProblemDescription }}
        </b-card>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, type PropType, type ComputedRef } from 'vue'
import { adjustedServicePointProblemDescription } from '@/const/Texts'
import { addressString } from '@/services/Helpers'
import type { Shipment } from '@/types/Models/Shipment'

export default defineComponent({
  name: 'ShipmentServicePoint',
  props: {
    shipment: {
      type: Object as PropType<Shipment>,
      required: true,
    },
  },
  setup(props) {
    const hasServicePoints: ComputedRef<boolean> = computed(() =>
      props.shipment.servicePoints ? props.shipment.servicePoints.length > 0 : false,
    )
    const hasAdjustedData: ComputedRef<boolean> = computed(() =>
      props.shipment.shipments ? props.shipment.shipments.length > 1 : false,
    )
    const display: ComputedRef<boolean> = computed(() => hasAdjustedData.value || hasServicePoints.value)

    return {
      display,
      hasServicePoints,
      adjustedServicePointProblemDescription,
      addressString,
    }
  },
})
</script>
