<template>
  <div class="easy-data-table-dark">
    <easy-data-table
      ref="dataTable"
      v-model:server-options="serverOptions"
      v-model:items-selected="checkedItems"
      table-class-name="vue3-easy-data-table table-shadow"
      :server-items-length="serverItemsLength"
      :loading="loading"
      :headers="headers"
      :items="items"
      :prevent-context-menu-row="false"
      :fixed-header="false"
      no-hover
      alternating
      hide-footer
    >
      <template #loading>
        <div style="text-align: center">Loading...</div>
      </template>
      <template #no-data>
        <div style="text-align: center">No data available</div>
      </template>
      <template #item="{ column, item }">
        <div
          v-b-tooltip="{
            title: getItemTooltip(column, item),
            customClass: getTooltipClass(item.validationFlags[column]),
            variant: 'info',
          }"
          class="h-48"
        >
          <div
            class="wrap-table-input no-wrap"
            :class="[getCellClasses(getValidationFlag(item, column)), getTrackingNumberClass(item, column)]"
          >
            <div v-if="column === 'carrierHandle'" class="row carrier-cell">
              <CarrierRowWithIcon :carrier="item.carrierHandle" :only-icon="false" />
            </div>
            <span v-else-if="column === 'shipmentNumber' && !item[column]" class="fatal shipment-cell">
              <div class="d-flex align-items-center pl-3 h-100">
                <span class="tooltip-text"> - </span>
              </div>
            </span>
            <span v-else-if="column === 'shipmentNumber'" class="success shipment-cell">
              <div class="d-flex table-input align-items-center pl-3 h-100">
                <span class="tooltip-text">
                  <a :href="getLinkToTrackingPage(item[column])" target="_blank">
                    {{ capitalize(item[column]) }}
                  </a>
                </span>
              </div>
            </span>
            <span v-else-if="column === 'trackingStatus' && !item.shipmentNumber">
              <div class="d-flex align-items-center pl-3 h-100">
                <span class="tooltip-text"> - </span>
              </div>
            </span>
            <span v-else-if="column === 'trackingStatus'" :class="getTooltipClass(item.validationFlags[column])">
              <div class="table-input status-center">
                {{ getTrackingStatus(item.lastTrackingEventStatus) }}
              </div>
            </span>
            <span v-else :class="getTooltipClass(item.validationFlags[column])">
              <select
                v-if="column === 'currency'"
                v-model="item.currency"
                class="table-cell-select"
                @change="updateField($event, item.id, column)"
              >
                <option
                  v-for="(option, key) in currencies"
                  :key="key"
                  :value="option.value"
                  :selected="item.currency == option.value"
                >
                  {{ option.value }}
                </option>
              </select>
              <select
                v-else-if="column === 'invoiceType'"
                v-model="item.invoiceType"
                class="table-cell-select"
                @change="updateField($event, item.id, column)"
              >
                <option
                  v-for="(option, key) in invoiceOptions"
                  :key="key"
                  :value="option.value"
                  :selected="item.invoiceType == option.value"
                >
                  {{ option.text }}
                </option>
              </select>
              <input
                v-else-if="numberTypeColumns.includes(column)"
                v-model="item[column]"
                class="table-input"
                :type="numberTypeColumns.includes(column) ? 'number' : 'text'"
                :class="{ 'input-number': numberTypeColumns.includes(column) }"
                :readonly="!isItemsEditable"
                @change="updateField($event, item.id, column)"
              />
              <input
                v-else-if="column === 'commodityCode' && item.product"
                v-model="item.product.commodityCode"
                class="table-input"
                type="text"
                :readonly="!isItemsEditable"
                @change="updateField($event, item.id, column)"
              />
              <input
                v-else-if="itemAddressColumns.includes(column)"
                v-model="item.recipientAddress[column]"
                class="table-input"
                type="text"
                :readonly="!isItemsEditable"
                @change="updateField($event, item.id, column)"
              />
              <input
                v-else
                v-model="item[column]"
                class="table-input"
                type="text"
                :readonly="!isItemsEditable"
                @change="updateField($event, item.id, column)"
              />
              <div class="cell-sizer">
                {{ item.recipientAddress[column] || item[column] }}
              </div>
            </span>
          </div>
        </div>
      </template>
    </easy-data-table>

    <TablePagination
      v-model:server-options-ref="serverOptions"
      v-model:data-table-ref="dataTable"
      :server-items-length="serverItemsLength"
    />
  </div>

  <div v-if="serverItemsLength > 7" class="table-side-scroll">
    <button @click="scrollLeft">
      <mdicon name="ChevronLeft" />
    </button>
    <button @click="scrollRight">
      <mdicon name="ChevronRight" />
    </button>
  </div>
</template>

<script lang="ts">
import { type Store, useStore } from 'vuex'
import { capitalize, catchErrors } from '@/services/Helpers'
import { scrollLeft, scrollRight } from '@/services/TableScroll'
import { getLinkToTrackingPage, getTrackingStatus } from '@/services/Tracking'
import { computed, defineComponent, onMounted, type ComputedRef, type Ref, ref, watch } from 'vue'
import { INTERNATIONAL } from '@/const/AssignmentDestinationTypes'
import { GIFT, MIXED, OTHER, RETURN, DOCUMENTS, SALES_OF_GOODS, COMMERCIAL_SAMPLE } from '@/const/ProformaInvoiceTypes'
import { DELIVERED, DELIVERY_ISSUE, FAILED_ATTEMPT } from '@/const/TrackingEventStatuses'
import {
  IN_REVIEW,
  LABELS_CREATING,
  OPTIMISING,
  PENDING,
  OPEN,
  REPORT_ACCEPTED,
  REPORT_DENIED,
  REPORT_RELEASED,
  VALIDATING,
  VALIDATION_COMPLETE,
} from '@/const/AssignmentStatuses'
import CarrierRowWithIcon from '@/views/Components/Assignment/CarrierRowWithIcon.vue'
import TablePagination from '@/views/Components/Elements/Table/TablePagination.vue'
import EasyDataTable, { type ServerOptions } from 'vue3-easy-data-table'
import currencies from '@/assets/files/currencies.json'
import AssignmentProxy from '@/proxies/AssignmentProxy'
import ItemTransformer from '@/transformers/ItemTransformer'
import type { TrackingClasses } from '@/types/Models/Tracking'
import type { SearchFilter } from '@/types/Models/SearchFilterType'
import type { Item, ItemData, ValidationFlags } from '@/types/Models/Item'
import type { SelectOptions } from '@/types/Components/SelectOptions'
import type { Assignment } from '@/types/Models/Assignment'
import type { RootStoreState } from '@/types/Store'

export default defineComponent({
  name: 'ItemsTable',
  components: {
    CarrierRowWithIcon,
    TablePagination,
    EasyDataTable,
  },
  props: {
    assignmentId: {
      type: String,
      required: true,
    },
    destinationType: {
      type: String,
      required: false,
      default: '',
    },
    version: {
      type: String,
      required: false,
      default: '',
    },
    filter: {
      type: Object as () => SearchFilter,
      required: false,
      default: () => ({}),
    },
  },
  setup(props) {
    const store: Store<RootStoreState> = useStore()
    const dataTable: Ref = ref()
    const items: Ref<Item[]> = ref([])
    const loading: Ref<boolean> = ref(false)
    const serverItemsLength: Ref<number> = ref(0)
    const checkedItems: Ref<any[]> = ref([])
    const serverOptions: Ref<ServerOptions> = ref({
      page: 1,
      rowsPerPage: 100,
    })

    const assignment: ComputedRef<Assignment> = computed(() => store.getters['assignment/get'])
    const isErrorsOnly: ComputedRef<boolean> = computed(() => store.getters['item/errorsOnly'])
    const isLabelsErrorsOnly: ComputedRef<boolean> = computed(() => store.getters['item/labelsErrorsOnly'])
    const isItemsEditable: ComputedRef<boolean> = computed(() => store.getters['assignment/isItemsEditable'])
    const destinationType: ComputedRef<string> = computed(() => props.destinationType)
    const filter: ComputedRef<SearchFilter> = computed(() => props.filter)
    const headers: ComputedRef<SelectOptions[]> = computed(() =>
      destinationType.value === INTERNATIONAL
        ? [
            { text: 'First Name', value: 'firstName' },
            { text: 'Last Name', value: 'lastName' },
            { text: 'Company', value: 'company' },
            { text: 'Address 1', value: 'addressLine' },
            { text: 'Address 2', value: 'addressLine2' },
            { text: 'Postcode', value: 'postalCode' },
            { text: 'Country', value: 'countryCode' },
            { text: 'City', value: 'city' },
            { text: 'E-mail', value: 'email' },
            { text: 'Phone', value: 'phoneNumber' },
            { text: 'Carrier', value: 'carrierHandle' },
            { text: 'Shipment Number', value: 'shipmentNumber' },
            { text: 'Tracking Status', value: 'trackingStatus' },
            { text: 'Weight (g)', value: 'weight' },
            { text: 'Height (cm)', value: 'height' },
            { text: 'Width (cm)', value: 'width' },
            { text: 'Length (cm)', value: 'length' },
            { text: 'Estimated Cost (kr)', value: 'estimatedCost' },
            { text: 'Commodity Code', value: 'commodityCode' },
            { text: 'Quantity', value: 'quantity' },
            { text: 'Invoice Type', value: 'invoiceType' },
            { text: 'Description', value: 'description' },
            { text: 'Currency', value: 'currency' },
            { text: 'Customer Reference', value: 'customerReference' },
            { text: 'Order Reference', value: 'orderReference' },
          ]
        : [
            { text: 'First Name', value: 'firstName' },
            { text: 'Last Name', value: 'lastName' },
            { text: 'Company', value: 'company' },
            { text: 'Address 1', value: 'addressLine' },
            { text: 'Address 2', value: 'addressLine2' },
            { text: 'Postcode', value: 'postalCode' },
            { text: 'Country', value: 'countryCode' },
            { text: 'City', value: 'city' },
            { text: 'E-mail', value: 'email' },
            { text: 'Phone', value: 'phoneNumber' },
            { text: 'Carrier', value: 'carrierHandle' },
            { text: 'Shipment Number', value: 'shipmentNumber' },
            { text: 'Tracking Status', value: 'trackingStatus' },
            { text: 'Weight (g)', value: 'weight' },
            { text: 'Height (cm)', value: 'height' },
            { text: 'Width (cm)', value: 'width' },
            { text: 'Length (cm)', value: 'length' },
            { text: 'Estimated Cost (kr)', value: 'estimatedCost' },
            { text: 'Customer Reference', value: 'customerReference' },
            { text: 'Order Reference', value: 'orderReference' },
          ],
    )

    const numberTypeColumns = ['weight', 'height', 'width', 'length', 'estimatedCost', 'quantity']
    const itemProductColumns = ['quantity', 'commodityCode', 'type']
    const itemAddressColumns = [
      'firstName',
      'lastName',
      'company',
      'addressLine',
      'addressLine2',
      'postalCode',
      'countryCode',
      'city',
      'email',
      'phoneNumber',
    ]
    const invoiceOptions: ComputedRef<SelectOptions[]> = computed(() => {
      return [SALES_OF_GOODS, GIFT, DOCUMENTS, COMMERCIAL_SAMPLE, RETURN, MIXED, OTHER].reduce(
        (acc: SelectOptions[], val) => {
          acc.push({
            value: val,
            text: (val[0].toUpperCase() + val.slice(1).toLowerCase()).replace(/_/g, ' '),
          })
          return acc
        },
        [],
      )
    })

    const getCellClasses = (flag: any) => (!flag ? {} : { validation: true, [flag.level]: true })
    const getTooltipClass = (flag: any) => (!flag ? '' : [flag.level].join(' '))
    const getValidationFlag = (row: Item, column: string) => (row.validationFlags && row.validationFlags[column]) || ''

    const loadFromServer = async () => {
      loading.value = true
      const params = {
        page: serverOptions.value.page,
        limit: serverOptions.value.rowsPerPage,
        byColumn: 0,
        ascending: 1,
        destinationType: destinationType.value,
        onlyWithErrors: isErrorsOnly.value ? true : null,
        onlyWithLabelsErrors: isLabelsErrorsOnly.value ? true : null,
        ...filter.value,
      }

      new AssignmentProxy(params)
        .getItems(props.assignmentId)
        .then((response) => {
          items.value = ItemTransformer.fetchCollection(response.data || [])
          serverItemsLength.value = response.count || 0
          loading.value = false
          updateFatalErrorCount(items.value)
        })
        .catch(() => {
          serverItemsLength.value = 0
          loading.value = false
          items.value = []
        })
    }

    const updateField = (event: any, itemId: string, field: string) => {
      const item = items.value.find((item) => item.id === itemId)

      const { value } = event.target
      switch (true) {
        case itemAddressColumns.includes(field):
          if (item?.recipientAddress?.id) {
            updateItemAddressField(value, itemId, item.recipientAddress.id, field)
          }
          break
        case itemProductColumns.includes(field):
          if (item?.product?.id) {
            updateItemProductField(value, itemId, item.product.id, field)
          }
          break
        default:
          updateItemField(value, itemId, field)
          break
      }
    }

    const validateItem = (id: string) => {
      store.dispatch('item/validateItem', id).then(reloadList)
    }

    const updateItemField = (value: string, id: string, field: string) => {
      store
        .dispatch('item/updateItem', { item: { id, [field]: value } })
        .then(() => validateItem(id))
        .catch(catchErrors)
    }

    const updateItemAddressField = (value: string, itemId: string, id: string, field: string) => {
      store
        .dispatch('item/updateItemAddress', { id, itemAddressField: { [field]: value } })
        .then(() => validateItem(itemId))
        .catch(catchErrors)
    }

    const updateItemProductField = (value: string, itemId: string, id: string, field: string) => {
      store
        .dispatch('item/updateItemProduct', { id, itemProductField: { [field]: value } })
        .then(() => validateItem(itemId))
        .catch(catchErrors)
    }

    const reloadList = (updatedItem: ItemData) => {
      const transformedItem = ItemTransformer.fetch(updatedItem)

      items.value.forEach((item: Item) => {
        if (transformedItem.id === item.id) {
          Object.assign(item, { ...transformedItem })
        }
      })
      updateFatalErrorCount()
    }

    const updateFatalErrorCount = (itemsList?: Item[]) => {
      const columnsList = [...headers.value.map((header) => header.value)]
      columnsList.forEach((column) => {
        if (column.includes('.')) {
          columnsList.push(column.split('.')[1])
        }
      })

      if (!itemsList) {
        itemsList = items.value
      }

      let errorsCount = 0
      itemsList.forEach((item: Item) => {
        let hasFatalError = 0
        columnsList.forEach((column: keyof ValidationFlags) => {
          if (item.validationFlags && item.validationFlags[column] && item.validationFlags[column].level === 'fatal') {
            hasFatalError = 1
          }
        })
        errorsCount += hasFatalError
      })
      store.dispatch('item/setFatalErrorCount', errorsCount)
    }

    const getTrackingNumberClass = (row: Item, rowName: string) => {
      if (rowName !== 'shipmentNumber') return ''
      if (
        [
          OPEN,
          PENDING,
          VALIDATING,
          VALIDATION_COMPLETE,
          IN_REVIEW,
          OPTIMISING,
          REPORT_RELEASED,
          REPORT_ACCEPTED,
          REPORT_DENIED,
          LABELS_CREATING,
        ].includes(assignment.value.status || '')
      ) {
        return ''
      }

      const status = getTrackingStatus(row.lastTrackingEventStatus).replace(/ /g, '_').toLowerCase()
      const { shipmentNumber, lastTrackingEventStatus } = row
      const classes: TrackingClasses = { validation: true }

      if (!shipmentNumber || [DELIVERY_ISSUE, FAILED_ATTEMPT].includes(status)) {
        classes.fatal = true
      } else if (status && status !== DELIVERED) {
        classes[status] = true
      } else if (lastTrackingEventStatus || status === DELIVERED) {
        classes.success = true
      }

      return classes
    }

    const getItemTooltip = (column: string, item: Item) => {
      if (column === 'shipmentNumber' && !item.shipmentNumber) {
        if (item.shipmentNumber === null && item.shippingError) {
          if (item.shippingError.charAt(0) !== '{' && item.shippingError.charAt(0) !== '[') {
            return item.shippingError
          }
          try {
            const errors = JSON.parse(item.shippingError)
            return errors ? Object.values(errors?.messages || errors).flat() : ''
          } catch (e) {
            return item.shippingError
          }
        }
        return ''
      }
      if (column === 'trackingStatus' || column === 'shipmentNumber') {
        return getTrackingStatus(item.lastTrackingEventStatus)
      }
      if (!item.validationFlags || !item.validationFlags[column]) {
        return ''
      }

      return item.validationFlags[column] ? item.validationFlags[column].reason : ''
    }

    onMounted(loadFromServer)

    watch(serverOptions, loadFromServer)
    watch(filter, loadFromServer)

    watch(
      checkedItems,
      () => {
        store.dispatch(
          'item/updateCheckedItems',
          checkedItems.value.map((item: any) => item.id),
        )
      },
      { deep: true },
    )

    watch([destinationType, isErrorsOnly, isLabelsErrorsOnly], () => {
      if (serverOptions.value.page !== 1) {
        serverOptions.value.page = 1
      } else {
        loadFromServer()
      }
    })

    watch(
      () => props.version,
      () => {
        if (checkedItems.value.length > 0) {
          checkedItems.value = []
        }
        loadFromServer()
      },
    )

    return {
      itemAddressColumns,
      numberTypeColumns,

      items,
      loading,
      headers,
      dataTable,
      currencies,
      serverOptions,
      serverItemsLength,
      isItemsEditable,
      invoiceOptions,
      checkedItems,
      scrollRight,
      scrollLeft,
      capitalize,
      getValidationFlag,
      getLinkToTrackingPage,
      getTrackingNumberClass,
      getTrackingStatus,
      getTooltipClass,
      getCellClasses,
      getItemTooltip,
      updateField,
    }
  },
})
</script>

<style lang="scss" scoped>
.table-input {
  background: transparent !important;
  width: 100%;
}
:deep(.vue3-easy-data-table__main) {
  overflow-y: hidden;
  width: 100%;
}
:deep(.vue3-easy-data-table__main thead tr th),
.h-48 {
  height: 48px;
  .easy-checkbox {
    margin: 0 !important;
  }
}
:deep(.vue3-easy-data-table__main tbody tr td) {
  height: 48px;
  min-width: 93px;
  white-space: nowrap;
  border-right: var(--easy-table-cell-border);

  .input-number {
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type='number'] {
      appearance: textfield;
    }
  }

  .table-cell-select {
    background: transparent;
    border: none;
    height: 100%;
  }
  .cell-sizer {
    width: auto;
    opacity: 0;
    height: 0;
    margin-left: 34px;
  }
  .status-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  a {
    color: var(--easy-table-body-row-font-color);
  }
  .easy-checkbox {
    margin: 0 !important;
  }
}

:deep(.vue3-easy-data-table__main tbody tr td:first-child),
:deep(.vue3-easy-data-table__main thead tr th:first-child) {
  border-right: none;
  min-width: auto;
  padding-right: 0;
}
.easy-data-table-footer {
  margin: 20px 0 70px;
}
.carrier-cell {
  padding: 0 20px;
  margin: 0;
  height: 100%;
  min-width: 150px;
}
</style>
