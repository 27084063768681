<template>
  <ShipmentsFeed :account-type="accountType" />
</template>

<script lang="ts">
import { useStore } from 'vuex'
import { computed, defineAsyncComponent, defineComponent } from 'vue'
import { CUSTOMER, STAFF } from '@/const/AccountTypes'

export default defineComponent({
  name: 'ShipmentsFeedPage',
  components: {
    ShipmentsFeed: defineAsyncComponent(() => import('@/views/Components/Shipments/ShipmentsFeed.vue')),
  },
  setup() {
    const store = useStore()

    const accountType = computed(() => {
      return store.getters['account/isStaff'] ? STAFF : CUSTOMER
    })

    return {
      accountType,
    }
  },
})
</script>
