<template>
  <b-button
    size="md"
    variant="link"
    :style="{ backgroundColor: '#08A762', color: '#151515' }"
    class="ml-3"
    @click="performAction"
  >
    <i class="fa fa-file" />
    Create labels
  </b-button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { notify } from '@kyvg/vue3-notification'
import { LABELS_CREATING_LOADER } from '@/const/LoadersNames'

export default defineComponent({
  name: 'CreateLabels',
  setup() {
    const store = useStore()
    const route = useRoute()

    const performAction = () => {
      store.dispatch('ui/loaderShow', {
        name: LABELS_CREATING_LOADER,
        data: { progress: 0 },
      })

      store.dispatch('assignment/createLabels', { id: route.params.id }).catch(() => {
        notify({ group: 'error', text: 'Labels Create Error' })
      })
    }

    return {
      performAction,
    }
  },
})
</script>
