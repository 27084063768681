<template>
  <component :is="wrapTag" v-clipboard:copy="text" v-clipboard:success="onSuccess" v-clipboard:error="onError">
    <slot />
  </component>
</template>

<script lang="ts">
import { notify } from '@kyvg/vue3-notification'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CopyToClipboard',
  props: {
    text: {
      type: String,
      required: true,
    },
    wrapTag: {
      type: String,
      default: 'span',
    },
  },
  methods: {
    onSuccess() {
      notify({ group: 'success', text: 'Copied to clipboard' })
    },
    onError() {
      notify({ group: 'error', text: 'Failed to copy to clipboard' })
    },
  },
})
</script>
