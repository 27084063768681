<template>
  <div v-if="hasActions">
    <b-dropdown right split :text="actionName" :class="dropdownClass" @click="dropdownClick">
      <DropdownItem
        v-for="actionItem of otherActions"
        :key="actionItem.handle"
        :action="actionItem"
        :assignment="assignment"
      />
    </b-dropdown>
  </div>
</template>

<script lang="ts">
import type { ListAssignment, StatusType } from '@/types/Models/Assignment'
import type { Action } from '@/types/Components/Actions'
import type { AccountType } from '@/types/Models/Account'
import { defineComponent, computed, type PropType, type ComputedRef } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ActionColor } from '@/services/Status/ActionColor'
import {
  ActionsConfig,
  filterAction,
  prepareAction,
  sortAction,
  transformAction,
  useActionHandlers,
} from '@/services/AssignmentsListActions'
import DropdownItem from '@/views/Components/Assignment/Cells/DropdownItem.vue'

export default defineComponent({
  name: 'ActionsCell',
  components: { DropdownItem },
  props: {
    assignment: {
      type: Object as PropType<ListAssignment>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore()
    const router = useRouter()

    const isStaff: ComputedRef<boolean> = computed(() => store.getters['account/isStaff'])
    const accountType: ComputedRef<AccountType> = computed(() => store.getters['account/accountType'])
    const filterer = filterAction(props.assignment)
    const preparer = prepareAction(props.assignment, isStaff.value)
    const transformer = transformAction(props.assignment, isStaff.value)

    const actions: ComputedRef<Action[]> = computed(() =>
      ActionsConfig.map((action: Action) => preparer(action))
        .filter(filterer)
        .map(transformer)
        .sort(sortAction),
    )

    const action: ComputedRef<Action> = computed(() => actions.value[0])
    const { actionRoute, actionLink, actionFn } = useActionHandlers(props.assignment, action.value)

    const actionColor: ComputedRef<string> = computed(
      () => ActionColor[accountType.value][props.assignment.status as StatusType],
    )

    const hasActions: ComputedRef<boolean> = computed(() => actions.value.length > 0)
    const otherActions: ComputedRef<Action[]> = computed(() => actions.value.slice(1))
    const dropdownClass: ComputedRef<string[]> = computed(() => ['assignment-cta', actionColor.value])

    function dropdownClick() {
      if (actionRoute.value) {
        router.push(actionRoute.value)
      } else if (actionLink.value) {
        window.location.href = actionLink.value
      } else {
        actionFn()
      }
    }

    const actionName: ComputedRef<string> = computed(() =>
      action.value?.name
        ? typeof action.value.name === 'string'
          ? action.value.name
          : action.value.name(props.assignment, isStaff.value) || ''
        : '',
    )

    return {
      actionName,
      hasActions,
      otherActions,
      dropdownClass,
      dropdownClick,
    }
  },
})
</script>
