import { CUSTOMER, STAFF } from '@/const/AccountTypes'
import {
  CANCELLED,
  COMPLETE,
  IN_REVIEW,
  LABELS_CREATING,
  LABELS_RELEASED,
  OPTIMISING,
  OPEN,
  PENDING,
  REPORT_ACCEPTED,
  REPORT_DENIED,
  REPORT_RELEASED,
  VALIDATING,
  VALIDATION_COMPLETE,
} from '@/const/AssignmentStatuses'
import type { AccountType } from '@/types/Models/Account'
import type { StatusType } from '@/types/Models/Assignment'

type ActionColorMap = {
  [key in AccountType]: {
    [key in StatusType]: string
  }
}

export const ActionColor: ActionColorMap = {
  [STAFF]: {
    [OPEN]: 'button-gray',
    [PENDING]: 'button-gray',
    [COMPLETE]: 'button-green',
    [CANCELLED]: 'button-light-red',
    [VALIDATING]: 'button-gray',
    [VALIDATION_COMPLETE]: 'button-black',
    [IN_REVIEW]: 'button-light-red',
    [OPTIMISING]: 'button-gray',
    [REPORT_RELEASED]: 'button-gray',
    [REPORT_ACCEPTED]: 'button-lettuce-green',
    [REPORT_DENIED]: 'button-light-red',
    [LABELS_CREATING]: 'button-gray',
    [LABELS_RELEASED]: 'button-green',
  },
  [CUSTOMER]: {
    [OPEN]: 'button-gray',
    [PENDING]: 'button-gray',
    [COMPLETE]: 'button-green',
    [CANCELLED]: 'button-light-red',
    [VALIDATING]: 'button-gray',
    [VALIDATION_COMPLETE]: 'button-black',
    [IN_REVIEW]: 'button-gray',
    [OPTIMISING]: 'button-gray',
    [REPORT_RELEASED]: 'button-yellow',
    [REPORT_ACCEPTED]: 'button-yellow',
    [REPORT_DENIED]: 'button-light-red',
    [LABELS_CREATING]: 'button-gray',
    [LABELS_RELEASED]: 'button-green',
  },
}
