// Type: Store Module
import type { ModalStoreState, RootStoreState } from '@/types/Store'
import type { ActionContext } from 'vuex'
import { findModal } from '@/services/Modal'

const state: ModalStoreState = {
  modalProps: {},
  renderedModals: [],
}

type Context = ActionContext<ModalStoreState, RootStoreState>

const mutations = {
  setModalProps(state: ModalStoreState, { name, data }: { name: string; data: any }) {
    state.modalProps = {
      ...state.modalProps,
      [name]: data,
    }
  },
  renderModal(state: ModalStoreState, name: string) {
    if (state.renderedModals.includes(name)) return
    state.renderedModals.push(name)
  },
}

const actions = {
  openModal({ commit }: Context, { name, data }: { name: string; data: any }) {
    commit('setModalProps', { name, data })
    findModal(name)?.show()
  },
  closeModal({ commit }: Context, name: string) {
    commit('setModalProps', { name, data: {} })
    findModal(name)?.hide()
  },
  renderModal({ commit }: Context, name: string) {
    commit('renderModal', name)
  },
}

const getters = {
  modalProps: (state: ModalStoreState) => (name: string) => state.modalProps[name],
  renderedModals: (state: ModalStoreState) => state.renderedModals,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
