import type { Item, ItemData } from '@/types/Models/Item'
import type { AssignmentPiece } from '@/views/Components/Assignment/Pieces/types'
import type { Product } from '@/types/Models/Product'

export default class ItemTransformer {
  /**
   * Method used to transform a fetched Assignment's Item.
   */
  static fetch(item: Item | ItemData): Item {
    const itemData = {
      weight: parseInt(item.weight?.toString() || '0', 10),
      width: parseInt(item.width?.toString() || '0', 10),
      height: parseInt(item.height?.toString() || '0', 10),
      length: parseInt(item.length?.toString() || '0', 10),
      estimatedCost: parseInt(item.estimatedCost?.toString() || '0', 10),
      recipientAddress: item.recipientAddress || {},
      products: item.products || [],
      product: item.product as Product | undefined,
      validationFlags: {},
    }
    if (Array.isArray(item.validationFlags)) {
      itemData.validationFlags = item.validationFlags.reduce(
        (acc: Record<string, { level: string; reason: string }>, cv) => {
          acc[cv.field] = { level: cv.level, reason: cv.reason }
          return acc
        },
        {} as Record<string, { level: string; reason: string }>,
      )
    }
    if (itemData.products?.length) {
      itemData.product = itemData.products[0]
    }
    return { ...item, ...itemData } as Item
  }

  static create(item: any) {
    const itemData = { ...item }
    delete itemData.pieces

    itemData.estimated_cost = item.pieces.reduce((acc: number, cv: AssignmentPiece) => acc + +cv.estimated_cost, 0)
    itemData.weight = item.pieces.reduce((acc: number, cv: AssignmentPiece) => acc + +cv.weight, 0)
    itemData.height = item.pieces.reduce((acc: number, cv: AssignmentPiece) => acc + +cv.height, 0)
    itemData.length = item.pieces.reduce((acc: number, cv: AssignmentPiece) => acc + +cv.length, 0)
    itemData.width = item.pieces.reduce((acc: number, cv: AssignmentPiece) => acc + +cv.width, 0)

    itemData.products = item.pieces.map((p: AssignmentPiece) => ({
      weight: p.weight,
      width: p.width,
      height: p.height,
      length: p.length,
      estimatedCost: p.estimated_cost,
      description: p.description,
      commodityCode: p.commodity_code,
      quantity: p.quantity,
    }))
    if (item.pieces.length > 0 && item.pieces[0].description) {
      itemData.reference = item.pieces[0].description
    }

    return itemData
  }

  /**
   * Method used to transform a fetched collection.
   */
  static fetchCollection(items: Item[] | ItemData[]) {
    return items.map((item) => this.fetch(item))
  }
}
