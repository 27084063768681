<template>
  <div class="optimization-top-list">
    <div class="row m-auto w-100">
      <div class="col-4 p-0">
        <ul class="mb-0 first-list">
          <li><span>Customer:</span> {{ customer }}</li>

          <li><span>Reference:</span> {{ assignment.reference }}</li>

          <li>
            <span>Date:</span>
            {{ formattedDate(assignment.createdAt, 'YYYY-MM-DD') }}
          </li>

          <li>
            <span>Distribution channel:</span>
            {{ getDistributionChannelTxt(assignment.distributionChannel) }}
          </li>
        </ul>
      </div>
      <div class="col-4 p-0">
        <ul class="mb-0">
          <li><span>Service level:</span> {{ ucFirst(assignment.serviceLevel) }}</li>

          <li>
            <span>Distribution start:</span>
            {{ formattedDate(assignment.createdAt, 'YYYY-MM-DD') }}
          </li>

          <li><span>Total quantity:</span> {{ itemsCount }}</li>

          <li><span>File name:</span> {{ fileNameTrim(fileName) }}</li>
        </ul>
      </div>
      <div v-if="isStaff" class="col-4 p-0">
        <ul class="mb-0">
          <li>
            <span>Customer number:</span>
            {{ ucFirst(assignment.user.clientNumber) }}
          </li>

          <li><span>Invoice reference:</span> {{ customerReference }}</li>

          <li><span>Short name:</span> {{ shortName }}</li>

          <li>
            <span>Total weight (kg):</span>
            {{ assignment.optimizationReport.report.info.totalWeight }}
          </li>
        </ul>
      </div>
    </div>

    <div class="logo ml-3">
      <Logo />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { fileNameTrim, formattedDate, ucFirst } from '@/services/Helpers'
import { getDistributionChannelTxt } from '@/services/Utils'
import Logo from '@/views/Components/Elements/Icons/Logo.vue'

export default defineComponent({
  name: 'OptimizationTopList',
  components: { Logo },
  props: {
    typeReport: {
      type: String,
      default: '',
    },
  },
  setup() {
    const store = useStore()

    const assignment = computed(() => store.getters['assignment/get'])
    const isStaff = computed(() => store.getters['account/isStaff'])
    const customer = computed(() => {
      const user = assignment.value.user
      if (!user) return ''
      return user.fullName && user.fullName.length > 1 ? user.fullName : user.email
    })
    const shortName = computed(() => assignment.value.user?.shortName || '')
    const company = computed(() => {
      const user = assignment.value.user
      if (!user) return ''
      return user.company && user.company.length > 1 ? user.company : user.email
    })
    const itemsCount = computed(() => assignment.value.itemsCount)
    const fileName = computed(() => assignment.value.fileName)
    const customerReference = computed(() => assignment.value.customerReference)

    return {
      assignment,
      isStaff,
      customer,
      shortName,
      company,
      itemsCount,
      fileName,
      customerReference,
      ucFirst,
      fileNameTrim,
      formattedDate,
      getDistributionChannelTxt,
    }
  },
})
</script>

<style lang="scss" scoped>
.logo {
  z-index: 0;
}
.optimization-top-list {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 24px;
  border-bottom: 1px solid #ccc;
}

.optimization-top-list li {
  line-height: 21px;
  margin-bottom: 5px;
  font-weight: 500;
  color: #757575;
}

.optimization-top-list span {
  color: #151515;
  min-width: 115px;
  display: inline-block;
  margin-right: 5px;
}
.logo {
  width: 80px;
  height: 80px;
}
.first-list {
  span {
    min-width: 80px;
  }
}
</style>
