<template>
  <b-button
    size="md"
    variant="link"
    :style="{ backgroundColor: '#08A762', color: '#ffffff' }"
    class="ml-3 btn no-wrap"
    @click="uploadFile"
  >
    <i class="fa fa-upload" />
    Upload File
  </b-button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { openModal } from '@/services/Modal'
import { ATTACH_SOURCE_FILE_MODAL } from '@/const/ModalTypes'

export default defineComponent({
  name: 'AttachSourceFile',
  setup() {
    const uploadFile = () => openModal(ATTACH_SOURCE_FILE_MODAL)

    return {
      uploadFile,
    }
  },
})
</script>
