<template>
  <b-button
    size="md"
    variant="link"
    :style="{ backgroundColor: '#757575', color: '#ffffff' }"
    class="ml-3"
    @click="performAction"
  >
    <i class="fa fa-archive" />
    Complete
  </b-button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import { notify } from '@kyvg/vue3-notification'
import { openModal } from '@/services/Modal'

export default defineComponent({
  name: 'Complete',
  setup() {
    const store = useStore()

    const performAction = () => {
      openModal('confirmWrap', {
        title: 'Complete the assignment?',
        message: 'This will mark the assignment as complete',
        onConfirm: () => {
          store.dispatch('assignment/submitComplete').then(() => {
            store.dispatch('assignment/fetch')
            notify({ group: 'success', text: 'Assignment was completed' })
          })
        },
      })
    }

    return {
      performAction,
    }
  },
})
</script>
