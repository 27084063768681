import type { ActionContext } from 'vuex'
import type { OptimizationReportStoreState, RootStoreState } from '@/types/Store'
import type { Report } from '@/types/Models/Report'
import type { AxiosItem } from '@/types/Components/Response'
import { none } from '@/services/Helpers'
import AssignmentProxy from '@/proxies/AssignmentProxy'

export const FETCH_REPORT_DATA = 'SET_REPORT_DATA'

type Context = ActionContext<OptimizationReportStoreState, RootStoreState>

const state: OptimizationReportStoreState = {
  report: {
    manual: [],
    optimization: [],
    manualTotal: '0',
    percentSaved: 0,
    averageSavingsPerPackage: '0',
    info: {
      customer: '',
      reference: '',
      date: '',
      distributionStart: '',
      serviceLevel: '',
      fileName: null,
      workName: '',
      totalAmount: 0,
      customerNumber: '',
      invoiceReference: '',
      shortName: '',
      totalWeight: '',
      distributionChannel: '',
    },
    totalSavings: '0',
    optimizationCost: '0',
    optimizationProductionManagement: 0,
    hidePrice: false,
  },
}

const getters = {
  all(state: OptimizationReportStoreState) {
    return state.report
  },
}

const mutations = {
  [FETCH_REPORT_DATA](state: OptimizationReportStoreState, data: Report) {
    state.report.manual = data.manual
    state.report.optimization = data.optimization
    state.report.manualTotal = data.manualTotal
    state.report.totalSavings = data.totalSavings
    state.report.percentSaved = data.percentSaved
    state.report.averageSavingsPerPackage = data.averageSavingsPerPackage
    state.report.info = data.info
    state.report.optimizationCost = data.optimizationCost
  },
}

const actions = {
  fetch: ({ commit }: Context, assignmentId: string) => {
    new AssignmentProxy()
      .getReportData(assignmentId)
      .then((response: AxiosItem<Report>) => {
        commit(FETCH_REPORT_DATA, response.data)
      })
      .catch(none)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
