<template>
  <AssignmentStripe />
  <ItemsPage />
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'BatchAssignmentDetails',
  components: {
    AssignmentStripe: defineAsyncComponent(() => import('@/views/Components/PageParts/AssignmentStripe.vue')),
    ItemsPage: defineAsyncComponent(() => import('@/views/Assignments/ItemsPage.vue')),
  },
  setup() {
    const store = useStore()

    onBeforeRouteLeave((_to, _from, next) => {
      store.dispatch('assignment/resetState')
      next()
    })

    return {}
  },
})
</script>
