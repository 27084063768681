<template>
  <div>
    <Spinner v-if="isLoading" class="mb-3"> Loading tracking events </Spinner>

    <div v-else>
      <table class="w-100">
        <thead>
          <tr>
            <th>Time</th>
            <th>Status</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <Event v-for="event in eventsOrdered" :key="event.id" :event="event" />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, type Ref } from 'vue'
import { orderByTimeDesc } from '@/services/Utils'
import { PENDING, LOADING, SUCCESS, FAILURE } from '@/const/LoadingStatus'
import TrackingProxy from '@/proxies/TrackingProxy'
import Event from '@/views/Components/Shipments/Events/Event.vue'
import Spinner from '@/views/Components/Loaders/Spinner.vue'
import type { TrackingEvent } from '@/types/Models/Tracking'

export default defineComponent({
  name: 'EventsCollection',
  components: {
    Spinner,
    Event,
  },
  props: {
    shipmentNumber: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const events: Ref<TrackingEvent[]> = ref([])
    const loadingStatus = ref(PENDING)

    const isLoading = computed(() => [PENDING, LOADING].includes(loadingStatus.value))
    const eventsOrdered = computed(() => [...events.value].sort(orderByTimeDesc))

    const fetchMessages = async () => {
      loadingStatus.value = LOADING
      try {
        const { data } = await new TrackingProxy().find(props.shipmentNumber)
        events.value = data
        loadingStatus.value = SUCCESS
      } catch {
        loadingStatus.value = FAILURE
      }
    }

    onMounted(fetchMessages)

    return {
      eventsOrdered,
      isLoading,
    }
  },
})
</script>
