<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="110" height="28" viewBox="0 0 303.72 69.58">
    <title>21grams</title>
    <g fill="currentColor">
      <g>
        <path
          d="M36.86,16.92C36.86,7.52,29.34,0,18.43,0,3.76,0,0,12.41,0,12.41l9.4,4.51s1.88-6.39,8.28-6.39c4.89,0,7.52,2.63,7.52,6.77C25.2,29,0,29,0,53.78H36.86V43.25H14.29C20.31,33.85,36.86,31.22,36.86,16.92Z"
        />
        <path d="M41.67,8.27v7.15h9V53.78H62V1.13H52.57S50.32,7.15,41.67,8.27Z" />
        <path
          d="M101.59,21.44a14,14,0,0,0-10.9-5.27c-8.28,0-16.55,8.28-16.55,18.43S82.41,53,90.69,53a14,14,0,0,0,10.9-5.27v3c0,6-3.76,9.78-9.77,9.78a12.81,12.81,0,0,1-10.16-4.89l-6.39,6a21.49,21.49,0,0,0,16.92,7.9c11.28,0,19.56-8.28,19.56-18.81V16.92H101.59ZM92.94,43.63c-4.51,0-8.27-3.76-8.27-9s3.76-9,8.27-9a9,9,0,0,1,0,18.06Z"
        />
        <path
          d="M131.16,21.4V16.89H121V53.75h10.15V36.07c0-6.39,3.39-9.78,8.65-9.78a11.73,11.73,0,0,1,3.39.38V16.51h-1.5A14,14,0,0,0,131.16,21.4Z"
        />
        <path
          d="M162.43,16.11a18.44,18.44,0,0,0-14.67,7.15l5.64,5.64a10.29,10.29,0,0,1,8.27-3.76c4.14,0,6.4,2.26,6.4,5.26l-11.66,2.11c-6.77,1.2-10.91,5.42-10.91,11.43,0,5.64,4.89,10.53,12.41,10.53A14.05,14.05,0,0,0,168.07,50v3.76h10.15V30.78C178.22,22.51,171.83,16.11,162.43,16.11Zm5.64,21.82c0,5.26-3,8.27-7.53,8.27-3.38,0-4.88-1.5-4.88-3.76s1.5-3.69,4.13-4.14l8.28-1.5Z"
        />
        <path
          d="M229.56,16.11a15.43,15.43,0,0,0-12.41,6.4,11.31,11.31,0,0,0-10.53-6.4,12.36,12.36,0,0,0-9.78,5.27V16.86H186.69V53.72h10.15V34.54c0-5.64,3-8.65,6.77-8.65,3.39,0,6,2.63,6,7.52V53.72h10.15V34.54c0-5.64,3-8.65,6.77-8.65,3.39,0,6,2.63,6,7.52V53.72h10.15V32.28C242.72,21.38,237.46,16.11,229.56,16.11Z"
        />
        <path
          d="M261.25,27.77c0-1.5,1.51-3,4.51-3a8.13,8.13,0,0,1,6,3l5.27-6a16.22,16.22,0,0,0-12.79-5.64c-8.27,0-14.29,6-14.29,11.66,0,12.19,16.92,9.4,16.92,15.05,0,1.88-1.5,3.38-4.89,3.38-4.89,0-7.89-4.14-7.89-4.14l-5.65,5.64s4.52,6.77,15,6.77c9.4,0,14.67-5.26,14.67-11.65C278.18,30.4,261.25,33,261.25,27.77Z"
        />
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Logo21Grams',
})
</script>
