<template>
  <div>
    <span ref="tooltipTrigger" class="pt-2 pb-2">
      <slot />
    </span>
    <b-tooltip v-if="text" :target="tooltipTrigger" :placement="placement" :title="text" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, type PropType } from 'vue'
import type { PopoverPlacement } from 'bootstrap-vue-next'

export default defineComponent({
  name: 'Tooltip',
  props: {
    text: {
      type: String,
      required: true,
    },
    placement: {
      type: String as PropType<PopoverPlacement>,
      default: 'bottom',
    },
  },
  setup() {
    const tooltipTrigger = ref<HTMLElement | null>(null)

    const computedTooltipTrigger = computed(() => tooltipTrigger.value)

    return {
      tooltipTrigger,
      computedTooltipTrigger,
    }
  },
})
</script>
