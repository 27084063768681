<template>
  <modal-wrap
    ref="requestErrors"
    :name="modalName"
    :header-class="'errors-header'"
    body-class="errors-modal"
    :hide-footer="true"
    size="md"
  >
    <template #modal-title>
      <div class="d-flex justify-content-between align-items-center">
        <h4 class="mb-0">
          {{ modalTitle }}
        </h4>
      </div>
    </template>
    <template #default>
      <div>
        <div v-for="(error, field) in errors" :key="field">
          <b>{{ formatKey(field) }}</b
          >: {{ error.join(', ') }}
        </div>
      </div>
    </template>
  </modal-wrap>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { REQUEST_ERROR } from '@/const/ModalTypes'
import ModalWrap from '@/views/Modals/ModalWrap.vue'

export default defineComponent({
  name: 'RequestErrorModal',
  components: {
    ModalWrap,
  },
  emits: ['refresh'],
  setup(_, { emit }) {
    const store = useStore()
    const modalName = REQUEST_ERROR
    const errors = computed(() => store.getters['vueNextModal/modalProps'](modalName)?.errors || [])
    const closeModal = () => {
      emit('refresh')
      store.dispatch('vueNextModal/closeModal', modalName)
    }
    const formatKey = (key: string | number) => {
      return String(key)
        .replace(/_/g, ' ')
        .replace(/\b\w/g, (l) => l.toUpperCase())
    }

    return {
      errors,
      formatKey,
      modalName,
      closeModal,
      modalTitle: 'Request Error',
    }
  },
})
</script>
