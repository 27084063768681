<template>
  <QueryStringDropdown :options="options" :with-filter="false" :query-param="STATUS" />
</template>

<script lang="ts">
import type { SelectOptions } from '@/types/Components/SelectOptions'
import { defineComponent } from 'vue'
import { STATUS } from '@/const/AssignmentListQueryFilters'
import {
  CANCELLED,
  COMPLETE,
  OPEN,
  IN_REVIEW,
  LABELS_CREATING,
  LABELS_RELEASED,
  OPTIMISING,
  PENDING,
  REPORT_ACCEPTED,
  REPORT_DENIED,
  REPORT_RELEASED,
  VALIDATING,
  VALIDATION_COMPLETE,
} from '@/const/AssignmentStatuses'
import QueryStringDropdown from '@/views/Components/PageParts/QueryStringDropdown.vue'

export default defineComponent({
  name: 'StatusFilter',
  components: { QueryStringDropdown },
  setup() {
    const options: SelectOptions[] = [
      { value: '', text: 'Status' },
      { value: OPEN, text: 'Open' },
      { value: PENDING, text: 'Pending' },
      { value: VALIDATING, text: 'Validating' },
      { value: VALIDATION_COMPLETE, text: 'Validation Complete' },
      { value: IN_REVIEW, text: 'In Review' },
      { value: OPTIMISING, text: 'Optimising' },
      { value: REPORT_RELEASED, text: 'Report Released' },
      { value: REPORT_ACCEPTED, text: 'Report Accepted' },
      { value: REPORT_DENIED, text: 'Report Denied' },
      { value: LABELS_CREATING, text: 'Labels Creating' },
      { value: LABELS_RELEASED, text: 'Labels Released' },
      { value: CANCELLED, text: 'Cancelled' },
      { value: COMPLETE, text: 'Complete' },
    ]

    return {
      options,
      STATUS,
    }
  },
})
</script>
