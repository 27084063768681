<template>
  <span class="text-secondary">
    <svg viewBox="0 0 484.66 484.66" fill="#ff4e10">
      <defs>
        <clipPath id="clip-path">
          <path class="cls-1" d="M0 0h484.66v484.66H0z" />
        </clipPath>
        <clipPath id="clip-path-2">
          <path class="cls-1" d="M0 0h484.66v484.66H0z" />
        </clipPath>
      </defs>
      <path class="cls-1" d="M0 0h484.66v484.66H0z" />
      <path class="cls-1" d="M0 0h484.66v484.66H0z" />
      <g clip-path="url(#clip-path)">
        <path class="cls-1" d="M0 0h484.66v484.66H0z" />
        <g clip-path="url(#clip-path-2)">
          <path
            d="M242.33 150.68a59.1 59.1 0 00-59 59V334h118.02V209.71a59.09 59.09 0 00-59-59m0 10a49 49 0 11-49 49 49.08 49.08 0 0149-49m0 108a59.22 59.22 0 007.58-.48l-56.6 49.68v-75.36a59 59 0 0049 26.18m49 55.24h-89.74l78.64-69a59.46 59.46 0 0011.14-12.39z"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Logo',
})
</script>

<style scoped>
.logo svg {
  width: 100%;
  height: 100%;
}
</style>
