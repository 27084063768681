<template>
  <div class="row">
    <div class="col-12">
      <button class="btn create-btn btn-green w-100" @click="processAssignment">Finish buffering</button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import DefaultActions from '@/services/Assignment/Actions/DefaultActions'

export default defineComponent({
  name: 'Open',
  setup() {
    const processAssignment = () => {
      DefaultActions.processAssignment()
    }

    return {
      processAssignment,
    }
  },
})
</script>
