<template>
  <ul class="footer-errors mb-0">
    <li>
      <div class="errors-slots">
        Errors:
        <mdicon name="Square" class="mx-2" />
        {{ fatalErrorCount }} {{ pluralize('slot', fatalErrorCount) }}
      </div>
    </li>

    <li v-if="false">
      <div class="errors-nav">
        <button class="disabled">
          <mdicon name="ChevronLeft" />
        </button>
        <span class="errors-nav-page">1/4</span>
        <button>
          <mdicon name="ChevronRight" />
        </button>
      </div>
    </li>

    <li>
      <div class="errors-type">
        <p>View type</p>
        <div class="switch-error">
          All
          <ToggleBtn v-model="isErrorsOnly" :value="fatalErrorCount" @change="updateErrorsFilter" />
          Errors only
        </div>
      </div>
    </li>
  </ul>
</template>

<script lang="ts">
import { computed, defineComponent, watch } from 'vue'
import { useStore } from 'vuex'
import { pluralize } from '@/services/Helpers'
import ToggleBtn from '@/views/Components/Elements/Buttons/ToggleBtn.vue'

export default defineComponent({
  name: 'ItemsErrors',
  components: {
    ToggleBtn,
  },
  setup() {
    const store = useStore()

    const fatalErrorCount = computed(() => store.getters['item/fatalErrorsCount'])
    const isErrorsOnly = computed(() => store.getters['item/errorsOnly'])

    const updateErrorsFilter = (value: boolean) => {
      store.dispatch('item/setErrorsOnly', value)
    }

    watch(fatalErrorCount, (newVal) => {
      if (newVal === 0 && isErrorsOnly.value) {
        updateErrorsFilter(false)
      }
    })

    return {
      pluralize,
      updateErrorsFilter,
      fatalErrorCount,
      isErrorsOnly,
    }
  },
})
</script>
