<template>
  <b-button size="md" variant="link" :style="{ backgroundColor: '#FFCA10', color: '#151515' }" class="ml-3" :to="to">
    <i class="fa fa-list" />
    View assignment
  </b-button>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { CUSTOMER_ASSIGNMENTS_SINGLE, STAFF_ASSIGNMENTS_SINGLE } from '@/types/router'

export default defineComponent({
  name: 'ViewAssignment',
  setup() {
    const store = useStore()

    const isStaff = computed(() => store.getters['account/isStaff'])
    const assignmentId = computed(() => store.getters['assignment/getId'])
    const routeName = computed(() => (isStaff.value ? STAFF_ASSIGNMENTS_SINGLE : CUSTOMER_ASSIGNMENTS_SINGLE))
    const to = computed(() => ({
      name: routeName.value,
      params: { id: assignmentId.value },
    }))

    return {
      to,
    }
  },
})
</script>
