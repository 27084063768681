<template>
  <div v-if="display" class="pb-3">
    <div class="child-row-item__title">Cost</div>
    <div class="child-row-item__subtitle">
      <span class="cost-value"> {{ shipment.actualPrice }} kr </span>

      <small v-if="isOptimised" class="text-muted">
        <s> {{ shipment.initialPrice }} kr </s>
      </small>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, type PropType } from 'vue'
import { useStore } from 'vuex'
import { OPTIMISED } from '@/const/AssignmentModeTypes'
import type { Shipment } from '@/types/Models/Shipment'

export default defineComponent({
  name: 'ShipmentCost',
  props: {
    shipment: {
      type: Object as PropType<Shipment>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore()

    const isStaff = computed(() => store.getters['account/isStaff'])
    const hidePrices = computed(() => store.getters['account/isHidePrice'])

    const isOptimised = computed(() => props.shipment.mode === OPTIMISED)
    const display = computed(() => isStaff.value || !hidePrices.value)

    return {
      isStaff,
      hidePrices,
      isOptimised,
      display,
    }
  },
})
</script>

<style lang="scss" scoped>
.cost-value {
  color: #08a762;
}
</style>
