<template>
  <div>
    {{ trackingStatus2Name(trackingStatus, 'Created') }}
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, type PropType, type ComputedRef } from 'vue'
import { trackingStatus2Name } from '@/services/Helpers'
import type { AdjustedShipment, Shipment } from '@/types/Models/Shipment'

export default defineComponent({
  name: 'TrackingStatusCell',
  props: {
    shipment: {
      type: Object as PropType<Shipment>,
      required: true,
    },
  },
  setup(props) {
    const orderedPieces: ComputedRef<AdjustedShipment[]> = computed(() =>
      (props.shipment.shipments ? [...props.shipment.shipments] : []).sort(
        (a: AdjustedShipment, b: AdjustedShipment) => b.runningNumber - a.runningNumber,
      ),
    )

    const primaryShipment: ComputedRef<AdjustedShipment | null> = computed(() => {
      return orderedPieces.value[0] || null
    })

    const secondaryShipment: ComputedRef<AdjustedShipment | null> = computed(() => {
      return orderedPieces.value[1] || null
    })

    const trackingStatus: ComputedRef<string> = computed(() => {
      return primaryShipment.value?.lastTrackingEventStatus || secondaryShipment.value?.lastTrackingEventStatus || ''
    })

    return {
      trackingStatus,
      trackingStatus2Name,
    }
  },
})
</script>
