<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import VLayout from '@/views/Layouts/DefaultLayout.vue'
import InfoLayout from '@/views/Layouts/InfoLayout.vue'

export default defineComponent({
  name: 'FAQ',
  components: {
    VLayout,
    InfoLayout,
  },
  setup() {
    const store = useStore()

    const authenticated = computed(() => store.getters['isLoggedIn'])
    const isStaff = computed(() => store.getters['account/isStaff'])

    const layout = computed(() => (authenticated.value && isStaff.value ? 'v-layout' : 'info-layout'))

    return {
      layout,
    }
  },
})
</script>
