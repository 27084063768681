<template>
  <!-- ############ Route view For child pages-->
  <router-view />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Assignments',
})
</script>
