<template>
  <div>
    <div class="d-flex mb-3 justify-content-between">
      <div class="d-flex">
        <div class="d-flex">
          <div class="mr-2">
            <date-picker
              v-model="date"
              format="yyyy-MM-dd"
              range
              multi-calendars
              placeholder="Filter by date"
              input-class="date-input"
              popup-class="date-drop"
              :clearable="true"
            >
            </date-picker>
          </div>
        </div>
        <div class="ml-4 d-flex align-items-center border-left">
          <div class="pl-4 border-start table m-0">
            <div class="d-flex align-items-center">
              <b-button v-if="!exportLoading" class="file-btn" @click="exportCSV">
                <Tooltip text="Export into CSV">
                  <CsvIcon :class="!exportData ? '' : 'text-success'" />
                </Tooltip>
              </b-button>
              <b-spinner v-if="exportLoading" class="mt-1" variant="success" small />
            </div>
          </div>
        </div>
      </div>
      <Search @search-triggered="search" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type Ref, ref, toRaw, watch } from 'vue'
import { useStore } from 'vuex'
import { notify } from '@kyvg/vue3-notification'
import Tooltip from '@/views/Components/Elements/Tooltip.vue'
import CsvIcon from '@/views/Components/Elements/Icons/CsvIcon.vue'
import Search from '@/views/Components/PageParts/Search.vue'
import DatePicker from '@vuepic/vue-datepicker'
import moment from 'moment'
import StatisticsProxy from '@/proxies/StatisticsProxy'
import type { StatisticsFilterType } from '@/types/Models/StatisticsFilterType'

export default defineComponent({
  name: 'StatisticsHeader',
  components: {
    Search,
    Tooltip,
    CsvIcon,
    DatePicker,
  },
  emits: ['filtrate'],
  setup(_, { emit }) {
    const store = useStore()
    const exportLoading = ref(false)
    const exportData: Ref<string | null> = ref(null)
    const filterData: Ref<StatisticsFilterType> = ref({
      startDate: moment().subtract(1, 'week').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      query: undefined,
    })
    const date = ref([moment().subtract(1, 'week').toDate(), moment().toDate()])
    const search = (value: string) => {
      filterData.value.query = value ? value : undefined
    }

    watch(
      date,
      (value: any) => {
        if (value === null) {
          filterData.value.startDate = moment().subtract(1, 'year').format('YYYY-MM-DD')
          filterData.value.endDate = moment().format('YYYY-MM-DD')
          return
        }
        filterData.value.startDate = moment(value[0]).format('YYYY-MM-DD')
        filterData.value.endDate = moment(value[1]).format('YYYY-MM-DD')
      },
      { deep: true },
    )
    watch(
      filterData,
      (value: StatisticsFilterType) => {
        emit('filtrate', toRaw(value))
      },
      { deep: true },
    )

    const exportCSV = () => {
      if (exportData.value !== null) {
        window.open(exportData.value, '_blank')
        return
      }
      window.Echo.private(`App.User.${store.state.account.id}`)
        .listen('.csv-ready', (data: any) => exportReady(data))
        .listen('.failed', (e: any) => {
          exportLoading.value = false
          notify({ group: 'error', text: e.message })
        })
      exportLoading.value = true
      const data = { ...toRaw(filterData.value) }

      new StatisticsProxy(data).advancedFinanceExport().catch(() => {
        exportLoading.value = false
        notify({
          group: 'error',
          text: 'Error while exporting, please try again.',
        })
      })
      setTimeout(() => {
        if (exportData.value === null) {
          notify({
            group: 'success',
            text: 'It seems file generation might take a long time, we will send a download link to your email',
          })
        }
      }, 20000)
    }

    const exportReady = (data: any) => {
      exportLoading.value = false
      notify({ group: 'success', text: `${data.message}` })
      if (data.link) {
        window.open(data.link, '_blank')
      } else {
        notify({
          group: 'error',
          text: 'Error while creating file, please try again',
        })
      }
    }

    return {
      search,
      exportCSV,
      exportReady,
      date,
      exportLoading,
      exportData,
    }
  },
})
</script>

<style scoped>
.table .file-btn:first-child {
  margin-right: 18px;
}
.table .file-btn {
  width: 24px;
  height: 24px;
  background: transparent;
  padding: 0;
  border: 0;
  color: #151515;
}
.file-btn > div {
  background: transparent !important;
  padding: 0;
}
.border-left {
  border-left: 1px solid #dee2e6 !important;
}
.border-left > div {
  border-left: none !important;
}
</style>
