import type { Ref } from 'vue'
import type { AssignmentPiece } from '@/views/Components/Assignment/Pieces/types'
import { defaultPiece } from '@/views/Components/Assignment/Pieces/utils/default-piece'

type EmitFunction = (event: 'update:pieces', payload: AssignmentPiece[]) => void

type Props = {
  emit: EmitFunction
  pieces: Ref<AssignmentPiece[]>
}

const numberTypeColumns = ['weight', 'height', 'length', 'width', 'estimated_cost', 'quantity']

export const useActions = ({ emit, pieces }: Props) => {
  const addNewPiece = () => {
    emit('update:pieces', [...pieces.value, { ...defaultPiece }])
  }

  const removePiece = (piece: AssignmentPiece) => {
    emit(
      'update:pieces',
      pieces.value.filter((i) => i !== piece),
    )
  }

  const updatePiece = (event: Event, item: AssignmentPiece, column: string) => {
    let inputValue: string | number = (event.target as HTMLInputElement).value

    if (numberTypeColumns.includes(column)) {
      inputValue = inputValue === '' ? 0 : Number(inputValue)
    } else {
      inputValue = String(inputValue)
    }

    item[column] = inputValue as AssignmentPiece[typeof column]

    emit('update:pieces', pieces.value)
  }

  return {
    addNewPiece,
    removePiece,
    updatePiece,
  }
}
