<template>
  <label class="switch">
    <input type="checkbox" :checked="isChecked" :value="value" @change="updateInput" />
    <span class="switch-slider" />
  </label>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'ToggleBtn',
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    trueValue: {
      type: Boolean,
      default: true,
    },
    falseValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
  setup(props, { emit }) {
    const isChecked = computed(() => {
      return props.modelValue === props.trueValue
    })

    function updateInput(event: Event) {
      const target = event.target as HTMLInputElement
      const isChecked = target.checked

      if (Array.isArray(props.modelValue)) {
        const newValue = [...props.modelValue]
        if (isChecked) {
          newValue.push(props.value)
        } else {
          newValue.splice(newValue.indexOf(props.value), 1)
        }
        emit('change', newValue)
      } else {
        emit('change', isChecked ? props.trueValue : props.falseValue)
      }
    }

    return {
      isChecked,
      updateInput,
    }
  },
})
</script>
