<template>
  <div>
    The assignment <b>{{ assignment.reference }}</b> has the labels created at
    <b>{{ readableDate(assignment.labelsCreatedAt) }} {{ readableTime(assignment.labelsCreatedAt) }}</b
    >, which is quite long ago.
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { readableDate, readableTime } from '@/services/Helpers'
import type { AssignmentsUnion } from '@/composables/labels/use-download-labels-action'

export default defineComponent({
  name: 'OneAssignment',
  props: {
    assignment: {
      type: Object as PropType<AssignmentsUnion>,
      required: true,
    },
  },
  setup() {
    return {
      readableDate,
      readableTime,
    }
  },
})
</script>
