<template>
  <div>
    <header class="main-header">
      <div class="d-flex h-110">
        <div class="logo">
          <router-link :to="{ name: assignmentsPage }">
            <img src="../../assets/img/logo.svg" alt="Logo" />
          </router-link>
        </div>
        <div class="header-title align-self-center">
          <router-link :to="{ name: assignmentsPage }">
            <Logo21Grams />
            <span class="header-title-page">{{ page }}</span>
          </router-link>
        </div>

        <div class="header-action">
          <b-button v-if="!isStaff" class="btn btn-help" href="mailto:support@21grams.com"> Need help? </b-button>

          <!-- App dropdown -->
          <button class="app-button" :class="{ open: appMenuShow }" @click="toggleAppMenu">
            <span class="icon-mask"></span>
          </button>

          <button class="menu-button" :class="{ open: dropMenuShow }" @click="toggleDropMenu">
            <span class="icon-mask"></span>
          </button>

          <div v-if="appMenuShow" v-click-outside="hideAppMenu" class="menu-apps">
            <a href="#">
              <span class="apps-link-icon-circle">
                <div class="apps-link-icon"></div>
              </span>
              File Exchange
            </a>

            <a href="#">
              <span class="apps-link-icon-circle">
                <div class="apps-link-icon"></div>
              </span>
              Postportalen
            </a>

            <a href="#">
              <span class="apps-link-icon-circle">
                <div class="apps-link-icon"></div>
              </span>
              eArkiv
            </a>

            <a href="#">
              <span class="apps-link-icon-circle">
                <div class="apps-link-icon"></div>
              </span>
              Document tracker
            </a>
          </div>

          <!-- Menu dropdown -->
          <div v-if="dropMenuShow" v-click-outside="hideDropMenu" class="menu-dropdown">
            <router-link v-if="can('manage', 'prices')" :to="{ name: basePricePage }"> Base Prices </router-link>

            <a href="#" @click="logout">
              <span>Sign out</span>
              <span class="logout-icon"></span>
            </a>

            <a href="tel: +4686003721">
              <span>Support <b>+46 8 600 37 21</b></span>
              <span class="phone-icon"></span>
            </a>
          </div>
        </div>
      </div>
    </header>
    <div class="pattern" />
  </div>
</template>

<script lang="ts">
import { ref, computed, watch, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { CUSTOMER_ASSIGNMENTS_LIST, STAFF_ASSIGNMENTS_LIST, STAFF_BASE_PRICE_PAGE } from '@/types/router'
import Logo21Grams from '@/views/Components/Elements/Icons/Logo21Grams.vue'

export default defineComponent({
  name: 'LayoutHeader',
  components: { Logo21Grams },
  setup() {
    const store = useStore()
    const route = useRoute()

    const appMenuShow = ref(false)
    const dropMenuShow = ref(false)

    const ability = computed(() => store.getters['ability'])
    const isStaff = computed(() => store.getters['account/isStaff'])
    const page = computed(() => (route.meta.title ? `/ ${route.meta.title}` : ''))
    const basePricePage = STAFF_BASE_PRICE_PAGE

    const logout = () => {
      store.dispatch('auth/logout')
    }

    const hideDropMenu = () => {
      dropMenuShow.value = false
    }

    const hideAppMenu = () => {
      appMenuShow.value = false
    }

    const toggleDropMenu = () => {
      dropMenuShow.value = !dropMenuShow.value
      appMenuShow.value = false
    }

    const toggleAppMenu = () => {
      appMenuShow.value = !appMenuShow.value
      dropMenuShow.value = false
    }

    watch(route, () => {
      dropMenuShow.value = false
    })

    return {
      appMenuShow,
      dropMenuShow,
      isStaff,
      page,
      basePricePage,
      logout,
      hideDropMenu,
      hideAppMenu,
      toggleDropMenu,
      toggleAppMenu,
      assignmentsPage: isStaff.value ? STAFF_ASSIGNMENTS_LIST : CUSTOMER_ASSIGNMENTS_LIST,
      can: (rule: string, section: string) => ability.value.can(rule, section),
    }
  },
})
</script>
