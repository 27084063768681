<template>
  <b-button
    size="md"
    variant="link"
    :style="{ backgroundColor: '#08A762', color: '#151515' }"
    class="ml-3"
    @click="performAction"
  >
    <i class="fa fa-list" />
    Create report
  </b-button>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { openModal } from '@/services/Modal'
import { OPTIMIZE_CREATING_REPORT_LOADER } from '@/const/LoadersNames'

export default defineComponent({
  name: 'CreateReport',
  setup() {
    const isStaff = computed(() => store.getters['account/isStaff'])
    const store = useStore()

    const performAction = () => {
      openModal('confirmWrap', {
        title: 'Optimize & Create Report?',
        message: isStaff.value
          ? 'This will create report and send it to client.'
          : 'You will be able to accept report and create labels at the next stage',
        okButtonText: 'Proceed',
        onConfirm: () => {
          store.dispatch('ui/loaderShow', {
            name: OPTIMIZE_CREATING_REPORT_LOADER,
            data: { progress: 0 },
          })
          store.dispatch('assignment/submitOptimize')
        },
      })
    }

    return {
      performAction,
    }
  },
})
</script>
