<template>
  <info-layout>
    <router-view />
  </info-layout>
</template>

<script lang="ts">
import InfoLayout from '@/views/Layouts/InfoLayout.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'InfoPage',
  components: {
    InfoLayout,
  },
})
</script>
