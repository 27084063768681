import { INFO_PAGE, PARCEL_REQUIREMENTS, PRIVACY, PROFORMA_INVOICE, TERMS } from '@/types/router'
import type { RouteRecordRaw } from 'vue-router'

const info: Array<RouteRecordRaw> = [
  {
    path: '/info',
    name: INFO_PAGE,
    component: () => import('@/views/InfoPages/Index.vue'),
    meta: {
      auth: false,
    },
    children: [
      {
        path: 'privacy-policy/',
        component: () => import('@/views/InfoPages/Privacy.vue'),
        name: PRIVACY,
        meta: {
          title: '21grams | Privacy Policy',
        },
      },
      {
        path: 'terms-of-use/',
        component: () => import('@/views/InfoPages/Terms.vue'),
        name: TERMS,
        meta: {
          title: '21grams | Terms of Use',
        },
      },
      {
        path: 'how-to-use-proforma-invoice/',
        component: () => import('@/views/InfoPages/ProformaInvoice.vue'),
        name: PROFORMA_INVOICE,
        meta: {
          title: '21grams | How to correctly create an electronic proforma invoice',
        },
      },
      {
        path: 'parcel-requirements/',
        component: () => import('@/views/InfoPages/ParcelRequirements.vue'),
        name: PARCEL_REQUIREMENTS,
        meta: {
          title: '21grams | Parcel Characteristics Requirements',
        },
      },
    ],
  },
]

export default info
