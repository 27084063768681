import type { DimensionsRules, RulesBucket } from '@/views/Components/Assignment/Pieces/validation/dimensions-rules'

const dimensions_sum_not_greater_than = [90, 'height', 'width', 'length']

export class PackageDimensions implements DimensionsRules {
  constructor(private readonly recipientCountry: string) {}

  weight(): RulesBucket {
    const min_value = 1

    /**
     * For Swedish shipments, we can use DHL Freight parcel to deliver 2kg-5kg shipments, but
     * we cannot do that for other countries. So the limit is still 2kg worldwide, except for  Sweden
     */
    const max_value = this.recipientCountry === 'SE' ? 5000 : 2000

    return { required: true, numeric: true, min_value, max_value }
  }

  width(): RulesBucket {
    return {
      required: true,
      numeric: true,
      min_value: 1,
      dimensions_sum_not_greater_than,
    }
  }

  length(): RulesBucket {
    return {
      required: true,
      numeric: true,
      min_value: 1,
      dimensions_sum_not_greater_than,
    }
  }

  height(): RulesBucket {
    return {
      required: true,
      numeric: true,
      min_value: 1,
      dimensions_sum_not_greater_than,
    }
  }
}
