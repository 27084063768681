<template>
  <b-button class="labels-button" @click="openDownloadLabelsModal">
    <i class="fa fa-print" />
  </b-button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { ListAssignment } from '@/types/Models/Assignment'
import { useDownloadLabelsAction } from '@/composables/labels/use-download-labels-action'

export default defineComponent({
  name: 'LabelsButton',
  props: {
    assignments: {
      type: Array as () => Array<ListAssignment>,
      required: true,
    },
  },
  setup({ assignments }) {
    const downloadLabelsAction = useDownloadLabelsAction()

    const openDownloadLabelsModal = () => downloadLabelsAction(assignments)

    return {
      openDownloadLabelsModal,
    }
  },
})
</script>

<style lang="scss">
.labels-button {
  padding: 0 !important;
  height: 40px !important;
  min-height: 40px;
  border: 0;
  width: 52px;
  background-color: #999999 !important;

  &:hover {
    background: #666666 !important;
  }
}
</style>
