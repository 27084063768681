<template>
  <div class="p-lg-4 faq-page" :class="authenticated && can('manage', 'faq') ? 'box-shadow bg-white table-shadow' : ''">
    <div class="d-flex justify-content-end mb-4">
      <router-link v-if="authenticated && can('manage', 'faq')" class="btn btn-plus w-auto d-flex" :to="createFAQRoute">
        <span class="mar-4">Add Question</span>
        <span class="pl-2">
          <mdicon name="plus" size="24" />
        </span>
      </router-link>
    </div>
    <div v-if="!authenticated || !can('manage', 'faq')" class="pl-lg-4 pr-lg-5 mb-5 col-12">
      <div class="pl-lg-1 pr-lg-1">
        <h1 class="mb-4">Questions & Answers</h1>
        <p class="font-weight-300 mb-4">Parcel-Portal</p>
        <p class="font-weight-300 mb-4">
          As users and customers come in with frequently asked questions, we fill in this page. If you don't see what
          you are looking for, test and search using the browser's own search function, ctrl + F or similar command.
        </p>
        <p class="font-weight-300">
          We are the ones who answer your questions, Petronella, Eleonor, Patrik and Martin. You can always contact
          support.
        </p>
      </div>
    </div>
    <div class="d-flex col-12 flex-wrap pl-0 pr-0">
      <div v-if="questions.length === 0" class="col-12 text-center">No Results</div>
      <QuestionAnswer
        v-for="question in questions"
        :id="question.id"
        :key="question.id"
        :question="question.question"
        :answer="question.answer"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { FAQ_CREATE } from '@/types/router'
import QuestionAnswer from '@/views/Components/FAQ/QuestionAnswer.vue'

export default defineComponent({
  name: 'FAQList',
  components: { QuestionAnswer },
  setup() {
    const store = useStore()

    const ability = computed(() => store.getters['ability'])
    const isStaff = computed(() => store.getters['account/isStaff'])
    const questions = computed(() => store.state.faq.questions)
    const authenticated = computed(() => store.getters['isLoggedIn'])
    const createFAQRoute = computed(() => ({ name: FAQ_CREATE }))

    onMounted(() => {
      store.dispatch('faq/fetchQuestions')
    })

    return {
      isStaff,
      questions,
      authenticated,
      createFAQRoute,
      can: (rule: string, section: string) => ability.value.can(rule, section),
    }
  },
})
</script>

<style scoped>
h1 {
  font-size: 20px;
}
@media screen and (min-width: 768px) {
  h1 {
    font-size: 48px;
  }
}
.faq-page {
  border-radius: 4px;
}
.btn {
  padding: 8px 17px !important;
}
.mar-4 {
  margin-top: 4px;
}
</style>
