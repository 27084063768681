<template>
  <div class="d-flex flex-column h-100 mh-v">
    <LayoutHeader />
    <div class="position-relative h-100">
      <div class="bg-light flex-fill">
        <SubHeader />
        <SenderAddressPricesActionMenu v-if="showSenderAddressActionMenu" />
        <div :class="containerClass" class="pt-5">
          <div class="row">
            <div class="col col-12">
              <!-- Content will be placed here -->
              <slot />
            </div>
          </div>
        </div>
      </div>
      <LayoutFooter v-if="!hideFooter" class="mt-auto" />
    </div>
    <LoaderDefault />
    <OptimizationCreatingReportLoader :id="OPTIMIZE_CREATING_REPORT_LOADER" />
    <CreatingLabelsLoader :id="LABELS_CREATING_LOADER" />
    <DownloadLabelsLoader :id="LABELS_DOWNLOAD_LOADER" />
    <DownloadLabelsModal :id="DOWNLOAD_LABELS" />
    <DownloadLabelsWarning :id="DOWNLOAD_LABELS_WARNING" />
    <Confirm id="confirmWrap" />
  </div>
</template>

<script lang="ts">
import { useRoute } from 'vue-router'
import { defineComponent, computed, onMounted } from 'vue'
import { registerModals } from '@/services/Modal'
import { DOWNLOAD_LABELS, DOWNLOAD_LABELS_WARNING } from '@/const/ModalTypes'
import { LABELS_CREATING_LOADER, LABELS_DOWNLOAD_LOADER, OPTIMIZE_CREATING_REPORT_LOADER } from '@/const/LoadersNames'
import LayoutHeader from '@/views/Layouts/LayoutHeader.vue'
import SubHeader from '@/views/Layouts/SubHeader.vue'
import LayoutFooter from '@/views/Layouts/LayoutFooter.vue'
import LoaderDefault from '@/views/Components/Loaders/LoaderDefault.vue'
import DownloadLabelsModal from '@/views/Modals/Assignment/DownloadLabelsModal.vue'
import CreatingLabelsLoader from '@/views/Components/Loaders/CreatingLabelsLoader.vue'
import DownloadLabelsLoader from '@/views/Components/Loaders/DownloadLabelsLoader.vue'
import SenderAddressPricesActionMenu from '@/views/Components/Customer/SenderAddressPricesActionMenu.vue'
import OptimizationCreatingReportLoader from '@/views/Components/Loaders/OptimizationCreatingReportLoader.vue'
import Confirm from '@/views/Modals/Confirm.vue'
import DownloadLabelsWarning from '@/views/Modals/Assignment/DownloadLabelsWarning/DownloadLabelsWarning.vue'

export default defineComponent({
  name: 'DefaultLayout',
  components: {
    DownloadLabelsWarning,
    Confirm,
    SubHeader,
    LayoutHeader,
    LayoutFooter,
    LoaderDefault,
    DownloadLabelsModal,
    DownloadLabelsLoader,
    CreatingLabelsLoader,
    SenderAddressPricesActionMenu,
    OptimizationCreatingReportLoader,
  },
  setup() {
    const route = useRoute()

    const containerClass = computed(() => route.meta?.containerClass || 'container')
    const hideFooter = computed(() => !!route.meta?.hideFooter)
    const showSenderAddressActionMenu = computed(() => route.meta?.showSenderAddressActionMenu || false)

    onMounted(() => {
      registerModals(DOWNLOAD_LABELS)
      registerModals(DOWNLOAD_LABELS_WARNING)
    })

    return {
      hideFooter,
      containerClass,
      showSenderAddressActionMenu,
      DOWNLOAD_LABELS,
      DOWNLOAD_LABELS_WARNING,
      LABELS_CREATING_LOADER,
      LABELS_DOWNLOAD_LOADER,
      OPTIMIZE_CREATING_REPORT_LOADER,
    }
  },
})
</script>
