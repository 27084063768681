<template>
  <div class="bg-light">
    <SimpleHeader />
    <div class="page-body">
      <div class="container">
        <slot />
      </div>
    </div>
    <LayoutFooter />
  </div>
</template>

<script lang="ts">
import SimpleHeader from '@/views/Layouts/SimpleHeader.vue'
import LayoutFooter from '@/views/Layouts/LayoutFooter.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'InfoLayout',
  components: { SimpleHeader, LayoutFooter },
})
</script>

<style lang="scss" scoped>
.page-body {
  background-color: #f5f5f3 !important;
}
.bg-light {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
footer {
  margin-top: auto;
}
</style>
