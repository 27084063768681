<template>
  <div class="assignment-stripe">
    <div class="assignment-number mr-4">
      <router-link :to="assignmentsPage" class="mr-4">
        <a class="back-to position-absolute">
          <mdicon name="ChevronLeft" />
        </a>
      </router-link>
      <span class="ml-1">Assignment #{{ assignment.reference }}</span>
    </div>
    <div v-if="isShowAssignmentInfo" class="assignment-action d-flex mr-4 user-info">
      <div class="d-flex flex-column mr-3">
        <div v-if="user?.shortName" class="color-grey">Short name</div>
        <div class="color-black">
          {{ user?.shortName }}
        </div>
      </div>
      <div v-if="user?.clientNumber" class="d-flex flex-column mr-3">
        <div class="color-grey">Client №</div>
        <div class="color-black">
          {{ senderAddress?.clientNumber || user?.clientNumber }}
        </div>
      </div>
      <div v-if="user?.company" class="d-flex flex-column">
        <div class="color-grey">Company</div>
        <div class="color-black">
          {{ senderAddress?.company || user?.company }}
        </div>
      </div>
    </div>
    <div class="d-flex pr-4 status" :class="{ 'border-l pl-4': !isShowAssignmentInfo }">
      <AssignmentStatus />
      <span v-if="errorCount > 0" class="ml-3"> ({{ errorCount }} {{ pluralize('error', errorCount) }}) </span>
    </div>
    <div class="assignment-action mr-4 d-flex">
      <b-button
        :disabled="!reportFile || isHidePrice"
        class="file-btn ml-0"
        :href="reportFile"
        target="_blank"
        :download="true"
      >
        <Tooltip :text="actionTooltip('report', !reportFile)">
          <mdicon name="file-pdf-box" />
        </Tooltip>
      </b-button>
      <b-button
        v-for="(sourceFile, key) in sourceFiles"
        :key="key"
        class="file-btn"
        :href="sourceFile.fileUrl"
        :download="true"
      >
        <Tooltip :text="actionTooltip('Source', true)">
          <mdicon name="Download" />
        </Tooltip>
      </b-button>
      <b-button class="file-btn ml-3" :href="exportLink" :download="true" target="_blank">
        <Tooltip text="Export assignment to CSV">
          <mdicon name="file-delimited-outline" />
        </Tooltip>
      </b-button>
    </div>

    <div class="assignment-last-update d-flex align-items-center">
      <mdicon name="History" />
      Last edited {{ toTimeFromNow(assignment.updatedAt) }}
    </div>

    <div class="assignment-function d-flex">
      <router-link
        v-if="can('manage', 'prices')"
        :to="dynamicPricesLink"
        :disabled="!isItemsEditable"
        :class="'btn ml-3 btn-height-md btn-outline-secondary no-wrap' + (isItemsEditable ? '' : ' disabled')"
      >
        Dynamic Pricing
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { defineComponent, computed, onMounted, onBeforeMount, onBeforeUnmount, type ComputedRef } from 'vue'
import { pluralize, toTimeFromNow } from '@/services/Helpers'
import { VALIDATION_COMPLETE } from '@/const/AssignmentStatuses'
import { CUSTOMER_ASSIGNMENTS_LIST, STAFF_ASSIGNMENT_PRICES, STAFF_ASSIGNMENTS_LIST } from '@/types/router'
import Tooltip from '@/views/Components/Elements/Tooltip.vue'
import AssignmentStatus from '@/views/Components/PageParts/AssignmentStatus.vue'
import type { BaseAssignment, SourceFile } from '@/types/Models/Assignment'
import type { RouterLink } from '@/types/Components/Router'
import type { PureAbility } from '@casl/ability'
import type { User } from '@/types/Models/User'
import type { Address } from '@/types/Models/Customer'

export default defineComponent({
  name: 'AssignmentStripe',
  components: {
    AssignmentStatus,
    Tooltip,
  },
  setup() {
    const store = useStore()
    const route = useRoute()

    const ability: ComputedRef<PureAbility> = computed(() => store.getters['ability'])
    const userId: ComputedRef<string> = computed(() => store.getters['account/userId'])
    const user: ComputedRef<User | null> = computed(() => store.getters['assignment/getUser'])
    const senderAddress: ComputedRef<Address | null> = computed(() => store.getters['assignment/getSenderAddress'])
    const isStaff: ComputedRef<boolean> = computed(() => store.getters['account/isStaff'])

    const assignment: ComputedRef<BaseAssignment> = computed(() => store.getters['assignment/get'])
    const isItemsEditable: ComputedRef<boolean> = computed(() => store.getters['assignment/isItemsEditable'])
    const assignmentStatus: ComputedRef<string> = computed(() => store.getters['assignment/getStatus'])

    const isHidePrice: ComputedRef<boolean> = computed(() => store.getters['account/isHidePrice'])
    const errorCount: ComputedRef<number> = computed(
      () => assignment.value.internationalErrorsCount + assignment.value.domesticErrorsCount,
    )
    const isShowAssignmentInfo: ComputedRef<boolean> = computed(
      () =>
        !!(
          user.value?.shortName ||
          user.value?.clientNumber ||
          user.value?.company ||
          senderAddress.value?.clientNumber
        ),
    )

    const dynamicPricesLink: ComputedRef<RouterLink> = computed(() => ({
      name: STAFF_ASSIGNMENT_PRICES,
      params: { id: route.params.id },
    }))
    const sourceFiles: ComputedRef<SourceFile[]> = computed(() => assignment.value?.sourceFiles || [])
    const reportFile: ComputedRef<string> = computed(() => assignment.value?.optimisationReportUrl || '')
    const exportLink: ComputedRef<string> = computed(
      () => `${import.meta.env.VITE_APP_API_LOCATION}/assignments/${assignment.value.id}/export`,
    )

    const actionTooltip = (type = '', disabled = false) => (disabled ? '' : `Download ${type} file`)

    onBeforeUnmount(() => {
      window.Echo.leave('App.Assignments')
    })

    onBeforeMount(() => {
      window.Echo.channel('App.Assignments').listen(
        '\\App\\Events\\Assignment\\AssignmentStatusChanged',
        (data: any) => {
          if (data.assignment.id === assignment.value.id) {
            store.dispatch('assignment/fetch').then(() => {
              const assignment = computed(() => store.getters['assignment/get'])
              if (assignment.value.status === VALIDATION_COMPLETE) {
                store.dispatch('assignment/refreshItems')
              }
            })
          }
        },
      )
    })

    return {
      assignment,
      isItemsEditable,
      assignmentStatus,
      user,
      userId,
      isStaff,
      isHidePrice,
      errorCount,
      senderAddress,
      isShowAssignmentInfo,
      dynamicPricesLink,
      sourceFiles,
      reportFile,
      exportLink,
      pluralize,
      actionTooltip,
      toTimeFromNow,
      can: (rule: string, section: string) => ability.value.can(rule, section),
      assignmentsPage: { name: isStaff.value ? STAFF_ASSIGNMENTS_LIST : CUSTOMER_ASSIGNMENTS_LIST },
    }
  },
})
</script>

<style lang="scss" scoped>
.border-r {
  border-right: 1px solid #cccccc;
}
.border-l {
  border-left: 1px solid #cccccc;
}
.status {
  display: flex;
  align-items: center;
  min-height: 40px;
}
.file-btn {
  background: transparent;
  padding: 0;
  border: 0;
  color: #000000;
  width: 24px;
  height: 24px;

  svg {
    width: 24px;
    height: 24px;
    max-height: 24px;
  }

  &:first-child {
    margin-right: 18px;
  }

  &.disabled {
    opacity: 1;
    color: #c8c8c8;
  }
}
.btn-height-md {
  height: 40px !important;
  line-height: 20px !important;
}
.user-info {
  .color-grey {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #757575;
  }
  .color-black {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    color: #151515;
  }
}

.border-l-none {
  border-left: none;
}

.assignment-function {
  .btn {
    width: auto;
    max-height: 40px;
    min-height: auto;
    padding: 9px 24px;
    font-size: 14px;
    color: #757575;
    border-color: #cccccc;
  }

  .btn:hover {
    color: #fff;
  }
}
</style>
