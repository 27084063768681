<template>
  <div class="row pb-4">
    <div class="col-4">
      <div class="child-row__title pt-1">Tracking events</div>
    </div>
    <div class="col-8 text-right">
      <b-button v-if="hasTrackingData" variant="success" size="sm" @click="openTrackingPortal">
        <i class="fa fa-external-link" />
        Full tracking
      </b-button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, type PropType } from 'vue'
import { getLinkToTrackingPage } from '@/services/Tracking'
import type { Shipment } from '@/types/Models/Shipment'

export default defineComponent({
  name: 'TrackingEventsHeader',
  props: {
    shipment: {
      type: Object as PropType<Shipment>,
      required: true,
    },
  },
  setup(props) {
    const hasTrackingData = computed(() => Boolean(props.shipment.lastTrackingEventStatus))

    function openTrackingPortal() {
      window.open(getLinkToTrackingPage(props.shipment.shipmentNumber), '_blank')
    }

    return {
      hasTrackingData,
      openTrackingPortal,
    }
  },
})
</script>
