<template>
  <table>
    <thead>
      <tr>
        <td>Operator</td>
        <td style="width: 180px">Service</td>
        <td>Contract</td>
        <td style="width: 90px">Quantity</td>
        <td>Of possible</td>
        <td>Avg.weight</td>
        <td>Avg. price</td>
        <td style="width: 190px">Cost</td>
      </tr>
    </thead>

    <tbody>
      <slot />
    </tbody>
  </table>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'OptimizationTable',
})
</script>
