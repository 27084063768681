<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col col-md-6 col-xs-12">
        <div id="content" class="flex">
          <div class="d-flex flex align-items-center mh-v">
            <div class="text-center p-5 w-100">
              <h1 class="display-5 my-5">Not enough permissions</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PagePermissionsError',
})
</script>
