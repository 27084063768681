<template>
  <b-dropdown :text="text" class="custom-dropdown">
    <div v-if="withFilter" class="p-2">
      <b-input v-model="filterText" placeholder="Search..." />
    </div>
    <b-dropdown-item
      v-for="(option, index) in filteredOptions"
      :key="'option_' + index + option.value"
      dropdown
      @click="selectOption(option)"
    >
      {{ option.text }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import type { SelectOptions } from '@/types/Components/SelectOptions'

export default defineComponent({
  name: 'QueryStringDropdown',
  props: {
    options: {
      type: Array as () => SelectOptions[],
      required: true,
    },
    withFilter: {
      type: Boolean,
      default: false,
    },
    queryParam: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const route = useRoute()
    const router = useRouter()
    const value = ref<string | null>(null)
    const text = ref<string | undefined>()
    const filterText = ref<string | null>(null)

    const filteredOptions = computed(() => {
      if (filterText.value) {
        return props.options.filter((option) =>
          option.text.toLowerCase().includes((filterText.value || '').toLowerCase()),
        )
      }
      return props.options
    })

    const selectOption = (option: SelectOptions) => {
      value.value = option.value
      text.value = option.text

      const query = { ...route.query }
      if (option.value) {
        query[props.queryParam] = option.value
      } else {
        delete query[props.queryParam]
      }

      router.push({ name: route.name!, query })
    }

    watch(
      () => route.query[props.queryParam],
      (newValue) => {
        value.value = newValue as string | null
        const selectedOption = props.options.find((option) => option.value === value.value)
        text.value = selectedOption ? selectedOption.text : props.options[0]?.text || 'Select option'
      },
      { immediate: true },
    )

    // Set the default text when the component is mounted
    if (!route.query[props.queryParam]) {
      text.value = props.options[0]?.text || 'Select option'
    }

    return {
      value,
      text,
      filterText,
      filteredOptions,
      selectOption,
    }
  },
})
</script>

<style lang="scss" scoped>
.custom-dropdown {
  &.show {
    :deep(.dropdown-toggle) {
      background-color: #ffffff;
    }
  }

  &.border-0 {
    :deep(.dropdown-toggle) {
      border: none !important;
    }
  }

  :deep(.dropdown-menu) {
    transform: translate3d(0, 45px, 0) !important;
    z-index: 3;
    max-height: 400px;
    overflow-y: auto;
  }

  :deep(.dropdown-toggle) {
    width: 172px;
    height: 40px;
    font-size: 14px;
    color: #757575;
    border-color: #c8c8c8;
    padding-left: 15px;
    text-align: left;
    line-height: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    background: #ffffff url(@/assets/img/icon-select.svg) right 14px top 14px / 12px 12px no-repeat !important;
    &:hover,
    &:focus,
    &:active {
      background-color: unset;
      border-color: #c8c8c8;
      color: #757575;
    }
    &::after {
      display: none;
    }
  }
}
</style>
