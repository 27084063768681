<template>
  <div class="d-flex justify-content-end mt-5">
    <b-button class="mr-2 btn-packing-slips" @click="renderModal(PACKING_SLIPS)"> Packing Slips </b-button>
    <b-button class="btn-create-packing-slips" @click="renderModal(CREATE_PACKING_SLIP)">
      Create Packing Slip
    </b-button>
  </div>
</template>

<script lang="ts">
import { useStore } from 'vuex'
import { openModal } from '@/services/Modal'
import { defineComponent } from 'vue'
import { CREATE_PACKING_SLIP, PACKING_SLIPS } from '@/const/ModalTypes'

export default defineComponent({
  name: 'PackingSlipButtons',
  setup() {
    const store = useStore()
    const renderModal = (modalName: string) => {
      store.dispatch('vueNextModal/renderModal', modalName)
      openModal(modalName)
    }
    return {
      renderModal,
      PACKING_SLIPS,
      CREATE_PACKING_SLIP,
    }
  },
})
</script>

<style lang="scss" scoped>
.btn-packing-slips {
  background-color: transparent !important;
  border-color: gray !important;
  color: #757575 !important;
}

.btn-create-packing-slips {
  background-color: #08a762 !important;
  border-color: #08a762 !important;
  color: #ffffff !important;
}
</style>
