<template>
  <div class="pieces-block">
    <div class="label required fs-xl">{{ title }}</div>

    <DeclarationData v-if="displayDeclarationData" />

    <easy-data-table
      ref="piecesTable"
      table-class-name="assignment-pieces-table"
      :headers="headers"
      :items="pieces"
      :prevent-context-menu-row="false"
      hide-footer
      no-hover
    >
      <template #header="header">
        <div
          v-if="tooltipText(header.value)"
          v-b-tooltip="{
            title: tooltipText(header.value),
            customClass: 'detailed-text-tooltip',
            variant: 'info',
          }"
        >
          {{ header.text }}
          <i class="fa fa-question-circle" />
        </div>
        <div v-else>
          {{ header.text }}
        </div>
      </template>

      <template #item="{ column, item }">
        <div v-if="column === 'remove'">
          <button class="delete-piece-cta" @click="removePiece(item)"><mdicon name="TrashCanOutline" /></button>
        </div>

        <div v-else>
          <Field
            v-slot="{ field, errors }"
            :name="column"
            :rules="getFieldRules(column)"
            :model-value="item[column]"
            :validate-on-blur="true"
          >
            <div v-b-tooltip="errorTooltipOptions(errors)" class="h-55">
              <div
                class="wrap-table-input no-wrap"
                :class="{ validation: errors.length > 0, fatal: errors.length > 0 }"
              >
                <input
                  v-bind="field"
                  :value="displayValue(item[column], column)"
                  class="table-input"
                  :type="inputType(column)"
                  @input="updatePiece($event, item, column)"
                />
              </div>
            </div>
          </Field>
        </div>
      </template>
    </easy-data-table>

    <div v-if="canAddPieces" class="mt-3">
      <button class="add-piece-cta" @click="addNewPiece">
        <img src="@/assets/img/add-client-icon.svg" alt="" class="mr-2" />
        Add new piece
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import type { KeyAccessor } from '@/types/Helpers/Arrays'
import type { AssignmentPiece } from '@/views/Components/Assignment/Pieces/types'
import { defineComponent, type PropType, toRefs } from 'vue'
import { useIndicators } from '@/views/Components/Assignment/Pieces/composables/use-indicators'
import { useHeaders } from '@/views/Components/Assignment/Pieces/composables/use-headers'
import { useActions } from '@/views/Components/Assignment/Pieces/composables/use-actions'
import { useValidationRules } from '@/views/Components/Assignment/Pieces/composables/use-validation-rules'
import { Field } from 'vee-validate'
import EasyDataTable from 'vue3-easy-data-table'
import DeclarationData from '@/views/Components/Assignment/Pieces/DeclarationData.vue'

export default defineComponent({
  name: 'Pieces',
  components: {
    DeclarationData,
    EasyDataTable,
    Field,
  },
  props: {
    pieces: {
      type: Array as PropType<AssignmentPiece[]>,
      required: true,
    },
    distributionChannel: {
      type: String,
      required: true,
    },
    recipientCountry: {
      type: String,
      required: false,
      default: '',
    },
    outsideEu: {
      type: Boolean,
      required: true,
    },
    isBusinessRecipient: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { distributionChannel, recipientCountry, isBusinessRecipient, outsideEu, pieces } = toRefs(props)

    const { title, displayDeclarationData, canAddPieces, canChangeQuantity } = useIndicators({
      distributionChannel,
      outsideEu,
    })

    const tooltips: KeyAccessor = {
      weight: 'Weight in GRAMS per one unit',
      height: 'Height in CM per one unit',
      width: 'Width in CM per one unit',
      length: 'Length in CM per one unit',
      description: 'Reference or shipment description, that will be placed on the shipping label',
    }

    const headers = useHeaders({
      canChangeQuantity,
      outsideEu,
      pieces,
    })

    const numberTypeColumns = ['weight', 'height', 'length', 'width', 'estimated_cost', 'quantity']

    const isNumeric = (column: string) => numberTypeColumns.includes(column)

    const inputType = (column: string) => (isNumeric(column) ? 'number' : 'text')

    const displayNumeric = (value: unknown) => (value === 0 ? '' : value)

    const displayValue = (value: unknown, column: string) => {
      return isNumeric(column) ? displayNumeric(value) : value
    }

    const { addNewPiece, removePiece, updatePiece } = useActions({ emit, pieces })

    const tooltipText = (field: string) => tooltips[field]

    const errorTooltipOptions = (errors: unknown[]) => {
      return { title: errors.join(', '), variant: 'danger', customClass: 'piece-error-tooltip' }
    }

    const { getFieldRules } = useValidationRules({
      distributionChannel,
      recipientCountry,
      isBusinessRecipient,
    })

    return {
      headers,
      numberTypeColumns,
      displayNumeric,
      updatePiece,
      addNewPiece,
      removePiece,
      canAddPieces,
      tooltipText,
      displayDeclarationData,
      title,
      getFieldRules,
      inputType,
      displayValue,
      errorTooltipOptions,
    }
  },
})
</script>

<style lang="scss">
.assignment-pieces-table {
  --easy-table-header-background-color: #eeeeee;
  --easy-table-body-row-background-color: #ffffff;
  --easy-table-cell-border: 1px solid #cccccc;

  .vue3-easy-data-table__main {
    min-height: auto;
  }

  .vue3-easy-data-table__main {
    tbody {
      tr {
        td {
          height: 48px;
          min-width: 93px;
          white-space: nowrap;
          border-right: var(--easy-table-cell-border);
          border-bottom: var(--easy-table-cell-border) !important;

          &:first-child {
            border-left: var(--easy-table-cell-border);
          }

          .h-55 {
            height: 55px;
          }
        }
      }
    }
  }

  .vue3-easy-data-table__main tbody tr td {
    .input-number {
      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type='number'] {
        appearance: textfield;
      }
    }

    .table-cell-select {
      background: transparent;
      border: none;
      height: 100%;
    }
    .cell-sizer {
      width: auto;
      opacity: 0;
      height: 0;
      margin-left: 34px;
    }
    .status-center {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    a {
      color: var(--easy-table-body-row-font-color);
    }
    .easy-checkbox {
      margin: 0 !important;
    }
  }
}

.add-piece-cta {
  background: none;
  font-size: 15px;
}

.delete-piece-cta {
  background: none;
}

.detailed-text-tooltip,
.piece-error-tooltip {
  .tooltip-inner {
    width: 500px;
  }
}
</style>
