import { CONSOLIDATE, SINGLE } from '@/const/AssignmentTypes'
import { CUSTOMER, STAFF } from '@/const/AccountTypes'
import {
  CANCELLED,
  COMPLETE,
  IN_REVIEW,
  LABELS_CREATING,
  LABELS_RELEASED,
  OPEN,
  OPTIMISING,
  PENDING,
  REPORT_ACCEPTED,
  REPORT_DENIED,
  REPORT_RELEASED,
  VALIDATING,
  VALIDATION_COMPLETE,
} from '@/const/AssignmentStatuses'
import type { StatusCategory, StatusType } from '@/types/Models/Assignment'
import type { AccountType } from '@/types/Models/Account'

type StatusNameMap = {
  [key in StatusCategory]: {
    [key in AccountType]: {
      [key in StatusType]: string
    }
  }
}

export const StatusName: StatusNameMap = {
  [SINGLE]: {
    [STAFF]: {
      [OPEN]: 'Buffering',
      [PENDING]: 'Pending',
      [COMPLETE]: 'Completed',
      [CANCELLED]: 'Cancelled',
      [VALIDATING]: 'Pending',
      [VALIDATION_COMPLETE]: 'Pending',
      [IN_REVIEW]: 'Pending',
      [OPTIMISING]: 'Pending',
      [REPORT_RELEASED]: 'Pending',
      [REPORT_ACCEPTED]: 'Pending',
      [REPORT_DENIED]: 'Pending',
      [LABELS_CREATING]: 'Completed',
      [LABELS_RELEASED]: 'Completed',
    },
    [CUSTOMER]: {
      [OPEN]: 'Buffering',
      [PENDING]: 'Pending',
      [COMPLETE]: 'Completed',
      [CANCELLED]: 'Cancelled',
      [VALIDATING]: 'Pending',
      [VALIDATION_COMPLETE]: 'Pending',
      [IN_REVIEW]: 'Pending',
      [OPTIMISING]: 'Pending',
      [REPORT_RELEASED]: 'Pending',
      [REPORT_ACCEPTED]: 'Pending',
      [REPORT_DENIED]: 'Pending',
      [LABELS_CREATING]: 'Completed',
      [LABELS_RELEASED]: 'Completed',
    },
  },
  [CONSOLIDATE]: {
    [STAFF]: {
      [OPEN]: 'Buffering',
      [PENDING]: 'Validating',
      [COMPLETE]: 'Shipped',
      [CANCELLED]: 'Cancelled',
      [VALIDATING]: 'Validating',
      [VALIDATION_COMPLETE]: 'Imported',
      [IN_REVIEW]: 'Under Review',
      [OPTIMISING]: 'Optimising',
      [REPORT_RELEASED]: 'Report Sent',
      [REPORT_ACCEPTED]: 'Report accepted',
      [REPORT_DENIED]: 'Report Denied',
      [LABELS_CREATING]: 'Labels Creating',
      [LABELS_RELEASED]: 'Labels Released',
    },
    [CUSTOMER]: {
      [OPEN]: 'Buffering',
      [PENDING]: 'Validating',
      [COMPLETE]: 'Shipped',
      [CANCELLED]: 'Cancelled',
      [VALIDATING]: 'Validating',
      [VALIDATION_COMPLETE]: 'Imported',
      [IN_REVIEW]: 'Under Review',
      [OPTIMISING]: 'Optimising',
      [REPORT_RELEASED]: 'Report Created',
      [REPORT_ACCEPTED]: 'Report accepted',
      [REPORT_DENIED]: 'Report Denied',
      [LABELS_CREATING]: 'Labels Creating',
      [LABELS_RELEASED]: 'Labels Released',
    },
  },
}
