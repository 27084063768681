<template>
  <modal-wrap
    :id="id"
    ref="PackingSlipPreviewModal"
    size="lg"
    header-class="packing-slip-header"
    body-class="packing-slip-modal"
    :name="modalName"
    :hide-footer="true"
  >
    <template #modal-title> Packing Slip </template>
    <template #default>
      <div v-if="previewLoading" class="d-flex justify-content-center p-4 packing-slip-preview-body">
        <b-spinner class="mt-1 ml-2" variant="success" small />
      </div>
      <PackingSlipPreview v-else-if="packingSlip && packingSlip.id" />
    </template>
  </modal-wrap>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { PACKING_SLIP_PREVIEW } from '@/const/ModalTypes'
import ModalWrap from '@/views/Modals/ModalWrap.vue'
import PackingSlipPreview from '@/views/Components/PackingSlip/PackingSlipPreview.vue'

export default defineComponent({
  name: 'PackingSlipPreviewModal',
  components: {
    PackingSlipPreview,
    ModalWrap,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup() {
    const store = useStore()

    const packingSlip = computed(() => store.getters['packingSlip/getPackingSlip'])
    const previewLoading = computed(() => packingSlip.value === null)
    const modalName = PACKING_SLIP_PREVIEW

    return {
      packingSlip,
      previewLoading,
      modalName,
    }
  },
})
</script>
