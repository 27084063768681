<template>
  <div class="pb-3">
    <div class="child-row-item__title">
      <span v-if="multipleCarriers">Carriers</span>
      <span v-else>Carrier</span>
    </div>
    <div class="child-row-item__subtitle">
      <CarrierData :shipment="shipment" :only-icon="false" />
    </div>
  </div>
</template>

<script lang="ts">
import CarrierData from '@/views/Components/Shipments/CarrierData.vue'
import { computed, defineComponent, type PropType } from 'vue'
import type { Shipment } from '@/types/Models/Shipment'

export default defineComponent({
  name: 'ShipmentCarrier',
  components: { CarrierData },
  props: {
    shipment: {
      type: Object as PropType<Shipment>,
      required: true,
    },
  },
  setup(props) {
    const multipleCarriers = computed(() => Boolean(props.shipment.adjustedCarrierHandle))

    return {
      multipleCarriers,
    }
  },
})
</script>
