import {
  STAFF_PAGE,
  STAFF_CUSTOMERS_LIST,
  STAFF_CUSTOMERS_CREATE,
  STAFF_ASSIGNMENTS_LIST,
  STAFF_ASSIGNMENTS_CREATE,
  STAFF_ASSIGNMENTS_SINGLE,
  STAFF_ASSIGNMENTS_OPTIMIZATION_REPORT,
  STAFF_BASE_PRICE_PAGE,
  STAFF_CUSTOMERS_PRICES,
  STAFF_ASSIGNMENT_PRICES,
  STAFF_ASSIGNMENTS_SINGLE_EDIT,
  STAFF_ASSIGNMENT_SUMMARY,
  STAFF_STATISTICS,
  STAFF_SENDER_ADDRESS_PRICES,
  STAFF_PICKUPS_LIST,
  STAFF_SHIPMENTS,
} from '@/types/router'
import type { RouteRecordRaw } from 'vue-router'

const staff: Array<RouteRecordRaw> = [
  {
    path: '/staff',
    name: STAFF_PAGE,
    redirect: '/staff/customers',
    component: () => import('@/views/Staff/Index.vue'),
    meta: {
      auth: true,
      resource: 'staff.area',
      action: 'manage',
      title: 'Staff',
    },
    children: [
      // proxy search
      {
        path: 'shipments',
        name: STAFF_SHIPMENTS,
        component: () => import('@/views/Shipments/Index.vue'),
        meta: {
          title: 'Shipments feed',
          containerClass: 'container-large',
        },
      },
      {
        path: 'pickups',
        name: STAFF_PICKUPS_LIST,
        component: () => import('@/views/Staff/Pickup/Index.vue'),
        meta: {
          title: 'Pickups',
          containerClass: 'container-large',
        },
      },
      {
        path: 'base-price',
        component: () => import('@/views/Staff/BasePrice/Index.vue'),
        name: STAFF_BASE_PRICE_PAGE,
        meta: {
          title: 'Base Price',
        },
      },
      {
        path: 'customers',
        component: () => import('@/views/Staff/Customers/Index.vue'),
        children: [
          {
            path: '',
            component: () => import('@/views/Staff/Customers/List.vue'),
            name: STAFF_CUSTOMERS_LIST,
            meta: {
              title: 'Customers',
              containerClass: 'container-large',
            },
          },
          {
            path: 'create',
            component: () => import('@/views/Staff/Customers/Create.vue'),
            name: STAFF_CUSTOMERS_CREATE,
            meta: {
              title: 'Create new customer',
            },
          },
          {
            path: ':id/prices',
            component: () => import('@/views/Staff/Customers/Prices.vue'),
            name: STAFF_CUSTOMERS_PRICES,
            meta: {
              title: 'Customer Prices',
              hideFooter: true,
            },
          },
        ],
      },
      {
        path: 'single-package-summary/:id',
        component: () => import('@/views/Assignments/SingleAssignmentSummary.vue'),
        name: STAFF_ASSIGNMENT_SUMMARY,
        meta: {
          title: 'Assignment summary',
          containerClass: 'container',
        },
      },
      {
        path: 'assignments',
        component: () => import('@/views/Assignments/Index.vue'),
        children: [
          {
            path: '',
            component: () => import('@/views/Assignments/AssignmentsListPage.vue'),
            name: STAFF_ASSIGNMENTS_LIST,
            meta: {
              title: 'Assignments',
              containerClass: 'container-large',
            },
          },
          {
            path: 'create',
            component: () => import('@/views/Assignments/AssignmentsCreate.vue'),
            name: STAFF_ASSIGNMENTS_CREATE,
            meta: {
              title: 'Create new assigment',
            },
          },
          {
            path: 'edit/:id',
            component: () => import('@/views/Assignments/AssignmentsEdit.vue'),
            name: STAFF_ASSIGNMENTS_SINGLE_EDIT,
            meta: {
              title: 'Edit Single Assignment',
            },
          },
          {
            path: ':id',
            component: () => import('@/views/Assignments/BatchAssignmentSummary.vue'),
            name: STAFF_ASSIGNMENTS_SINGLE,
            meta: {
              title: 'Assigment',
              containerClass: 'container-fluid',
              hideFooter: true,
            },
          },
          {
            path: ':id/optimization-report',
            component: () => import('@/views/OptimizationReport/Index.vue'),
            name: STAFF_ASSIGNMENTS_OPTIMIZATION_REPORT,
            meta: {
              title: 'Report',
              containerClass: 'container',
              hideFooter: true,
            },
          },
          {
            path: ':id/prices',
            component: () => import('@/views/Assignments/AssignmentPrices.vue'),
            name: STAFF_ASSIGNMENT_PRICES,
            meta: {
              title: 'Prices',
              containerClass: 'container-fluid',
              hideFooter: true,
            },
          },
        ],
      },
      {
        path: 'statistics',
        component: () => import('@/views/Statistics/Index.vue'),
        name: STAFF_STATISTICS,
        meta: {
          title: 'Statistics',
          containerClass: 'container-fluid',
          hideFooter: true,
        },
      },
      {
        path: 'customer/:id/sender-addresses/prices',
        component: () => import('@/views/Staff/Customers/SenderAddressPrices.vue'),
        name: STAFF_SENDER_ADDRESS_PRICES,
        meta: {
          title: 'Sender Address Prices',
          containerClass: 'container-fluid',
          hideFooter: true,
          showSenderAddressActionMenu: true,
        },
      },
    ],
  },
]

export default staff
