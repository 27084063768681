import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'
import authRoutes from '@/router/sections/auth'
import infoRoutes from '@/router/sections/info'
import faqRoutes from '@/router/sections/faq'
import staffRoutes from '@/router/sections/staff'
import customerRoutes from '@/router/sections/customer'
import uncategorizedRoutes from '@/router/sections/uncategorized'

const routes: Array<RouteRecordRaw> = [
  ...authRoutes,
  ...infoRoutes,
  ...faqRoutes,
  ...staffRoutes,
  ...customerRoutes,
  ...uncategorizedRoutes,
]

const router = createRouter({ history: createWebHistory(), routes })

router.afterEach((to) => {
  document.title = String(to.meta.title || 'PacSort – 21grams')
})
export default router
