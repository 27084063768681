<template>
  <div id="pickups">
    <PickupsTable :v-table-name="TABLE_PICKUPS_LIST" />
  </div>
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent } from 'vue'
import { TABLE_PICKUPS_LIST } from '@/const/TableNames'

export default defineComponent({
  name: 'PickupsListPage',
  components: {
    PickupsTable: defineAsyncComponent(() => import('@/views/Components/Pickup/PickupsTable.vue')),
  },
  setup() {
    return {
      TABLE_PICKUPS_LIST,
    }
  },
})
</script>
