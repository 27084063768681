import { BaseProxy, type ProxyMethods } from '@/proxies/Proxy'

export default class AssignmentStatusProxy extends BaseProxy implements ProxyMethods {
  public endpoint = 'assignments/id/status'

  constructor(id: string, parameters: object = {}) {
    super(parameters)
    this.endpoint = `assignments/${id}/status`
  }

  submitProcess() {
    return this.submit('post', `/${this.endpoint}/process`)
  }

  submitReportAccept() {
    return this.submit('post', `/${this.endpoint}/report/accept`)
  }

  submitReportDeny() {
    return this.submit('post', `/${this.endpoint}/report/deny`)
  }

  submitCancel() {
    return this.submit('post', `/${this.endpoint}/cancel`)
  }

  submitUnArchive() {
    return this.submit('post', `/${this.endpoint}/unarchive`)
  }

  submitComplete() {
    return this.submit('post', `/${this.endpoint}/complete`)
  }
}
