<template>
  <div class="d-flex align-items-center saved-cost">
    <span class="saved-cost__value">{{ cost }} kr</span>
    <div v-if="report.percentSaved > 0" ref="tooltipTrigger" class="saved-cost__percent">
      {{ formattedNumber(report.percentSaved) }}% Saved
    </div>
    <b-tooltip :target="tooltipTrigger" :noninteractive="true" title="In comparison to Postnord default pricing." />
  </div>
</template>

<script lang="ts">
import { formattedNumber } from '@/services/Helpers'
import { defineComponent, ref, computed, type PropType } from 'vue'
import type { Report } from '@/types/Models/Report'

export default defineComponent({
  name: 'ShippingCostCell',
  props: {
    report: {
      type: Object as PropType<Report>,
      default: () => ({}),
    },
  },
  setup(props) {
    const tooltipTrigger = ref<HTMLElement | null>(null)
    const cost = computed(() => props.report.optimizationCost)

    return {
      cost,
      tooltipTrigger,
      formattedNumber,
    }
  },
})
</script>

<style lang="scss" scoped>
.saved-cost {
  display: flex;
  align-items: center;
  span {
    color: #08a762;
  }
  &__percent {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    background: rgba(8, 167, 98, 0.15);
    border-radius: 32px;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 144%;
    color: #08a762;
    padding: 0 5px;
    margin-left: 8px;
  }
}
</style>
