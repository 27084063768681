<template>
  <div class="d-flex ml-3 search-item position-relative">
    <Dropdown
      :options="trackingEventStatuses"
      :dropdown-text="dropdownText"
      class="border-0"
      @selected="selectTrackingStatusFilter"
    />
    <button class="bg-white pl-3 border-left" @click="handle">
      <Tooltip text="Click search icon or press enter to search">
        <SearchIcon class="text-secondary" />
      </Tooltip>
    </button>
    <input v-model="searchText" placeholder="Search for" class="assignment-elements border-left-0" />
    <div class="bg-white p-0">
      <div class="d-flex align-items-center h-100">
        <button
          v-if="searchText.length > 0"
          class="mr-2 clear d-flex justify-content-center align-items-center clear-button"
          @click="clear"
        >
          x
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from 'vue'
import { ucFirst } from '@/services/Helpers'
import {
  RETURNED,
  DELIVERED,
  EXCEPTION,
  IN_TRANSIT,
  INFO_RECEIVED,
  FAILED_ATTEMPT,
  DELIVERY_ISSUE,
  OUT_FOR_DELIVERY,
} from '@/const/TrackingEventStatuses'
import Dropdown from '@/views/Components/Elements/Dropdown.vue'
import Tooltip from '@/views/Components/Elements/Tooltip.vue'
import SearchIcon from '@/views/Components/Elements/Icons/SearchIcon.vue'

export default defineComponent({
  name: 'SearchItemsBy',
  components: {
    Dropdown,
    Tooltip,
    SearchIcon,
  },
  emits: ['search'],
  setup(_, { emit }) {
    const dropdownText = ref('Search All')
    const searchText = ref('')
    const trackingEventStatus = ref('')

    const trackingEventStatuses = computed(() =>
      [INFO_RECEIVED, IN_TRANSIT, OUT_FOR_DELIVERY, DELIVERED, DELIVERY_ISSUE, FAILED_ATTEMPT, EXCEPTION, RETURNED].map(
        (status: string) => ({
          value: status,
          text: ucFirst(status.replace(/_/g, ' ')),
        }),
      ),
    )

    const handle = () => {
      emit('search', {
        query: searchText.value,
        byTrackingStatus: trackingEventStatus.value,
      })
    }

    const selectTrackingStatusFilter = (option: { value: string; text: string }) => {
      trackingEventStatus.value = option.value
      dropdownText.value = option.text
      emit('search', {
        query: searchText.value,
        byTrackingStatus: trackingEventStatus.value,
      })
    }

    const clear = () => {
      trackingEventStatus.value = ''
      searchText.value = ''
    }

    watch(searchText, handle)

    return {
      searchText,
      dropdownText,
      trackingEventStatuses,
      selectTrackingStatusFilter,
      handle,
      clear,
    }
  },
})
</script>

<style lang="scss" scoped>
.search-item {
  :deep(fieldset) {
    input {
      border-radius: 0 !important;
      width: 100% !important;
      border: 0;
      border-bottom: 1px solid;
    }
  }
}
.clear-button {
  font-size: 12px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
}
</style>
