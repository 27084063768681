<template>
  <modal-wrap :id="id" :name="id" :hide-footer="false" :hide-header-close="true" size="md">
    <template #default>
      {{ messageText }}
    </template>
    <template #modal-title>
      {{ titleText }}
    </template>
    <template #modal-footer>
      <div>
        <b-button size="md" class="btn-grey mr-3" @click="cancel"> Cancel </b-button>
        <b-button variant="success" size="md" class="btn-green" @click="confirm()">
          {{ okButtonText }}
        </b-button>
      </div>
    </template>
  </modal-wrap>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted } from 'vue'
import { registerModals } from '@/services/Modal'
import { useStore } from 'vuex'
import ModalWrap from '@/views/Modals/ModalWrap.vue'

export default defineComponent({
  name: 'Confirm',
  components: { ModalWrap },
  props: {
    id: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    onConfirm: {
      type: Function,
      required: false,
      default: null,
    },
    onCancel: {
      type: Function,
      required: false,
      default: null,
    },
  },
  setup(props) {
    onMounted(() => {
      registerModals(props.id)
    })
    const store = useStore()
    const modalData = computed(() => store.getters['vueNextModal/modalProps'](props.id))
    const messageText = computed(() => props.message || modalData.value?.message || '')
    const titleText = computed(() => props.title || modalData.value?.title || '')
    const okButtonText = computed(() => modalData.value?.okButtonText || 'Confirm')
    const confirm = () => {
      const onSubmit = props.onConfirm || modalData.value?.onConfirm || (() => {})
      onSubmit(store.getters['vueNextModal/modalProps'](props.id))
      store.dispatch('vueNextModal/closeModal', props.id)
    }
    const cancel = () => {
      const onCancel = props.onCancel || modalData.value?.onCancel || (() => {})
      onCancel(store.getters['vueNextModal/modalProps'](props.id))
      store.dispatch('vueNextModal/closeModal', props.id)
    }

    return {
      okButtonText,
      messageText,
      titleText,
      confirm,
      cancel,
    }
  },
})
</script>
