<template>
  <modal-wrap
    :id="id"
    ref="packingSlips"
    size="lg"
    body-class="packing-slips-modal"
    :header-class="'packing-slips-header'"
    :hide-footer="false"
    :name="modalName"
  >
    <template #modal-title> Packing Slips </template>
    <template #default>
      <div>
        <div class="row mt-3 mb-3">
          <ClientSelect v-if="isStaff" table-name="packing-slips-list" @change-client="setClient" />
        </div>
      </div>
      <div id="packingSlips">
        <div v-for="(packingSlip, i) in packingSlipsItems" :key="packingSlip.id + '_' + i" role="tablist">
          <SinglePackingSlip :packing-slip="packingSlip" :index="packingSlip.id" />
        </div>
        <PackingSlipPagination
          class="mt-3 p-1 VueTables__pagination"
          :records="totalPackages"
          :per-page="perPage"
          :value="page"
          @input="setPage"
        />
        <div v-if="loading" class="d-flex justify-content-center pt-5 pb-5">Loading...</div>
        <div v-if="!loading && packingSlipsItems.length === 0" class="d-flex justify-content-center pt-5 pb-5">
          {{ !isStaff ? "You Don't Have Any Packing Slips" : 'There are not any packing slips.' }}
        </div>
      </div>
    </template>
    <template #modal-footer>
      <div class="row w-100 m-0">
        <div class="col-7 p-0 my-auto">
          <div class="row">
            <div class="col-6 package-info my-auto no-wrap">
              Total weight: <span>{{ totalWeight }}</span>
            </div>
            <div class="col-6 package-info my-auto no-wrap">
              Total Packing Slips: <span>{{ totalPackages }}</span>
            </div>
          </div>
        </div>
        <Continue :enable-continue-button="enableContinueButton" :from-previous-packing-slip="true" />
      </div>
    </template>
  </modal-wrap>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { PACKING_SLIPS } from '@/const/ModalTypes'
import { PACKING_SLIPS_CLEAR_CHECKED_ITEMS, SET_CUSTOMER_ID } from '@/store/modules/packingSlip'
import ModalWrap from '@/views/Modals/ModalWrap.vue'
import SinglePackingSlip from '@/views/Components/PackingSlip/SinglePackingSlip.vue'
import ClientSelect from '@/views/Components/PackingSlip/ClientSelect.vue'
import PackingSlipPagination from '@/views/Components/Elements/Table/PackingSlipPagination.vue'
import Continue from '@/views/Components/PackingSlip/Continue.vue'
import { openModal } from '@/services/Modal'

export default defineComponent({
  name: 'PackingSlipsModal',
  components: {
    Continue,
    ModalWrap,
    ClientSelect,
    PackingSlipPagination,
    SinglePackingSlip,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup() {
    const store = useStore()
    const loading = ref(true)
    const page = ref(1)
    const perPage = ref(10)
    const modalName = PACKING_SLIPS

    const checkedItems = computed(() => store.getters['packingSlip/getCheckedItems'])
    const packingSlipsItems = computed(() => store.getters['packingSlip/getPackingSlips'])
    const totalPackages = computed(() => store.getters['packingSlip/getTotalPackages'])
    const totalWeight = computed(() => store.getters['packingSlip/getTotalWeights'])
    const isStaff = computed(() => store.getters['account/isStaff'])

    const enableContinueButton = computed(() => checkedItems.value.length > 0 && packingSlipsItems.value.length !== 0)

    const setPage = (newPage: number) => {
      page.value = newPage
      store.dispatch('packingSlip/getPackingSlips', { page: newPage })
    }
    const setClient = (customerId: number) => {
      store.commit(`packingSlip/${SET_CUSTOMER_ID}`, customerId)
      store.dispatch('packingSlip/getPackingSlips', {
        page: page.value,
        limit: perPage.value,
        customerId,
      })
    }

    onMounted(async () => {
      openModal(modalName)
      loading.value = false
      await store.dispatch('packingSlip/getPackingSlips')
      const payload = {
        page: page.value,
        limit: perPage.value,
        customerId: null,
      }
      if (!isStaff.value) {
        payload.customerId = store.getters['account/getCustomerId']
      }
      await store.dispatch('packingSlip/getPackingSlips', payload)
    })

    return {
      page,
      perPage,
      loading,
      modalName,
      enableContinueButton,
      packingSlipsItems,
      totalPackages,
      totalWeight,
      isStaff,
      setClient,
      setPage,
    }
  },
  beforeMount() {
    const store = useStore()
    store.commit(`packingSlip/${PACKING_SLIPS_CLEAR_CHECKED_ITEMS}`)
    store.commit(`packingSlip/${SET_CUSTOMER_ID}`, null)
  },
})
</script>

<style lang="scss" scoped>
.package-info {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #757575;

  span {
    color: #151515;
  }
}
</style>
