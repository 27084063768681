export const QueryParams = {
  LAST_TRACKING_STATUS: 'lastTrackingStatus',
  SHIPMENT_NUMBER: 'shipmentNumber',
  SEARCH: 'search',
  CUSTOMER: 'customer',
  ASSIGNMENT: 'assignment',
  CARRIER_HANDLE: 'carrierHandle',
  DATE_FROM: 'dateFrom',
  DATE_TO: 'dateTo',
  PACKAGES_DIVERTED_TO_SERVICE_POINT: 'packagesDivertedToServicePoint',
  WITH_SERVICE_POINT_COLLECTING_PROBLEM: 'withServicePointCollectingProblem',
  WITH_RECIPIENT_NOTIFICATION_PROBLEM: 'withRecipientNotificationProblem',
}
