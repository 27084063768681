<template>
  <router-link :to="link" class="sub-header-button d-flex flex-row align-items-center">
    <mdicon v-if="icon" :name="icon" class="mr-2" />
    <span class="mr-3">{{ name }}</span>

    <span v-if="isAddNew" class="header-icon-plus" @click.prevent="prevent">
      <mdicon name="plus" size="24" />
    </span>
  </router-link>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'SubHeaderButton',
  props: {
    to: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    icon: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    isAddNew: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['add-new'],
  setup(props, { emit }) {
    const router = useRouter()

    const link = computed(() => {
      const resolved = router.resolve(props.to)
      return resolved.href
    })

    const prevent = () => {
      emit('add-new')
    }

    return {
      link,
      prevent,
    }
  },
})
</script>
