<template>
  <div>
    <h3 class="mt-4">Before optimization</h3>

    <OptimizationTable header-color="grey" class="table-report bg-grey-title">
      <tr v-for="(report, index) in reportData.manual" :key="'report_' + index">
        <td>{{ ucFirst(report.carrier) }}</td>
        <td>{{ ucFirst(report.productName) }}</td>
        <td>{{ contract }}</td>
        <td>{{ report.itemsCount }}</td>
        <td>{{ itemsCount }} pc</td>
        <td>{{ report.averageWeight }} g</td>
        <td>{{ report.averagePrice }} kr</td>
        <td>{{ report.totalPrice }} kr</td>
      </tr>

      <tr class="footer-cells">
        <td colspan="6" class="border-0" />
        <td class="fw-400">Total</td>
        <td class="fw-400">{{ totalBefore }} kr</td>
      </tr>
    </OptimizationTable>
  </div>
</template>

<script lang="ts">
import type { Report } from '@/types/Models/Report'
import { defineComponent, computed, type ComputedRef } from 'vue'
import { useStore } from 'vuex'
import { ucFirst } from '@/services/Helpers'
import OptimizationTable from '@/views/Components/Elements/Table/OptimizationTable.vue'

export default defineComponent({
  name: 'BeforeOptimizationTable',
  components: { OptimizationTable },
  setup() {
    const store = useStore()

    const reportData: ComputedRef<Report> = computed(() => store.getters['assignment/optimizationReport/all'])
    const assignment: ComputedRef = computed(() => store.getters['assignment/get'])
    const contract: ComputedRef<string> = computed(() => assignment.value.contract)
    const itemsCount: ComputedRef<number> = computed(() => assignment.value.itemsCount)
    const totalBefore: ComputedRef<string> = computed(() => reportData.value.manualTotal)

    return {
      reportData,
      contract,
      itemsCount,
      totalBefore,
      ucFirst,
    }
  },
})
</script>

<style lang="scss" scoped>
.manual-title {
  margin-top: 10px;
  .manual-logo {
    margin-left: 5px;
  }
}
.btn-outline-light-green {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #058880;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  border: 1px solid #058880;
}
.manual-optimization {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  color: #058880;
}
.hidden {
  opacity: 0;
  border: none;
}
.table-report {
  :deep(thead td) {
    font-weight: 400;
  }
}
.fw-400 {
  font-weight: 400;
}
</style>
