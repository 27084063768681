import { type Ref, watch } from 'vue'
import { LETTER, PACKAGE, PARCEL } from '@/const/DistributionChannels'
import { defaultPiece } from '@/views/Components/Assignment/Pieces/utils/default-piece'
import type { AssignmentData } from '@/views/Components/Assignment/AssignmentForm/types'

type Props = {
  assignment: Ref<AssignmentData>
}

export const watchDistributionChannelChange = ({ assignment }: Props) => {
  watch(
    () => assignment.value.distribution_channel,
    (distributionChannel) => {
      switch (distributionChannel) {
        case PARCEL:
          // always send service level priority for parcel assignments
          assignment.value.service_level = 'priority'
          break
        case LETTER:
          // always send service level economy for letter assignments
          assignment.value.service_level = 'economy'
          break
        case PACKAGE:
          if (assignment.value.item.pieces.length > 1) {
            assignment.value.item.pieces = [{ ...defaultPiece }]
          }
          break
      }
    },
  )
}
