<template>
  <div>
    <div class="row">
      <div class="col-6">
        <div class="heading required">Book Pickup</div>
      </div>
      <div class="col-6 pt-4">
        <div class="custom-alert pr-2 pl-2">
          <small v-if="isBeforeCutoffTime">Same-day pickup <b>available</b>.</small>
          <small v-else>Same-day pickup <b>not available</b>.</small>
          <small>
            You are able to book same-day pickup
            <b>before {{ cutoffTime?.format('HH:mm') }}</b>
          </small>
        </div>
      </div>
    </div>

    <div class="months-row d-flex" :class="{ centered: false, 'bold-font': true }">
      <div v-if="showPrevArrow" class="arrows prev-arrow" @click="prevMonth" />
      <div>{{ startMonth }} - {{ endMonth }} {{ year }}</div>
      <div v-if="showNextArrow" class="arrows next-arrow" @click="nextMonth" />
    </div>

    <div class="date-items d-flex">
      <div v-if="showPrevArrow" class="arrows prev-arrow" @click="prevWeek" />

      <div
        v-for="(date, index) in dateList"
        :key="'date_' + index"
        :class="{
          'date-item_disabled': date.disabled,
          'date-item_active': selectedDate === date.date,
        }"
        class="date-item"
        @click="() => selectDate(date.date)"
      >
        <div class="date-item__body">
          <span class="date-item__day">
            {{ date.day }}
          </span>
          <span class="date-item__month">
            {{ date.month }}
          </span>
        </div>
        <span class="date-item__weekday">
          {{ date.weekday }}
        </span>
      </div>

      <div v-if="showNextArrow" class="arrows next-arrow" @click="nextWeek" />
    </div>

    <div v-if="pickupError" class="mt-3 pickup-required-msg">Please select Pickup Date</div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, watch } from 'vue'
import moment from 'moment'

export default defineComponent({
  name: 'PickupCalendar',
  props: {
    maxPeriod: {
      type: Number,
      default: 0,
    },
    pickupError: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['select-date'],
  setup(props, { emit }) {
    const selectedDate = ref<string | null>(null)
    const startDate = ref<Date | null>(null)
    const show = ref<boolean>(false)

    const dateList = computed(() => {
      const currentDate = startDate.value || new Date()
      const dates = []

      for (let i = 0; i <= 7; i += 1) {
        const date = new Date(currentDate.setDate(currentDate.getDate() + (i === 0 ? 0 : 1)))
        dates.push({
          day: date.getDate(),
          month: date.toLocaleString('default', { month: 'short' }),
          weekday: date.toLocaleDateString('default', { weekday: 'short' }),
          date: moment(date).format('YYYY-MM-DD'),
          disabled: isDisabled(date),
        })
      }

      return dates
    })

    const startMonth = computed(() => moment(dateList.value[0].date).format('MMMM'))
    const endMonth = computed(() => moment(dateList.value[dateList.value.length - 1].date).format('MMMM'))
    const year = computed(() => moment(dateList.value[0].date).format('YYYY'))
    const showPrevArrow = computed(() => !isBeforeOrToday(dateList.value[0].date))
    const showNextArrow = computed(() => !isExceedMaxDate(dateList.value[dateList.value.length - 1].date))
    const cutoffTime = computed(() => moment().set({ hour: 12, minute: 0, second: 0 }))
    const isBeforeCutoffTime = computed(() => moment().isBefore(cutoffTime.value))

    const selectDate = (date: string) => {
      selectedDate.value = date
      emit('select-date', date)
    }

    const isWeekend = (weekday: string) => ['Sat', 'Sun'].includes(weekday)
    const isBeforeOrToday = (date: string) => moment().format('YYYY-MM-DD') >= date
    const isYesterdayOrBefore = (date: string) => moment().format('YYYY-MM-DD') > date
    const isExceedMaxDate = (date: string) => {
      if (props.maxPeriod > 0) {
        const diffDays = Math.ceil((new Date(date).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24))
        return diffDays > props.maxPeriod
      }
      return false
    }

    const isDisabled = (date: Date) => {
      const dateData = {
        day: date.getDate(),
        month: date.toLocaleString('default', { month: 'short' }),
        weekday: date.toLocaleDateString('default', { weekday: 'short' }),
        date: moment(date).format('YYYY-MM-DD'),
      }
      return (
        isWeekend(dateData.weekday) ||
        isExceedMaxDate(dateData.date) ||
        (isBeforeCutoffTime.value ? isYesterdayOrBefore(dateData.date) : isBeforeOrToday(dateData.date))
      )
    }

    const nextWeek = () => {
      startDate.value = new Date(dateList.value.slice(-1)[0].date)
    }

    const prevWeek = () => {
      const date = new Date(dateList.value[0].date)
      startDate.value = new Date(date.setDate(date.getDate() - 8))
    }

    const nextMonth = () => {
      const today = new Date()
      const date = new Date(dateList.value.slice(-1)[0].date)
      const nextMonth =
        date.getMonth() === 11
          ? new Date(date.getFullYear() + 1, 0, 1)
          : new Date(date.getFullYear(), date.getMonth() + 1, 1)

      startDate.value =
        nextMonth.getFullYear() === today.getFullYear() && nextMonth.getMonth() === today.getMonth()
          ? new Date()
          : nextMonth
    }

    const prevMonth = () => {
      const today = new Date()
      const date = new Date(dateList.value[0].date)
      const prevMonth =
        date.getMonth() === 0
          ? new Date(date.getFullYear() - 1, 11, 1)
          : new Date(date.getFullYear(), date.getMonth() - 1, 1)

      startDate.value =
        prevMonth.getFullYear() === today.getFullYear() && prevMonth.getMonth() === today.getMonth()
          ? new Date()
          : prevMonth
    }

    onMounted(() => {
      selectDate(dateList.value[1].date)
    })

    return {
      selectedDate,
      startDate,
      show,
      dateList,
      startMonth,
      endMonth,
      year,
      showPrevArrow,
      showNextArrow,
      cutoffTime,
      isBeforeCutoffTime,
      selectDate,
      nextWeek,
      prevWeek,
      nextMonth,
      prevMonth,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';
.heading {
  text-transform: capitalize;
  margin-bottom: 24px;
  margin-top: 40px;
  color: $black;
  font-weight: 700;
  line-height: 24px;
  font-size: 24px;

  &.required::after {
    content: '*';
    color: #ff5252;
  }
}

.arrows {
  height: 15px;
  width: 9px;
  cursor: pointer;
  background-repeat: no-repeat;

  &.prev-arrow {
    background-image: url('@/assets/img/arrow_left_light.png');
    margin-right: 10px;

    &:hover {
      background-image: url('@/assets/img/arrow_left_dark.png');
    }
  }

  &.next-arrow {
    background-image: url('@/assets/img/arrow_right_light.png');
    margin-left: 10px;

    &:hover {
      background-image: url('@/assets/img/arrow_right_dark.png');
    }
  }
}
.months-row {
  align-items: baseline;
  margin-bottom: 10px;

  &.centered {
    justify-content: center;
  }
  &.bold-font {
    font-weight: 700;
    font-size: 18px;
  }
}
.date-items {
  width: 100%;

  .arrows {
    margin-top: 20px;
  }
}

.date-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% / 8);
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    .date-item__body {
      border-color: #058880 !important;
    }
  }

  &_disabled {
    pointer-events: none;
    cursor: unset;
    .date-item__body {
      border: 1px solid #f5f5f3 !important;
    }
    .date-item__day,
    .date-item__month,
    .date-item__weekday {
      color: #c8c8c8;
    }
  }

  &_active {
    .date-item__body {
      background: #058880;
      border-color: #058880;
    }
    .date-item__day,
    .date-item__month {
      color: #ffffff;
    }
  }

  &:not(:last-child) {
    .date-item__body {
      border-right-color: rgba(0, 0, 0, 0);
      //border-width: 1px 0 1px 1px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 11.5px 17px;
    border: 1px solid #cccccc;
    transition: all 0.3s;
  }

  &__day {
    display: inline-block;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0;
    text-align: center;
    transition: all 0.3s;
  }
  &__month {
    display: inline-block;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0;
    text-align: center;
    transition: all 0.3s;
    margin-top: 2px;
  }
  &__weekday {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0;
    text-align: center;
    margin-top: 12px;
    transition: all 0.3s;
  }
}

.pickup-required-msg {
  font-size: 14px;
  color: #ff5252;
}

.pickup-required-msg {
  font-size: 14px;
  color: #ff5252;
}
</style>
