<template>
  <div class="info-alert text-nowrap text">
    <mdicon name="InformationOutline" />
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'InfoAlert',
})
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';
.info-alert {
  padding: 8px 12px 8px 12px;
  line-height: 21px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 2px;
  svg {
    width: 21px;
  }
  span {
    margin-right: 8px;
  }
  &.danger {
    background: rgba($danger, 0.1);
    color: $orange;
  }

  &.success {
    background: rgba($green, 0.1);
    color: $green;
  }

  &.secondary {
    background: $light-gray-bg;
    color: $black;
  }
}
</style>
