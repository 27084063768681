<template>
  <QueryStringDropdown :options="options" :with-filter="false" :query-param="queryParam" />
</template>

<script lang="ts">
import type { SelectOptions } from '@/types/Components/SelectOptions'
import { defineComponent } from 'vue'
import { CONSOLIDATE, SINGLE } from '@/const/AssignmentTypes'
import { ASSIGNMENT_TYPE } from '@/const/AssignmentListQueryFilters'
import QueryStringDropdown from '@/views/Components/PageParts/QueryStringDropdown.vue'

export default defineComponent({
  name: 'TypeFilter',
  components: { QueryStringDropdown },
  setup() {
    const queryParam = ASSIGNMENT_TYPE

    const options: SelectOptions[] = [
      { value: '', text: 'Type' },
      { value: SINGLE, text: 'Single-item' },
      { value: CONSOLIDATE, text: 'Batch' },
    ]

    return {
      queryParam,
      options,
    }
  },
})
</script>
