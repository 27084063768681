import { BaseProxy, type ProxyMethods } from '@/proxies/Proxy'
import type { ForgotPasswordData, AuthData, CreatePasswordData } from '@/types/Models/Account'

export default class AuthProxy extends BaseProxy implements ProxyMethods {
  public endpoint = 'auth'

  login(data: AuthData) {
    return this.submit('post', `${this.endpoint}/login`, data)
  }

  logout() {
    return this.submit('post', `${this.endpoint}/logout`)
  }

  forgotPassword(data: ForgotPasswordData) {
    return this.submit('post', `${this.endpoint}/forgot-password`, data)
  }

  createPassword(data: CreatePasswordData) {
    return this.submit('post', `${this.endpoint}/reset-password`, data)
  }
}
