<template>
  <b-button v-bind="$attrs" class="btn" :variant="variant" @click="onClickEvent">
    <slot />
  </b-button>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import type { ButtonVariant } from 'bootstrap-vue-next'

export default defineComponent({
  name: 'StripeBtn',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String as PropType<ButtonVariant>,
      default: 'outline-secondary',
    },
  },
  emits: ['click'],
  setup(_, { emit }) {
    const onClickEvent = () => {
      emit('click')
    }

    return {
      onClickEvent,
    }
  },
})
</script>

<style scoped lang="scss">
.btn {
  width: auto;
  max-height: 40px;
  min-height: auto;
  padding: 9px 24px;
  font-size: 14px;
  color: #757575;
  border-color: #cccccc;
}

.btn:hover {
  color: #fff;
}

.btn.only-icon {
  padding: 10px 18px;
}

.btn.only-icon svg {
  width: 18px;
  height: 18px;
}

.btn.only-icon:disabled {
  border-color: #c8c8c8;
  color: #c8c8c8;
  cursor: default;
}
</style>
