<template>
  <modal-wrap :id="id" :name="modalName" size="sm" :hide-footer="false">
    <template #modal-title> Edit DMT Fee </template>
    <template #default>
      <div class="edit-customer-data">
        <VeeForm @submit="onSubmit">
          <b-form-group>
            <div class="form-inline-row">
              <label class="label-input">DHL Freight (%)</label>
              <VInput
                v-if="!loading"
                v-model="dhlFreightFee.percent"
                :rules="rules.number"
                :hide-number-arrow="true"
                type="number"
                name="dhlFreightFee"
                placeholder="DHL Freight"
              />
            </div>
          </b-form-group>
          <b-form-group>
            <div class="form-inline-row">
              <label class="label-input">DHL Express (%)</label>
              <VInput
                v-if="!loading"
                v-model="dhlExpressFee.percent"
                :rules="rules.number"
                :hide-number-arrow="true"
                type="number"
                name="dhlExpressFee"
                placeholder="DHL Express"
              />
            </div>
          </b-form-group>
          <b-form-group>
            <div class="form-inline-row">
              <label class="label-input">UPS (%)</label>
              <VInput
                v-if="!loading"
                v-model="upsFee.percent"
                :rules="rules.number"
                :hide-number-arrow="true"
                type="number"
                name="upsFee"
                placeholder="UPS"
              />
            </div>
          </b-form-group>
        </VeeForm>
      </div>
    </template>
    <template #modal-footer>
      <div>
        <b-button size="md" class="btn-grey mr-3" @click="cancelEdit"> Cancel </b-button>
        <b-button variant="success" size="md" :disabled="!isFormChanged" class="btn-green" @click="onSubmit">
          Save changes
        </b-button>
      </div>
    </template>
  </modal-wrap>
</template>

<script lang="ts">
import { EDIT_DMT_FEE_MODAL } from '@/const/ModalTypes'
import { ref, computed, onMounted, defineComponent } from 'vue'
import { openModal } from '@/services/Modal'
import { Form as VeeForm } from 'vee-validate'
import { useNotification } from '@kyvg/vue3-notification'
import { useStore } from 'vuex'
import VInput from '@/views/Components/Elements/Form/VInput.vue'
import ModalWrap from '@/views/Modals/ModalWrap.vue'
import FeeProxy from '@/proxies/FeeProxy'

export default defineComponent({
  name: 'EditDmtFeeModal',
  components: {
    VeeForm,
    VInput,
    ModalWrap,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup() {
    const proxy = new FeeProxy()
    const dhlFreightFee = ref({ id: '', percent: 25, initialFeeValue: 25 })
    const dhlExpressFee = ref({ id: '', percent: 25, initialFeeValue: 25 })
    const upsFee = ref({ id: '', percent: 25, initialFeeValue: 25 })
    const loading = ref(true)
    const modalName = EDIT_DMT_FEE_MODAL
    const dmtConfirm = EDIT_DMT_FEE_MODAL + '_confirm'
    const rules = {
      number: {
        required: true,
      },
    }
    const { notify } = useNotification()
    const store = useStore()

    const isFormChanged = computed(() => {
      return (
        dhlFreightFee.value.initialFeeValue !== dhlFreightFee.value.percent ||
        dhlExpressFee.value.initialFeeValue !== dhlExpressFee.value.percent ||
        upsFee.value.initialFeeValue !== upsFee.value.percent
      )
    })

    const cancelEdit = () => {
      if (!isFormChanged.value) {
        closeModal()
        return
      }
      openModal('confirmWrap', {
        title: 'There are unsaved changes',
        message: 'Are you sure you want to cancel?',
        onConfirm: closeModal,
      })
    }

    const catchError = () => {
      notify({ group: 'error', text: 'Saving error, please try again' })
    }

    const closeModal = () => {
      store.dispatch('vueNextModal/closeModal', modalName)
    }

    const onSubmit = () => {
      const promises = [
        proxy.updateField(dhlFreightFee.value.id, 'percent', dhlFreightFee.value.percent),
        proxy.updateField(dhlExpressFee.value.id, 'percent', dhlExpressFee.value.percent),
        proxy.updateField(upsFee.value.id, 'percent', upsFee.value.percent),
      ]

      Promise.all(promises)
        .then(() => {
          notify({ group: 'success', text: 'DMT fee have been updated' })
        })
        .catch(catchError)
        .finally(closeModal)
    }

    const loadFees = async () => {
      const promises = [
        proxy.getByType('dmt', 'dhl_freight').then((response) => {
          const { id, percent } = response.data
          dhlFreightFee.value = { id, percent, initialFeeValue: percent }
        }),
        proxy.getByType('dmt', 'dhl_express').then((response) => {
          const { id, percent } = response.data
          dhlExpressFee.value = { id, percent, initialFeeValue: percent }
        }),
        proxy.getByType('dmt', 'ups').then((response) => {
          const { id, percent } = response.data
          upsFee.value = { id, percent, initialFeeValue: percent }
        }),
      ]

      await Promise.all(promises)
        .catch((e) => {
          notify({
            group: 'error',
            text: e.message || 'Server error. Please try again later.',
          })
        })
        .finally(() => {
          loading.value = false
        })
    }

    onMounted(loadFees)

    return {
      dhlFreightFee,
      dhlExpressFee,
      upsFee,
      loading,
      modalName,
      dmtConfirm,
      rules,
      onSubmit,
      isFormChanged,
      cancelEdit,
    }
  },
})
</script>
