<template>
  <b-button
    v-if="hasDocuments"
    size="md"
    variant="link"
    :style="{ backgroundColor: '#08A762', color: '#ffffff' }"
    class="ml-3"
    @click="documentsDownload"
  >
    <i class="fa fa-download" />
    Download documents
  </b-button>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'DownloadDocuments',
  setup() {
    const store = useStore()

    const hasDocuments = computed(() => !!store.state.assignment.documentsUrl)

    const documentsDownload = () => {
      window.open(store.state.assignment.documentsUrl, '_blank')
    }

    return {
      hasDocuments,
      documentsDownload,
    }
  },
})
</script>
