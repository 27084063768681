<template>
  <BaseLabel :id="labelId" :short-text="shortText" :tooltip-text="tooltipText" :class-name="className" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { getAssignmentTypeTxt } from '@/services/Utils'
import { SINGLE } from '@/const/AssignmentTypes'
import BaseLabel from '@/views/Components/Labels/BaseLabel.vue'
import type { ListAssignment } from '@/types/Models/Assignment'

export default defineComponent({
  name: 'AssignmentType',
  components: { BaseLabel },
  props: {
    assignment: {
      type: Object as () => ListAssignment,
      required: true,
    },
  },
  setup(props) {
    const labelId = `assignment-type-${props.assignment.id}`
    const tooltipText = getAssignmentTypeTxt(props.assignment.type)
    const shortText = props.assignment.type === SINGLE ? 'S' : 'B'
    const className = props.assignment.type === SINGLE ? 'assignment-type-single' : 'assignment-type-batch'

    return {
      labelId,
      shortText,
      tooltipText,
      className,
    }
  },
})
</script>
