/* ============
 * Customers Module
 * ============
 */

import type { ActionContext } from 'vuex'
import type { CustomersStoreState, RootStoreState } from '@/types/Store'
import type { AxiosItem } from '@/types/Components/Response'
import type { User } from '@/types/Models/User'
import type { CreateCustomer } from '@/types/Models/Customer'
import type { RegistrationData } from '@/types/Models/Account'
import { CUSTOMER } from '@/const/AccountTypes'
import { none } from '@/services/Helpers'
import integrations from '@/store/modules/customers/integrations'
import senderAddresses from '@/store/modules/customers/senderAddresses'
import UserProxy from '@/proxies/UserProxy'
import UserTransformer from '@/transformers/UserTransformer'
import ApiProxy from '@/proxies/ApiProxy'

export const FILL_CUSTOMER = 'FILL_CUSTOMER'
export const FILL_CUSTOMERS = 'FILL_CUSTOMERS'

type Context = ActionContext<CustomersStoreState, RootStoreState>

const state: CustomersStoreState = {
  customer: null,
  allCustomers: [],
}

const getters = {
  get: (state: CustomersStoreState) => state.customer,
  all: (state: CustomersStoreState) => state.allCustomers,
}

const mutations = {
  [FILL_CUSTOMER](state: CustomersStoreState, payload: User) {
    state.customer = payload
  },
  [FILL_CUSTOMERS](state: CustomersStoreState, payload: User[]) {
    state.allCustomers = payload.map((customer: User) => ({
      ...customer,
      label: customer.company || (customer.fullName.length > 1 ? customer.fullName : customer.email),
      name: customer.fullName || customer.email,
      handle: customer.id,
      status: 'active',
    }))
  },
}

const actions = {
  create: (_: Context, payload: CreateCustomer) => new UserProxy().create(payload),
  archive: (_: Context, id: string) => new UserProxy().updateField(id, 'isActive', 0),
  unarchive: (_: Context, id: string) => new UserProxy().updateField(id, 'isActive', 1),
  update: (_: Context, { id, data }: { id: string; data: any }) =>
    new UserProxy().update(id, UserTransformer.send(data)),
  requestCreate: (_: Context, payload: RegistrationData) => new ApiProxy().requestCreate(payload),
  fetch: ({ commit }: Context, id: string) => {
    new UserProxy()
      .find(id)
      .catch(none)
      .then((response: AxiosItem<User>) => {
        if (response?.data) {
          commit(FILL_CUSTOMER, response.data)
        }
      })
  },
  fetchAll: ({ commit }: Context) => {
    new UserProxy({ type: CUSTOMER })
      .all()
      .catch(none)
      .then((response) => {
        commit(FILL_CUSTOMERS, response?.data || [])
      })
  },
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
  modules: {
    integrations,
    senderAddresses,
  },
}
