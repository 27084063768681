<template>
  <div class="d-flex">
    <b-icon icon="circle-fill" scale="1.3" :style="circleStyle" />
    <div class="pl-2 ptop">
      {{ statusName }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { useAssignmentStatus } from '@/composables/assignment/useAssignmentStatus'

export default defineComponent({
  name: 'AssignmentStatus',
  setup() {
    const store = useStore()
    const assignment = computed(() => store.getters['assignment/get'])

    const { statusName, circleStyle } = useAssignmentStatus({ assignment })

    return {
      statusName,
      circleStyle,
    }
  },
})
</script>
