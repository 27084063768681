<template>
  <modal-wrap
    :id="id"
    ref="createSenderAddress"
    :name="modalName"
    :header-class="'service-level-header'"
    :body-class="'service-level-modal'"
    :dialog-class="'sender-modal'"
    :hide-footer="true"
    size="md"
  >
    <template #modal-title> Create Customer </template>
    <template #default>
      <div class="pt-3 mt-3">
        <create-customer-form :created="customerCreated" />
      </div>
    </template>
  </modal-wrap>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import { CREATE_NEW_CUSTOMER } from '@/const/ModalTypes'
import ModalWrap from '@/views/Modals/ModalWrap.vue'
import CreateCustomerForm from '@/views/Components/Customer/CreateCustomerForm.vue'

export default defineComponent({
  name: 'CreateNewCustomerModal',
  components: {
    CreateCustomerForm,
    ModalWrap,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup() {
    const store = useStore()
    const modalName = CREATE_NEW_CUSTOMER

    const customerCreated = () => {
      store.dispatch('vueNextModal/closeModal', modalName)
      store.dispatch('customers/fetchAll')
    }

    return {
      modalName,
      customerCreated,
    }
  },
})
</script>

<style lang="scss" scoped>
:deep(.form-control) {
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 4px;
  color: #757575;
  padding-left: 16px;
}
:deep(.custom-select) {
  border: 1px solid #cccccc;
  color: #757575;
  padding-left: 16px;
}

.client-city {
  width: 390px;
  margin-right: 16px;
}
</style>
