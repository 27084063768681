<template>
  <div>
    <b-button v-b-modal="'shipment-notification-modal-' + user.id" class="p-2 fz-inherit" variant="outline-secondary">
      <div class="d-flex align-items-center">
        <mdicon name="CogOutline" class="color-black mr-2" size="36" />
        <span class="color-black">Configure</span>
      </div>
    </b-button>

    <b-modal :id="'shipment-notification-modal-' + user.id" hide-header hide-footer centered>
      <div class="d-flex justify-content-between align-items-center">
        <span>
          Email Notifications Are
          <span v-if="shipmentNotifications" class="text-success"> Enabled </span>
          <span v-else class="text-danger"> Disabled </span>
        </span>
        <b-button v-if="shipmentNotifications" variant="danger" class="ml-2" @click="toggleShipmentNotifications">
          Disable
        </b-button>
        <b-button v-else variant="success" class="ml-2" @click="toggleShipmentNotifications"> Enable </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, type PropType } from 'vue'
import { useStore } from 'vuex'
import type { User } from '@/types/Models/User'

export default defineComponent({
  name: 'ShipmentNotificationModal',
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore()
    const shipmentNotifications = ref(false)

    onMounted(() => {
      shipmentNotifications.value = props.user.shipmentNotifications
    })

    const toggleShipmentNotifications = async () => {
      try {
        shipmentNotifications.value = !shipmentNotifications.value
        await store.dispatch('customers/update', {
          id: props.user.id,
          data: {
            shipmentNotifications: shipmentNotifications.value,
          },
        })
      } catch (e) {
        console.error(e)
      }
    }

    return {
      shipmentNotifications,
      toggleShipmentNotifications,
    }
  },
})
</script>
