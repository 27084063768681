import type { ComputedRef } from 'vue'
import type { SelectOptions } from '@/types/Components/SelectOptions'
import { computed } from 'vue'
import type { City } from '@/types/Models/Customer'
import allCities from '@/assets/files/cities.json'

export const useCities = (): ComputedRef<SelectOptions[]> => {
  return computed(() => {
    const result: City[] = [
      {
        value: '',
        text: 'Choose city *',
      },
    ]
    return result.concat(
      allCities.map((cityName: string) => ({
        value: cityName,
        text: cityName,
      })),
    )
  })
}
