<template>
  <modal-wrap
    :id="id"
    ref="IntegrationsShopifySettings"
    :name="modalName"
    :header-class="'integrations-shopify-settings-header'"
    body-class="integrations-shopify-settings-modal"
    :hide-footer="true"
    size="md"
  >
    <template #modal-title>
      <div class="d-flex justify-content-between align-items-center">
        <h4 class="mb-0">Shopify Webhook Settings</h4>
      </div>
    </template>
    <template #default>
      <b-form-group>
        <div class="form-group">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="mb-0">Webhook URL</h4>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                1. YourShop -> Settings -> Notifications -> Webhooks -> Create Webhook
                <br /><br />
                2. Select "Order Creation" and paste the following URL:
                <br />
                https://core.pacsortonline.com/api/integrations/shopify/callback?user_id={{
                  customerId
                }}&external_user_id={{ externalId }} <br /><br />
                3. Copy the Webhook Signature and paste it below.
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-between align-items-center">
            <h4 class="mb-0">Connection Settings</h4>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Webhook Signature</label>
                <VInput v-model="hmacKey" type="text" name="hmacKey" :rules="{ required: true, min: 32 }" />
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <button type="button" class="btn create-btn btn-green" @click="connect">Connect</button>
        </div>
      </b-form-group>
    </template>
  </modal-wrap>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { openModal } from '@/services/Modal'
import { notify } from '@kyvg/vue3-notification'
import { INTEGRATIONS, SHOPIFY_WEBHOOK_SETTINGS } from '@/const/ModalTypes'
import ModalWrap from '@/views/Modals/ModalWrap.vue'
import VInput from '@/views/Components/Elements/Form/VInput.vue'

export default defineComponent({
  name: 'IntegrationsShopifySettingsModal',
  components: {
    ModalWrap,
    VInput,
  },
  props: {
    id: {
      type: String,
      default: '',
      required: true,
    },
  },
  setup() {
    const store = useStore()
    const customerId = ref('')
    const externalId = ref('')
    const hmacKey = ref('')

    const modalName = SHOPIFY_WEBHOOK_SETTINGS

    const modalData = computed(() => store.getters['vueNextModal/modalProps'](modalName) || {})
    const parentModal = computed(() => store.getters['vueNextModal/modalProps'](INTEGRATIONS) || {})

    const connect = () => {
      store
        .dispatch('customers/integrations/connectShopify', {
          customerId: customerId.value,
          token: hmacKey.value,
        })
        .then((response) => {
          if (response.message) {
            notify({ group: 'error', text: response.message })
          } else {
            notify({
              group: 'success',
              text: 'The integration has been connected.',
            })

            store.dispatch('vueNextModal/closeModal', INTEGRATIONS)
            store.dispatch('vueNextModal/closeModal', modalName)
            store.dispatch('customers/integrations/fetch', customerId.value).then((res) => {
              openModal(INTEGRATIONS, {
                ...parentModal.value,
                integrations: res.data || [],
              })
            })
          }
        })
        .catch((e) => {
          notify({
            group: 'error',
            text: `The integration has not been connected. ${e.message}`,
          })
        })
    }

    watch(modalData, () => {
      customerId.value = modalData.value.customerId
      externalId.value = modalData.value.externalId
      hmacKey.value = modalData.value.hmacKey
    })

    return {
      customerId,
      externalId,
      modalName,
      hmacKey,
      connect,
    }
  },
})
</script>

<style lang="scss" scoped>
.integrations-shopify-settings-modal .radio-group {
  padding: 10px 16px 10px 44px;
  margin: 6px 0;
}
.integrations-shopify-settings-modal .radio-group::before {
  top: 12px;
}
</style>
