<template>
  <modal-wrap
    ref="PackingSlipEditModal"
    :name="modalName"
    :header-class="'packing-slip-edit-header'"
    :body-class="'packing-slip-edit-modal'"
    :hide-footer="true"
    size="lg"
  >
    <template #modal-title> Edit Packing Slip </template>
    <template #default>
      <div v-if="packingSlip && !packingSlip.id" class="d-flex justify-content-center p-4 packing-slip-preview-body">
        <b-spinner class="mt-1 ml-2" variant="success" small />
      </div>
      <div v-if="packingSlip && packingSlip.id">
        <PackingSlipItemsTable :packing-slip="packingSlip" index="edit" :is-edit="true" />
      </div>
      <div class="d-flex justify-content-between pt-5 pr-4 pl-4 mr-0">
        <b-button class="file-btn" variant="danger" @click="openModal('confirmDelete')">
          <b-icon icon="trash" class="mr-2" variant="white" small />
          Delete
        </b-button>
      </div>
      <confirm
        id="confirmDelete"
        message="Are you sure you want to delete this packing slip? This action is irreversible."
        title="Delete Packing Slip"
        :on-confirm="onDelete"
      />
    </template>
  </modal-wrap>
</template>

<script lang="ts">
import { defineComponent, computed, type ComputedRef } from 'vue'
import { useStore } from 'vuex'
import { openModal } from '@/services/Modal'
import { PACKING_SLIP_EDIT, PACKING_SLIP_PREVIEW } from '@/const/ModalTypes'
import ModalWrap from '@/views/Modals/ModalWrap.vue'
import PackingSlipItemsTable from '@/views/Components/PackingSlip/PackingSlipItemsTable.vue'
import Confirm from '@/views/Modals/Confirm.vue'
import type { PackingSlip } from '@/types/Models/PackingSlip'

export default defineComponent({
  name: 'PackingSlipEditModal',
  components: {
    Confirm,
    PackingSlipItemsTable,
    ModalWrap,
  },
  setup() {
    const store = useStore()

    const modalName = PACKING_SLIP_EDIT
    const packingSlip: ComputedRef<PackingSlip> = computed(() => store.getters['packingSlip/getPackingSlip'])

    const onDelete = () => {
      store.dispatch('packingSlip/deletePackingSlip', packingSlip.value.id).then(() => {
        store.dispatch('packingSlip/getPackingSlips')
        store.dispatch('vueNextModal/closeModal', PACKING_SLIP_EDIT)
        store.dispatch('vueNextModal/closeModal', PACKING_SLIP_PREVIEW)
      })
    }

    return {
      modalName,
      packingSlip,
      openModal,
      onDelete,
    }
  },
})
</script>
