<template>
  <BaseLabel :id="labelId" :short-text="shortText" :tooltip-text="tooltipText" :class-name="className" />
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import BaseLabel from '@/views/Components/Labels/BaseLabel.vue'
import { ECONOMY, PRIORITY } from '@/const/AssignmentServiceLevels'
import type { ListAssignment } from '@/types/Models/Assignment'

export default defineComponent({
  name: 'AssignmentServiceLevel',
  components: { BaseLabel },
  props: {
    assignment: {
      type: Object as () => ListAssignment,
      required: true,
    },
  },
  setup(props) {
    const getMessage = (type = '') => {
      switch (type) {
        case ECONOMY:
          return 'Economy'
        case PRIORITY:
          return 'Priority'
        default:
          return ''
      }
    }

    const labelId = computed(() => `assignment-service-level-${props.assignment.id}`)
    const shortText = computed(() => (props.assignment.serviceLevel === ECONOMY ? 'EC' : 'PR'))
    const tooltipText = computed(() => getMessage(props.assignment.serviceLevel))
    const className = computed(() => props.assignment.serviceLevel)

    return {
      labelId,
      shortText,
      tooltipText,
      className,
    }
  },
})
</script>
