<template>
  <div>
    <DatePicker
      v-model="date"
      format="yyyy-MM-dd"
      range
      multi-calendars
      placeholder="Filter by date"
      input-class="date-input"
      popup-class="date-drop"
      :clearable="true"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import DatePicker from '@vuepic/vue-datepicker'
import moment from 'moment'
import { DATE_FROM, DATE_TO } from '@/const/AssignmentListQueryFilters'

export default defineComponent({
  name: 'DateFilter',
  components: { DatePicker },
  setup() {
    const date = ref<Date[] | null>(null)
    const route = useRoute()
    const router = useRouter()

    watch(date, (value) => {
      const query = { ...route.query }

      if (value && value[0] && value[1]) {
        const [dateFrom, dateTo] = value
        query[DATE_FROM] = moment(dateFrom).format('YYYY-MM-DD')
        query[DATE_TO] = moment(dateTo).format('YYYY-MM-DD')
      } else {
        delete query[DATE_FROM]
        delete query[DATE_TO]
      }

      const next = {
        name: route.name,
        query,
      }

      router.push(next)
    })

    onMounted(() => {
      if (route.query[DATE_FROM] && route.query[DATE_TO]) {
        date.value = [moment(String(route.query[DATE_FROM])).toDate(), moment(String(route.query[DATE_TO])).toDate()]
      }
    })

    return {
      date,
    }
  },
})
</script>
