<template>
  <div>
    <StatisticsHeader class="mb-2" @filtrate="updateFilters" />
    <StatisticsList :query="filters.query" :start-date="filters.startDate" :end-date="filters.endDate" />
  </div>
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent, type Ref, ref } from 'vue'
import { TABLE_NAME_STATISTICS } from '@/const/TableNames'
import type { StatisticsFilterType } from '@/types/Models/StatisticsFilterType'

export default defineComponent({
  name: 'Statistics',
  components: {
    StatisticsList: defineAsyncComponent(() => import('@/views/Statistics/StatisticsList.vue')),
    StatisticsHeader: defineAsyncComponent(() => import('@/views/Components/Statistics/StatisticsHeader.vue')),
  },
  setup() {
    const tableName = TABLE_NAME_STATISTICS
    const filters: Ref<StatisticsFilterType> = ref({
      startDate: undefined,
      endDate: undefined,
      query: undefined,
    })

    const updateFilters = (newFilters: StatisticsFilterType) => {
      filters.value = newFilters
    }

    return {
      filters,
      tableName,
      updateFilters,
    }
  },
})
</script>
