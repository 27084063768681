import { computed, type Ref } from 'vue'
import type { AssignmentData } from '@/views/Components/Assignment/AssignmentForm/types'
import countriesEU from '@/assets/files/countriesEU.json'

type Props = {
  assignment: Ref<AssignmentData>
}

export const useDerivedValues = ({ assignment }: Props) => {
  const outsideEu = computed(
    () => Boolean(assignment.value.item.country_code) && !countriesEU.includes(assignment.value.item.country_code),
  )
  const recipientCountry = computed(() => assignment.value.item.country_code)
  const isBusinessRecipient = computed(() => Boolean(assignment.value.item.company_name))
  const isSwedenPicked = computed(() => assignment.value.item.country_code === 'SE')

  const showDeliveryTime = computed(() => isSwedenPicked.value && assignment.value.assignment_type === 'single')

  const maxPickupPeriod = computed(() => {
    // DHL Express max pickup date period is 10 days after current day
    // DHL Freight max pickup date period is 100 days after current day
    return !isSwedenPicked.value ? 10 : 100
  })

  return {
    outsideEu,
    recipientCountry,
    isBusinessRecipient,
    isSwedenPicked,
    showDeliveryTime,
    maxPickupPeriod,
  }
}
