<template>
  <Field v-slot="{ errors, meta }" :name="name" :rules="rules">
    <b-form-group :class="fieldsetClass">
      <select v-model="innerValue" :class="['form-control', getValidationState(errors, meta)]" @change="onUpdate">
        <option v-for="option in options" :key="option.value" :value="option.value">
          {{ option.text }}
        </option>
      </select>
      <div v-if="errors.length" class="invalid-feedback">
        {{ errors.join() }}
      </div>
    </b-form-group>
  </Field>
</template>

<script lang="ts">
import { Field, useField } from 'vee-validate'
import { ref, watch, defineComponent } from 'vue'
import type { SelectOptions } from '@/types/Components/SelectOptions'

export default defineComponent({
  name: 'VSelect',
  components: {
    Field,
  },
  props: {
    name: {
      type: String,
      default: null,
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    value: {
      type: [String, Number],
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    options: {
      type: Array as () => SelectOptions[],
      default: () => [],
    },
    fieldsetClass: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const innerValue = ref(props.value)

    const { value: fieldValue } = useField(props.name)

    watch(
      () => props.value,
      (val) => {
        innerValue.value = val
      },
    )

    watch(innerValue, (newVal) => {
      emit('update:modelValue', newVal)
      fieldValue.value = newVal
    })

    const getValidationState = (errors: any[], meta: any) => {
      if (!meta.touched && !meta.dirty) {
        return null
      }

      return errors.length ? 'is-invalid' : 'is-valid'
    }

    const onUpdate = (event: Event) => {
      const target = event.target as HTMLInputElement
      innerValue.value = target.value
    }

    return {
      innerValue,
      getValidationState,
      onUpdate,
    }
  },
})
</script>

<style lang="scss" scoped>
:deep(.form-group) {
  margin-bottom: 1rem;
}
</style>
