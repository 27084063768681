<template>
  <div>
    <div class="child-row__title pb-4">Optimisation summary</div>

    <AddressValidation :shipment="shipment" />

    <div>
      <div class="child-row-item__title">Prices</div>
      <div class="child-row-item__subtitle">
        <b-badge v-for="{ carrier, price } in prices" :key="carrier" pill variant="light" class="pl-0 mr-3">
          {{ carrierName(carrier) }}: {{ price }}
        </b-badge>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { CARRIERS_NAMES } from '@/const/Carriers'
import type { PropType } from 'vue'
import type { Shipment } from '@/types/Models/Shipment'
import AddressValidation from '@/views/Components/Shipments/OptimisationSummary/AddressValidation.vue'

export default defineComponent({
  name: 'ShipmentOptimisationSummary',
  components: { AddressValidation },
  props: {
    shipment: {
      type: Object as PropType<Shipment>,
      required: true,
    },
  },
  setup(props) {
    const parsed = computed(() =>
      props.shipment.optimisationSummary ? JSON.parse(props.shipment.optimisationSummary) : {},
    )

    const prices = computed(() => {
      if (!parsed.value.prices) return []

      const summaryData = parsed.value.prices
      return Object.keys(summaryData).map((carrier: string) => {
        return {
          carrier,
          price: summaryData[carrier] ? (Math.round(summaryData[carrier] * 100) / 100).toFixed(2) + ' kr' : '-',
        }
      })
    })

    function carrierName(carrierHandle: string) {
      if (!carrierHandle) return ''
      return CARRIERS_NAMES[carrierHandle]
    }

    return {
      prices,
      carrierName,
    }
  },
})
</script>
