<template>
  <!-- ############ Route view For child pages-->
  <v-layout>
    <router-view />
  </v-layout>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue'
import { useStore } from 'vuex'
import { SET_PACKING_SLIPS_ASSIGNMENT_TYPE } from '@/store/modules/packingSlip'
import VLayout from '@/views/Layouts/DefaultLayout.vue'
import { none } from '@/services/Helpers'

export default defineComponent({
  name: 'Staff',
  components: {
    VLayout,
  },
  setup() {
    const store = useStore()

    onMounted(() => {
      store.commit(`packingSlip/${SET_PACKING_SLIPS_ASSIGNMENT_TYPE}`, 'consolidate')
      if (store.getters['packingSlip/getAllStaffs'].length === 0) {
        store.dispatch(`packingSlip/fetchStaffs`).catch(none)
      }
    })

    return {}
  },
})
</script>
