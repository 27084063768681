<template>
  <modal-wrap
    :name="modalName"
    :header-class="'border-0 service-level-header'"
    :body-class="'service-level-modal'"
    :hide-footer="false"
    :hide-header-close="true"
    size="md"
  >
    <template #modal-title>
      <h2>Oh! Fatal errors founded.</h2>
    </template>
    <template #default>
      <p>We found <b>fatal errors</b> in your file and cannot proceed just yet:</p>
      <ul>
        <template v-for="(error, index) in errors" :key="`errors-list-${index}`">
          <li>
            - <b>{{ error }}</b>
          </li>
        </template>
      </ul>
      <p>You must first fix all fatal errors in file.</p>
    </template>
    <template #modal-footer>
      <b-button size="md" class="btn-grey mr-3" @click="closeModal">Edit file</b-button>
      <a href="mailto:support@21grams.se" target="_blank" class="btn btn-danger">Get Help</a>
    </template>
  </modal-wrap>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { CREATE_ASSIGNMENT_SOURCE_FILE_ERRORS } from '@/const/ModalTypes'
import ModalWrap from '@/views/Modals/ModalWrap.vue'

export default defineComponent({
  name: 'SourceFileErrorsModal',
  components: { ModalWrap },
  setup() {
    const store = useStore()
    const modalName = CREATE_ASSIGNMENT_SOURCE_FILE_ERRORS
    const errors = computed(() => store.getters['vueNextModal/modalProps'](modalName)?.fileErrors || [])
    const closeModal = () => store.dispatch('vueNextModal/closeModal', modalName)

    return {
      errors,
      modalName,
      closeModal,
    }
  },
})
</script>
