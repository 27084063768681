<template>
  <b-dropdown right class="add-assignment-wrap">
    <template #button-content>
      <mdicon name="plus" size="24" />
    </template>

    <b-dropdown-item :to="single">Single-item assignment</b-dropdown-item>
    <b-dropdown-item :to="batch">Batch assignment</b-dropdown-item>
  </b-dropdown>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { STAFF_ASSIGNMENTS_CREATE } from '@/types/router'
import { SINGLE } from '@/const/AssignmentTypes'

export default defineComponent({
  name: 'AddButton',
  setup() {
    const single = {
      name: STAFF_ASSIGNMENTS_CREATE,
      query: {
        assignmentType: SINGLE,
      },
    }

    const batch = {
      name: STAFF_ASSIGNMENTS_CREATE,
    }

    return {
      single,
      batch,
    }
  },
})
</script>

<style lang="scss">
.add-assignment-wrap {
  margin-left: 12px;
  .dropdown-toggle {
    padding: 0 !important;
    height: 40px !important;
    min-height: 40px;
    border: 0;
    width: 52px;
    background-color: #058880 !important;

    &:hover {
      background: #00736c !important;
    }

    &::after {
      display: none;
    }
  }
}
</style>
