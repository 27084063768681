<template>
  <b-button
    size="md"
    variant="link"
    :style="{ backgroundColor: '#08A762', color: '#ffffff' }"
    class="ml-3"
    @click="performAction"
  >
    <i class="fa fa-arrow-right" />
    Finish Buffering
  </b-button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import DefaultActions from '@/services/Assignment/Actions/DefaultActions'

export default defineComponent({
  name: 'Process',
  setup() {
    const performAction = () => {
      DefaultActions.processAssignment()
    }

    return {
      performAction,
    }
  },
})
</script>
