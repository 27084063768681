<template>
  <div class="privacy">
    <h1 class="privacy-title">General terms and conditions</h1>
    <div>
      <div class="privacy-section">
        <h2 class="privacy-sub-title">1. Definitions</h2>
        <p class="privacy-text">1.1 User means the entity who uses the Service.</p>
        <p class="privacy-text">
          1.2 Agreement means the agreement, including all appendices, to which these General Terms and Conditions are
          part.
        </p>
        <p class="privacy-text">
          1.3 Implementation Plan means the applicable time plan and the measures required to implement the Services
          with the Customer.
        </p>
        <p class="privacy-text">
          1.4 Supplier means the entity that is listed on the front page of the Agreement, and is the entity which
          provides the Service.
        </p>
        <p class="privacy-text">
          1.5 Service means the type of services, with defined functionality, that the Supplier shall provide under the
          Service Description, and is listed on the front page of the Agreement.
        </p>
        <p class="privacy-text">
          1.6 Service Description means the description of services attached to the agreement to which these General
          Terms and Conditions are part.
        </p>
        <p class="privacy-text">
          1.7 Buyer means the entity that is listed on the front page of the Agreement, and is the entity that gives the
          Supplier the task of providing the Service.
        </p>
        <p class="privacy-text">
          1.8 Bank Day means a day in Sweden which is not a Saturday, Sunday or public holiday when commercial banks are
          open to the public other than for internet services.
        </p>
      </div>
      <div class="privacy-section">
        <h2 class="privacy-sub-title">2. PLA Implementation pla</h2>
        <p class="privacy-text">
          2.1 The Implementation Plan describes the measures required for enabling the Service to be provided to the
          Buyer in accordance with the Service Description and the time plan under which the measures are to be
          provided.
        </p>
        <p class="privacy-text">
          2.2 A written Implementation Plan shall be drawn up together by the parties, immediately after entering into
          the Agreement.
        </p>
        <p class="privacy-text">
          2.3 Upon completion of the measures under the Implementation Plan, the parties shall set a starting date for
          the providing of the Service.
        </p>
      </div>
      <div class="privacy-section">
        <h2 class="privacy-sub-title">3. Service description</h2>
        <p class="privacy-text">
          3.1 The Service Description describes the agreed scope and the requirements for the Service. The Service
          Description applies together with any agreed deviations and amendments noted in this Agreement or in a more
          recent document which is signed by duly authorized representatives from both parties.
        </p>
        <p class="privacy-text">
          3.2 Without prior consent from the Buyer, the Supplier may make changes in the Services, as long as the
          changes do not entail any inconvenience on the Buyer.
        </p>
        <p class="privacy-text">
          3.3 The Supplier may make changes to Services with one (1) month prior notice. The Buyer is entitled to give
          notice of termination of the Services, which will come into force no later than the day for the start of the
          changes or the later day listed in the notice of termination. However, such listed date must take place within
          one month from the start of the changes to the Services.
        </p>
        <p class="privacy-text">
          3.4 Any changes by the Supplier under this section may not violate agreed requirements for the Services under
          sub-section 3.1.
        </p>
      </div>
      <div class="privacy-section">
        <h2 class="privacy-sub-title">4. Obligations of the supplier</h2>
        <h3 class="privacy-sub-point mt-1 mb-3">The Supplier agrees and warrants:</h3>
        <p class="privacy-text">
          4.1 To implement necessary and agreed implementation measures at the Buyer in accordance with the
          Implementation Plan,
        </p>
        <p class="privacy-text">4.2 To provide the Service in accordance with the Service Description</p>
        <p class="privacy-text">
          4.3 To ensure that in the Service only process information that has been transmitted in accordance with
          sub-section 7.1,
        </p>
        <p class="privacy-text">
          4.4 To return transmitted information to authorized recipients at the Buyer or a recipient defined by the
          Buyer, or make the information available to the Buyer by other means in accordance with the Agreement without
          undue delay
        </p>
        <p class="privacy-text">
          4.5 To monitor the Service during normal Swedish office hours (Monday to Friday 8.30-17.00 except for public
          holidays and the day prior such public holiday), and
        </p>
        <p class="privacy-text">4.6 To perform the Service in a professional manner.</p>
      </div>
      <div class="privacy-section">
        <h2 class="privacy-sub-title">5. Availability</h2>
        <p class="privacy-text">
          5.1 The Supplier is required to keep the Service available as specified in the Service Description.
        </p>
        <p class="privacy-text">
          5.2 However, the Supplier is entitled to take measures affecting the availability of the Service during office
          hours if required by technical, maintenance, operational or safety reasons. The Supplier shall take such
          measures promptly and in such a way as to limit the disturbance. The Buyer must be notified in good time and
          usually no later than one week prior to the planned interruptions.
        </p>
      </div>
      <div class="privacy-section">
        <h2 class="privacy-sub-title">6. Obligations of the buyer</h2>
        <h3 class="privacy-sub-point mt-1 mb-3">The Buyer agrees and warrants:</h3>
        <p class="privacy-text">
          6.1 To provide the Supplier with assistance and access to personnel, premises, information and computer
          systems necessary for the proper implementation of the Implementation Plan and the Service
        </p>
        <p class="privacy-text">
          6.2 To ensure that the information transmitted to the Supplier for use in the Service complies with the
          requirements agreed upon
        </p>
        <p class="privacy-text">
          6.3 To provide the Supplier with access to the agreements with the Sub-processors of the Buyer regarding
          printing, enveloping, and mail delivery unless such access is prevented by existing privacy clauses
        </p>
        <p class="privacy-text">6.4 To list contact persons in accordance with sub-section 7.1 below</p>
        <p class="privacy-text">
          6.5 To refrain from using resources or seek unauthorized access to the Supplier’s systems, which are not
          intended for the Buyer, and
        </p>
        <p class="privacy-text">6.6 To pay fees according to issued invoice.</p>
      </div>
      <div class="privacy-section">
        <h2 class="privacy-sub-title">7. Cooperation</h2>
        <p class="privacy-text">
          7.1 The Buyer has listed a contact person who the Supplier may contact in relation to questions about the
          performance of the Service. The contact person shall list the persons authorized to transmit information to
          the Supplier for use in the Service (authorized users) and the persons authorized to receive processed
          information from the Supplier (authorized recipients), and inform the Supplier about how to deliver processed
          information. The Supplier shall be notified immediately if the listed contact person ends his or her
          employment or if the Buyer for any other reason needs to change the contact person.
        </p>
        <p class="privacy-text">
          7.2 The Supplier shall continually inform the Buyer of the manner in which the Supplier may be contacted
          regarding the performance of the Service.
        </p>
      </div>
      <div class="privacy-section">
        <h2 class="privacy-sub-title">8. Risk of for distributed information</h2>
        <p class="privacy-text">
          8.1 The Supplier is not responsible for any loss or misrepresentation of the information provided through the
          Service, unless the Supplier is responsible for this in accordance with sub-section 15.5.
        </p>
        <p class="privacy-text">
          8.2 The Supplier is not required to make a backup of the information transferred from the Buyer to the
          Supplier.
        </p>
        <p class="privacy-text">
          8.3 If Users may provide information to the Service, the Supplier shall store such information to the extent
          agreed. If nothing has not been agreed upon in this regard, the information shall be stored until it has been
          logged as transmitted from the Supplier to the Buyer according to the Supplier’s log. In case the information
          is not transmitted from the Supplier but instead is made available to the Buyer in another way, the
          information shall be stored for twenty four (24) hours from the time the information was made available to the
          Buyer.
        </p>
      </div>
      <div class="privacy-section">
        <h2 class="privacy-sub-title">9. Liability for the contents of distributed information</h2>
        <p class="privacy-text">
          9.1 The Buyer is responsible to the Supplier that the information transmitted to or processed in the Service
          does not infringe any rights of third parties or in any other way violates applicable legislation.
        </p>
        <p class="privacy-text">
          9.2 When information may be provided by a User, the Buyer is responsible for having such access to information
          as is necessary to prevent further disclosure of information as required by applicable legislation.
        </p>
        <p class="privacy-text">
          9.3 The Supplier is entitled to access all information transmitted or submitted to the Service in order to
          fulfill its obligations under the Agreement.
        </p>
        <p class="privacy-text">
          9.4 The Supplier is entitled to promptly prevent continued disclosure of information in the Service if it is
          reasonable to assume that continued disclose is in violation of current legislation.
        </p>
      </div>
      <div class="privacy-section">
        <h2 class="privacy-sub-title">10. Obligations of the supplier as data processor</h2>
        <p class="privacy-text">
          The Supplier's obligations and rights in relation to processing of personal data that is made in connection
          with the Service is governed by Appendix 2.
        </p>
      </div>
      <div class="privacy-section">
        <h2 class="privacy-sub-title">11. Limitation of liability for processing of personal data, costs</h2>
        <p class="privacy-text">
          11.1 The Supplier's responsibility for the processing of personal data shall be limited in accordance with
          paragraph
        </p>
        <p class="privacy-text">
          11.2 The Buyer shall compensate the Supplier for all costs incurred as a result of fulfillment of the
          Supplier’s obligations under the Agreement. Such compensation includes inter alia compensation (per hour) for
          the recourses required from the Supplier for disclosure of information upon request, and other forms of
          assistance.
        </p>
      </div>
      <div class="privacy-section">
        <h2 class="privacy-sub-title">12. Registration of user</h2>
        <p class="privacy-text">
          The Supplier will log the use of the Service, to the extent permitted by applicable law. Unless otherwise
          agreed, data from the Service Use Log may be used solely by the Supplier for the performance of the Service
          or, on an anonymous basis, to develop the Service, the Supplier's Routines, or the Supplier's System. The
          Supplier shall allow the Buyer to access the information that the Supplier registers regarding the use of the
          Service as above, if this is specifically agreed and may be in accordance with applicable legislation.
        </p>
      </div>
      <div class="privacy-section">
        <h2 class="privacy-sub-title">13. Fees</h2>
        <p class="privacy-text">
          13.1 The fees for the Service are stated in the price appendix for each Service. The specified fees are
          exclusive of VAT at any given time. Fees are charged weekly by invoice ex post. Payment must be made in
          accordance with the payment terms in the price appendix.
        </p>
        <p class="privacy-text">13.2 Fees are charged in connection with charge of cost compensation as below.</p>
        <p class="privacy-text">
          13.3 The Supplier is entitled to charge a minimum bid if the amount of the invoices sent per month does not
          reach the minimum bid limit, as shown in the price appendix.
        </p>
        <p class="privacy-text">
          13.4 If payment is not received on time, the Supplier is entitled to charge a reminder charge and, where
          applicable, collection fee and late payment interest from the due date until full payment is made. The late
          payment interest shall correspond to two (2) percent per month and is calculated from the due date of an
          invoice due until full payment is made. Payment terms for invoices regarding late payment interest are 10
          days.
        </p>
        <p class="privacy-text">
          13.5 The fees are usually adjusted annually and are announced at least one month in advance.
        </p>
        <p class="privacy-text">
          13.6 In addition to what is stated in sub-section 13.5 above, the Supplier is entitled to adjust the price for
          any extraordinary changes in tariffs and regulations from postal operators or suppliers of material. Such
          adjustments shall be notified to the Buyer.
        </p>
      </div>
      <div class="privacy-section">
        <h2 class="privacy-sub-title">14. Creditworthiness</h2>
        <p class="privacy-text">
          14.1 The Supplier reserves the right to request changed credit terms during a current contractual period if an
          updated review of the Supplier’s creditworthiness by the Buyer provides a result that differs significantly
          from the creditworthiness upon the entering of the Agreement.
        </p>
        <p class="privacy-text">
          14.2 Changes in credit terms as above shall be communicated between the Suppler and the Buyer in written form.
        </p>
      </div>
      <div class="privacy-section">
        <h2 class="privacy-sub-title">15. Liability of the supplier for errors in the service</h2>
        <p class="privacy-text">
          15.1 Under the following sub-sections in this section 15, the Supplier is responsible for errors in the
          Service that consists of the Service not being in compliance with the Service Description. The Supplier is not
          responsible for errors which are without significance for the intended use of the Service and which cannot
          reasonably be considered to constitute anything other than a slight inconvenience for the Buyer. The Supplier
          is not responsible for errors caused by an action taken by the Supplier when using his or her right under
          sub-section 9.4.
        </p>
        <p class="privacy-text">
          15.2 The Buyer shall notify the Supplier of any error in the Service without undue delay after the error has
          been discovered. The Supplier is shall during normal office hours rectify any such error to the extent
          possible at his or her own expense.
        </p>
        <p class="privacy-text">
          15.3 If the Buyer has not been able to use the Service as intended, the Buyer is entitled to a reduction of
          the fee corresponding to the extent of the error. The same applies if an error for which the Supplier is
          responsible, has resulted in that the use of the Service has resulted in errors to the deliveries. Any claims
          of reductions shall be made no later than two months after the expiry of the period for which compensation is
          claimed.
        </p>
        <p class="privacy-text">
          15.4 The Buyer may give the Supplier written notice of termination with immediate effect if an error, for
          which the Supplier is responsible, has entailed that the Buyer has not been able to use the Service as
          intended and no rectification has been made within one month from the Buyer’s notification of the error.
        </p>
        <p class="privacy-text">
          15.5 The Supplier is responsible for damages caused by negligence from the Supplier or from a sub-processor
          employed by the Supplier, apart from when the limitation of liability set out in sub-section 15.6 is
          applicable.
        </p>
        <p class="privacy-text">
          15.6 Unless the damage is caused intentional or by negligence, the Supplier's liability shall be limited to
          direct losses per contract year to a total amount of six (6) months’ fees for the Service, but no more than
          two (2) times the at the time of the occurrence of the damage applicable price base amount. Price base amount
          means the price base amount under the Social Insurance Code (2010:110) (Sw. socialförsäkringsbalken).
        </p>
        <p class="privacy-text">
          15.7 The Supplier’s liability for the Service, including errors, delays, distortions, loss of information, is
          limited to the above.
        </p>
        <p class="privacy-text">
          Supplier no later than ninety (90) days after the Buyer noticed or should have noticed the ground for the
          claim.
        </p>
      </div>
      <div class="privacy-section">
        <h2 class="privacy-sub-title">16. Termination of the agreement / cancellation of the service</h2>
        <p class="privacy-text">
          16.1 The Supplier is entitled to immediately terminate its providing of the Service or give written notice of
          termination of the Agreement with immediate effect if
        </p>
        <ul class="privacy-list">
          <li>
            <p class="privacy-text">
              A material breach of any provision in this Agreement is made by the Buyer and correction of such breach of
              contract does not occur within fourteen (14) days from receipt of written notice thereto by the Supplier,
              or
            </p>
          </li>
          <li>
            <p class="privacy-text">
              The Buyer is in default with payment of the due fee and has not paid the fee within fourteen (14) days
              after the request has been made, or
            </p>
          </li>
          <li>
            <p class="privacy-text">
              The Buyer is bankrupt, initiates corporate reconstruction, liquidation, fails to impose any other
              non-confidential relationship or otherwise is deemed insolvent.
            </p>
          </li>
        </ul>
        <p class="privacy-text">
          16.2 The Supplier is also entitled to restrict the use of the Service in accordance with sub-section 9.4
          above.
        </p>
        <p class="privacy-text">
          16.3 The Supplier's right to terminate his or her providing of the Service or to give written notice of
          termination of the Agreement shall not be valid if the negligence of the Buyer is of minor importance or if
          the Supplier has granted a suspension of payment.
        </p>
        <p class="privacy-text">
          16.4 The Buyer is entitled to give written notice of termination with immediate effect if
        </p>
        <ul class="privacy-list">
          <li>
            <p class="privacy-text">
              A material breach of any provision in this Agreement is made by the Supplier and correction of such breach
              of contract does not occur within fourteen (14) days from receipt of written notice thereto by the Buyer,
              or
            </p>
          </li>
          <li>
            <p class="privacy-text">
              The Buyer is bankrupt, initiates corporate reconstruction, liquidation, fails to impose any other
              non-confidential relationship or otherwise is deemed insolvent.
            </p>
          </li>
        </ul>
      </div>
      <div class="privacy-section">
        <h2 class="privacy-sub-title">17. Force majeure</h2>
        <p class="privacy-text">
          If a party is prevented from fulfilling its obligations under this agreement due to circumstances outside of
          that party’s control, such as lightning strikes, labor disputes, fire, seizures, government regulations,
          public communications interruptions including access to public networks such as the Internet, as well as
          errors or delays in sub-contracting services due to circumstances specified herein, this shall constitute an
          exemption basis which implies the advancement of the date of performance and exemption from damages and other
          possible penalties. If a substantial part of the performance of the Service is prevented from being fulfilled
          for longer than one (1) month and it is due to a certain circumstance stated above, either party is entitled
          to withdraw from the agreement without any obligation to pay compensation.
        </p>
      </div>
      <div class="privacy-section">
        <h2 class="privacy-sub-title">18. Intellectual property rights</h2>
        <p class="privacy-text">
          All intellectual property rights and technical solutions such as sorting tables and algorithms regarding any
          party’s services, data and associated software are the property of that party and are not transferred to the
          counterparty. Unless expressly stated otherwise, this Agreement shall not constitute any transferal of
          licenses for computer programs, sorting tables or algorithms. Rights granted pursuant to this Agreement may
          not be used by the counterparty for any purpose other than the performance of its obligations under this
          Agreement.
        </p>
      </div>
      <div class="privacy-section">
        <h2 class="privacy-sub-title">19. Transfer of agreement</h2>
        <p class="privacy-text">
          The Supplier may, without the Buyer’s consent, transfer the right to receive payment under this Agreement. A
          party may, without the other party's approval, transfer the Agreement to any other group company in which
          party is included.
        </p>
      </div>
      <div class="privacy-section">
        <h2 class="privacy-sub-title">20. Amendments of the agreement</h2>
        <p class="privacy-text">
          The Supplier may amend the Agreement at the earliest one (1) month after the Buyer has been notified in
          writing of the forthcoming amendments. Upon receipt of such notification, the Buyer has the right to terminate
          the Agreement within one (1) month, either at the end of the one-month period or on the later date specified
          by the Supplier, whereas such date may last occur two (2) months after the Buyer received the notice of
          amendment. During the notice period, the Agreement shall remain unchanged. The Buyer’s continued use of the
          Service after the entry into force of the amendments shall be understood as acceptance of the amendments.
        </p>
      </div>
      <div class="privacy-section">
        <h2 class="privacy-sub-title">21. Term of agreement</h2>
        <p class="privacy-text">
          21.1 This agreement is valid for two (2) years from the date on which both parties has signed.
        </p>
        <p class="privacy-text">
          21.2 The agreement is deemed to be prolonged for the period specified in sub-section 21.1 at the end of the
          agreed agreement period unless written notice of termination has been given no later than three (3) months
          prior to the expiration of the contract period.
        </p>
      </div>
      <div class="privacy-section">
        <h2 class="privacy-sub-title">22. Written notice is required. Notifications</h2>
        <p class="privacy-text">
          Notices or other messages under this Agreement may be given to the other party's contact person by fax or by
          electronic mail to the address provided by each party.
        </p>
      </div>
      <div class="privacy-section">
        <h2 class="privacy-sub-title">23. Arbitration</h2>
        <p class="privacy-text">23.1 This Agreement shall be governed by Swedish law.</p>
        <p class="privacy-text">
          23.2 Any dispute arising out of or in connection with this contract shall be finally settled by arbitration in
          accordance with the Arbitration Rules of the Arbitration Institute of the Stockholm Chamber of Commerce (SCC).
        </p>
        <p class="privacy-text">
          23.3 The Rules for Expedited Arbitrations shall apply if the amount in dispute does not exceed SEK 1,000,000.
          If the amount in dispute exceeds SEK 1,000,000, but not SEK 10,000,000, the arbitral tribunal shall be
          composed of a sole arbitrator, and if the amount in dispute exceeds SEK 10,000,000, the arbitral tribunal
          shall be composed of three arbitrators. The disputed amount includes the claimant’s claim in the application
          and counterclaims which is made in reply to the application. However, in the case of payments for the Service
          due, the Supplier may bring an action before a general court or the enforcement authority (Sw.
          Kronofogdemyndigheten).
        </p>
      </div>
      <div class="privacy-section">
        <h2 class="privacy-sub-title">24. Security, Confidentiality</h2>
        <p class="privacy-text">
          24.1 The Supplier shall comply with the safety instructions communicated by the Buyer. A party is responsible
          for the adequate preservation and use of identities, passwords and the like shared by the other party.
        </p>
        <p class="privacy-text">
          24.2 The parties undertake to not in any way disclose to third parties information received from the other
          party within the framework of the Agreement. The Supplier shall not provide third parties, except as may be
          required by the User, data from the Service Use Log. The confidentiality undertaking does not apply to such
          information as a party is able show to be known to him otherwise than through the assignment or to information
          that is generally known. The confidentiality undertaking does not apply when a party is required by law to
          disclose information. The confidentiality undertaking continues to apply after the expiry of the agreement.
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Terms',
})
</script>
