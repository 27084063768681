<template>
  <Field v-slot="{ field, errors, meta }" :model-value="innerValue" :name="name" :rules="rules">
    <b-form-group class="position-relative">
      <input
        v-bind="field"
        v-model="innerValue"
        :class="['form-control', 'datalist-input', getValidationState(meta)]"
        :type="type"
        :max="max"
        :disabled="disabled"
        :placeholder="placeholderText"
        :list="id"
      />
      <datalist :id="id">
        <option v-for="(option, index) in list" :key="index" :value="option.text">
          {{ option.text }}
        </option>
      </datalist>
      <div v-if="unit.length" class="unit">
        {{ unit }}
      </div>
      <div class="invalid-feedback">
        {{ errors.length ? errors.join() : '' }}
      </div>
    </b-form-group>
  </Field>
</template>

<script lang="ts">
import { Field } from 'vee-validate'
import { ref, computed, watch, defineComponent } from 'vue'
import { getValidationState } from '@/services/Validation'

export default defineComponent({
  name: 'VInputDataList',
  components: {
    Field,
  },
  props: {
    id: {
      type: String,
      default: 'my-list-id',
    },
    name: {
      type: String,
      default: null,
    },
    list: {
      type: Array as () => { text: string; value: string }[],
      required: true,
    },
    vid: {
      type: String,
      default: '',
    },
    rules: {
      type: [String, Object],
      default: () => ({}),
    },
    value: {
      type: [String, Number],
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    hidePlaceholder: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
      validator: (value: string) => ['text'].indexOf(value) !== -1,
    },
    unit: {
      type: String,
      default: '',
      required: false,
    },
    max: {
      type: String,
      default: '',
      required: false,
    },
    formatter: {
      type: Function,
      default: null,
    },
  },
  emits: ['input', 'select'],
  setup(props, { emit }) {
    const innerValue = ref(props.value)

    const placeholderText = computed(() => {
      return props.hidePlaceholder ? '' : props.placeholder || props.name
    })

    watch(
      () => innerValue.value,
      (val) => {
        const searchVal = String(val).toLowerCase()
        const inList: any = props.list.find((option: any) => option.text?.toLowerCase() === searchVal)
        if (inList) {
          innerValue.value = inList.text
          emit('input', inList.value)
          emit('select', inList.value)
        } else {
          emit('input', val)
        }
      },
      { deep: true },
    )

    watch(
      () => props.value,
      (val) => {
        innerValue.value = val
      },
    )

    return {
      innerValue,
      placeholderText,
      getValidationState,
    }
  },
})
</script>

<style lang="scss" scoped>
.unit {
  position: absolute;
  right: 1px;
  top: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 43px;
  width: 43px;
  background: #f5f5f3;
  border-radius: 0 4px 4px 0;
  border-left-width: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #757575;
}
.datalist-input::-webkit-calendar-picker-indicator {
  display: none !important;
}
</style>
