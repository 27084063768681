/* ============
 * Assignment List Transformer
 * ============
 *
 * The transformer for the Assignment in the list.
 */

import ItemTransformer from '@/transformers/ItemTransformer'
import type { ListAssignment, ListAssignmentResponse } from '@/types/Models/Assignment'
import SenderAddressTransformer from '@/transformers/SenderAddressTransformer'

export default class AssignmentsListTransformer {
  /**
   * Method used to transform a fetched Assignment.
   */
  static fetch(data: ListAssignmentResponse): ListAssignment {
    return {
      ...data,
      itemsCount: data.domesticItemsCount + data.internationalItemsCount,
      item: data.item ? ItemTransformer.fetch(data.item) : null,
      optimisationReportUrl: data.optimisationReportUrl || null,
      customerReference: data.customerReference || null,
      hasCustomPrices: data.hasCustomPrices || false,
      hasCustomPriorities: data.hasCustomPriorities || false,
      archived: data.archived || false,
      report: data.report || null,
      type: data.type || 'single',
      hasDocuments: data.hasDocuments || false,
      carriers: data.carriers || [],
      carriersSummary: data.carriersSummary || {},
      recipient: data.recipient || null,
      hidePrice: data.user?.hidePrice || false,
      source: data.source || '',
      sourceFiles: data.sourceFiles || [],
      distributionChannel: data.distributionChannel || '',
      pickupInstructions: data.pickupInstructions || '',
      senderAddress: data.senderAddress ? SenderAddressTransformer.fetch(data.senderAddress) : null,
    } as ListAssignment
  }

  /**
   * Method used to transform a fetched collection.
   */
  static fetchCollection(items: ListAssignmentResponse[]) {
    return items.map((item) => this.fetch(item))
  }
}
