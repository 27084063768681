<template>
  <div>
    <div class="container mb-5">
      <h2 class="form-title">
        {{ assignmentId ? 'Edit Single Assignment' : 'Create new assigment' }}
      </h2>
      <VeeForm class="assignment-form box-shadow" @submit="submit">
        <div v-if="isStaff">
          <div class="form-group">
            <label class="required fs-xl mt-0"> Customer </label>
            <div class="input-group">
              <CustomSelect
                placeholder="Choose client"
                :selected="assignment.customer_id"
                :options="customers"
                :selectable="(option: any) => !option.status.includes('inactive')"
                :filter="clientFilter"
                @selected="chooseClient"
              >
                <template #custom_option="{ option }">
                  <div :class="'item-' + option.status">
                    <span class="status" />
                    <span class="name">{{ option.company || option.name }}</span>
                    (Client number: {{ option.clientNumber }}; Email: {{ option.email }}; Phone:
                    {{ option.phoneNumber }})
                  </div>
                </template>
                <template #custom_list-footer>
                  <div class="list-footer">
                    <div class="create-client" @click="openModal(CREATE_NEW_CUSTOMER)">Create New Customer</div>
                  </div>
                </template>
              </CustomSelect>
            </div>
            <Field v-if="!isStaff" name="Customer" :rules="rules.customer_id">
              <template #default="{ field, errors }">
                <b-form-group>
                  <div class="input-group">
                    <b-form-input
                      v-bind="field"
                      :state="errors.length === 0"
                      type="text"
                      class="hidden"
                      placeholder="Description"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </b-form-group>
              </template>
            </Field>
            <div v-if="errorsList.customer_id.length" class="text-danger">
              {{ errorsList.customer_id }}
            </div>
          </div>
        </div>

        <div class="mb-4" :class="{ 'mt-4 mb-0': isStaff }">
          <label class="fs-xl mt-0">Sender Address</label>
          <CustomSelect
            :selected="assignment.sender_address_id"
            :options="senderAddresses"
            placeholder="Choose sender address"
            class="w-100"
            @selected="selectSenderAddress"
          >
            <template #custom_option="{ option }">
              <div :class="'d-flex align-items-baseline item-' + option.id">
                <div class="d-flex justify-content-between w-100 align-items-center">
                  <div class="d-flex align-items-baseline">
                    <span class="status" />
                    <span class="name d-block mr-2">{{ option.company || option.fullName }} </span>
                    <div>
                      {{ option.countryCode }}, {{ option.city }},
                      {{ option.addressLine }}
                    </div>
                    <div v-if="isStaff" class="d-flex ml-2">
                      <b-badge v-if="option.customNumbers?.length" class="mt-0" variant="primary">
                        Customer Numbers
                      </b-badge>
                      <b-badge v-if="option.isDefault === 1" class="ml-1 mt-0" variant="primary">
                        Default Address
                      </b-badge>
                      <b-badge v-if="option.hasCustomPrices === 1" variant="success" class="ml-1">
                        Sender prices
                      </b-badge>
                      <b-badge v-if="option.hasCustomPriorities === 1" variant="warning" class="ml-1">
                        Sender carriers
                      </b-badge>
                    </div>
                  </div>
                  <div v-if="!isStaff">
                    <b-button
                      class="d-flex align-items-center justify-content-center action-btn"
                      variant="outline-secondary"
                      @click="openSenderAddressEditModal(option.id)"
                    >
                      <mdicon name="pencil-outline" class="pointer" size="18" />
                    </b-button>
                  </div>
                </div>
              </div>
            </template>
            <template #custom_list-footer>
              <div v-if="assignment.customer_id || (!isStaff && userId)" class="list-footer">
                <div
                  class="create-client"
                  @click="
                    openModal(SENDER_ADDRESS_ACTION, {
                      customerId: assignment.customer_id || userId,
                      address: '',
                      canHasCustomerNumbers: customerCanHaveCustomerNumbers,
                    })
                  "
                >
                  Create New Sender Address
                </div>
              </div>
            </template>
          </CustomSelect>
          <div v-if="errorsList.sender_address_id.length" class="text-danger">
            {{ errorsList.sender_address_id }}
          </div>
        </div>

        <div class="form-group">
          <div class="label required fs-xl" :class="{ 'mt-0': !isStaff }">Assignment type</div>
          <div class="row">
            <div class="col-12 col-md-6">
              <input
                id="consolidated_type"
                v-model="assignment.assignment_type"
                name="assignment_type"
                type="radio"
                value="consolidate"
              />
              <label for="consolidated_type" class="radio-group">
                <span class="radio-title"> Batch shipment (Upload file) </span>
              </label>
            </div>
            <div class="col-12 col-md-6">
              <input
                id="single_type"
                v-model="assignment.assignment_type"
                name="assignment_type"
                type="radio"
                value="single"
              />
              <label for="single_type" class="radio-group">
                <span class="radio-title">Single package (Enter address)</span>
              </label>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="label required fs-xl" :class="{ 'mt-0': !isStaff }">Distribution Channel</div>
          <div class="row">
            <div v-for="(channel, i) in distributionChannels" :key="i" :class="channel.class">
              <input
                v-if="!channel.staffOnly || isStaff"
                :id="'type_' + channel.code"
                v-model="assignment.distribution_channel"
                :checked="assignment.distribution_channel === channel.code"
                :value="channel.code"
                name="distribution_channel"
                type="radio"
              />
              <label v-if="!channel.staffOnly || isStaff" :for="'type_' + channel.code" class="radio-group">
                <span class="radio-title">
                  <span v-if="channel.icon" :class="channel.icon" />
                  {{ channel.name }}
                </span>
                <span class="radio-description">{{ channel.description }}</span>
                <span v-if="channel.beta" class="company-name-info">
                  <img src="../../../../assets/img/info-circle-outlined.svg" alt="" class="mr-1" /> beta
                </span>
              </label>
            </div>
          </div>
        </div>

        <div v-if="assignment.assignment_type === CONSOLIDATE" class="row">
          <div class="col-12 col-md-9">
            <div class="form-group">
              <label class="required"> Select files </label>
              <div class="input-group">
                <div class="select-file">
                  <input id="file" type="file" accept=".csv,.xlsx,.xls,.txt" @change="fileSelected($event)" />
                  <div v-if="assignment.file && assignment.file.name">
                    <span>{{ assignment.file.name }}</span>
                  </div>
                  <div v-else><span>Choose file</span> or drop file here</div>
                </div>
              </div>
              <div v-if="errorsList.file.length" class="text-danger">
                {{ errorsList.file }}
              </div>
            </div>
          </div>
          <div class="col-12 col-md-3">
            <label>File requirements</label>
            <div class="file-requirements">
              <div class="requirements-list">
                <div>Format: <strong>CSV, TXT, XLSX</strong></div>
                <div>Max size: <strong>50mb</strong></div>
              </div>
              <a
                v-b-tooltip="{
                  title:
                    'CSV files support most common delimiters are: a comma\n' +
                    '<code>,</code>, semicolon <code>;</code> and tab <code>\\t</code>',
                }"
                href="https://cdn-pacsortonline.s3.eu-north-1.amazonaws.com/frontend/sample-spreadsheet.txt"
                class="download-sample"
                download="sample-spreadsheet.txt"
              >
                Download TXT example files
              </a>
              <a
                v-b-tooltip="{
                  title:
                    'TXT files support most common delimiters are: a comma\n' +
                    '<code>,</code>, semicolon <code>;</code> and tab <code>\\t</code>',
                }"
                href="https://cdn-pacsortonline.s3.eu-north-1.amazonaws.com/frontend/sample-spreadsheet.csv"
                class="download-sample"
                download="sample-spreadsheet.csv"
              >
                Download CSV example files
              </a>
              <a
                href="https://cdn-pacsortonline.s3.eu-north-1.amazonaws.com/frontend/sample-spreadsheet.xlsx"
                class="download-sample"
                download="sample-spreadsheet.xlsx"
              >
                Download XLSX example file
              </a>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="form-group">
            <div class="shipping-address-heading">
              <div class="label required fs-xl">Shipping address</div>
              <b-button class="ship-to-terminal-btn" @click="fillTerminalAddress">Ship to Terminal</b-button>
            </div>

            <div class="row mt-4 shipping-address-form">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Company</label>
                  <span class="company-name-info">
                    <img src="../../../../assets/img/info-circle-outlined.svg" alt="" class="mr-1" />
                    Insert only, if sending to company
                  </span>
                  <VInput
                    v-model="assignment.item.company_name"
                    :rules="{ required: false, min: 3 }"
                    type="text"
                    vid="item.company_name"
                    name="item.company_name"
                    placeholder="Company"
                    @input="companyNameChange"
                  />
                </div>
                <div class="d-flex flex-row">
                  <div class="form-group pl-0 m-0 col-6 pr-12">
                    <label class="required">First Name</label>
                    <VInput
                      v-model="assignment.item.first_name"
                      type="text"
                      :rules="{ required: true }"
                      vid="item.first_name"
                      name="item.first_name"
                      placeholder="First Name *"
                    />
                  </div>
                  <div class="form-group p-0 m-0 col-6">
                    <label>Last Name</label>
                    <VInput
                      v-model="assignment.item.last_name"
                      type="text"
                      :rules="{ permissible: true }"
                      vid="item.last_name"
                      name="item.last_name"
                      placeholder="Last Name"
                    />
                  </div>
                </div>
                <div class="form-group mb-0">
                  <label class="required">Address 1</label>
                  <VInput
                    v-model="assignment.item.address"
                    :rules="{ required: true, min: 3, max: 30 }"
                    type="text"
                    vid="item.address"
                    name="item.address"
                    placeholder="Client Address 1 *"
                  />
                </div>
                <div class="form-group">
                  <label>Address 2</label>
                  <VInput
                    v-model="assignment.item.secondary_address"
                    :rules="{ required: false, min: 3, max: 30 }"
                    type="text"
                    vid="item.secondary_address"
                    name="item.secondary_address"
                    placeholder="Client Address 2"
                  />
                </div>
                <div class="d-flex flex-row">
                  <div class="form-group mb-0">
                    <label class="required">ZIP</label>
                    <VInput
                      v-model="assignment.item.postal_code"
                      type="text"
                      :rules="{ required: true, zip: assignment.item.country_code }"
                      vid="postal_code"
                      name="item.postal_code"
                      placeholder="ZIP *"
                    />
                  </div>
                  <div class="form-group client-city mb-0">
                    <label :class="{ required: true }">City</label>
                    <VInputDatalist
                      id="city"
                      v-model="assignment.item.city"
                      type="text"
                      :list="cities"
                      :rules="{ required: true }"
                      vid="item.city"
                      name="item.city"
                      placeholder="City *"
                      @input="onCityUpdate"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <div class="form-group">
                    <label class="required">Country</label>
                    <CountryPicker
                      :value="assignment.item.country_code"
                      :disabled="false"
                      vinput-id="country"
                      name="item.country_code"
                      vid="country_code"
                      placeholder="Choose country *"
                      :required="true"
                      @change="onCountryUpdate"
                    />
                  </div>
                  <div class="form-group">
                    <label
                      :class="{
                        required: (isSwedenPicked && assignment.item.phone_number.length === 0) || !isSwedenPicked,
                      }"
                    >
                      Email Address
                    </label>
                    <VInput
                      v-model="assignment.item.email"
                      type="text"
                      vid="item.email"
                      name="item.email"
                      :rules="rules.emailRule"
                      :placeholder="
                        (isSwedenPicked && assignment.item.phone_number.length === 0) || !isSwedenPicked
                          ? 'Email address *'
                          : 'Email address'
                      "
                    />
                  </div>
                  <div class="form-group">
                    <label
                      :class="{
                        required: (isSwedenPicked && assignment.item.email.length === 0) || !isSwedenPicked,
                      }"
                    >
                      SMS-Number
                    </label>
                    <VInput
                      v-model="assignment.item.phone_number"
                      type="text"
                      vid="item.phone_number"
                      name="item.phone_number"
                      :rules="rules.phoneRule"
                      :formatter="numbersOnly"
                      :placeholder="
                        (isSwedenPicked && assignment.item.email.length === 0) || !isSwedenPicked
                          ? 'SMS-Number *'
                          : 'SMS-Number'
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Pieces
            v-model:pieces="assignment.item.pieces"
            :distribution-channel="assignment.distribution_channel"
            :outside-eu="outsideEu"
            :recipient-country="recipientCountry"
            :is-business-recipient="isBusinessRecipient"
          />
        </div>

        <div v-if="![PARCEL, LETTER].includes(assignment.distribution_channel)" class="form-group">
          <div class="label required fs-xl">Choose Service Level</div>
          <div class="row">
            <div class="col-12 col-md-6">
              <input id="service_level_priority" v-model="assignment.service_level" type="radio" value="priority" />
              <label for="service_level_priority" class="radio-group">
                <span class="radio-title">Priority</span>
              </label>
            </div>
            <div class="col-12 col-md-6">
              <input id="service_level_economy" v-model="assignment.service_level" type="radio" value="economy" />
              <label for="service_level_economy" class="radio-group">
                <span class="radio-title">Economy</span>
                <span v-if="showDeliveryTime" class="radio-description"> 1-4 days </span>
              </label>
            </div>
          </div>
        </div>

        <div v-if="assignment.distribution_channel === PARCEL" class="pickup-block">
          <PickupCalendar
            :pickup-error="errorsList.pickup_date"
            :max-period="maxPickupPeriod"
            @select-date="setPickUpDate"
          />

          <b-form-group class="mt-5 pickup-instructions">
            <label>Pickup Instructions</label>
            <div class="input-group">
              <input
                v-model="assignment.pickup_instructions"
                type="text"
                placeholder="Eg. Pickup behind the little small red house"
                class="form-control"
              />
            </div>
          </b-form-group>
        </div>
        <b-form-group>
          <label>Description</label>
          <VInput
            v-model="assignment.description"
            type="text"
            :rules="rules.description"
            vid="customer_reference"
            name="Description"
            placeholder="Description"
          />
        </b-form-group>
        <b-form-group v-if="assignment.assignment_type !== SINGLE">
          <label>Message</label>
          <textarea v-model="assignment.message" class="form-control" placeholder="Message" rows="5" />
        </b-form-group>
        <b-form-group>
          <label class="fs-xl">Invoice Reference</label>
          <VInput
            v-model="assignment.customer_reference"
            type="text"
            :rules="{ required: false }"
            vid="customer_reference"
            name="customer_reference"
            placeholder="Here you can add a reference for your account administrator or billing administrator"
          />
        </b-form-group>
        <div class="form-group mt-4">
          <b-button type="submit" class="btn create-btn btn-green">
            {{ assignmentId ? 'Update Assignment' : 'Create new assigment' }}
          </b-button>
        </div>
      </VeeForm>
      <SourceFileErrorsModal :id="CREATE_ASSIGNMENT_SOURCE_FILE_ERRORS" />
    </div>
    <CreateNewCustomerModal :id="CREATE_NEW_CUSTOMER" />
    <SenderAddressActionModal
      :id="SENDER_ADDRESS_ACTION"
      :address-id="modalAddressId"
      @refresh="fetchSenderAddresses"
    />
    <RequestErrorModal :id="REQUEST_ERROR" />
  </div>
</template>

<script lang="ts">
import type { AssignmentData, ErrorsList } from '@/views/Components/Assignment/AssignmentForm/types'
import type { UserWithLabel } from '@/types/Models/User'
import type { Address } from '@/types/Models/Customer'
import { defaultAssignment } from '@/views/Components/Assignment/AssignmentForm/data'
import { useValidationRules } from '@/views/Components/Assignment/AssignmentForm/composables/useValidationRules'
import { useDistributionChannels } from '@/views/Components/Assignment/AssignmentForm/composables/useDistributionChannels'
import { useOnSubmit } from '@/views/Components/Assignment/AssignmentForm/composables/useOnSubmit'
import { watchDistributionChannelChange } from '@/views/Components/Assignment/AssignmentForm/composables/watch/watchDistributionChannelChange'
import { watchCustomerChange } from '@/views/Components/Assignment/AssignmentForm/composables/watch/watchCustomerChange'
import { useDerivedValues } from '@/views/Components/Assignment/AssignmentForm/composables/useDerivedValues'
import { useCities } from '@/views/Components/Assignment/AssignmentForm/composables/useCities'
import { useOnLifecycle } from '@/views/Components/Assignment/AssignmentForm/composables/useOnLifecycle'
import { useSetterShortcuts } from '@/views/Components/Assignment/AssignmentForm/composables/useSetterShortcuts'
import { ref, reactive, computed, defineComponent, type ComputedRef } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { openModal } from '@/services/Modal'
import { numbersOnly } from '@/services/Helpers'
import { Form as VeeForm, Field } from 'vee-validate'
import { BOX, LETTER, PACKAGE, PARCEL } from '@/const/DistributionChannels'
import { ADDRESS_DELIVERY, SERVICE_POINT_DELIVERY } from '@/const/AssignmentDeliveryTypes'
import { CONSOLIDATE, SINGLE } from '@/const/AssignmentTypes'
import {
  CREATE_ASSIGNMENT_SOURCE_FILE_ERRORS,
  CREATE_NEW_CUSTOMER,
  REQUEST_ERROR,
  SENDER_ADDRESS_ACTION,
} from '@/const/ModalTypes'
import VInput from '@/views/Components/Elements/Form/VInput.vue'
import CustomSelect from '@/views/Components/Elements/Form/CustomSelect.vue'
import CountryPicker from '@/views/Components/Elements/Form/CountryPicker.vue'
import VInputDatalist from '@/views/Components/Elements/Form/VInputDatalist.vue'
import PickupCalendar from '@/views/Components/Assignment/PickupCalendar.vue'
import RequestErrorModal from '@/views/Modals/RequestErrorModal.vue'
import SourceFileErrorsModal from '@/views/Modals/Assignment/SourceFileErrorsModal.vue'
import CreateNewCustomerModal from '@/views/Modals/Customer/CreateNewCustomerModal.vue'
import SenderAddressActionModal from '@/views/Modals/SenderAddress/SenderAddressActionModal.vue'
import Pieces from '@/views/Components/Assignment/Pieces/Pieces.vue'

export default defineComponent({
  name: 'AssignmentForm',
  components: {
    Pieces,
    VeeForm,
    Field,
    VInput,
    CustomSelect,
    CountryPicker,
    VInputDatalist,
    PickupCalendar,
    SenderAddressActionModal,
    CreateNewCustomerModal,
    SourceFileErrorsModal,
    RequestErrorModal,
  },
  setup() {
    const store = useStore()
    const route = useRoute()

    const assignment = ref<AssignmentData>(defaultAssignment())
    const modalAddressId = ref('')
    const customerCanHaveCustomerNumbers = ref(false)

    const errorsList = reactive<ErrorsList>({
      customer_id: '',
      file: '',
      sender_address_id: '',
      pickup_date: false,
    })
    const isFileUpdated = ref(false)
    const clientFilter = (option: any, label: any, search: string) => {
      const temp = search.toLowerCase()
      if (option.company !== null) {
        return option.label.toLowerCase().indexOf(temp) > -1 || option?.company.toLowerCase().indexOf(temp) > -1
      }
      return label
    }

    const userId: ComputedRef<string> = computed(() => store.getters['account/userId'])
    const isStaff: ComputedRef<boolean> = computed(() => store.getters['account/isStaff'])
    const customers: ComputedRef<UserWithLabel[]> = computed(() => store.getters['customers/all'])
    const senderAddresses: ComputedRef<Address[]> = computed(() => store.getters['customers/senderAddresses/get'])
    const assignmentId: ComputedRef<string> = computed(() => route.params.id as string)

    const { outsideEu, recipientCountry, isBusinessRecipient, isSwedenPicked, showDeliveryTime, maxPickupPeriod } =
      useDerivedValues({ assignment })

    const rules = useValidationRules({ assignment, isSwedenPicked })

    const cities = useCities()

    const distributionChannels = useDistributionChannels({ isStaff })

    const fetchSenderAddresses = () => {
      if (isStaff.value) {
        store.dispatch('customers/fetchAll')
      } else {
        store.dispatch('customers/senderAddresses/fetch', userId.value).then(() => {
          assignment.value.sender_address_id = senderAddresses.value.find((el: any) => el.isDefault)?.id || ''
        })
      }
    }

    const fileSelected = (file: any) => {
      ;[assignment.value.file] = file.target.files
      isFileUpdated.value = true
    }

    const openSenderAddressEditModal = (addressId: string) => {
      modalAddressId.value = addressId
      openModal(SENDER_ADDRESS_ACTION, {
        addressId,
        customerId: userId.value,
        canHasCustomerNumbers: customerCanHaveCustomerNumbers.value,
      })
    }

    const submit = useOnSubmit({
      assignment,
      isStaff,
      isFileUpdated,
      errorsList,
    })

    const {
      onCityUpdate,
      onCountryUpdate,
      selectSenderAddress,
      chooseClient,
      fillTerminalAddress,
      setPickUpDate,
      companyNameChange,
    } = useSetterShortcuts({ assignment, errorsList })

    watchDistributionChannelChange({ assignment })
    watchCustomerChange({ assignment, customerCanHaveCustomerNumbers })

    useOnLifecycle({ assignmentId, assignment, fetchSenderAddresses })

    return {
      userId,
      isStaff,
      cities,
      submit,
      openModal,
      numbersOnly,
      onCityUpdate,
      chooseClient,
      clientFilter,
      onCountryUpdate,
      selectSenderAddress,
      fillTerminalAddress,
      fetchSenderAddresses,
      openSenderAddressEditModal,
      companyNameChange,
      setPickUpDate,
      fileSelected,
      rules,
      customers,
      errorsList,
      assignment,
      assignmentId,
      distributionChannels,
      showDeliveryTime,
      maxPickupPeriod,
      senderAddresses,
      modalAddressId,
      isSwedenPicked,
      outsideEu,
      customerCanHaveCustomerNumbers,
      BOX,
      PARCEL,
      LETTER,
      PACKAGE,
      SINGLE,
      CONSOLIDATE,
      ADDRESS_DELIVERY,
      SERVICE_POINT_DELIVERY,
      CREATE_ASSIGNMENT_SOURCE_FILE_ERRORS,
      SENDER_ADDRESS_ACTION,
      CREATE_NEW_CUSTOMER,
      REQUEST_ERROR,
      recipientCountry,
      isBusinessRecipient,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/scss/parts/assignment_form.scss';
</style>
