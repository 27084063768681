<template>
  <div>
    <div class="container mb-5">
      <h2 class="form-title">Create FAQ</h2>
      <VeeForm @submit="createFAQ">
        <Field v-slot="{ field, errors, meta }" name="question" :rules="rules.question">
          <div class="form-group">
            <label class="fs-xl required">Question</label>
            <div class="input-group">
              <input
                v-bind="field"
                v-model="question.question"
                :class="['form-control', getValidationState(meta)]"
                type="text"
                placeholder="Question"
              />
              <div v-if="errors.length" class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </div>
          </div>
        </Field>

        <Field v-slot="{ field, errors, meta }" name="answer" :rules="rules.answer">
          <div class="form-group">
            <label class="fs-xl required">Answer</label>
            <div class="input-group">
              <input
                v-bind="field"
                v-model="question.answer"
                :class="['form-control', getValidationState(meta)]"
                type="text"
                placeholder="Answer"
              />
              <div v-if="errors.length" class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </div>
          </div>
        </Field>

        <div class="form-group mt-4">
          <button type="submit" class="btn create-btn btn-green">Create new question</button>
        </div>
      </VeeForm>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { Form as VeeForm, Field } from 'vee-validate'
import { FAQ_LIST } from '@/types/router'
import { getValidationState } from '@/services/Validation'

export default defineComponent({
  name: 'FAQCreate',
  components: { Field, VeeForm },
  setup() {
    const store = useStore()
    const router = useRouter()
    const question = ref({
      question: '',
      answer: '',
    })

    const rules = {
      question: 'required|min:3',
      answer: 'required|min:3',
    }

    const isStaff = computed(() => store.getters['account/isStaff'])

    const createFAQ = async () => {
      await store.dispatch('faq/createQuestion', question.value).then(() => {
        router.push({ name: FAQ_LIST })
      })
    }

    return {
      question,
      rules,
      isStaff,
      createFAQ,
      getValidationState,
    }
  },
})
</script>
