<template>
  <div class="bg-light">
    <simple-header />
    <div class="page-body">
      <div class="container">
        <div class="d-flex flex-column justify-content-end align-items-center">
          <h1 class="title">404</h1>
          <span class="sub-title">
            Oops! Looks like you ended up in a wrong place.
            <br />
            If you need help finding something contact us.
          </span>
          <router-link :to="toHome" class="home-btn"> Go to home </router-link>
        </div>
      </div>
    </div>
    <LayoutFooter />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { CUSTOMER_HOME_PAGE, LOGIN_PAGE, STAFF_HOME_PAGE } from '@/types/router'
import SimpleHeader from '@/views/Layouts/SimpleHeader.vue'
import LayoutFooter from '@/views/Layouts/LayoutFooter.vue'

export default defineComponent({
  name: 'PageNotFound404',
  components: { LayoutFooter, SimpleHeader },
  setup() {
    const store = useStore()

    const isAuth = computed(() => store.getters['isLoggedIn'])
    const isStaff = computed(() => store.getters['account/isStaff'])

    const toHome = computed(() => {
      if (!isAuth.value) {
        return { name: LOGIN_PAGE }
      }
      return { name: isStaff.value ? STAFF_HOME_PAGE : CUSTOMER_HOME_PAGE }
    })

    return {
      toHome,
    }
  },
})
</script>

<style lang="scss" scoped>
.page-body {
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 0 130px 0;
}
.bg-light {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-image: url('@/assets/img/grey-vector.svg');
}
footer {
  margin-top: auto;
}
.title {
  font-style: normal;
  font-weight: normal;
  font-size: 200px;
  line-height: 100%;
  color: #012b2b;
  margin-bottom: 0;
}
.sub-title {
  display: inline-block;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  text-align: center;
  color: #757575;
  margin: 28px 0 32px 0;
}
.home-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #058880;
  border-radius: 4px;
  width: 135px;
  height: 40px;
  padding: 8px 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #ffffff;
}
</style>
