<template>
  <QueryStringDropdown :options="options" :with-filter="false" :query-param="queryParam" />
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { ACTIVE, ALL, ARCHIVED } from '@/const/ArchiveModes'
import { ARCHIVE_MODE } from '@/const/AssignmentListQueryFilters'
import QueryStringDropdown from '@/views/Components/PageParts/QueryStringDropdown.vue'

export default defineComponent({
  name: 'ArchiveModeFilter',
  components: { QueryStringDropdown },
  setup() {
    const queryParam = computed(() => ARCHIVE_MODE)

    const options = computed(() => [
      { value: ACTIVE, text: 'Active' },
      { value: ARCHIVED, text: 'Archived' },
      { value: ALL, text: 'Active + Archived' },
    ])

    return {
      queryParam,
      options,
    }
  },
})
</script>
