<template>
  <BaseLabel
    v-if="customCarriersType"
    :id="labelId"
    :short-text="shortText"
    :tooltip-text="tooltipText"
    :class-name="className"
  />
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import BaseLabel from '@/views/Components/Labels/BaseLabel.vue'
import type { ListAssignment } from '@/types/Models/Assignment'

export default defineComponent({
  name: 'AssignmentCustomCarriers',
  components: { BaseLabel },
  props: {
    assignment: {
      type: Object as () => ListAssignment,
      required: true,
    },
  },
  setup(props) {
    const customCarriersType = computed(() => {
      const { user, hasCustomPriorities } = props.assignment
      if (hasCustomPriorities) {
        return 'Assignment carriers'
      }
      if (user && user.hasCustomPriorities) {
        return 'Customer carriers'
      }
      return null
    })

    const labelId = computed(() => `assignment-custom-carriers-${props.assignment.id}`)
    const shortText = computed(() => 'CC')
    const tooltipText = computed(() => `Custom carriers (${customCarriersType.value}) are used`)
    const className = computed(() => 'custom-carriers')

    return {
      customCarriersType,
      labelId,
      shortText,
      tooltipText,
      className,
    }
  },
})
</script>
