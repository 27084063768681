<template>
  <modal-wrap
    :id="id"
    :name="modalName"
    :header-class="'border-0 service-level-header'"
    :body-class="'service-level-modal'"
    :hide-footer="false"
    :hide-header-close="true"
    size="md"
  >
    <template #modal-title>
      Available carriers
      <span>Several carriers can be selected.</span>
    </template>
    <template #default>
      <h2 v-if="economyType.length > 0 || priorityType.length > 0">Packets</h2>
      <div class="raw d-flex">
        <div class="col col-6 no-padding">
          <h3>Priority carrier</h3>
          <ul class="carrier-group mb-0">
            <li v-for="priority in priorityType" :key="priority.id" class="carrier-item">
              <div class="form-check mb-0">
                <input
                  :id="'priority-' + priority.id"
                  v-model="priority.active"
                  class="form-check-input"
                  type="checkbox"
                  @change="changeActive(priority)"
                />
                <label :for="'priority-' + priority.id" class="form-check-label">
                  {{ priority.name }}
                </label>
              </div>
            </li>
          </ul>
        </div>

        <div class="col col-6 no-padding">
          <h3>Economy carrier</h3>
          <ul class="carrier-group mb-0">
            <li v-for="economy in economyType" :key="economy.id" class="carrier-item">
              <div class="form-check mb-0">
                <input
                  :id="'economy-' + economy.id"
                  v-model="economy.active"
                  class="form-check-input"
                  type="checkbox"
                  @change="changeActive(economy)"
                />
                <label :for="'economy-' + economy.id" class="form-check-label">
                  {{ economy.name }}
                </label>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <h2 v-if="economyTypeParcel.length > 0 || priorityTypeParcel.length > 0">Parcels</h2>
      <div v-if="economyTypeParcel.length > 0 || priorityTypeParcel.length > 0" class="raw d-flex">
        <div v-if="priorityTypeParcel.length > 0" class="col col-6 no-padding">
          <h3>Priority carrier</h3>
          <ul class="carrier-group mb-0">
            <li v-for="priority in priorityTypeParcel" :key="priority.id" class="carrier-item">
              <div class="form-check mb-0">
                <input
                  :id="'priority-parcel-' + priority.id"
                  v-model="priority.active"
                  class="form-check-input"
                  type="checkbox"
                  @change="changeActive(priority)"
                />
                <label :for="'priority-parcel-' + priority.id" class="form-check-label">
                  {{ priority.name }}
                </label>
              </div>
            </li>
          </ul>
        </div>

        <div v-if="economyTypeParcel.length > 0" class="col col-6 no-padding">
          <h3>Economy carrier</h3>
          <ul class="carrier-group mb-0">
            <li v-for="economy in economyTypeParcel" :key="economy.id" class="carrier-item">
              <div class="form-check mb-0">
                <input
                  :id="'economy-parcel-' + economy.id"
                  v-model="economy.active"
                  class="form-check-input"
                  type="checkbox"
                  @change="changeActive(economy)"
                />
                <label :for="'economy-parcel-' + economy.id" class="form-check-label">
                  {{ economy.name }}
                </label>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <h2 v-if="economyTypeBox.length > 0 || priorityTypeBox.length > 0">Parcel lockers</h2>
      <div v-if="economyTypeBox.length > 0 || priorityTypeBox.length > 0" class="raw d-flex">
        <div v-if="priorityTypeBox.length > 0" class="col col-6 no-padding">
          <h3>Priority carrier</h3>
          <ul class="carrier-group mb-0">
            <li v-for="priority in priorityTypeBox" :key="priority.id" class="carrier-item">
              <div class="form-check mb-0">
                <input
                  :id="'priority-box-' + priority.id"
                  v-model="priority.active"
                  class="form-check-input"
                  type="checkbox"
                  @change="changeActive(priority)"
                />
                <label :for="'priority-box-' + priority.id" class="form-check-label">
                  {{ priority.name }}
                </label>
              </div>
            </li>
          </ul>
        </div>

        <div v-if="economyTypeBox.length > 0" class="col col-6 no-padding">
          <h3>Economy carrier</h3>
          <ul class="carrier-group mb-0">
            <li v-for="economy in economyTypeBox" :key="economy.id" class="carrier-item">
              <div class="form-check mb-0">
                <input
                  :id="'economy-box-' + economy.id"
                  v-model="economy.active"
                  class="form-check-input"
                  type="checkbox"
                  :value="true"
                  @change="changeActive(economy)"
                />
                <label :for="'economy-box-' + economy.id" class="form-check-label">
                  {{ economy.name }}
                </label>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <b-alert
        :model-value="isFinishedStatus"
        variant="secondary"
        class="alert-info-danger d-flex align-items-center mr-3 mb-0 mt-2 w-100"
      >
        <span><b>Important: </b>You cannot change priority for this assignment</span>
      </b-alert>
    </template>
    <template #modal-footer>
      <b-button size="md" class="btn-grey mr-3" @click="closeModal"> Cancel </b-button>
      <b-button
        variant="success"
        size="md"
        class="btn-green"
        :disabled="isDisabled || isFinishedStatus"
        @click="savePriorities"
      >
        Apply
      </b-button>
    </template>
  </modal-wrap>
</template>

<script lang="ts">
import { useStore } from 'vuex'
import { notify } from '@kyvg/vue3-notification'
import { openModal } from '@/services/Modal'
import { defineComponent, ref, computed, onMounted, type Ref } from 'vue'
import { UPDATE_PRIORITIES } from '@/store/modules/priority'
import ModalWrap from '@/views/Modals/ModalWrap.vue'
import type { Priority } from '@/types/Models/Priority'
import { none } from '@/services/Helpers'

export default defineComponent({
  name: 'CarrierPriorityModal',
  components: { ModalWrap },
  props: {
    type: {
      type: String,
      required: true,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    entityId: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const store = useStore()
    const modalName = props.id

    const economyType: Ref<Priority[]> = ref([])
    const priorityType: Ref<Priority[]> = ref([])
    const economyTypeParcel: Ref<Priority[]> = ref([])
    const priorityTypeParcel: Ref<Priority[]> = ref([])
    const economyTypeBox: Ref<Priority[]> = ref([])
    const priorityTypeBox: Ref<Priority[]> = ref([])

    const changed = computed(() => store.getters['priority/changed'])
    const isDisabled = computed(() => changed.value.length === 0)
    const isFinishedStatus = computed(() => store.state.priority.isFinishedStatus)

    const changeActive = (priority: Priority) => {
      store.commit(`priority/${UPDATE_PRIORITIES}`, [{ ...priority }])
    }

    const savePriorities = () => {
      store.dispatch('priority/save', props.entityId).then(() => {
        notify({ group: 'success', text: 'Priority have been updated' })
        store.dispatch('vueNextModal/closeModal', modalName)
      })
    }

    const closeModal = () => {
      if (changed.value.length === 0) {
        store.dispatch('vueNextModal/closeModal', modalName)
        return
      }
      openModal('confirmWrap', {
        title: 'There are unsaved changes',
        message: 'Are you sure you want to cancel?',
        onConfirm: () => {
          store.dispatch('vueNextModal/closeModal', modalName)
        },
      })
    }

    onMounted(() => {
      store.commit('priority/SET_TYPE', props.type)
      store
        .dispatch('priority/fetch', props.entityId)
        .then((priorities) => {
          economyType.value = priorities
            .filter(
              (el: Priority) =>
                el.serviceLevel === 'economy' && (el.distributionChannel === 'package' || !el.distributionChannel),
            )
            .sort((prev: Priority, next: Priority) => prev.order - next.order)
            .map((el: Priority) => ({ ...el, active: !!el.active }))

          priorityType.value = priorities
            .filter(
              (el: Priority) =>
                el.serviceLevel === 'priority' && (el.distributionChannel === 'package' || !el.distributionChannel),
            )
            .sort((prev: Priority, next: Priority) => prev.order - next.order)
            .map((el: Priority) => ({ ...el, active: !!el.active }))

          economyTypeParcel.value = priorities
            .filter((el: Priority) => el.serviceLevel === 'economy' && el.distributionChannel === 'parcel')
            .sort((prev: Priority, next: Priority) => prev.order - next.order)
            .map((el: Priority) => ({ ...el, active: !!el.active }))

          priorityTypeParcel.value = priorities
            .filter((el: Priority) => el.serviceLevel === 'priority' && el.distributionChannel === 'parcel')
            .sort((prev: Priority, next: Priority) => prev.order - next.order)
            .map((el: Priority) => ({ ...el, active: !!el.active }))

          economyTypeBox.value = priorities
            .filter((el: Priority) => el.serviceLevel === 'economy' && el.distributionChannel === 'box')
            .sort((prev: Priority, next: Priority) => prev.order - next.order)
            .map((el: Priority) => ({ ...el, active: !!el.active }))

          priorityTypeBox.value = priorities
            .filter((el: Priority) => el.serviceLevel === 'priority' && el.distributionChannel === 'box')
            .sort((prev: Priority, next: Priority) => prev.order - next.order)
            .map((el: Priority) => ({ ...el, active: !!el.active }))
        })
        .catch(none)
    })

    return {
      changed,
      economyType,
      priorityType,
      economyTypeParcel,
      priorityTypeParcel,
      economyTypeBox,
      priorityTypeBox,
      modalName,
      isDisabled,
      isFinishedStatus,
      changeActive,
      savePriorities,
      closeModal,
    }
  },
})
</script>

<style scoped>
.no-padding {
  padding: 0;
}
.service-level-modal h3 {
  margin-top: 0;
}
.service-level-modal h2 {
  margin-top: 20px;
}
</style>
