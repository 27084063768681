<template>
  <div class="pricing-status">
    <div class="pricing-status__header">
      <b>Status:</b>
      <span class="pricing-status__label" :class="isPricesEmpty">
        {{ setStatus }}
      </span>
    </div>
    <div class="pricing-status__body">
      <div v-if="customerPrices.length > 0" class="pricing-status__update">
        <mdicon name="History" class="mr-1" />
        Last edited {{ toTimeFromNow(lastUpdate) }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from 'vue'
import CustomerPriceProxy from '@/proxies/Price/CustomerPriceProxy'
import { toTimeFromNow } from '@/services/Helpers'

export default defineComponent({
  name: 'DynamicCustomerPricingStatus',
  props: {
    hasCustomPrices: {
      type: Boolean,
      required: true,
      default: false,
    },
    userId: {
      type: String,
      required: true,
      default: '',
    },
  },
  setup(props) {
    const customerPrices = ref<Array<{ updatedAt: string }>>([])

    const isPricesEmpty = computed(() =>
      !props.hasCustomPrices ? 'pricing-status__label_default' : 'pricing-status__label_custom',
    )

    const setStatus = computed(() => (!props.hasCustomPrices ? 'Default' : 'Custom'))

    const lastUpdate = computed(() => {
      if (customerPrices.value.length === 0) return ''
      const prices = customerPrices.value
        .slice()
        .sort((objA, objB) => new Date(objB.updatedAt).getTime() - new Date(objA.updatedAt).getTime())
      return prices[0].updatedAt
    })

    onMounted(async () => {
      const res = await new CustomerPriceProxy().find(props.userId)
      customerPrices.value = res.data
    })

    return {
      customerPrices,
      isPricesEmpty,
      setStatus,
      lastUpdate,
      toTimeFromNow,
    }
  },
})
</script>

<style lang="scss" scoped>
.pricing-status {
  &__header {
    display: flex;
  }
  &__label {
    display: flex;
    align-items: center;
    margin-left: 16px;
    &::before {
      display: inline-block;
      content: '';
      width: 16px;
      height: 16px;
      border-radius: 50%;
      margin-right: 8px;
    }
    &_custom {
      &::before {
        background: #08a762;
      }
    }
    &_default {
      &:before {
        background: #cccccc;
      }
    }
  }
  &__update {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 0;
    color: #757575;
    margin-top: 18.5px;
  }
}
</style>
