<template>
  <div>
    <div class="d-flex align-items-center mb-4">
      <router-link :to="assignmentPage" class="mr-2">
        <a class="back-to">
          <mdicon name="ChevronLeft" />
        </a>
      </router-link>
      <h3 class="m-0">Prices for assignment {{ assignment.reference }}</h3>
    </div>
    <div class="d-flex justify-content-between mb-4">
      <StripeBtn @click="openModal(carrierPriorityModal)"> Available carriers </StripeBtn>
      <div class="d-flex align-items-center justify-content-end">
        <span class="d-inline-block mr-2">Reset everything to default</span>
        <StripeBtn :disabled="isFinishedStatus" @click="resetEverything"> Reset </StripeBtn>
      </div>
    </div>
    <BasePriceTable :prices="prices" :default-prices="defaultPrices" @update-price="updatePrice" />
    <PageFooter @save-prices="savePrices" />
    <CarrierPriorityModal :id="carrierPriorityModal" :entity-id="assignmentId" type="assignment" />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onBeforeMount, ref, onMounted, defineAsyncComponent } from 'vue'
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router'
import { openModal, registerModals } from '@/services/Modal'
import { useStore } from 'vuex'
import { notify } from '@kyvg/vue3-notification'
import { CARRIER_PRIORITY_MODAL } from '@/const/ModalTypes'
import { STAFF_ASSIGNMENTS_SINGLE } from '@/types/router'
import { CANCELLED, COMPLETE } from '@/const/AssignmentStatuses'
import { DEFAULT_LOADER } from '@/const/LoadersNames'
import { ASSIGNMENT } from '@/const/PriceTypes'
import StripeBtn from '@/views/Components/Elements/Buttons/StripeBtn.vue'
import CarrierPriorityModal from '@/views/Modals/Assignment/CarrierPriorityModal.vue'
import { none } from '@/services/Helpers'

export default defineComponent({
  name: 'AssignmentPrices',
  components: {
    PageFooter: defineAsyncComponent(() => import('@/views/Components/PageParts/PageFooter.vue')),
    BasePriceTable: defineAsyncComponent(() => import('@/views/Staff/BasePrice/BasePriceTable.vue')),
    StripeBtn,
    CarrierPriorityModal,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const defaultPrices = ref([])

    const prices = computed(() => store.getters['prices/all'])
    const changedPrices = computed(() => store.getters['prices/changedPrices'])
    const assignment = computed(() => store.getters['assignment/get'])
    const carrierPriorityModal = CARRIER_PRIORITY_MODAL + 'assignment'

    const assignmentId = computed(() => String(route.params.id))
    const isFinishedStatus = computed(() =>
      assignment.value ? assignment.value.status === COMPLETE || assignment.value.status === CANCELLED : false,
    )
    const assignmentPage = computed(() => ({
      name: STAFF_ASSIGNMENTS_SINGLE,
      params: { id: assignmentId.value },
    }))

    onBeforeMount(() => {
      store.dispatch('assignment/fetch', assignmentId.value).catch(none)
      store.commit('prices/SET_TYPE', ASSIGNMENT)
      store
        .dispatch('prices/fetchDefault', assignmentId.value)
        .then((res) => {
          defaultPrices.value = res
          store
            .dispatch('prices/fetch', assignmentId.value)
            .then((result) => {
              if (result.length === 0) {
                store.commit('prices/SET_PRICES', defaultPrices.value)
              }
            })
            .catch(none)
        })
        .catch(none)
    })

    onMounted(() => {
      registerModals(carrierPriorityModal)
    })

    onBeforeRouteLeave((to, from, next) => {
      if (changedPrices.value.length > 0) {
        openModal('confirmWrap', {
          title: 'There are unsaved changes',
          message: 'Are you sure you want to cancel?',
          onConfirm: () => {
            store.dispatch('prices/reset')
            next(true)
          },
          onCancel: () => {
            next(false)
          },
        })
      } else {
        next()
      }
    })

    const updatePrice = (price: any) => {
      store.dispatch('prices/updatePrices', price)
    }

    const savePrices = () => {
      store.dispatch('ui/loaderShow', { name: DEFAULT_LOADER })
      store
        .dispatch('prices/save', assignmentId.value)
        .then(() => {
          store.dispatch('ui/loaderHide', { name: DEFAULT_LOADER })
          notify({ group: 'success', text: 'Prices have been updated' })
        })
        .catch(() => {
          store.dispatch('ui/loaderHide', { name: DEFAULT_LOADER })
          notify({ group: 'error', text: 'Price update error' })
        })
    }

    const resetEverything = () => {
      openModal('confirmWrap', {
        title: 'Reset custom prices',
        message: 'Are you sure you want to reset everything to default?',
        onConfirm: () => {
          store.dispatch('prices/reset', assignmentId.value)
          router.push(assignmentPage.value)
        },
      })
    }

    return {
      prices,
      assignment,
      assignmentId,
      defaultPrices,
      assignmentPage,
      isFinishedStatus,
      carrierPriorityModal,
      resetEverything,
      updatePrice,
      savePrices,
      openModal,
    }
  },
})
</script>
