<template>
  <div>
    <b-button v-b-modal="'buffering-modal-' + user.id" class="p-2 fz-inherit" variant="outline-secondary">
      <div class="d-flex align-items-center">
        <mdicon name="CogOutline" class="color-black mr-2" size="36" />
        <span class="color-black">Configure</span>
      </div>
    </b-button>

    <b-modal :id="'buffering-modal-' + user.id" hide-header hide-footer centered>
      <div class="d-flex justify-content-between align-items-center">
        <span>
          Buffering is
          <span v-if="bufferingEnabled" class="text-success"> Enabled </span>
          <span v-else class="text-danger"> Disabled </span>
        </span>
        <b-button v-if="bufferingEnabled" variant="danger" class="ml-2" @click="toggleBufferingEnabled">
          Disable
        </b-button>
        <b-button v-else variant="success" class="ml-2" @click="toggleBufferingEnabled"> Enable </b-button>
      </div>

      <div v-if="bufferingEnabled">
        <div class="form-group mt-3">
          <label>Cutoff Time:</label>
          <input
            id="bufferingOptionsCutoffTime"
            v-model="bufferingOptions.cutoffTime"
            type="time"
            max="100"
            class="form-control"
            name="bufferingOptionsCutoffTime"
            placeholder=" "
          />
        </div>

        <div class="form-group mt-3">
          <label>Items Count:</label>
          <input
            id="bufferingOptionsItemsCount"
            v-model="bufferingOptions.itemsCount"
            type="number"
            min="0"
            class="form-control"
            name="bufferingOptionsItemsCount"
            placeholder=" "
          />
        </div>

        <div class="form-group mt-3">
          <label>Minutes from create date:</label>
          <input
            id="bufferingOptionsMinutesDiff"
            v-model="bufferingOptions.minutesDiff"
            type="number"
            min="0"
            class="form-control"
            name="bufferingOptionsMinutesDiff"
            placeholder=" "
          />
        </div>

        <div class="mt-3 text-center">
          <b-button @click="sendRequest"> Save Settings </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, type PropType, type Ref } from 'vue'
import { useStore } from 'vuex'
import { notify } from '@kyvg/vue3-notification'
import type { BufferingOptions, RequestUser, User } from '@/types/Models/User'

export default defineComponent({
  name: 'BufferingModal',
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore()
    const bufferingEnabled: Ref<boolean> = ref(props.user.isBufferingEnabled)
    const bufferingOptions: Ref<BufferingOptions> = ref({
      cutoffTime: props.user.bufferingOptions?.cutoffTime || '',
      itemsCount: props.user.bufferingOptions?.itemsCount || '',
      minutesDiff: props.user.bufferingOptions?.minutesDiff || '',
    })

    const toggleBufferingEnabled = async () => {
      bufferingEnabled.value = !bufferingEnabled.value
      if (bufferingEnabled.value) {
        bufferingOptions.value = {
          cutoffTime: '',
          itemsCount: '',
          minutesDiff: 15,
        }
      }
      await sendRequest()
    }

    const sendRequest = async () => {
      const data: Partial<RequestUser> = {
        isBufferingEnabled: bufferingEnabled.value,
        bufferingOptions: bufferingEnabled.value
          ? {
              cutoffTime: bufferingOptions.value.cutoffTime ? bufferingOptions.value.cutoffTime.toString() : null,
              itemsCount: bufferingOptions.value.itemsCount
                ? parseInt(bufferingOptions.value.itemsCount.toString())
                : null,
              minutesDiff: bufferingOptions.value.minutesDiff
                ? parseInt(bufferingOptions.value.minutesDiff.toString())
                : null,
            }
          : null,
      }
      await store.dispatch('customers/update', { id: props.user.id, data }).catch(() => {
        notify({
          group: 'error',
          text: 'Failed to update settings',
        })
      })
    }

    return {
      bufferingEnabled,
      bufferingOptions,
      toggleBufferingEnabled,
      sendRequest,
    }
  },
})
</script>
