<template>
  <div class="main__search-field">
    <b-input-group>
      <template #prepend>
        <div class="input-group-prepend">
          <button class="bg-white" @click="search">
            <Tooltip text="Click search icon or press enter to search">
              <SearchIcon class="text-secondary" />
            </Tooltip>
          </button>
        </div>
      </template>
      <input
        id="searchElement"
        ref="filter"
        v-model="query"
        :placeholder="placeholder"
        type="text"
        autocomplete="off"
        name="query"
        class="form-control"
        @keyup="search"
      />
      <template #append>
        <div class="input-group-append d-flex">
          <button
            v-if="query.length > 0"
            class="mr-1 clear d-flex justify-content-center align-items-center clear-button"
            @click="clear"
          >
            x
          </button>
        </div>
      </template>
    </b-input-group>
    <!-- prevent from autofill, autocomplete="off" doesnt work-->
    <input ref="email" type="text" name="email" class="form-control autofill" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import SearchIcon from '@/views/Components/Elements/Icons/SearchIcon.vue'
import Tooltip from '@/views/Components/Elements/Tooltip.vue'

export default defineComponent({
  name: 'Search',
  components: {
    Tooltip,
    SearchIcon,
  },
  props: {
    placeholder: {
      type: String,
      default: 'Search...',
    },
  },
  emits: ['searchTriggered'],
  setup(_, { emit }) {
    const show = ref(false)
    const query = ref('')

    const search = () => {
      emit('searchTriggered', query.value)
    }

    const clear = () => {
      show.value = false
      query.value = ''
      emit('searchTriggered', query.value)
    }

    return {
      show,
      query,
      search,
      clear,
    }
  },
})
</script>

<style scoped lang="scss">
.main__search-field {
  .form-control {
    border: 1px solid #c8c8c8;
    border-left: 0;
    padding-left: 5px;
    z-index: 0;
  }

  .input-group-text {
    border: 1px solid #c8c8c8;
    background: #ffffff;
  }

  input {
    height: 40px;
    border-right: 0 !important;
  }

  .clear {
    padding: 2px;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    margin-top: 3px;
  }

  .clear-button {
    font-size: 12px;
    width: 16px;
    height: 16px;
  }
}
.autofill {
  max-width: 1px;
  max-height: 1px;
  padding: 0;
  opacity: 0;
}
</style>
