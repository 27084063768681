<template>
  <div class="optimization-report-header d-flex justify-content-between">
    <div class="optimization-report-title">
      <div class="d-flex mb-2">
        <div class="d-flex">
          <h1>
            <router-link :to="assigmentPage">
              <a class="back-to position-absolute"><mdicon name="ChevronLeft" /></a>
            </router-link>
          </h1>
          <span class="ml-4 report-title"> Optimization report </span>
        </div>
        <InfoAlert v-show="!!alertData.class" class="ml-3" :class="alertData.class">
          {{ alertData.text }}
        </InfoAlert>
      </div>
      <p class="ml-4 sub-title">
        {{ subtitle }}
      </p>
    </div>

    <ul class="optimization-report-option mb-0">
      <li>
        <b-button class="file-btn" :disabled="!sourceFile" :href="sourceFile" :download="true">
          <Tooltip :text="tooltipText">
            <mdicon name="Download" />
          </Tooltip>
        </b-button>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, type ComputedRef } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { STAFF_ASSIGNMENTS_SINGLE } from '@/types/router'
import { REPORT_ACCEPTED, REPORT_DENIED, REPORT_RELEASED } from '@/const/AssignmentStatuses'
import Tooltip from '@/views/Components/Elements/Tooltip.vue'
import InfoAlert from '@/views/Components/PageParts/InfoAlert.vue'
import type { AssignmentStoreState } from '@/types/Store'
import type { RouterLink } from '@/types/Components/Router'

export default defineComponent({
  name: 'OptimizationReportHeader',
  components: {
    Tooltip,
    InfoAlert,
  },
  setup() {
    const store = useStore()
    const route = useRoute()

    const assignment: ComputedRef<AssignmentStoreState> = computed(() => store.getters['assignment/get'])
    const isStaff: ComputedRef<boolean> = computed(() => store.getters['account/isStaff'])

    const assigmentPage: ComputedRef<RouterLink> = computed(() => ({
      name: STAFF_ASSIGNMENTS_SINGLE,
      params: { id: route.params.id },
    }))

    const sourceFile: ComputedRef<string> = computed(() => assignment.value?.optimisationReportUrl || '')

    const tooltipText: ComputedRef<string> = computed(() => (sourceFile.value ? 'Download Report' : ''))

    const alertData: ComputedRef<{ class: string; text: string }> = computed(() => {
      const { status } = assignment.value
      switch (status) {
        case REPORT_DENIED:
          return {
            class: 'danger',
            text: 'Report denied',
          }
        case REPORT_ACCEPTED:
          return {
            class: 'success',
            text: 'Report accepted',
          }
        case REPORT_RELEASED:
          return {
            class: 'secondary',
            text: 'Report sent',
          }
        default:
          return {
            class: '',
            text: '',
          }
      }
    })

    const subtitle: ComputedRef<string> = computed(() => {
      return isStaff.value
        ? 'You can send this report to the client for approval or cancel to make changes to the assignment and create a new report.'
        : 'You can come back to the report later, it isn’t necessary to make the decision right now 🙂'
    })

    return {
      assignment,
      isStaff,
      assigmentPage,
      sourceFile,
      tooltipText,
      alertData,
      subtitle,
    }
  },
})
</script>

<style lang="scss" scoped>
.optimization-report-option li {
  margin-left: 18px;
  display: inline-block;
  vertical-align: top;
}

.optimization-report-option button,
.optimization-report-option svg {
  width: 20px;
  height: 20px;
}
.file-link {
  color: black;
}
.cursor-pointer {
  cursor: pointer;
}
.file-btn {
  color: black;
  background: none;
  border: none;
  opacity: 1;
  padding: 0;
}

.sub-title {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  color: #151515;
}

.report-title {
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 150%;
  color: #151515;
}

.info-alert {
  display: flex;
  align-items: center;
  &.secondary {
    background: #e9e9e7;
  }
}
</style>
