export const EDIT_USER_MODAL = 'edit_user_modal'
export const EDIT_USER_GENERAL_INFO_MODAL = 'edit_user_general_info_modal'
export const EDIT_USER_ADDRESS_MODAL = 'edit_user_address_modal'
export const EDIT_DMT_FEE_MODAL = 'edit_dmt_fee_modal'
export const EDIT_PRICE_WEIGHT = 'EDIT_PRICE_WEIGHT'
export const ATTACH_SOURCE_FILE_MODAL = 'ATTACH_SOURCE_FILE_MODAL'
export const CREATE_ASSIGNMENT_SOURCE_FILE_ERRORS = 'CREATE_ASSIGNMENT_SOURCE_FILE_ERRORS'
export const CARRIER_PRIORITY_MODAL = 'CARRIER_PRIORITY_MODAL'
export const DOWNLOAD_LABELS = 'DOWNLOAD_LABELS'
export const DOWNLOAD_LABELS_WARNING = 'DOWNLOAD_LABELS_WARNING'
export const CREATE_NEW_CUSTOMER = 'CREATE_NEW_CUSTOMER'
export const CREATE_PACKING_SLIP = 'CREATE_PACKING_SLIP'
export const PACKING_SLIPS = 'PACKING_SLIPS'
export const PACKING_SLIP = 'PACKING_SLIP'
export const PACKING_SLIP_PREVIEW = 'PACKING_SLIP_PREVIEW'
export const PACKING_SLIP_EDIT = 'PACKING_SLIP_EDIT'
export const SENDER_ADDRESSES = 'SENDER_ADDRESSES'
export const SENDER_ADDRESS_ACTION = 'SENDER_ADDRESS_ACTION'
export const INTEGRATIONS = 'INTEGRATIONS'
export const INTEGRATION_DETAILS = 'INTEGRATION_DETAILS'
export const INTEGRATION_ORDERS = 'INTEGRATION_ORDERS'
export const WOO_COMMERCE_KEYS = 'WOO_COMMERCE_KEYS'
export const SHOPIFY_WEBHOOK_SETTINGS = 'SHOPIFY_WEBHOOK_SETTINGS'
export const ONGOING_WEBHOOK_SETTINGS = 'ONGOING_WEBHOOK_SETTINGS'
export const REQUEST_ERROR = 'REQUEST_ERROR'
export const CONFIRM_REPORT = 'CONFIRM_REPORT'
