import { LETTER, PARCEL } from '@/const/DistributionChannels'
import { LetterDimensions } from '@/views/Components/Assignment/Pieces/validation/letter-dimensions'
import { PackageDimensions } from '@/views/Components/Assignment/Pieces/validation/package-dimensions'
import { ParcelBusinessDimensions } from '@/views/Components/Assignment/Pieces/validation/parcel-business-dimensions'
import { ParcelConsumerDimensions } from '@/views/Components/Assignment/Pieces/validation/parcel-consumer-dimensions'
import type { DimensionsRules } from '@/views/Components/Assignment/Pieces/validation/dimensions-rules'

type Options = {
  distributionChannel: string
  recipientCountry: string
  isBusinessRecipient: boolean
}

export const rulesFactory = (options: Options): DimensionsRules => {
  switch (true) {
    case options.distributionChannel === LETTER:
      return new LetterDimensions()
    case options.distributionChannel === PARCEL:
      return options.isBusinessRecipient ? new ParcelBusinessDimensions() : new ParcelConsumerDimensions()
    default:
      return new PackageDimensions(options.recipientCountry)
  }
}
