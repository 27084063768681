/* ============
 * Account Transformer
 * ============
 *
 * The transformer for the account.
 */

import type { Account } from '@/types/Models/Account'

export default class AccountTransformer {
  /**
   * Method used to transform a fetched account.
   */
  static fetch(account: Account): Account {
    return {
      id: account.id,
      type: account.type,
      clientType: account.clientType,
      firstName: account.firstName,
      lastName: account.lastName,
      email: account.email,
      clientNumber: account.clientNumber,
      phoneNumber: account.phoneNumber,
      contractParty: account.contractParty,
      shortName: account.shortName,
      company: account.company,
      createdAt: account.createdAt,
      updatedAt: account.updatedAt,
      salesman: account.salesman,
      hidePrice: account.hidePrice,
      canHasCustomerNumbers: account.canHasCustomerNumbers,
    }
  }

  /**
   * Method used to transform a send account.
   */
  static send(account: Account): Account {
    return {
      id: account.id,
      type: account.type,
      clientType: account.clientType,
      firstName: account.firstName,
      lastName: account.lastName,
      email: account.email,
      clientNumber: account.clientNumber,
      phoneNumber: account.phoneNumber,
      contractParty: account.contractParty,
      shortName: account.shortName,
      company: account.company,
      createdAt: account.createdAt,
      updatedAt: account.updatedAt,
      salesman: account.salesman,
      hidePrice: account.hidePrice,
      canHasCustomerNumbers: account.canHasCustomerNumbers,
    }
  }
}
