<template>
  <div class="container-fluid">
    <div class="d-flex justify-content-between mb-3">
      <div>
        <StripeBtn @click="openModal(carrierPriorityModal)"> Available carriers </StripeBtn>
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <span class="d-inline-block mr-2">Reset everything to default</span>
        <StripeBtn @click="resetEverything(senderAddressId)"> Reset </StripeBtn>
      </div>
    </div>
    <BasePriceTable :prices="prices" :default-prices="defaultPrices" @update-price="updatePrice" />
    <PageFooter @save-prices="savePrices(selectedSenderAddress.id)" />
    <CarrierPriorityModal
      v-if="senderAddressId"
      :id="carrierPriorityModal"
      :entity-id="senderAddressId"
      type="sender_address"
    />
  </div>
</template>

<script lang="ts">
import type { Address } from '@/types/Models/Customer'
import type { BasePrice } from '@/types/Models/BasePrice'
import {
  defineComponent,
  computed,
  onBeforeMount,
  onBeforeUnmount,
  ref,
  onMounted,
  watch,
  defineAsyncComponent,
  type Ref,
  type ComputedRef,
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { notify } from '@kyvg/vue3-notification'
import { none } from '@/services/Helpers'
import { openModal, registerModals } from '@/services/Modal'
import { CARRIER_PRIORITY_MODAL } from '@/const/ModalTypes'
import { SENDER_ADDRESS } from '@/const/PriceTypes'
import { DEFAULT_LOADER } from '@/const/LoadersNames'
import { STAFF_CUSTOMERS_LIST } from '@/types/router'
import StripeBtn from '@/views/Components/Elements/Buttons/StripeBtn.vue'

export default defineComponent({
  name: 'SenderAddressPrices',
  components: {
    StripeBtn,
    PageFooter: defineAsyncComponent(() => import('@/views/Components/PageParts/PageFooter.vue')),
    BasePriceTable: defineAsyncComponent(() => import('@/views/Staff/BasePrice/BasePriceTable.vue')),
    CarrierPriorityModal: defineAsyncComponent(() => import('@/views/Modals/Assignment/CarrierPriorityModal.vue')),
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const defaultPrices: Ref<BasePrice[]> = ref([])
    const carrierPriorityModal: string = CARRIER_PRIORITY_MODAL + 'sender_address'

    const customerId: Ref<string> = computed(() => String(route.params.id))
    const selectedSenderAddress: Ref<Address> = computed(
      () => store.getters['customers/senderAddresses/getSelectedSenderAddress'] || {},
    )
    const senderAddressId: ComputedRef<string> = computed(() => selectedSenderAddress.value?.id || '')
    const prices: ComputedRef<BasePrice[]> = computed(() => store.getters['prices/all'])

    onBeforeMount(() => {
      store.dispatch('ui/loaderShow', { name: DEFAULT_LOADER })
      store.dispatch('customers/fetch', customerId.value).catch(none)
      store.dispatch('customers/senderAddresses/fetch', customerId.value).catch(none)
      store.dispatch('customers/fetchAll').catch(none)
      store.commit('prices/SET_TYPE', SENDER_ADDRESS)

      fetchSenderAddress()
    })

    onBeforeUnmount(() => {
      store.dispatch('prices/resetState')
    })

    onMounted(() => {
      registerModals(carrierPriorityModal)
    })

    const fetchSenderAddress = () => {
      if (senderAddressId.value) {
        store
          .dispatch('prices/fetchDefault', senderAddressId.value)
          .then((res) => {
            defaultPrices.value = res
            store
              .dispatch('prices/fetch', senderAddressId.value)
              .then((result) => {
                if (result.length === 0) {
                  store.commit('prices/SET_PRICES', defaultPrices.value)
                }
              })
              .catch(none)
          })
          .catch(none)
      }
    }

    const updatePrice = (price: any) => {
      store.dispatch('prices/updatePrices', price).catch(none)
    }

    const savePrices = (id?: string) => {
      if (!id) return
      store.dispatch('ui/loaderShow', { name: DEFAULT_LOADER })
      store
        .dispatch('prices/save', id)
        .then(() => {
          store.dispatch('ui/loaderHide', { name: DEFAULT_LOADER })
          notify({ group: 'success', text: 'Prices have been updated' })
        })
        .catch(() => {
          store.dispatch('ui/loaderHide', { name: DEFAULT_LOADER })
          notify({ group: 'error', text: 'Price update error' })
        })
    }

    const resetEverything = (id: string) => {
      openModal('confirmWrap', {
        title: 'Reset custom prices',
        message: 'Are you sure you want to reset everything to default?',
        onConfirm: () => {
          store.dispatch('prices/reset', id).catch(none)
          router.push({ name: STAFF_CUSTOMERS_LIST })
        },
      })
    }

    watch(senderAddressId, fetchSenderAddress)

    return {
      prices,
      defaultPrices,
      carrierPriorityModal,
      selectedSenderAddress,
      senderAddressId,
      resetEverything,
      updatePrice,
      savePrices,
      openModal,
    }
  },
})
</script>

<style lang="scss" scoped>
.sender-address-prices {
  &-header {
    min-height: 70px;
    background: white;
  }
}
</style>
