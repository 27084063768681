<template>
  <div class="privacy">
    <h1 class="privacy-title">How to correctly create an electronic proforma invoice (customs declaration)</h1>
    <div>
      <div>
        <p class="privacy-text">
          If you are shipping outside of the EU a prompt will appear for you to fill out the information for the
          proforma invoice electronically in the course of creating the shipment for your goods.
        </p>
        <p class="privacy-text">
          In the receiving country, customs agents will need the proforma invoice in order to authenticate the value of
          the goods in question.
        </p>
        <p class="privacy-text">
          The electronic information entered will be transmitted to the carrier. You are still required to attach the
          correct number of copies (regulated by the receiver country) of the proforma invoice to the outside of the
          container of your goods/shipment.
        </p>
      </div>
      <div class="privacy-section">
        <h2 class="privacy-sub-title">Correctly Filling out the Invoice</h2>
        <p class="privacy-text">
          It is important the proceeding information be filled out correctly for each category within the shipment.
        </p>
        <div class="sub-section">
          <h3 class="privacy-sub-point">Select a Category</h3>
          <p class="privacy-text">Pick the corresponding category the items you are shipping fall under.</p>
          <p class="privacy-text">
            Picking Sales of Goods or Other, will generate the sales invoice you will require for your shipment.
          </p>
          <p class="privacy-text">
            If any other category is chosen, a proforma invoice will be generated, therefore it is imperative your
            selection be the correct one for your items to ensure the correct documents are automatically generated for
            your shipment.
          </p>
        </div>
        <div class="sub-section">
          <h3 class="privacy-sub-point">Quantity</h3>
          <p class="privacy-text">
            The correct quantity or number of unique products within the shipment must be entered.
          </p>
        </div>
        <div class="sub-section">
          <h3 class="privacy-sub-point">Country of Origin ISO Code</h3>
          <p class="privacy-text">
            The ISO country code for which the product was originally produced must be filled in on the shipping
            invoice. E.g. if the product was made in China the country code used would be “CN”.
          </p>
          <p class="privacy-text">
            A list of country ISO codes can be found at SKAT’s website:
            <a
              href="http://tulltaxan.tullverket.se/#!/taric/nomenclature/sbn?sd=2022-02-04&d=I&cc=&l=sv&ql=sv&ea=false"
            >
              tulltaxan.tullverket.se
            </a>
          </p>
        </div>
        <div class="sub-section">
          <h3 class="privacy-sub-point">Content Listed in English</h3>
          <p class="privacy-text">A description of the contents of the shipment must be in English e.g. “shirts”.</p>
        </div>
        <div class="sub-section">
          <h3 class="privacy-sub-point">Use of Commodity Code</h3>
          <p class="privacy-text">
            A commodity code is a number code allocated to all goods to classify imports shipped from outside the EU.
            The code will dictate duty rating and alert you to any import or export restrictions. The code also is used
            to collect statistics on trade goods by the Intrastat system.
          </p>
          <p class="privacy-text">
            When entering in the commodity code ensure there are no spaces or other characters, numbered digits only.
          </p>
          <p class="privacy-text">
            The commodity code will contain only 8, 10, or 12 digits. If, for some reason, the commodity contains 7, 9,
            or 11 digits a 0 (zero) must be added at the end.
          </p>
          <p class="privacy-text">
            If the commodity code is unknown for any item being shipped contact the supplier of the product. When it is
            not possible to contact the supplier the shipper is expected to classify the item to the best of their
            ability. It is possible to find the right commodity code on SKAT’s website:
            https://ec.europa.eu/taxation_customs/dds2/taric/taric_consultation.jsp
          </p>
        </div>
        <div class="sub-section">
          <h3 class="privacy-sub-point">Weight of Items</h3>
          <p class="privacy-text">The weight of each item being shipped must be listed in grams.</p>
        </div>
        <div class="sub-section">
          <h3 class="privacy-sub-point">Value of Items</h3>
          <p class="privacy-text">
            Each individual item’s sale value (customer’s purchase price) should be listed without decimals.
          </p>
        </div>
        <div class="sub-section">
          <h3 class="privacy-sub-point">How the Electronic Custom Documents are Handled</h3>
          <p class="privacy-text">The carrier will receive the above information electronically.</p>
          <p class="privacy-text">
            Regulations of certain countries and carriers may require a specified number of copies of the
            proforma/commercial invoice to be attached to the outside of the shipping container as well.
          </p>
          <p class="privacy-text">
            If the carrier requires copies of the proforma/commercial invoice they will be automatically printed as well
            as the shipping labels.
          </p>
          <p class="privacy-text">
            Requirements for the handling of customs documents can differ depending on the carrier.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ProformaInvoice',
})
</script>
