export const LOGIN_PAGE = 'login.index'
export const REGISTRATION = 'registration.index'
export const PERMISSIONS_ERROR = 'permissions_error.index'
export const MOBILE_UNSUPPORTED = 'mobile.unsupported'
export const PRIVACY = 'info.privacy'
export const TERMS = 'info.terms'
export const PROFORMA_INVOICE = 'info.proforma_invoice'

export const PARCEL_REQUIREMENTS = 'info.parcel_requirements'

// Staff router names
export const STAFF_PAGE = 'staff.index'
export const STAFF_CUSTOMERS_LIST = 'staff.customers.list'
export const STAFF_CUSTOMERS_CREATE = 'staff.customers.create'
export const STAFF_CUSTOMERS_PRICES = 'staff.customers.prices'
export const STAFF_ASSIGNMENTS_LIST = 'staff.assignments.list'
export const STAFF_ASSIGNMENTS_CREATE = 'staff.assignments.create'
export const STAFF_ASSIGNMENTS_SINGLE = 'staff.assignments.single'
export const STAFF_ASSIGNMENTS_SINGLE_EDIT = 'staff.assignments.edit'
export const STAFF_ASSIGNMENT_PRICES = 'staff.assignments.prices'
export const STAFF_BASE_PRICE_PAGE = 'staff.base.price'
export const STAFF_ASSIGNMENTS_OPTIMIZATION_REPORT = 'staff.assignments.optimization.report'
export const STAFF_SHIPMENTS = 'staff.shipments'
export const STAFF_HOME_PAGE = STAFF_SHIPMENTS

export const STAFF_ASSIGNMENT_SUMMARY = 'staff.assignment.summary.index'
export const STAFF_STATISTICS = 'staff.statistics.index'
export const STAFF_SENDER_ADDRESS_PRICES = 'staff.senderAddress.prices.index'
export const STAFF_PICKUPS_LIST = 'customer.pickups.index'

// Customer router names
export const CUSTOMER_PAGE = 'customer.index'
export const CUSTOMER_FORGOT_PASSWORD = 'customer.forgotPass.index'
export const CUSTOMER_CREATE_PASSWORD = 'customer.createPass.index'
export const CUSTOMER_RESET_PASSWORD = 'customer.resetPass.index'
export const CUSTOMER_ASSIGNMENTS_LIST = 'customer.assignments.list'
export const CUSTOMER_ASSIGNMENTS_CREATE = 'customer.assignments.create'
export const CUSTOMER_ASSIGNMENTS_SINGLE = 'customer.assignments.single'
export const CUSTOMER_ASSIGNMENTS_SINGLE_EDIT = 'customer.assignments.edit'
export const CUSTOMER_ASSIGNMENTS_OPTIMIZATION_REPORT = 'customer.assignments.optimization.report'
export const CUSTOMER_ASSIGNMENT_SUMMARY = 'customer.assignment.summary.index'
export const CUSTOMER_STATISTICS = 'customer.statistics.index'

export const CUSTOMER_SHIPMENTS = 'customer.shipments'
export const CUSTOMER_HOME_PAGE = CUSTOMER_SHIPMENTS

export const FAQ_PAGE = 'faq.index'
export const FAQ_LIST = 'faq.list'
export const FAQ_CREATE = 'faq.create'
export const FAQ_EDIT = 'faq.edit'

export const INFO_PAGE = 'info'

// Guides router names
export const PROFORMA_INVOICE_GUIDE = 'guides.proformaInvoice.index'
