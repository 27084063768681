import { useStore } from 'vuex'
import type { ComputedRef } from 'vue'
import type { AccountType } from '@/types/Models/Account'
import { computed } from 'vue'
import { StatusName } from '@/services/Status/StatusName'
import type { BaseAssignment, ListAssignment, StatusCategory, StatusType } from '@/types/Models/Assignment'
import { StatusColor } from '@/services/Status/StatusColor'

type Props = {
  assignment: ComputedRef<BaseAssignment | ListAssignment>
}

export const useAssignmentStatus = ({ assignment }: Props) => {
  const store = useStore()

  const accountType: ComputedRef<AccountType> = computed(() => store.getters['account/accountType'])

  const statusName: ComputedRef<string> = computed(() => {
    if (!assignment.value.type || !assignment.value.status) {
      return ''
    }

    return StatusName[assignment.value.type as StatusCategory][accountType.value][assignment.value.status as StatusType]
  })

  const statusColor: ComputedRef<string> = computed(() => {
    if (!assignment.value.status) {
      return ''
    }

    return StatusColor[accountType.value][assignment.value.status as StatusType]
  })

  const circleStyle = computed(() => {
    return {
      color: statusColor.value,
    }
  })

  return {
    statusName,
    circleStyle,
  }
}
