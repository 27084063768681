/* ============
 * Order Transformer
 * ============
 *
 * The transformer for the order.
 */

import type { IntegrationOrder } from '@/types/Models/Integration'

export default class IntegrationOrderTransformer {
  /**
   * Method used to transform a fetched item.
   */
  static fetch(item: IntegrationOrder) {
    return {
      externalId: item.external_identifier,
      assignmentId: item.internal_identifier,
      status: item.import_status,
      error: item.error,
    }
  }

  /**
   * Method used to transform a fetched collection.
   */
  static fetchCollection(items: IntegrationOrder[]) {
    return items.map((item) => this.fetch(item))
  }
}
