<template>
  <div>
    <div v-if="date">
      <div>
        <span>
          <b>
            <span class="no-wrap">{{ dayText }}</span>
          </b>
        </span>
      </div>
      <div v-if="displayTime">
        <span class="text-muted">{{ timeText }}</span>
      </div>
    </div>
    <div v-else>-</div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { readableDate, readableTime } from '@/services/Helpers'

export default defineComponent({
  name: 'RowDate',
  props: {
    date: {
      type: String,
      required: false,
      default: '',
    },
    displayTime: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props) {
    const dayText = computed(() => readableDate(props.date))
    const timeText = computed(() => readableTime(props.date))

    return {
      dayText,
      timeText,
    }
  },
})
</script>
