<template>
  <div class="d-inline-flex flex-row">
    <div>
      <CarrierRowWithIcon :carrier="shipment.carrierHandle" :only-icon="onlyIcon" />
    </div>
    <div v-if="shipment.adjustedCarrierHandle" class="ml-2">
      <CarrierRowWithIcon :only-icon="onlyIcon" :carrier="shipment.adjustedCarrierHandle" />
    </div>
  </div>
</template>

<script lang="ts">
import CarrierRowWithIcon from '@/views/Components/Assignment/CarrierRowWithIcon.vue'
import { defineComponent, type PropType } from 'vue'
import type { Shipment } from '@/types/Models/Shipment'

export default defineComponent({
  name: 'CarrierData',
  components: { CarrierRowWithIcon },
  props: {
    shipment: {
      type: Object as PropType<Shipment>,
      required: true,
    },
    onlyIcon: {
      type: Boolean,
      required: true,
    },
  },
})
</script>
