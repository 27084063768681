<template>
  <modal-wrap
    :id="id"
    ref="listIntegrations"
    body-class="integrations-modal"
    :name="modalName"
    :header-class="'integrations-header'"
    :hide-footer="true"
    size="md"
  >
    <template #modal-title>
      <div class="d-flex justify-content-between align-items-center">
        <h4 class="mb-0">Integrations</h4>
      </div>
    </template>
    <template #default>
      <div>
        {{ modalData.id }}
        <div class="integrations">
          <div
            v-for="integration in integrations"
            :key="integration.id"
            :ref="'integrations' + integration.id"
            class="integration"
          >
            <div class="integration-name d-flex">
              <div class="flex-column integration-label col-4">
                {{ getSystemDisplayName(integration.system) }}
              </div>
              <div class="flex-column integration-button col-4">
                <button v-if="integration.id" class="btn btn-grey" @click="showDetails(integration)">Details</button>
              </div>
              <div class="flex-column integration-button col-4">
                <button v-if="integration.id" class="btn btn-danger" @click="destroyIntegration(integration)">
                  Disconnect
                </button>
                <button v-if="!integration.id" class="btn btn-green" @click="connectIntegration(integration)">
                  Connect
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal-wrap>
  <ModalWrap :id="disconnectConfirm" :name="disconnectConfirm">
    <template #modal-title> The Integration will be disabled. </template>
    <p>Are you sure you want to disable this integration?</p>
    <template #modal-footer>
      <div class="d-flex justify-content-between">
        <b-button @click="closeDisconnect">Cancel</b-button>
        <b-button variant="danger" @click="confirmDisconnect">Yes</b-button>
      </div>
    </template>
  </ModalWrap>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch, onMounted, type Ref, type ComputedRef } from 'vue'
import { openModal, registerModals } from '@/services/Modal'
import { getSystemDisplayName } from '@/services/Helpers'
import { useStore } from 'vuex'
import { notify } from '@kyvg/vue3-notification'
import {
  INTEGRATION_DETAILS,
  INTEGRATIONS,
  ONGOING_WEBHOOK_SETTINGS,
  SHOPIFY_WEBHOOK_SETTINGS,
  WOO_COMMERCE_KEYS,
} from '@/const/ModalTypes'
import ModalWrap from '@/views/Modals/ModalWrap.vue'
import type { Integration, IntegrationsModal } from '@/types/Models/Integration'

export default defineComponent({
  name: 'IntegrationsActionModal',
  components: { ModalWrap },
  props: {
    id: {
      type: String,
      default: '',
      required: true,
    },
  },
  setup() {
    const store = useStore()
    const integrations: Ref<Integration[]> = ref([])
    const modalName = INTEGRATIONS

    const modalData: ComputedRef<IntegrationsModal> = computed(
      () => store.getters['vueNextModal/modalProps'](modalName) || {},
    )
    const customerId: ComputedRef<string> = computed(() => modalData.value?.id || '')

    const disconnectConfirm = 'disconnectConfirm'

    const closeDisconnect = () => {
      store.dispatch('vueNextModal/closeModal', disconnectConfirm)
    }
    const confirmDisconnect = () => {
      const integrationId = store.getters['vueNextModal/modalProps'](disconnectConfirm).integrationId
      store
        .dispatch('customers/integrations/destroy', {
          customerId: customerId.value,
          integrationId,
        })
        .then(() => {
          notify({
            group: 'success',
            text: 'The integration has been disabled',
          })
          store.dispatch('vueNextModal/closeModal', disconnectConfirm)
        })
        .catch((e) => {
          notify({
            group: 'success',
            text: `The integration has not been disabled. ${e.message}`,
          })
          store.dispatch('vueNextModal/closeModal', disconnectConfirm)
        })
    }

    const destroyIntegration = (integration: Integration) => {
      openModal(disconnectConfirm, {
        integrationId: integration.id,
      })
    }

    const connectIntegration = (integration: Integration) => {
      store
        .dispatch('customers/integrations/init', {
          customerId: customerId.value,
          system: integration.system,
        })
        .then((response) => {
          if (response.postInitAction) {
            switch (response.postInitAction) {
              case 'close':
              default:
                store.dispatch('customers/integrations/fetch', customerId.value)
                store.dispatch('vueNextModal/closeModal', modalName)
                notify({ group: 'success', text: response.message })
                window.open(response.redirectUrl, '_blank')
                break
              case 'redirect':
                window.open(response.redirectUrl, '_blank')
                break
              case 'setupWooCommerceKeys':
                setupWooCommerceKeys()
                break
              case 'shopifySettings':
                shopifySettings(response.postInitActionData)
                break
              case 'onGoingSettings':
                ongoingSettings(response.postInitActionData)
                break
            }
          } else {
            notify({
              group: 'error',
              text: 'The integration has not been initialized',
            })
          }
        })
        .catch((e) => {
          notify({
            group: 'error',
            text: `The integration has not been initialized. ${e.message}`,
          })
        })
    }

    const shopifySettings = (data: any) => {
      openModal(SHOPIFY_WEBHOOK_SETTINGS, {
        action: 'create',
        externalId: data.id || '',
        customerId: customerId.value || '',
        hmacKey: data.token || '',
      })
    }

    const ongoingSettings = (data: any) => {
      openModal(ONGOING_WEBHOOK_SETTINGS, {
        action: 'create',
        extUserId: data.id || '',
        externalId: '',
        customerId: customerId.value || '',
        user: data.token ? atob(data.token).split(':')[0] : '',
        pwd: data.token ? atob(data.token).split(':')[1] : '',
      })
    }

    const setupWooCommerceKeys = () => {
      openModal(WOO_COMMERCE_KEYS, {
        customerId: customerId.value,
        url: 'https://my.site/',
        key: '',
        secret: '',
        importInterval: 60,
        importType: 'scheduled',
        active: true,
        default_weight: 500,
        default_length: 34,
        default_width: 24,
        default_height: 7,
      })
    }

    const showDetails = (integration: any) => {
      openModal(INTEGRATION_DETAILS, {
        ...integration,
        customerId: customerId.value,
      })
    }

    watch(customerId, () => {
      if (customerId.value === '') {
        integrations.value = []
        return
      }
      integrations.value = modalData.value.integrations
    })

    onMounted(() => {
      registerModals(disconnectConfirm)
    })

    return {
      modalName,
      modalData,
      integrations,
      destroyIntegration,
      connectIntegration,
      showDetails,
      shopifySettings,
      ongoingSettings,
      setupWooCommerceKeys,
      getSystemDisplayName,

      //confirm disconnect
      disconnectConfirm,
      closeDisconnect,
      confirmDisconnect,
    }
  },
})
</script>

<style lang="scss" scoped>
.integration-label {
  line-height: 45px;
  vertical-align: middle;
}
.integration-button {
  text-align: right;
}
.integration-label,
.integration-button {
  padding: 15px 0;
}
</style>
