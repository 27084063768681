import mdiVue from 'mdi-vue/v3'
import * as mdijs from '@mdi/js'

export default {
  install(app: any) {
    app.use(mdiVue, {
      icons: mdijs,
    })
  },
}
