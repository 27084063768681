<template>
  <modal-wrap :name="modalName" size="xl" :hide-footer="false">
    <template #modal-title> Edit customer general info </template>
    <template #default>
      <div class="edit-customer-data">
        <VeeForm v-if="userData" @submit="onSubmit">
          <b-form-group class="form-group">
            <div class="form-inline-row">
              <label class="label-input">Email address</label>
              <VInput
                v-model="customer.email"
                :rules="{ required: true, email: true }"
                autocomplete="off"
                type="email"
                name="email"
                placeholder="Email address"
              />
            </div>

            <div class="form-inline-row">
              <label class="label-input">Client №</label>
              <VInput
                v-model="customer.clientNumber"
                :rules="{ required: true }"
                type="text"
                name="clientNumber"
                placeholder="Client №"
              />
            </div>

            <div class="form-inline-row">
              <label class="label-input">First name</label>
              <VInput
                v-model="customer.firstName"
                :rules="{ required: true }"
                type="text"
                name="firstName"
                placeholder="First name"
              />
            </div>

            <div class="form-inline-row">
              <label class="label-input">Last name</label>
              <VInput
                v-model="customer.lastName"
                :rules="{ required: true }"
                type="text"
                name="lastName"
                placeholder="Last name"
              />
            </div>

            <div class="form-inline-row">
              <label class="label-input">Company</label>
              <VInput v-model="customer.company" type="text" name="company" placeholder="Company" />
            </div>

            <div class="form-inline-row">
              <label class="label-input">Phone number</label>
              <VInput
                v-model="customer.phoneNumber"
                :rules="{ required: true }"
                type="text"
                name="phoneNumber"
                placeholder="Phone number"
              />
            </div>

            <div class="form-inline-row">
              <label class="label-input">Short name</label>
              <VInput v-model="customer.shortName" type="text" name="shortName" placeholder="Short name" />
            </div>

            <div class="form-inline-row">
              <label class="label-input">Contract party</label>
              <VInput v-model="customer.contractParty" type="text" name="contractParty" placeholder="Contract party" />
            </div>

            <div class="form-inline-row">
              <label class="label-input">Assigned Salesman</label>
              <v-select
                v-model="customer.salesmanId"
                :options="staffsOptions"
                fieldset-class="form-group position-relative"
                name="assignedSalesman"
                placeholder="Assigned Salesman"
              />
            </div>
          </b-form-group>
        </VeeForm>
      </div>
    </template>
    <template #modal-footer>
      <div>
        <b-button size="md" class="btn-grey mr-3" @click="cancelEdit"> Cancel </b-button>
        <b-button
          variant="success"
          size="md"
          :disabled="!isFormChanged"
          class="btn-green"
          type="submit"
          @click="onSubmit"
        >
          Save changes
        </b-button>
      </div>
    </template>
  </modal-wrap>
</template>

<script lang="ts">
import { ref, computed, defineComponent, toRaw, onMounted, watch, type ComputedRef } from 'vue'
import { useStore } from 'vuex'
import { isEqual } from '@/services/Utils'
import { notify } from '@kyvg/vue3-notification'
import { Form as VeeForm } from 'vee-validate'
import { EDIT_USER_GENERAL_INFO_MODAL } from '@/const/ModalTypes'
import VInput from '@/views/Components/Elements/Form/VInput.vue'
import VSelect from '@/views/Components/Elements/Form/VSelect.vue'
import ModalWrap from '@/views/Modals/ModalWrap.vue'
import type { SelectOptions } from '@/types/Components/SelectOptions'

export default defineComponent({
  name: 'EditUserGeneralInfoModal',
  components: { ModalWrap, VeeForm, VInput, VSelect },
  emits: ['update'],
  setup(_, { emit }) {
    const store = useStore()
    const modalName = EDIT_USER_GENERAL_INFO_MODAL
    const userData = computed(() => store.getters['vueNextModal/modalProps'](modalName))
    const customer = ref({
      email: '',
      clientNumber: '',
      firstName: '',
      lastName: '',
      company: '',
      phoneNumber: '',
      contractParty: '',
      shortName: '',
      salesmanId: '',
    })

    const staffsOptions: ComputedRef<SelectOptions[]> = computed(() => {
      const result: SelectOptions[] = [
        {
          value: '',
          text: 'Choose Assigned Salesman',
        },
      ]
      if (!store.getters['packingSlip/getAllStaffs']?.length) {
        return result
      }

      return result.concat(
        store.getters['packingSlip/getAllStaffs'].map((staff: any) => ({
          value: staff.id,
          text: staff.fullName.trim() || staff.email,
        })),
      )
    })

    const isFormChanged = computed(() => {
      const customerData = { ...customer.value }
      return !isEqual(customerData, userData.value)
    })

    const onSubmit = async () => {
      const data: any = { ...toRaw(customer.value) }
      await store
        .dispatch('customers/update', {
          id: userData.value.id,
          data,
        })
        .then(() => {
          notify({ group: 'success', text: 'Customer data updated' })
          emit('update', data)
          store.dispatch('vueNextModal/closeModal', modalName)
        })
    }

    const cancelEdit = () => {
      store.dispatch('vueNextModal/closeModal', modalName)
    }

    const loadAddressData = () => {
      if (userData.value) {
        customer.value = {
          email: userData.value.email,
          clientNumber: userData.value.clientNumber,
          firstName: userData.value.firstName,
          lastName: userData.value.lastName,
          company: userData.value.company,
          phoneNumber: userData.value.phoneNumber,
          contractParty: userData.value.contractParty,
          shortName: userData.value.shortName,
          salesmanId: userData.value.salesmanId,
        }
      }
    }

    onMounted(loadAddressData)

    watch(userData, loadAddressData)

    return {
      customer,
      modalName,
      userData,
      staffsOptions,
      isFormChanged,
      onSubmit,
      cancelEdit,
    }
  },
})
</script>

<style lang="scss" scoped>
.edit-customer-data {
  .form-group {
    margin-bottom: 0;
    width: 100%;
  }
  .form-inline-row {
    margin-bottom: 12px;
  }
}
.edit-customer-data {
  :deep(.form-group) {
    margin-bottom: 0;
    width: 100%;
  }
}
</style>
