import type { AssignmentData } from '@/views/Components/Assignment/AssignmentForm/types'
import type { UserWithLabel } from '@/types/Models/User'
import type { Address } from '@/types/Models/Customer'
import { computed, type ComputedRef, type Ref, watch } from 'vue'
import { useStore } from 'vuex'

type Props = {
  assignment: Ref<AssignmentData>
  customerCanHaveCustomerNumbers: Ref<boolean>
}

export const watchCustomerChange = ({ assignment, customerCanHaveCustomerNumbers }: Props) => {
  const store = useStore()

  const customers: ComputedRef<UserWithLabel[]> = computed(() => store.getters['customers/all'])
  const senderAddresses: ComputedRef<Address[]> = computed(() => store.getters['customers/senderAddresses/get'])

  watch(
    () => assignment.value.customer_id,
    (customerId) => {
      if (!customerId) {
        return
      }
      assignment.value.sender_address_id = ''
      customerCanHaveCustomerNumbers.value =
        customers.value.find((el: UserWithLabel) => el.id === customerId)?.canHasCustomerNumbers || false
      store.dispatch('customers/senderAddresses/fetch', customerId).then(() => {
        assignment.value.sender_address_id = senderAddresses.value.find((el: any) => el.isDefault)?.id || ''
      })
    },
  )
}
