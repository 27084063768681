import * as Sentry from '@sentry/vue'
import router from '@/router'

export default {
  install(app: any) {
    if (!import.meta.env.VITE_APP_SENTRY_DSN) {
      return
    }
    Sentry.init({
      app,
      dsn: import.meta.env.VITE_APP_SENTRY_DSN,
      integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
      tracePropagationTargets: ['localhost', /^\//],
      beforeSend(event, hint) {
        if (event.message && (event.message.includes('Load failed') || event.message.includes('Unable to preload'))) {
          return null
        }
        // ignore validation errors
        if (event.message && event.message.includes('Object captured as promise rejection with keys')) {
          return null
        }
        // ignore 401 errors
        const { response } = hint.originalException as any
        if (response && response.status && response.status === 401) {
          return null
        }
        return event
      },
    })
  },
}
