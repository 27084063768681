<template>
  <div>
    <modal-wrap :id="id" size="xl" :name="modalName" :hide-footer="false">
      <template #modal-title>
        <h5 v-if="Object.entries(modalData).length !== 0">
          {{ carrierHandler2Name(modalData.price?.carrierHandle || '') }} /
          {{ ucFirst(modalData.price?.serviceLevel || '') }}
        </h5>
      </template>
      <template #default>
        <div class="modal-table">
          <div class="modal-table__title">Weight prices</div>
        </div>
        <div v-if="Object.entries(modalData).length !== 0" class="table-shadow bg-dark-green-title table-editable mt-2">
          <table class="table bg-table-cell-dark">
            <thead>
              <tr>
                <th>Weight</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="weight in sortWeights(modalData.price?.weights || [])" :key="weight.id">
                <td colspan="1">{{ weight.minWeight }} - {{ weight.maxWeight }}</td>
                <td colspan="1" class="p-0">
                  <div class="form-group form-group_bg_white">
                    <input
                      v-model="weight.price"
                      type="text"
                      class="form-control form-control_hide_arrow"
                      @input="numberFormat(weight, $event)"
                      @change="updatePriceWeight(weight, modalData.price.id, $event)"
                    />
                    <div
                      v-if="
                        isShowDefaultPrices &&
                        getDefaultWeight(modalData, weight) &&
                        getDefaultWeight(modalData, weight).price
                      "
                      class="base-price"
                    >
                      {{ getDefaultWeight(modalData, weight).price }}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <b-alert
            :model-value="isFinishedStatus"
            variant="secondary"
            class="alert-info-danger d-flex align-items-center mr-3 mb-0"
          >
            <span><b>Important: </b>You cannot change prices level for this assignment</span>
          </b-alert>
        </div>
      </template>
      <template #modal-footer>
        <div>
          <b-button size="md" class="btn-grey mr-3" @click="closeModal"> Cancel </b-button>
          <b-button variant="success" size="md" class="btn-green" @click="closeModal"> Ok </b-button>
        </div>
      </template>
    </modal-wrap>
  </div>
</template>

<script lang="ts">
import { useStore } from 'vuex'
import { computed, defineComponent, ref } from 'vue'
import { EDIT_PRICE_WEIGHT } from '@/const/ModalTypes'
import { CANCELLED, COMPLETE } from '@/const/AssignmentStatuses'
import ModalWrap from '@/views/Modals/ModalWrap.vue'
import type { BasePrice } from '@/types/Models/BasePrice'
import { carrierHandler2Name, ucFirst } from '@/services/Helpers'

export default defineComponent({
  name: 'EditBasePriceWeight',
  components: { ModalWrap },
  props: {
    defaultPrices: {
      type: Array as () => BasePrice[],
      default: () => [],
      required: false,
    },
    id: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const store = useStore()
    const prices = ref(null)

    const modalName = EDIT_PRICE_WEIGHT
    const modalData = computed(() => store.getters['vueNextModal/modalProps'](modalName) || {})
    const assignment = computed(() => store.getters['assignment/get'])
    const isShowDefaultPrices = computed(() => props.defaultPrices && props.defaultPrices.length > 0)

    const numberFormat = (model: any, event: any) => {
      let value = event.target.value.replace(/\s+/g, '').replace(/[^0-9.]/g, '')

      if (value.indexOf('.') === -1) {
        model.price = Number(value)
        event.target.value = value
        return
      }

      let decimalIndex = value.indexOf('.')
      value = value.slice(0, decimalIndex + 3)
      event.target.value = value
      model.price = Number(value)
    }

    const updatePriceWeight = (weight: any, priceId: string, event: any) => {
      const priceWeightData = { ...weight }
      priceWeightData.price = Number(event.target.value)
      store.dispatch('prices/updateWeightPrices', { priceWeightData, priceId })
    }

    const closeModal = () => {
      store.dispatch('vueNextModal/closeModal', modalName)
    }

    const sortWeights = (arr: any[]) => {
      const sortableArr = JSON.parse(JSON.stringify(arr))
      return sortableArr.sort((prev: any, next: any) => prev.minWeight - next.minWeight)
    }

    const getDefaultWeight: any = (data: any, weight: any) => {
      const basePrice = props.defaultPrices.find(
        (el: any) =>
          el?.minPackagesAmount === data?.price?.minPackagesAmount &&
          el?.countryGroup === data?.price?.countryGroup &&
          el?.carrierHandle === data?.price?.carrierHandle &&
          el?.destination === data?.price?.destination &&
          el?.serviceLevel === data?.price?.serviceLevel,
      )
      const baseWeight = basePrice?.weights.find(
        (el: any) => el?.minWeight === weight?.minWeight && el?.maxWeight === weight?.maxWeight,
      )
      return baseWeight || {}
    }

    const isFinishedStatus = computed(() => {
      if (assignment.value) {
        return assignment.value.status === COMPLETE || assignment.value.status === CANCELLED
      }
      return false
    })

    return {
      prices,
      modalName,
      modalData,
      isFinishedStatus,
      isShowDefaultPrices,
      ucFirst,
      numberFormat,
      carrierHandler2Name,
      updatePriceWeight,
      closeModal,
      sortWeights,
      getDefaultWeight,
    }
  },
})
</script>

<style lang="scss" scoped>
.modal {
  .modal-table {
    &__title {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 150%;
      color: #757575;
    }
  }

  .table {
    tbody {
      tr {
        td {
          width: 50%;
        }
      }
    }
  }
}

.form-group {
  position: relative;
}

.base-price {
  position: absolute;
  top: 50%;
  right: 5%;
  display: flex;
  min-width: 60px;
  transform: translate(-5%, -50%);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  color: #757575;

  &::before {
    display: block;
    content: '';
    width: 1px;
    background: #cccccc;
    margin-right: 15px;
  }
}
</style>
