<template>
  <footer>
    <div class="container">
      <div class="d-flex align-items-center">
        <p class="mb-0">
          <router-link :to="{ name: assignmentsPage }" class="d-flex footer-logo">
            <img src="../../assets/img/logo-footer.svg" alt="" />
            <img class="ml-4 footer-logo" src="../../assets/img/21grmas.svg" alt="" />
          </router-link>
        </p>

        <div class="footer-column ml-auto">
          <div class="column-title">
            <a href="https://www.21grams.se/kontakt/" class="d-flex align-items-center">
              Contact us
              <img src="../../assets/img/icon-green-arrow.svg" class="d-flex ml-2" alt="" />
            </a>
          </div>
          <p class="mb-2">
            <a href="mailto:support@21grams.com">support@21grams.com</a>
          </p>
          <p class="pt-1">
            <a href="tel:004686003721">+46 (8) 600 37 21 knappval 2</a>
          </p>
          <div class="d-flex align-items-center pt-1">
            <p>
              <router-link :to="{ name: privacyLink }"> Privacy Policy </router-link>
            </p>
            <p>
              <router-link :to="{ name: termsLink }" class="ml-3"> Terms of Use </router-link>
            </p>
            <p>
              <router-link :to="{ name: faqLink }" class="ml-3"> FAQ </router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { PRIVACY, TERMS, FAQ_LIST, CUSTOMER_ASSIGNMENTS_LIST, STAFF_ASSIGNMENTS_LIST } from '@/types/router'

export default defineComponent({
  name: 'LayoutFooter',
  setup() {
    const store = useStore()

    const isStaff = computed(() => store.getters['account/isStaff'])
    const assignmentsPage = computed(() => (isStaff.value ? STAFF_ASSIGNMENTS_LIST : CUSTOMER_ASSIGNMENTS_LIST))

    return {
      assignmentsPage,
      privacyLink: PRIVACY,
      termsLink: TERMS,
      faqLink: FAQ_LIST,
    }
  },
})
</script>
