import { parsePhoneNumber } from 'awesome-phonenumber'

const phoneHandler = {
  validate(phone: string, country: string) {
    try {
      const countryCode = country ? country : 'SE'
      const pn = parsePhoneNumber(phone, { regionCode: countryCode })
      return pn.valid
    } catch {
      return false
    }
  },
  validateWithoutCountry(phone: string, country: string | null = null) {
    try {
      if (country) {
        return this.validate(phone, String(country))
      }
      const pn = parsePhoneNumber(phone)
      return pn.valid
    } catch {
      return false
    }
  },
}

export default phoneHandler
