import type { DimensionsRules, RulesBucket } from '@/views/Components/Assignment/Pieces/validation/dimensions-rules'

export class ParcelConsumerDimensions implements DimensionsRules {
  weight(): RulesBucket {
    return {
      required: true,
      numeric: true,
      min_value: 100,
      max_value: 20000,
    }
  }

  width(): RulesBucket {
    return {
      required: true,
      numeric: true,
      min_value: 11,
      circumference_not_greater_than: 300,
    }
  }

  length(): RulesBucket {
    return {
      required: true,
      numeric: true,
      min_value: 15,
      max_value: 150,
      circumference_not_greater_than: 300,
    }
  }

  height(): RulesBucket {
    return {
      required: true,
      numeric: true,
      min_value: 3.5,
      circumference_not_greater_than: 300,
    }
  }
}
