<template>
  <div class="bg-light">
    <simple-header />
    <div class="page-body">
      <div class="container">
        <div class="wrap-form">
          <div>
            <h1 class="title mb-4">Reset a password for your account</h1>
            <VeeForm @submit="forgotPassword">
              <Field v-slot="{ field, errors }" name="email" :rules="{ email: true, required: true }">
                <b-form-group class="form-group position-relative">
                  <input
                    v-bind="field"
                    v-model="customer.email"
                    type="email"
                    name="Email*"
                    class="form-control is-invalid required"
                    placeholder="Email*"
                  />
                  <div class="invalid-feedback">
                    {{ errors.length ? errors.join() : '' }}
                  </div>
                </b-form-group>
              </Field>
              <b-button type="submit" class="w-100" :disabled="isDisabled"> Submit </b-button>
            </VeeForm>
          </div>
        </div>
      </div>
    </div>
    <LayoutFooter />
  </div>
</template>

<script lang="ts">
import { computed, type ComputedRef, defineComponent, type Ref, ref } from 'vue'
import { Form as VeeForm, Field } from 'vee-validate'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { LOGIN_PAGE } from '@/types/router'
import { useNotification } from '@kyvg/vue3-notification'
import LayoutFooter from '@/views/Layouts/LayoutFooter.vue'
import SimpleHeader from '@/views/Layouts/SimpleHeader.vue'
import type { ForgotPasswordData } from '@/types/Models/Account'

export default defineComponent({
  name: 'ForgotPassword',
  components: {
    Field,
    VeeForm,
    SimpleHeader,
    LayoutFooter,
  },
  setup() {
    const customer: Ref<ForgotPasswordData> = ref({
      email: '',
    })

    const store = useStore()
    const router = useRouter()
    const { notify } = useNotification()

    const isDisabled: ComputedRef<boolean> = computed(() => !customer.value.email)

    const forgotPassword = () => {
      store
        .dispatch('auth/forgotPassword', customer.value)
        .then(() => {
          notify({
            group: 'success',
            text: 'We have sent a message to your email',
          })
          setTimeout(() => {
            router.push({
              name: LOGIN_PAGE,
            })
          }, 1500)
        })
        .catch(() => {
          notify({
            group: 'error',
            text: "We're sorry. We weren't able to identify you given the information provided.",
          })
        })
    }

    return {
      customer,
      isDisabled,
      forgotPassword,
    }
  },
})
</script>

<style lang="scss" scoped>
.title {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0;
  text-align: center;
}
.bg-light {
  min-height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
footer {
  margin-top: auto;
}
</style>
