<template>
  <div>
    <AssignmentsList :version="assignmentsVersion" />

    <PackingSlipButtons />

    <PackingSlips v-if="showPackingSlips" :id="PACKING_SLIPS" />
    <CreatePackingSlip :id="PACKING_SLIP" />
    <CreatePackingSlipAssignmentsModal v-if="showCreatePackingSlip" :id="CREATE_PACKING_SLIP" />
    <PackingSlipPreviewModal :id="PACKING_SLIP_PREVIEW" />
    <PackingSlipEditModal :id="PACKING_SLIP_EDIT" />
  </div>
</template>

<script lang="ts">
import { computed, defineAsyncComponent, defineComponent, onBeforeMount, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { registerModals } from '@/services/Modal'
import {
  CREATE_PACKING_SLIP,
  PACKING_SLIP,
  PACKING_SLIP_EDIT,
  PACKING_SLIP_PREVIEW,
  PACKING_SLIPS,
} from '@/const/ModalTypes'
import { SET_PACKING_SLIPS_ASSIGNMENT_TYPE } from '@/store/modules/packingSlip'

export default defineComponent({
  name: 'StaffAssignmentsList',
  components: {
    PackingSlips: defineAsyncComponent(() => import('@/views/Modals/PackingSlip/PackingSlipsModal.vue')),
    AssignmentsList: defineAsyncComponent(() => import('@/views/Components/Assignment/AssignmentsList.vue')),
    PackingSlipButtons: defineAsyncComponent(() => import('@/views/Components/PageParts/PackingSlipButtons.vue')),
    PackingSlipEditModal: defineAsyncComponent(() => import('@/views/Modals/PackingSlip/PackingSlipEditModal.vue')),
    PackingSlipPreviewModal: defineAsyncComponent(
      () => import('@/views/Modals/PackingSlip/PackingSlipPreviewModal.vue'),
    ),
    CreatePackingSlipAssignmentsModal: defineAsyncComponent(
      () => import('@/views/Modals/PackingSlip/CreatePackingSlipAssignmentsModal.vue'),
    ),
    CreatePackingSlip: defineAsyncComponent(() => import('@/views/Modals/PackingSlip/CreatePackingSlipModal.vue')),
  },
  setup() {
    const store = useStore()

    const modals = computed(() => store.getters['vueNextModal/renderedModals'])
    const assignmentsVersion = computed(() => store.getters['assignment/version'])

    const showPackingSlips = ref(false)
    const showCreatePackingSlip = ref(false)

    watch(modals.value, () => {
      showPackingSlips.value = modals.value.includes(PACKING_SLIPS)
      showCreatePackingSlip.value = modals.value.includes(CREATE_PACKING_SLIP)
    })

    onBeforeMount(() => {
      registerModals(PACKING_SLIP)
      registerModals(PACKING_SLIPS)
      registerModals(PACKING_SLIP_EDIT)
      registerModals(PACKING_SLIP_PREVIEW)
      registerModals(CREATE_PACKING_SLIP)
      store.commit(`packingSlip/${SET_PACKING_SLIPS_ASSIGNMENT_TYPE}`, 'single')
    })

    return {
      showPackingSlips,
      showCreatePackingSlip,
      assignmentsVersion,
      CREATE_PACKING_SLIP,
      PACKING_SLIP,
      PACKING_SLIP_EDIT,
      PACKING_SLIP_PREVIEW,
      PACKING_SLIPS,
    }
  },
})
</script>
