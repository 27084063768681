import { computed, type Ref } from 'vue'
import { BOX, PARCEL } from '@/const/DistributionChannels'

type Props = {
  outsideEu: Ref<boolean>
  distributionChannel: Ref<string>
}

export const useIndicators = ({ outsideEu, distributionChannel }: Props) => {
  const title = computed(() => {
    return !outsideEu.value ? 'Shipment data' : 'Shipment and customs declaration data'
  })
  const displayDeclarationData = computed(() => outsideEu.value)
  const canAddPieces = computed(() => distributionChannel.value === PARCEL)
  const canChangeQuantity = computed(() => [PARCEL, BOX].includes(distributionChannel.value))

  return {
    title,
    displayDeclarationData,
    canAddPieces,
    canChangeQuantity,
  }
}
