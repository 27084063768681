import type { Ref } from 'vue'

export const checkMessage = (message: Ref, progress: Ref) => {
  setTimeout(() => {
    if (progress.value === 0) {
      message.value = 'Server is busy with other requests. waiting for them to finish...'
    }
  }, 25000)
}

export const parseLoaderProgress = (response: any, message: Ref, progress: Ref) => {
  progress.value = progress.value === 100 ? 100 : response.progress
  message.value = typeof response.message !== 'undefined' ? response.message : null
}
