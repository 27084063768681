import type { ActionContext } from 'vuex'
import type { Address } from '@/types/Models/Customer'
import type { RootStoreState, SenderAddressesStoreState } from '@/types/Store'
import type { AxiosCollection, AxiosErrors } from '@/types/Components/Response'
import SenderAddressProxy from '@/proxies/Customer/SenderAddressProxy'
import SenderAddressTransformer from '@/transformers/SenderAddressTransformer'

export const FILL_SENDER_ADDRESSES = 'FILL_SENDER_ADDRESSES'
export const RESET_SENDER_ADDRESSES = 'RESET_SENDER_ADDRESSES'
export const RESET_SELECTED_SENDER_ADDRESS = 'RESET_SELECTED_SENDER_ADDRESS'
export const FILL_SENDER_ADDRESS = 'FILL_SENDER_ADDRESS'

type Context = ActionContext<SenderAddressesStoreState, RootStoreState>

const state: SenderAddressesStoreState = {
  senderAddresses: [],
  selectedSenderAddress: null,
}

const getters = {
  get(state: SenderAddressesStoreState) {
    return state.senderAddresses
  },
  getSelectedSenderAddress(state: SenderAddressesStoreState) {
    return JSON.parse(JSON.stringify(state.selectedSenderAddress))
  },
}

const mutations = {
  [FILL_SENDER_ADDRESSES](state: SenderAddressesStoreState, payload: Address[]) {
    state.senderAddresses = payload
  },
  [RESET_SENDER_ADDRESSES](state: SenderAddressesStoreState) {
    state.senderAddresses = []
  },
  [FILL_SENDER_ADDRESS](state: SenderAddressesStoreState, payload: Address) {
    state.selectedSenderAddress = payload
  },
  [RESET_SELECTED_SENDER_ADDRESS](state: SenderAddressesStoreState) {
    state.selectedSenderAddress = null
  },
}

const actions = {
  store: (_: Context, { customerId, data }: { customerId: string; data: any }) =>
    new SenderAddressProxy(customerId).create(data),
  fetch: ({ commit }: Context, userId: string) =>
    new Promise((resolve, reject) => {
      new SenderAddressProxy(userId)
        .all()
        .then((response: AxiosCollection<Address>) => {
          commit(FILL_SENDER_ADDRESSES, SenderAddressTransformer.fetchCollection(response.data))
          resolve(null)
        })
        .catch((e: AxiosErrors) => {
          reject(e)
        })
    }),
  resetSenderAddresses: ({ commit }: Context) => {
    commit(RESET_SENDER_ADDRESSES)
  },
  selectSenderAddress: ({ commit }: Context, { customerId, addressId }: { customerId: string; addressId: string }) =>
    new Promise((resolve, reject) => {
      new SenderAddressProxy(customerId)
        .getSenderAddress(addressId)
        .then((res) => {
          commit(FILL_SENDER_ADDRESS, res.data)
          resolve(res)
        })
        .catch((e: AxiosErrors) => reject(e))
    }),
  updateSenderAddress: (
    _: Context,
    { customerId, addressId, sender }: { customerId: string; addressId: string; sender: any },
  ) =>
    new Promise((resolve, reject) => {
      new SenderAddressProxy(customerId)
        .update(addressId, sender)
        .then((res) => resolve(res))
        .catch((e: AxiosErrors) => reject(e))
    }),
  destroySenderAddress: (_: Context, { customerId, addressId }: { customerId: string; addressId: string }) =>
    new Promise((resolve, reject) => {
      new SenderAddressProxy(customerId)
        .destroy(addressId)
        .then(() => resolve(null))
        .catch((e: AxiosErrors) => reject(e))
    }),
  setAsDefaultSenderAddress: (_: Context, { customerId, addressId }: { customerId: string; addressId: string }) =>
    new Promise((resolve, reject) => {
      new SenderAddressProxy(customerId)
        .setDefaultSenderAddress(addressId)
        .then(() => resolve(null))
        .catch((e: AxiosErrors) => reject(e))
    }),
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
}
