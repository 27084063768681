import { type ComputedRef, onMounted, onUnmounted, type Ref } from 'vue'
import { registerModals } from '@/services/Modal'
import {
  CREATE_ASSIGNMENT_SOURCE_FILE_ERRORS,
  CREATE_NEW_CUSTOMER,
  REQUEST_ERROR,
  SENDER_ADDRESS_ACTION,
} from '@/const/ModalTypes'
import { CONSOLIDATE, SINGLE } from '@/const/AssignmentTypes'
import AssignmentProxy from '@/proxies/AssignmentProxy'
import { useRouter } from 'vue-router'
import type { AssignmentData } from '@/views/Components/Assignment/AssignmentForm/types'
import { useStore } from 'vuex'

type Props = {
  assignmentId: ComputedRef<string | string[]>
  assignment: Ref<AssignmentData>
  fetchSenderAddresses: () => void
}

export const useOnLifecycle = ({ assignmentId, assignment, fetchSenderAddresses }: Props) => {
  const router = useRouter()
  const store = useStore()

  const proxy = new AssignmentProxy()

  onMounted(() => {
    registerModals(REQUEST_ERROR)
    registerModals(CREATE_NEW_CUSTOMER)
    registerModals(SENDER_ADDRESS_ACTION)
    registerModals(CREATE_ASSIGNMENT_SOURCE_FILE_ERRORS)
    if (assignmentId.value) {
      proxy.find(String(assignmentId.value)).then((val) => {
        assignment.value = Object.assign(
          { ...val.data },
          {
            item: assignment.value.item,
            customer_id: val.data.user?.id || assignment.value.customer_id,
            sender_address_id: val.data.senderAddress.id || assignment.value.sender_address_id,
            service_level: val.data.serviceLevel,
            distribution_channel: val.data.distributionChannel,
            file: val.data.sourceFiles[0] || { name: '', data: '' },
          },
        )

        fetchSenderAddresses()
      })
    } else {
      assignment.value.assignment_type =
        router.currentRoute.value.query?.assignmentType === SINGLE ? SINGLE : CONSOLIDATE
      fetchSenderAddresses()
    }
  })

  onUnmounted(() => {
    store.dispatch('customers/senderAddresses/resetSenderAddresses')
  })
}
