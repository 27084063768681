<template>
  <BaseLabel
    v-if="hiddenPrices"
    :id="labelId"
    :short-text="shortText"
    :tooltip-text="tooltipText"
    :class-name="className"
  />
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import BaseLabel from '@/views/Components/Labels/BaseLabel.vue'

export default defineComponent({
  name: 'HiddenPrices',
  components: { BaseLabel },
  props: {
    hiddenPrices: {
      type: Boolean,
      required: true,
    },
    labelId: {
      type: String,
      required: true,
    },
  },
  setup() {
    const shortText = computed(() => 'HP')
    const tooltipText = computed(() => 'Prices are hidden from assignment owner')
    const className = computed(() => 'hidden-prices')

    return {
      shortText,
      tooltipText,
      className,
    }
  },
})
</script>
