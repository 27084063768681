<template>
  <modal-wrap
    :id="id"
    ref="integrationsWoocommerceKeys"
    :name="modalName"
    :header-class="'integrations-woocommerce-keys-header'"
    :hide-footer="true"
    body-class="integrations-woocommerce-keys-modal"
    size="lg"
  >
    <template #modal-title>
      <div class="d-flex justify-content-between align-items-center">
        <h4 class="mb-0">
          {{ modalTitle }}
        </h4>
      </div>
    </template>
    <template #default>
      <b-form-group>
        <div class="form-group">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label><b>Credentials</b></label>
                <div>
                  Secrets could be found in WooCommerce admin panel:<br />
                  WooCommerce &rarr; Settings &rarr; Advanced &rarr; REST API
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label><b>Webhook URL (optional)</b></label>
                <div>
                  {{ webhooksUrl }}
                  <CopyToClipboard :text="webhooksUrl">
                    <button class="fa-copy fa" title="Copy" />
                  </CopyToClipboard>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Site Url</label>
                <VInput v-model="url" type="text" name="url" :rules="{ required: true, min: 10 }" />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Key</label>
                <VInput
                  v-model="key"
                  type="text"
                  name="key"
                  :rules="{ required: true, min: 36 }"
                  placeholder="ck_..."
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Secret</label>
                <VInput
                  v-model="secret"
                  type="text"
                  name="secret"
                  :rules="{ required: true, min: 36 }"
                  placeholder="cs_..."
                />
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-between align-items-center">
            <h4 class="mb-0">Parameters</h4>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <div class="label required fs-xl">Import Type</div>
                <div class="row">
                  <div class="col-12 col-md-6">
                    <input
                      id="import_type_scheduled"
                      v-model="importType"
                      type="radio"
                      value="scheduled"
                      :checked="importType === 'scheduled'"
                    />
                    <label for="import_type_scheduled" class="radio-group">
                      <span class="radio-title">Scheduled</span>
                    </label>
                  </div>
                  <div class="col-12 col-md-6">
                    <input
                      id="import_type_on_demand"
                      v-model="importType"
                      type="radio"
                      value="on_demand"
                      :checked="importType === 'on_demand'"
                    />
                    <label for="import_type_on_demand" class="radio-group">
                      <span class="radio-title">On Demand</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Interval (minutes)</label>
                <VInput
                  v-model="importInterval"
                  type="text"
                  name="import_interval"
                  :formatter="numbersOnly"
                  :rules="{ required: true, min: 1 }"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Default Weight (g)</label>
                <VInput
                  v-model="default_weight"
                  type="text"
                  name="default_weight"
                  placeholder="Default Weight"
                  :formatter="numbersOnly"
                  :rules="{ required: true, min: 1 }"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Default Height (cm)</label>
                <VInput
                  v-model="default_height"
                  type="text"
                  name="default_height"
                  placeholder="Default Height"
                  :formatter="numbersOnly"
                  :rules="{ required: true, min: 1 }"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Default Width (cm)</label>
                <VInput
                  v-model="default_width"
                  type="text"
                  name="default_width"
                  placeholder="Default Width"
                  :formatter="numbersOnly"
                  :rules="{ required: true, min: 1 }"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Default Length (cm)</label>
                <VInput
                  v-model="default_length"
                  type="text"
                  name="default_length"
                  placeholder="Default Length"
                  :formatter="numbersOnly"
                  :rules="{ required: true, min: 1 }"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <b-form-checkbox v-model="active" :value="active"> Active </b-form-checkbox>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <button type="button" class="btn create-btn btn-green" :disabled="isButtonDisabled" @click="connect">
            Connect
          </button>
        </div>
      </b-form-group>
    </template>
  </modal-wrap>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { notify } from '@kyvg/vue3-notification'
import { openModal } from '@/services/Modal'
import { numbersOnly } from '@/services/Helpers'
import { INTEGRATIONS, WOO_COMMERCE_KEYS } from '@/const/ModalTypes'
import ModalWrap from '@/views/Modals/ModalWrap.vue'
import VInput from '@/views/Components/Elements/Form/VInput.vue'
import CopyToClipboard from '@/views/Components/Elements/CopyToClipboard.vue'

export default defineComponent({
  name: 'IntegrationsWoocommerceKeysModal',
  components: {
    ModalWrap,
    VInput,
    CopyToClipboard,
  },
  props: {
    id: {
      type: String,
      default: '',
      required: true,
    },
  },
  setup() {
    const store = useStore()
    const url = ref('')
    const key = ref('')
    const secret = ref('')
    const importInterval = ref(0)
    const importType = ref('')
    const active = ref(true)
    const default_weight = ref(500)
    const default_length = ref(34)
    const default_width = ref(24)
    const default_height = ref(7)
    const isButtonDisabled = ref(false)

    const modalName = WOO_COMMERCE_KEYS

    const modalData = computed(() => store.getters['vueNextModal/modalProps'](modalName) || {})
    const customerId = computed(() => modalData.value.customerId || '')
    const modalTitle = 'WooCommerce keys configuration'
    const webhooksUrl = computed(
      () =>
        `https://service-importer-orders.pacsortonline.com/api/v1/webhook/woocommerce/?client_internal_id=${customerId.value}`,
    )

    const closeModal = () => {
      store.dispatch('vueNextModal/closeModal', INTEGRATIONS)
      store.dispatch('vueNextModal/closeModal', modalName)
      store.dispatch('customers/integrations/fetch', customerId.value).then((res) => {
        openModal(INTEGRATIONS, {
          ...store.getters['vueNextModal/modalProps'](INTEGRATIONS),
          integrations: res.data || [],
        })
      })
    }

    const connect = () => {
      if (isButtonDisabled.value) return
      isButtonDisabled.value = true
      store
        .dispatch('customers/integrations/connectWoocommerce', {
          customerId: customerId.value,
          credentials: {
            url: url.value,
            key: key.value,
            secret: secret.value,
          },
          options: {
            import_interval: importInterval.value,
            import_type: importType.value,
            active: active.value,
            default_weight: default_weight.value,
            default_length: default_length.value,
            default_width: default_width.value,
            default_height: default_height.value,
          },
        })
        .then((response) => {
          if (response.message) {
            notify({ group: 'error', text: response.message })
            isButtonDisabled.value = false
          } else {
            notify({
              group: 'success',
              text: 'The integration has been connected.',
            })
            closeModal()
          }
        })
        .catch((e) => {
          isButtonDisabled.value = false
          notify({
            group: 'error',
            text: `The integration has not been connected. ${e.message}`,
          })
        })
    }

    watch(customerId, () => {
      url.value = modalData.value.url
      key.value = modalData.value.key
      secret.value = modalData.value.secret
      importInterval.value = modalData.value.importInterval
      importType.value = modalData.value.importType
      active.value = modalData.value.active
      default_weight.value = modalData.value.default_weight
      default_length.value = modalData.value.default_length
      default_width.value = modalData.value.default_width
      default_height.value = modalData.value.default_height
    })

    return {
      url,
      key,
      secret,
      importInterval,
      importType,
      active,
      default_weight,
      default_length,
      default_width,
      default_height,
      isButtonDisabled,
      modalName,
      modalTitle,
      webhooksUrl,
      numbersOnly,
      closeModal,
      connect,
    }
  },
})
</script>

<style lang="scss" scoped>
.integrations-woocommerce-keys-modal .radio-group {
  padding: 10px 16px 10px 44px;
  margin: 6px 0;
}
.integrations-woocommerce-keys-modal .radio-group::before {
  top: 12px;
}
</style>
