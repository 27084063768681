<template>
  <div class="mb-4 col-sm-12 col-lg-6">
    <div class="faq-heading d-flex justify-content-between pl-lg-2">
      <div class="col-11 d-flex align-items-center pl-0 pr-0 pl-lg-2">
        <button class="bg-transparent" @click="toggle">
          <mdicon
            :id="'dropdown-button' + id"
            :name="isOpen ? 'minus' : 'plus'"
            size="14"
            :style="isOpen ? { transform: 'rotate(180deg)' } : {}"
            class="dropdown-green"
          />
        </button>
        <h2 class="pointer faq-title mb-0 col-11" @click="toggle">
          {{ question }}
        </h2>
      </div>
      <span class="d-flex mar-9">
        <span @click="openDeleteModal">
          <b-icon v-if="authenticated && isStaff" icon="trash" scale="1.5em" class="pointer text-danger mr-3" />
        </span>
        <span @click="editQuestion">
          <b-icon v-if="authenticated && isStaff" icon="pencil" scale="1.5em" class="pointer text-success" />
        </span>
      </span>
    </div>
    <div v-if="isOpen" class="pt-4 pr-5">
      <div class="mb-4 pl-5">
        <div>
          <div>
            <div>
              <span class="faq-answer">{{ answer }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { openModal } from '@/services/Modal'
import { FAQ_EDIT } from '@/types/router'

export default defineComponent({
  name: 'QuestionAnswer',
  props: {
    id: {
      type: Number,
      required: true,
    },
    question: {
      type: String,
      required: true,
    },
    answer: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isOpen = ref(false)
    const store = useStore()
    const router = useRouter()

    const isStaff = computed(() => store.getters['account/isStaff'])
    const authenticated = computed(() => store.getters['isLoggedIn'])

    const toggle = () => {
      isOpen.value = !isOpen.value
    }

    const openDeleteModal = () => {
      openModal('confirmWrap', {
        title: 'Are you sure you want to delete this questions?',
        message: 'This action cannot be undone.',
        onConfirm: () => {
          store.dispatch('faq/deleteQuestion', props.id)
        },
      })
    }

    const editQuestion = () => {
      router.push({
        name: FAQ_EDIT,
        params: {
          id: props.id,
        },
      })
    }

    return {
      isOpen,
      isStaff,
      authenticated,
      toggle,
      openDeleteModal,
      editQuestion,
    }
  },
})
</script>

<style>
.faq-answer {
  font-size: 14px;
}
.mar-9 {
  margin-top: 9px;
}
</style>
